import { FC } from 'react';
import RadioButton, { IRadioButtonProps } from '../RadioButton/RadioButton';
import { Row } from '../Row/Row';
import { CSSProperties, useTheme } from 'styled-components';

interface IOwnProps
  extends Pick<
    CSSProperties,
    'flex' | 'flexDirection' | 'justifyContent' | 'alignItems' | 'alignSelf'
  > {
  data: IRadioButtonProps[];
}

const RadioButtons: FC<IOwnProps> = ({
  data,
  flex,
  flexDirection,
  justifyContent,
  alignItems,
  alignSelf,
}) => {
  const theme = useTheme();

  return (
    <Row
      gap={theme.spacing.m}
      flex={flex}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignSelf={alignSelf}
    >
      {data.map((item) => (
        <RadioButton key={item.id} {...item} />
      ))}
    </Row>
  );
};

export default RadioButtons;
