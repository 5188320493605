import useExternalInvoiceContactFromSearchContact from 'hooks/useExternalInvoiceContactFromSearchContact';
import { useState, useEffect } from 'react';
import { getInvoiceDetailsFromAttachments } from 'services/firebase/invoices';
import { IInvoiceFromSearch, IRecipient } from 'types';
import { errorHandler } from 'utils/errors';
import { getSourceSystemContactFromInvoiceFromSearch } from 'utils/invoices';

const useInvoiceRecipientPrePopulatedValues = (
  invoice: IInvoiceFromSearch | null
) => {
  const {
    isLoadingContact,
    recipientFromInvoiceExternalSystem,
    setRecipientFromInvoiceExternalSystem,
  } = useExternalInvoiceContactFromSearchContact(invoice);
  const [recipientFromAttachments, setRecipientFromAttachments] = useState<
    Partial<IRecipient>
  >({});

  useEffect(() => {
    if (invoice?.id) {
      const fetchInvoiceDetails = async () => {
        try {
          const response = await getInvoiceDetailsFromAttachments(invoice.id);

          if (response.data.data) {
            setRecipientFromAttachments(response.data.data);
          }
        } catch (error) {
          errorHandler(error);
        }
      };

      void fetchInvoiceDetails();
    }
  }, [invoice?.id]);

  const recipientFromInvoiceFromSearch = getSourceSystemContactFromInvoiceFromSearch(
    invoice
  );

  const resetPrePopulatedValues = () => {
    setRecipientFromInvoiceExternalSystem(undefined);
    setRecipientFromAttachments({});
  };

  return {
    recipientPrePopulatedValues: {
      ...recipientFromInvoiceFromSearch,
      ...(recipientFromInvoiceExternalSystem ?? {}),
      ...recipientFromAttachments,
      currency: invoice?.currency,
    },
    recipientFromInvoiceExternalSystem,
    isLoadingContact,
    resetPrePopulatedValues,
  };
};

export default useInvoiceRecipientPrePopulatedValues;
