import { FC } from 'react';
import { Col } from '../Col/Col';
import { Row } from '../Row/Row';
import { Paragraph } from '../Typography/Typography';
import { IPlatformEventsSearchResult } from 'types/events';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';

interface IOwnProps {
  events: IPlatformEventsSearchResult[];
}

const EventsHistory: FC<IOwnProps> = ({ events }) => {
  const theme = useTheme();

  return (
    <Col gap={theme.spacing.m}>
      {events.map((event) => (
        <Row
          key={event.timestamp}
          style={{
            borderTop: `1px solid ${theme.color.greyLight_1}`,
            paddingTop: theme.spacing.m,
          }}
        >
          <Paragraph>{event.description}</Paragraph>
          <Paragraph>
            {dayjs(event.timestamp).format('DD/MM/YYYY, HH:mm:ss')}
          </Paragraph>
        </Row>
      ))}
    </Col>
  );
};

export default EventsHistory;
