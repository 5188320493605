import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';

interface GetSubscriptionSessionUrlParams {
  entityId: string;
  packageName: string;
  redirectUrl: string;
}

interface IGetSubscriptionSessionUrlReturn {
  redirectUrl: string;
}

export const getSubscriptionSessionUrl = async ({
  entityId,
  packageName,
  redirectUrl,
}: GetSubscriptionSessionUrlParams) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<IGetSubscriptionSessionUrlReturn>
  >(`/billing/create-package-session`, {
    entityId,
    packageName,
    redirectUrl,
  });

interface GetCustomerPortalUrlParams {
  entityId: string;
  redirectUrl: string;
}

interface IGetCustomerPortalUrlReturn {
  redirectUrl: string;
}

export const getCustomerPortalUrl = async ({
  entityId,
  redirectUrl,
}: GetCustomerPortalUrlParams) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IGetCustomerPortalUrlReturn>>(
    `/billing/customer-portal/${entityId}?return_url=${redirectUrl}`
  );
