import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Col, Row, StaleInfo } from 'components';
import Pill from 'components/shared/Pill/Pill';
import { IEntityInvite } from 'state/user';

interface IOwnProps {
  roles: IEntityInvite['roles'];
}

const InvitesRolesCell: FC<IOwnProps> = ({ roles }) => {
  const theme = useTheme();

  if (!roles.length) {
    return null;
  }

  if (roles.length <= 2) {
    return (
      <Row flex={1} gap={theme.spacing.s} justifyContent="flex-start">
        {roles.map((role: string) => (
          <Pill key={role} text={role ?? ''} />
        ))}
      </Row>
    );
  }

  return (
    <Row flex={1} gap={theme.spacing.s} justifyContent="flex-start">
      <Pill text={`${roles.length} Roles`} />
      <StaleInfo
        mode="hover"
        strategy="fixed"
        portal
        placement="top"
        infoSize="20px"
      >
        <Col gap={theme.spacing.xxs}>
          {roles.map((role: string) => (
            <Pill key={role} text={role ?? ''} />
          ))}
        </Col>
      </StaleInfo>
    </Row>
  );
};

export default InvitesRolesCell;
