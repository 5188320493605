import { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  Paragraph,
  Row,
  StaleInputSelect,
  StaleSwitch,
  TableSearchInput,
} from 'components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { useStoreState } from 'state';
import Filters from 'components/shared/Filters/Filters';
import { invoiceDateFilters, invoicePaymentDateFilters } from './consts';
import { getCountFromInvoicesAggregationPerFilter } from 'pages/Invoices/utils';
import useControls from '../GroupedInvoicesTable/hooks/useControls';

const Controls: FC = () => {
  const theme = useTheme();
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const {
    currenciesSelectData,
    searchInputValue,
    setSearchInputValue,
    onFilterChange,
    urlValues: { setUrlValue, tab, currency, filter, grouped },
  } = useControls();

  return (
    <Row alignSelf="stretch">
      <Row justifyContent="flex-start" gap={theme.spacing.xl} flex={1}>
        <StaleInputSelect
          selected={currency}
          data={currenciesSelectData}
          onSelect={(item) => setUrlValue({ currency: item.value })}
          id="invoices-currency"
          inputHeight="32px"
          style={{ minWidth: '132px' }}
        />

        <TableSearchInput
          placeholder="Search"
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
        />

        {tab === 'outstanding' && (
          <Row gap={theme.spacing.xs} flex={1} justifyContent="flex-start">
            <FilterButton
              onClick={() => setUrlValue({ filter: 'payables' })}
              active={filter === 'payables'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>All</Paragraph>
                <NumberCircle
                  value={getCountFromInvoicesAggregationPerFilter(
                    invoicesAggregations,
                    'payable'
                  )}
                />
              </Row>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue({
                  filter: filter !== 'overdue' ? 'overdue' : 'payables',
                })
              }
              active={filter === 'overdue'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Overdue</Paragraph>
                <NumberCircle
                  value={getCountFromInvoicesAggregationPerFilter(
                    invoicesAggregations,
                    'overdue'
                  )}
                />
              </Row>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue({
                  filter: filter !== 'dueIn14Days' ? 'dueIn14Days' : 'payables',
                })
              }
              active={filter === 'dueIn14Days'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph whiteSpace="nowrap">Due soon</Paragraph>
                <NumberCircle
                  value={getCountFromInvoicesAggregationPerFilter(
                    invoicesAggregations,
                    'dueWithin14Days'
                  )}
                />
              </Row>
            </FilterButton>
          </Row>
        )}

        <Row flex={1} gap={theme.spacing.m} justifyContent="flex-end">
          {tab === 'paid' && (
            <Filters
              initialFilters={invoicePaymentDateFilters}
              onFilterChange={onFilterChange}
            />
          )}

          {tab !== 'paid' && (
            <Filters
              initialFilters={invoiceDateFilters}
              onFilterChange={onFilterChange}
            />
          )}

          <Row gap={theme.spacing.xs}>
            <Paragraph>Grouped</Paragraph>
            <StaleSwitch
              id="grouped"
              isOn={grouped === 'true'}
              handleToggle={() =>
                setUrlValue({ grouped: grouped !== 'true' ? 'true' : '' })
              }
            />
          </Row>
        </Row>
      </Row>
    </Row>
  );
};

export default Controls;
