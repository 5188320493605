import { IRecipient } from './../types/recipients';
import {
  IIntegrationSettingsAccount,
  IIntegrationSettingsAll,
  IInvoice,
  IInvoiceFromSearch,
} from 'types';
import {
  IAccountData,
  IIntegrationStatusSummary,
  IAvailableIntegration,
} from 'types/integrations';

export const generateIntegrationEngineBankFeesAccountData = (
  account: IIntegrationSettingsAccount,
  code?: string
): IAccountData => {
  const { name } = account;
  const nameValue = !!code ? `${name} (${code})` : name;
  return {
    id: nameValue,
    value: account,
    name: nameValue,
  };
};

interface IIsContactIdsMatchWithInvoiceFromSearchParams {
  invoice: IInvoiceFromSearch;
  recipient: IRecipient;
}
export const isSourceSystemContactIdsNotMatchWithInvoiceFromSearch = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceFromSearchParams) =>
  invoice.externalRefsSourceSystemContactId &&
  invoice.externalRefsSourceSystemContactId !==
    recipient.externalRefs?.sourceSystemId;

interface IIsContactIdsMatchWithInvoiceParams {
  invoice: IInvoice;
  recipient: IRecipient;
}

export const isExternalContactIdsNotMatchWithInvoice = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceParams) =>
  invoice.externalRefs?.sourceSystemContactId &&
  invoice.externalRefs.sourceSystemContactId !==
    recipient.externalRefs?.sourceSystemId;

// TODO: We need to change this to use the IE Settings endpoint
export const getExcludedCurrenciesFromIntegrations = (
  integrationEngineSettings: IIntegrationSettingsAll
) => {
  const currenciesToExclude: string[] = [];

  if (integrationEngineSettings?.settings?.excludedCurrencies) {
    currenciesToExclude.push(
      ...integrationEngineSettings.settings.excludedCurrencies
    );
  }

  return currenciesToExclude;
};

export const isIntegrationConnected = ({
  integrationsSummary,
  integrationDoc,
}: {
  integrationsSummary: IIntegrationStatusSummary | null;
  integrationDoc: IAvailableIntegration;
}) =>
  integrationsSummary?.system === integrationDoc.system &&
  !!integrationsSummary?.connected;
