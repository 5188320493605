import { Row } from 'components';
import styled from 'styled-components';

export const EditableCellInput = styled.input`
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: 0;
  width: fit-content;
  max-width: 140px;

  &:focus,
  &:active {
    text-decoration: underline;
  }
`;

export const HeaderEditableWrapper = styled(Row)``;

export const EditButton = styled.button`
  height: 16px;
  opacity: 0;

  ${HeaderEditableWrapper}:hover & {
    opacity: 1;
  }
`;
