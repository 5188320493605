import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Title, Paragraph, StaleBtnGroup } from 'components';
import Button from 'components/shared/Button/Button';
import { StaleGetRateFailed } from './StaleGetRateFailed.styles';

interface OwnProps {
  onCancel: () => void;
  onRetry: () => void;
  onClose: () => void;
}

const StaleGetRateFailedComponent: FC<OwnProps> = ({
  onCancel,
  onRetry,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <StaleGetRateFailed>
      <div className="popup">
        <button className="close" onClick={onClose}>
          <svg width="24" height="24">
            <use xlinkHref="#cross-ico" />
          </svg>
        </button>

        <Title variant="h4" mb mbValue={theme.spacing.xs}>
          Rate is temporarily unavailable.
        </Title>

        <Paragraph>
          Please try again or cancel in order to use previous currency.
        </Paragraph>

        <StaleBtnGroup horizontal container={false}>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onRetry}>Try again</Button>
        </StaleBtnGroup>
      </div>
    </StaleGetRateFailed>
  );
};

export default StaleGetRateFailedComponent;
