import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import _orderBy from 'lodash.orderby';
import _get from 'lodash.get';
import _omit from 'lodash.omit';
import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'state';
import { Notify } from 'utils';
import { DB_DATE_FORMAT } from 'variables';

import useIsAwaitingRegistration from 'hooks/useIsAwaitingRegistration';
import {
  RECIPIENT_STATUS,
  IContact,
  ICountry,
  ICurrency,
  IRecipient,
  PAYMENT_TYPE,
  RECIPIENT_TYPE,
  TRANSFER_TYPE,
  SWIFT_TYPE,
  IPurpose,
  Nullable,
  IRecipientMeta,
  IRecipientFieldInstructions,
  TScamQuestions,
  PartialContact,
  isContact,
} from 'types';
import {
  LoaderWrapper,
  AdditionalRow,
  AccountGroupRow,
  GroupRow,
  TypesRow,
  CustomForm,
  ContactGroupCol,
  DraftWarnParagraph,
  FieldWrapper,
  SwiftCol,
  FieldRow,
  FormCol,
  CustomSubtitle,
  RadioRow,
  SwiftTypeRow,
} from './AddContact.styles';
import {
  ResponsiveCenteredContainer,
  StalePopup,
  StaleBtnGroup,
  StaleCheckboxControlled,
  StaleInput,
  StaleInputSelect,
  Loader,
  StaleInputIban,
  StaleInputSwift,
  StaleInputRoutingNumber,
  StaleInputRadioNew,
  Row,
  StaleInfo,
  StaleLimitedAccess,
  Popup,
  PermissionsChecker,
} from 'components';
import { Col } from '../Col/Col';
import Button from '../Button/Button';
import { Paragraph, Subtitle, Title } from '../Typography/Typography';
import Icon from '../Icon/Icon';
import Expansion from '../Expansion/Expansion';
import ScamAlert from './components/ScamAlert/ScamAlert';
import CreatableSelectMenu from '../CreatableSelectMenu/CreatableSelectMenu';
import {
  createCreatableSelectMenuOption,
  onValidateEmails,
} from '../CreatableSelectMenu/utils';
import { CreatableSelectMenuOption } from '../CreatableSelectMenu/types';
import StaleTooltip from '../StaleTooltip/StaleTooltip';

export type AddContactInputs = {
  recipientName: string;
  recipientEmail: string;
  recipientCity: string;
  recipientPostcode: string;
  recipientAddress: string;
  bankName: string;
  accountNumber: string;
  bicSwift: string;
  recipientCountry: ICountry;
  recipientStateOrProvince?: string;
  routingNumber?: string;
  routingNumber2?: string;
  recipientEntityType?: RECIPIENT_TYPE;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactEmail: string;
  primaryContactPhoneNumber: string;
  isSupplier: boolean;
  isBuyer: boolean;
  accountDetails: string;
  purpose: {
    name: IPurpose['description'];
    id: IPurpose['description'];
    value: IContact['purpose'];
  };
  currency: {
    name: ICurrency['name'];
    id: ICurrency['id'];
    icon: ICurrency['countryCode'];
    value: ICurrency;
  };
  transferType: TRANSFER_TYPE;
  isTrusted: boolean;
  emails: CreatableSelectMenuOption[];
  shouldSendRemittance: boolean;
};

interface OwnProps {
  metadata?: IRecipientMeta;
  initialCurrency: ICurrency;
  sellCurrency: ICurrency;
  initialTransferType: TRANSFER_TYPE;
  validateOnMount?: boolean;
  recipientForEdit?: Nullable<IContact | PartialContact>;
  onClose: () => void;
  onContinue?: () => void;
  setRecipient?: (state: IRecipient) => void;
  withDraftWarning?: boolean;
  withSaveAsDraft?: boolean;
  isForceCreate?: boolean;
  disableCurrency?: boolean;
}

const AddContact: FC<OwnProps> = ({
  recipientForEdit,
  onClose,
  metadata: initialMetadata,
  initialCurrency,
  sellCurrency,
  initialTransferType,
  validateOnMount = false,
  setRecipient,
  onContinue,
  withDraftWarning = false,
  withSaveAsDraft = true,
  isForceCreate = false,
  disableCurrency = false,
}) => {
  const {
    countries,
    countryByCode,
    getSwiftShared,
    getSwiftOurs,
  } = useStoreState(({ ReferenceDataState }) => ReferenceDataState);
  const { isEntityOnboarded } = useStoreState(({ UserState }) => UserState);
  const { currencies } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { createRecipient, updateRecipient } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { getRecipientCreationMetadata } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { getTransferPurposes } = useStoreActions(
    ({ TransfersState }) => TransfersState
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm<AddContactInputs>({
    mode: 'all',
    defaultValues: {
      ...(recipientForEdit || {}),
      isTrusted: recipientForEdit?.isTrusted,
      primaryContactFirstName: recipientForEdit?.contactFirstName,
      primaryContactLastName: recipientForEdit?.contactLastName,
      primaryContactEmail: recipientForEdit?.contactEmail,
      isBuyer: recipientForEdit?.isCustomer,
      recipientCountry: recipientForEdit?.recipientCountry
        ? countryByCode(recipientForEdit.recipientCountry) || undefined
        : undefined,
      transferType: initialTransferType,
      currency: {
        name: initialCurrency.name,
        id: initialCurrency.id,
        icon: initialCurrency.countryCode,
        value: initialCurrency,
      },
      purpose: recipientForEdit?.purpose
        ? {
            name: recipientForEdit?.purpose?.description,
            id: recipientForEdit?.purpose?.description,
            value: recipientForEdit?.purpose,
          }
        : undefined,
      emails: recipientForEdit?.contactEmail
        ? recipientForEdit.contactEmail
            .split(',')
            .map(createCreatableSelectMenuOption)
        : [],
    },
  });

  const [isSwiftInputFocused, setIsSwiftInputFocused] = useState(false);
  const [routingType, setRoutingType] = useState(
    recipientForEdit?.routingType || null
  );
  const [routingType2, setRoutingType2] = useState(
    recipientForEdit?.routingType2 || null
  );
  const [isCreatingRecipient, setIsCreatingRecipient] = useState(false);
  const [isCreatingDraftRecipient, setIsCreatingDraftRecipient] = useState(
    false
  );
  const [countriesSearchValue, setCountriesSearchValue] = useState('');
  const [currenciesSearchValue, setCurrenciesSearchValue] = useState('');
  const [ibanBankData, setIbanBankData] = useState<any>(null);
  const [swiftBankData, setSwiftBankData] = useState<any>(null);
  const [routingNumberBankData, setRoutingNumberBankData] = useState<any>(null);
  const [accountCountry, setAccountCountry] = useState(
    recipientForEdit?.accountCountry
  );
  const [fieldsMetadata, setFieldsMetadata] = useState(initialMetadata);
  const [fieldGroups, setFieldGroups] = useState<IRecipientFieldInstructions[]>(
    []
  );
  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);
  const [isPurposesLoading, setIsPurposesLoading] = useState(true);
  const [purposes, setPurposes] = useState<any>(null);

  const recipientEntityType = watch('recipientEntityType');
  const currencyValue = watch('currency');
  const transferType = watch('transferType');
  const recipientCountry = watch('recipientCountry');
  const emails = watch('emails');
  const purposeValue = watch('purpose');
  const bicSwiftValue = watch('bicSwift');

  const isAwaitingRegistration = useIsAwaitingRegistration();

  const [openRegisterCompanyPopup, setOpenRegisterCompanyPopup] = useState(
    false
  );
  const [openDirtyPopup, setOpenDirtyPopup] = useState(false);
  const [isOpenContactDetails, setIsOpenContactDetails] = useState(false);
  const [showExtendedScamAlert, setShowExtendedScamAlert] = useState(
    !!recipientForEdit
  );

  const [isLoadingCompanyDetails, setIsLoadingCompanyDetails] = useState(true);
  const [scamAlertAnswer, setScamAlertAnswer] = useState<
    TScamQuestions | undefined
  >(recipientForEdit?.scamAlert?.answer);

  const accountGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Account Details'),
    [fieldGroups]
  );

  const paymentGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Account details'),
    [fieldGroups]
  );

  const swiftGroupFields = useMemo(
    () =>
      fieldGroups?.filter(
        (item) =>
          item.title === 'Detailed address (required for SWIFT payments)'
      ),
    [fieldGroups]
  );

  const contactGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Contact details'),
    [fieldGroups]
  );

  const swiftOurs = accountCountry
    ? getSwiftOurs(sellCurrency.code, accountCountry)
    : 0;
  const swiftShared = getSwiftShared(sellCurrency.code);

  // set recipient entity type
  useEffect(() => {
    if (!recipientEntityType && fieldsMetadata) {
      const defaultRecipientType = fieldsMetadata?.company
        ? RECIPIENT_TYPE.company
        : RECIPIENT_TYPE.individual;

      setValue('recipientEntityType', defaultRecipientType);
    } else if (
      !recipientEntityType &&
      !fieldsMetadata?.company &&
      recipientEntityType === RECIPIENT_TYPE.company
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.individual);
    } else if (
      !recipientEntityType &&
      !fieldsMetadata?.individual &&
      recipientEntityType === RECIPIENT_TYPE.individual
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    } else if (
      recipientEntityType === RECIPIENT_TYPE.individual &&
      fieldsMetadata &&
      !fieldsMetadata?.individual
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    } else if (
      recipientEntityType === RECIPIENT_TYPE.company &&
      fieldsMetadata &&
      !fieldsMetadata?.company
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    }
  }, [fieldsMetadata, recipientEntityType, setValue]);

  // update account country based on bank details
  useEffect(() => {
    const bankDetails = swiftBankData || ibanBankData || routingNumberBankData;
    const bankDetailsCountry = _get(
      bankDetails,
      'bankCountry_ISO',
      ''
    ).toLowerCase();

    if (bankDetails && bankDetailsCountry !== accountCountry) {
      setAccountCountry(bankDetailsCountry);
    }
  }, [swiftBankData, ibanBankData, routingNumberBankData, accountCountry]);

  // set SWIFT automatically when we have it inside routing bank details
  useEffect(() => {
    if (!bicSwiftValue && routingNumberBankData && !isSwiftInputFocused) {
      setValue('bicSwift', routingNumberBankData.bicSwift, {
        shouldValidate: true,
      });
    }
  }, [routingNumberBankData, bicSwiftValue, isSwiftInputFocused, setValue]);

  // set SWIFT automatically when we have it inside iban bank details
  useEffect(() => {
    if (!bicSwiftValue && ibanBankData && !isSwiftInputFocused) {
      setValue('bicSwift', ibanBankData.bicSwift, {
        shouldValidate: true,
      });
    }
  }, [ibanBankData, bicSwiftValue, isSwiftInputFocused, setValue]);

  // show different fields based on conditions
  useEffect(() => {
    if (fieldsMetadata && recipientEntityType) {
      const metadataForUse =
        fieldsMetadata[recipientEntityType]?.[transferType];

      if (metadataForUse) {
        // @ts-expect-error TS(2345) FIXME: Argument of type '(IRecipientFieldInstructions[] |... Remove this comment to see the full error message
        setFieldGroups(_orderBy(Object.values(metadataForUse), 'order'));
      }
    }
  }, [recipientEntityType, transferType, fieldsMetadata]);

  useEffect(() => {
    setIsLoadingMetadata(true);

    getRecipientCreationMetadata({
      accountCountry: accountCountry,
      currency: currencyValue.value.code,
    })
      .then((data: any) => {
        if (!data) {
          return;
        }

        setFieldsMetadata(data);
      })
      .catch()
      .finally(() => {
        setIsLoadingMetadata(false);
      });
  }, [getRecipientCreationMetadata, currencyValue, accountCountry]);

  const canBeRegular = useMemo(
    () =>
      !!(
        _get(fieldsMetadata, 'company.regular', null) ||
        _get(fieldsMetadata, 'individual.regular', null)
      ),
    [fieldsMetadata]
  );

  const canBeSwift = useMemo(
    () =>
      !!(
        _get(fieldsMetadata, 'company.priority', null) ||
        _get(fieldsMetadata, 'individual.priority', null)
      ),
    [fieldsMetadata]
  );

  const purposesToUse = useMemo(
    () =>
      purposes?.[recipientEntityType ?? '']?.map((item: any) => ({
        id: item.description,
        value: item,
        name: item.description,
      })),
    [purposes, recipientEntityType]
  );

  useEffect(() => {
    getTransferPurposes({
      currency: currencyValue?.value?.code,
      accountCountry,
    })
      .then(({ data }: any) => {
        setPurposes(data);
      })
      .catch((error: any) => console.log(error))
      .finally(() => {
        setIsPurposesLoading(false);
      });
  }, [getTransferPurposes, setPurposes, accountCountry, currencyValue]);

  useEffect(() => {
    if (!canBeSwift && canBeRegular) {
      setValue('transferType', TRANSFER_TYPE.regular);
    } else if (!canBeRegular && canBeSwift) {
      setValue('transferType', TRANSFER_TYPE.priority);
    }
  }, [canBeRegular, canBeSwift, setValue]);

  useEffect(() => {
    if (!recipientForEdit && currencyValue.id !== 'GBP') {
      if (currencyValue.id === 'EUR') {
        setValue('transferType', TRANSFER_TYPE.regular);
      } else {
        setValue('transferType', TRANSFER_TYPE.priority);
      }
    }
  }, [currencyValue, recipientForEdit, setValue]);

  const isCompanyType = recipientEntityType === RECIPIENT_TYPE.company;
  const orderedCountries = useMemo(() => _orderBy(countries, 'name', 'asc'), [
    countries,
  ]);
  const isRegular = useMemo(() => transferType === TRANSFER_TYPE.regular, [
    transferType,
  ]);
  const isRegularEUR = useMemo(
    () =>
      currencyValue.value.code === 'EUR' &&
      transferType === TRANSFER_TYPE.regular,
    [currencyValue, transferType]
  );

  const isSaveAsDraftButtonDisabled =
    isLoadingMetadata ||
    isLoadingCompanyDetails ||
    isSubmitting ||
    isCreatingDraftRecipient ||
    isCreatingRecipient;

  const isSaveFullContactButtonDisabled =
    isSaveAsDraftButtonDisabled || !isEntityOnboarded;

  const onSubmit = async (values: AddContactInputs, isDraft = false) => {
    const {
      recipientName,
      routingNumber,
      routingNumber2,
      accountNumber,
      bicSwift,
      bankName,
      recipientCountry,
      currency,
      purpose,
      isBuyer,
      isSupplier,
      shouldSendRemittance,
      isTrusted,
      emails,
      ...restValues
    } = values;

    let accountDetails: Record<string, unknown>;

    if (!isCompanyType && recipientName.trim().indexOf(' ') === -1) {
      Notify.info('Please enter your both first name and last name.');
      return;
    }

    if (!isDraft && !scamAlertAnswer && showExtendedScamAlert) {
      Notify.info(
        'Please check which steps did you take to verify payment details for this recipient.'
      );
      return;
    }

    if (isRegularEUR) {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.iban],
        accountNumber,
        accountCountry,
      };
    } else if (isRegular) {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.local],
        routingType,
        routingNumber,
        routingType2,
        routingNumber2,
        accountNumber,
        bicSwift,
        accountCountry,
      };
    } else {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.swift],
        routingType,
        routingNumber,
        accountNumber,
        bicSwift,
        bankName,
        accountCountry,
      };
    }

    // USD and GBP usecases
    if (currency.value.code === 'USD' || currency.value.code === 'GBP') {
      accountDetails = {
        routingType,
        paymentType:
          transferType === TRANSFER_TYPE.priority
            ? PAYMENT_TYPE[PAYMENT_TYPE.swift]
            : PAYMENT_TYPE.local,
        routingNumber,
        accountNumber,
        bicSwift,
        bankName,
        accountCountry,
      };
    }

    if (!accountDetails) {
      Notify.error('Account details not correct.');
      return;
    }

    if (isDraft) {
      setIsCreatingDraftRecipient(true);
    } else {
      setIsCreatingRecipient(true);
    }

    const recipientData: IRecipient = {
      currency: currency.value.code,
      recipientEntityType,
      recipientCountry: recipientCountry?.alpha2,
      recipientName,
      isCustomer: !!isBuyer,
      isSupplier: !!isSupplier,
      shouldSendRemittance,
      purpose: purpose?.value,
      isTrusted,
      contactEmail: emails?.map((email) => email.value).join(','),
      // account details are read only, rework later when we have correct inputs and can omit react-hook-form
      ...(_omit(restValues, 'accountDetails', 'transferType') as any),
      ...accountDetails,
    };

    if (scamAlertAnswer) {
      recipientData.scamAlert = {
        answer: scamAlertAnswer,
        date: dayjs().format(DB_DATE_FORMAT),
      };
    }

    recipientData.externalRefs = {
      sourceSystem: recipientForEdit?.externalRefs?.sourceSystem,
      sourceSystemId: recipientForEdit?.externalRefs?.sourceSystemId,
    };

    if (isContact(recipientForEdit) && !isForceCreate) {
      // update recipient
      const response = await updateRecipient({
        recipientData,
        recipientId: recipientForEdit.id,
        isDraft,
      });

      if (isDraft) {
        setIsCreatingDraftRecipient(false);
      } else {
        setIsCreatingRecipient(false);
      }

      if (response && response.success) {
        const { id, data: newRecipient } = response;

        setRecipient?.({ ...newRecipient, id });
        onClose();
      }
    } else {
      const response = await createRecipient({
        recipient: recipientData,
        isDraft,
      });

      if (isDraft) {
        setIsCreatingDraftRecipient(false);
      } else {
        setIsCreatingRecipient(false);
      }

      if (response && response.success) {
        const { id, data: newRecipient } = response;
        if (!newRecipient.enabled) {
          onClose();
        } else {
          setRecipient?.({ ...newRecipient, id });
          // TODO: remove this hack later when we will take transfer type from recipient
          // setTransferTypeFromProps(transferType);
          if (onContinue) {
            onContinue();
          } else {
            onClose();
          }
        }
      }
    }
  };

  const onSaveAsDraft = async () => {
    clearErrors();

    const values = watch();
    let isValid = true;

    const valuesToValidate = Object.entries(values).reduce<string[]>(
      (total, [key, value]) => {
        if ((key === 'accountNumber' || key === 'bicSwift') && value) {
          total.push(key);
        }

        return total;
      },
      []
    );

    if (valuesToValidate.length) {
      isValid = await trigger(valuesToValidate);
    }

    if (isValid) {
      onSubmit(values, true);
    }
  };

  const generateField = (field: IRecipientFieldInstructions) => {
    if (
      (field.readOnly && !recipientForEdit) ||
      (!watch(field.name) && field.hideIfEmpty)
    ) {
      return null;
    }

    switch (field.type) {
      case 'IBAN':
        return (
          <StaleInputIban
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            name={field.name}
            control={control}
            rules={{
              required: field.required,
              minLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.minLength,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.maxLength,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setIbanBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            swiftCountryCode={swiftBankData?.bankCountry_ISO}
          />
        );
      case 'BICSWIFT':
        return (
          <StaleInputSwift
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            name={field.name}
            control={control}
            onChangeCallback={(e) => {
              setValue(field.name, e.target.value.toUpperCase().trim());
            }}
            rules={{
              required: field.required,
              minLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.minLength,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.maxLength,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setSwiftBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            onFocus={() => setIsSwiftInputFocused(true)}
            onBlur={() => setIsSwiftInputFocused(false)}
            ibanCountryCode={ibanBankData?.bankCountry_ISO}
            routingNumberCountryCode={routingNumberBankData?.bankCountry_ISO}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
          />
        );
      case 'ROUTING':
        return (
          <StaleInputRoutingNumber
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            name={field.name}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            control={control}
            onChangeCallback={() => {
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              setRoutingType(field.routingType);
            }}
            rules={{
              required: field.required,
              minLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.minLength,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.maxLength,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setRoutingNumberBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            routingType={field.routingType ?? ''}
            swiftCountryCode={swiftBankData?.bankCountry_ISO}
          />
        );
      case 'ROUTING2':
        return (
          <StaleInputRoutingNumber
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            name={field.name}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            control={control}
            onChangeCallback={() => {
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              setRoutingType2(field.routingType);
            }}
            rules={{
              required: field.required,
              minLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.minLength,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.maxLength,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setRoutingNumberBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            routingType={field.routingType ?? ''}
            swiftCountryCode={swiftBankData?.bankCountry_ISO}
          />
        );
      case 'COUNTRY':
        return (
          <Controller
            id={field.name}
            name={field.name}
            control={control}
            defaultValue={null}
            rules={{
              required: field.required,
              minLength: {
                value: field.minLength ?? 0,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                value: field.maxLength ?? 0,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            render={({ onChange, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label={field.title}
                  view="moving"
                  data={orderedCountries
                    .filter((item) =>
                      item.name
                        .toLowerCase()
                        .trim()
                        .includes(countriesSearchValue.toLowerCase().trim())
                    )
                    .map((item) => ({
                      name: item.name,
                      id: item.name,
                      icon: item.alpha2.toLowerCase(),
                      value: { ...item, id: item.alpha2.toUpperCase() },
                    }))}
                  selected={
                    recipientCountry
                      ? {
                          name: recipientCountry.name,
                          id: recipientCountry.name,
                          icon: recipientCountry.alpha2.toLowerCase(),
                          value: {
                            ...recipientCountry,
                            id: recipientCountry.alpha2.toUpperCase(),
                          },
                        }
                      : null
                  }
                  onSelect={(item) => {
                    onChange(item.value);
                  }}
                  withSearch
                  searchValue={countriesSearchValue}
                  onSearch={(e) =>
                    setCountriesSearchValue(e.currentTarget.value)
                  }
                  onClose={() => setCountriesSearchValue('')}
                  strategy="fixed"
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  error={errors[field.name]?.message}
                />
              );
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
          />
        );
      case 'CURRENCY':
        return (
          <Controller
            id={field.name}
            name={field.name}
            control={control}
            rules={{
              required: field.required,
              minLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.minLength,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.maxLength,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            render={({ onChange, value, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label={field.title}
                  view="moving"
                  data={currencies
                    .filter(
                      (item) =>
                        item.name
                          .toLowerCase()
                          .trim()
                          .includes(
                            currenciesSearchValue.toLowerCase().trim()
                          ) ||
                        item.code
                          .toLowerCase()
                          .trim()
                          .includes(
                            currenciesSearchValue.toLowerCase().trim()
                          ) ||
                        item.country
                          .toLowerCase()
                          .trim()
                          .includes(currenciesSearchValue.toLowerCase().trim())
                    )
                    .map((item) => ({
                      name: item.id ?? '',
                      id: item.id ?? '',
                      icon: item.countryCode,
                      value: item,
                    }))}
                  // TODO: replace later when we use Add Contact not just for invoices
                  selected={value}
                  onSelect={(item) => {
                    // We need to reset the data after a new currency is selected
                    setSwiftBankData(null);
                    setIbanBankData(null);
                    setRoutingNumberBankData(null);
                    setAccountCountry(undefined);

                    onChange(item);
                  }}
                  withSearch
                  searchValue={currenciesSearchValue}
                  onSearch={(e) =>
                    setCurrenciesSearchValue(e.currentTarget.value)
                  }
                  onClose={() => setCurrenciesSearchValue('')}
                  strategy="fixed"
                  disabled={
                    disableCurrency ||
                    (!!recipientForEdit &&
                      recipientForEdit.status !== RECIPIENT_STATUS.draft)
                  }
                />
              );
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
          />
        );
      case 'SWIFTTYPE':
        return (
          <SwiftTypeRow>
            <Paragraph>{field.title}</Paragraph>
            <StaleInfo mode="hover" strategy="fixed" placement="top">
              <Subtitle variant="bold">SWIFT charges</Subtitle>
              <Paragraph color="white" mb variant="bold">
                Shared
              </Paragraph>
              <Paragraph color="white">
                The intermediary bank charges are deducted from the payment
                amount. The payment amount received in the beneficiary bank
                account may be less than the full amount expected.
              </Paragraph>

              <Paragraph mb mt variant="bold" color="white">
                Ours
              </Paragraph>
              <Paragraph color="white">
                The intermediary bank charges are covered by us and not deducted
                from the payment amount. The beneficiary bank receives the full
                payment amount.
              </Paragraph>
            </StaleInfo>

            <Controller
              name={field.name}
              control={control}
              defaultValue={SWIFT_TYPE.shared}
              render={({ onChange, value }) => (
                <>
                  <StaleInputRadioNew
                    id="shared"
                    label={`Shared | ${sellCurrency.symbol}${swiftShared}`}
                    checked={value === SWIFT_TYPE.shared}
                    onChange={() => onChange(SWIFT_TYPE.shared)}
                  />

                  <StaleInputRadioNew
                    id="ours"
                    label={
                      swiftOurs
                        ? `Ours | ${sellCurrency.symbol}${swiftOurs}`
                        : `Ours | Not Available`
                    }
                    checked={value === SWIFT_TYPE.ours}
                    onChange={() => onChange(SWIFT_TYPE.ours)}
                    disabled={!swiftOurs}
                  />
                </>
              )}
            />
          </SwiftTypeRow>
        );
      case 'PURPOSE':
        return (
          <Controller
            id={field.name}
            name={field.name}
            control={control}
            defaultValue={null}
            rules={{
              required: field.required,
            }}
            render={({ onChange, name }) => (
              <StaleInputSelect
                id={name}
                name={name}
                label={field.title}
                view="moving"
                disabled={isPurposesLoading}
                data={purposesToUse}
                selected={purposeValue}
                onSelect={(item) => onChange(item)}
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                error={errors[field.name]?.message}
              />
            )}
          />
        );
      case 'RADIO':
        return (
          <StaleCheckboxControlled
            id="shouldSendRemittance"
            name="shouldSendRemittance"
            defaultValue={false}
            disabled={!emails.length}
            control={control}
            Label={<>Send remittance emails</>}
          />
        );

      default:
        if (field.name === 'contactEmail') {
          return (
            <Controller
              name="emails"
              control={control}
              rules={{
                validate: onValidateEmails,
              }}
              render={({ value, name, onChange }) => (
                <CreatableSelectMenu
                  name={name}
                  label={field.title}
                  data={value}
                  onChange={onChange}
                  // @ts-expect-error - Property 'message' does not exist on type '(DeepMap<CreatableSelectOption, FieldError> | undefined)[]'
                  error={errors.emails?.message}
                />
              )}
            />
          );
        }
        return (
          <StaleInput
            id={field.name}
            view="moving"
            label={field.title}
            name={field.name}
            control={control}
            disabled={
              field.readOnly ||
              (field.name === 'recipientName' &&
                !!recipientForEdit &&
                recipientForEdit.status !== RECIPIENT_STATUS.draft)
            }
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            onChangeCallback={
              field.name === 'accountNumber'
                ? (e) => setValue(field.name, e.target.value.toUpperCase())
                : undefined
            }
            rules={{
              required: field.required,
              minLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.minLength,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                value: field.maxLength,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
          />
        );
    }
  };

  return (
    <>
      <Popup
        HeaderContent={
          <Title variant="h3">
            {recipientForEdit && !isForceCreate
              ? 'Edit contact'
              : 'Add new contact'}
          </Title>
        }
        width="954px"
        onClose={() => {
          if (
            !!Object.keys(dirtyFields).length &&
            !isAwaitingRegistration &&
            isEntityOnboarded
          ) {
            setOpenDirtyPopup(true);
          } else {
            onClose();
          }
        }}
        FooterContent={
          <PermissionsChecker
            action={recipientForEdit ? 'update' : 'create'}
            resource="recipients"
          >
            <StaleTooltip
              disabled={isEntityOnboarded}
              placement="top"
              content={
                <Paragraph color="white">
                  Only fully onboarded companies can create full contacts.
                </Paragraph>
              }
            >
              <Button
                isLoading={isCreatingRecipient || isSubmitting}
                disabled={isSaveFullContactButtonDisabled}
                form="add-contact-form"
              >
                Save contact
              </Button>
            </StaleTooltip>
            {withSaveAsDraft &&
              (!recipientForEdit || recipientForEdit?.status === 'draft') && (
                <Button
                  ml
                  variant="secondary"
                  isLoading={isCreatingDraftRecipient}
                  disabled={isSaveAsDraftButtonDisabled}
                  onClick={onSaveAsDraft}
                >
                  Save as draft
                </Button>
              )}
          </PermissionsChecker>
        }
      >
        <CustomForm
          id="add-contact-form"
          onSubmit={(event) => {
            event.preventDefault();

            if (isAwaitingRegistration) {
              setOpenRegisterCompanyPopup(true);
              return;
            }

            if (!isCreatingRecipient) {
              handleSubmit((data) => onSubmit(data))();
            }
          }}
        >
          <AdditionalRow>
            <FormCol>
              <RadioRow>
                <CustomSubtitle variant={'bold'}>
                  Account details
                </CustomSubtitle>
                <TypesRow>
                  <Controller
                    name="recipientEntityType"
                    control={control}
                    defaultValue={null}
                    render={({ onChange, value }) => (
                      <>
                        <StaleInputRadioNew
                          id="business"
                          label="Business"
                          checked={value === RECIPIENT_TYPE.company}
                          onChange={() => onChange(RECIPIENT_TYPE.company)}
                          disabled={
                            // Disable Recipient Type radio buttons when contact is not new or in DRAFT state.
                            (!!recipientForEdit &&
                              recipientForEdit.status !==
                                RECIPIENT_STATUS.draft) ||
                            !fieldsMetadata?.company
                          }
                        />
                        <StaleInputRadioNew
                          id="individual"
                          label="Individual"
                          checked={value === RECIPIENT_TYPE.individual}
                          onChange={() => onChange(RECIPIENT_TYPE.individual)}
                          disabled={
                            // Disable Recipient Type radio buttons when contact is not new or in DRAFT state.
                            (!!recipientForEdit &&
                              recipientForEdit.status !==
                                RECIPIENT_STATUS.draft) ||
                            !fieldsMetadata?.individual
                          }
                        />
                      </>
                    )}
                  />
                </TypesRow>
              </RadioRow>
              <Row>
                <AccountGroupRow>
                  {accountGroupFields &&
                    // TODO: clarify abbout correct types for that
                    // @ts-expect-error TS(2339) FIXME: Property 'fields' does not exist on type 'IRecipie... Remove this comment to see the full error message
                    _orderBy(accountGroupFields.fields, 'order').map(
                      (field) => (
                        <FieldWrapper
                          isCurrency={field.type === 'CURRENCY'}
                          accountGroup={true}
                          key={field.name}
                        >
                          {generateField(field)}
                        </FieldWrapper>
                      )
                    )}
                </AccountGroupRow>
              </Row>

              <Col>
                <RadioRow>
                  <CustomSubtitle variant="bold">
                    Payment details
                  </CustomSubtitle>
                  <TypesRow>
                    <Controller
                      name="transferType"
                      control={control}
                      defaultValue={null}
                      render={({ onChange, value }) => (
                        <>
                          <StaleInputRadioNew
                            id="transferType_local"
                            label="Local"
                            checked={value === TRANSFER_TYPE.regular}
                            onChange={() => onChange(TRANSFER_TYPE.regular)}
                            disabled={!canBeRegular}
                          />
                          <StaleInputRadioNew
                            id="transferType_swift"
                            label="SWIFT"
                            checked={value === TRANSFER_TYPE.priority}
                            onChange={() => onChange(TRANSFER_TYPE.priority)}
                            disabled={!canBeSwift}
                          />
                        </>
                      )}
                    />
                  </TypesRow>
                </RadioRow>
                <GroupRow>
                  {paymentGroupFields &&
                    // TODO: clarify abbout correct types for that
                    // @ts-expect-error TS(2339) FIXME: Property 'fields' does not exist on type 'IRecipie... Remove this comment to see the full error message
                    _orderBy(paymentGroupFields.fields, 'order').map(
                      (field) => {
                        return (
                          <FieldRow
                            key={field.name}
                            fullWidth={
                              (currencyValue?.id === 'EUR' &&
                                field.type === 'IBAN') ||
                              field.type === 'SWIFTTYPE'
                            }
                          >
                            <FieldWrapper>{generateField(field)}</FieldWrapper>
                          </FieldRow>
                        );
                      }
                    )}
                </GroupRow>
              </Col>

              {(transferType === TRANSFER_TYPE.priority ||
                !['GBP', 'EUR'].includes(currencyValue.value.code)) && (
                <SwiftCol>
                  <CustomSubtitle variant="bold">
                    Detailed address
                  </CustomSubtitle>
                  <GroupRow>
                    {swiftGroupFields?.[0] &&
                      // @ts-expect-error TS(2339) FIXME: Property 'fields' does not exist on type 'IRecipie... Remove this comment to see the full error message
                      _orderBy(swiftGroupFields[0]?.fields, 'order').map(
                        (field) => {
                          // TODO: discuss why we need this nested `div` here
                          return (
                            <FieldRow
                              key={field.name}
                              fullWidth={field.name === 'recipientAddress'}
                            >
                              <FieldWrapper>
                                {generateField(field)}
                              </FieldWrapper>
                            </FieldRow>
                          );
                        }
                      )}
                  </GroupRow>
                </SwiftCol>
              )}

              {recipientForEdit?.isTrusted && (
                <div style={{ marginTop: 14 }}>
                  <StaleCheckboxControlled
                    id="isTrusted"
                    control={control}
                    name="isTrusted"
                    Label={<Paragraph variant="bold">Trusted Payee</Paragraph>}
                  />
                </div>
              )}
            </FormCol>

            <FormCol>
              {accountGroupFields && (
                <>
                  <Row
                    mb
                    as="button"
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsOpenContactDetails((prevState) => !prevState);
                    }}
                  >
                    <Subtitle variant="bold">Contact details</Subtitle>

                    <Icon
                      icon="arrow-down"
                      style={{
                        transform: isOpenContactDetails ? '' : 'rotate(180deg)',
                      }}
                    />
                  </Row>

                  <Expansion isOpen={isOpenContactDetails}>
                    <ContactGroupCol>
                      {
                        // TODO: clarify about correct types for that
                        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                        _orderBy(contactGroupFields?.fields, 'order').map(
                          (field) => {
                            // TODO: discuss why we need this nested `div` here
                            return (
                              <FieldRow key={field.name} fullWidth>
                                <FieldWrapper>
                                  {generateField(field)}
                                </FieldWrapper>
                              </FieldRow>
                            );
                          }
                        )
                      }
                    </ContactGroupCol>
                  </Expansion>
                </>
              )}

              <ScamAlert
                setIsOpenContactDetails={setIsOpenContactDetails}
                setShowExtendedScamAlert={setShowExtendedScamAlert}
                setScamAlertAnswer={setScamAlertAnswer}
                setIsLoadingCompanyDetails={setIsLoadingCompanyDetails}
                showExtendedScamAlert={showExtendedScamAlert}
                scamAlertAnswer={scamAlertAnswer}
                recipientCountry={recipientCountry}
                recipientForEdit={recipientForEdit}
                dirtyFields={dirtyFields}
              />
            </FormCol>
          </AdditionalRow>

          {recipientForEdit &&
            recipientForEdit.status === RECIPIENT_STATUS.draft &&
            withDraftWarning && (
              <DraftWarnParagraph>
                Please note that this contact cannot be used during payments
                until all validation errors have been fixed. <br />
                <b>Status: Draft</b>
              </DraftWarnParagraph>
            )}
        </CustomForm>

        {(isLoadingMetadata || isLoadingCompanyDetails) && (
          <LoaderWrapper>
            <Loader size="large" />
          </LoaderWrapper>
        )}
      </Popup>

      {openRegisterCompanyPopup && (
        <StaleLimitedAccess
          onClose={() => setOpenRegisterCompanyPopup(false)}
        />
      )}

      {openDirtyPopup && (
        <StalePopup
          title="You have unsaved changes, do you want to:"
          theme="small"
          width="347px"
          minHeight="auto"
          onClose={() => setOpenDirtyPopup(false)}
        >
          <ResponsiveCenteredContainer style={{ padding: 0 }}>
            <StaleBtnGroup horizontal container={false}>
              <Button
                disabled={isCreatingRecipient && !isEntityOnboarded}
                onClick={() => {
                  setOpenDirtyPopup(false);
                  if (!isCreatingRecipient) {
                    handleSubmit((data) => onSubmit(data))();
                  }
                }}
              >
                Save
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Ignore
              </Button>
            </StaleBtnGroup>
          </ResponsiveCenteredContainer>
        </StalePopup>
      )}
    </>
  );
};

export default AddContact;
