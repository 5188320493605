import {
  forwardRef,
  PropsWithChildren,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { VariableSizeList } from 'react-window';

import {
  TableFooter,
  TableRow,
  TableWrapper,
  TableHead,
  TableHeaderCell,
  SortIcon,
  TableBody,
  InfiniteLoadingIndicatorWrapper,
} from './Table.styles';
import Checkbox from '../Checkbox/Checkbox';
import StaleTooltip from '../StaleTooltip/StaleTooltip';
import {
  useExpanded,
  useRowSelect,
  useSortBy,
  useTable,
  useGlobalFilter,
  useFilters,
  useFlexLayout,
  useRowState,
  CellProps,
} from 'react-table';
import ExpanderCell from './components/ExpanderCell';
import VirtualizedRow from './components/VirtualizedRow';
import NormalRow from './components/NormalRow';
import {
  CELL_DEFAULT_WIDTH,
  DEFAULT_COLUMN,
  DEFAULT_ROW_HEIGHT,
} from './consts';
import { ExposedUseTableProps, TableProps } from './types';
import InfiniteLoader from './components/InfiniteLoader';
import InlineLoader from '../InlineLoader/InlineLoader';
import Loader from '../Loader/Loader';

const Table = forwardRef(
  <T extends {}>(
    {
      columns,
      data,
      expansionRender,
      isExpandable,
      selectable = false,
      sortable = false,
      isRowDisabled,
      onRowClick,
      isRowSelectable = () => true,
      onSelect,
      disabledCheckboxHint = <></>,
      withHead = true,
      withSelectAll = false,
      globalFilter,
      manualGlobalFilter,
      disableGlobalFilter,
      renderFooterContent,
      autoResetExpanded,
      autoResetSelectedRows,
      autoResetSortBy,
      autoResetGlobalFilter,
      autoResetFilters,
      filterTypes,
      initialState,
      isVirtualized = false,
      defaultRowHeight = DEFAULT_ROW_HEIGHT,
      minRowHeight,
      minVisibleRows = 5,
      maxVisibleRows,
      withInfiniteLoading = false,
      loadingThreshold = 10,
      onLoadMoreItems,
      itemsCount = 0,
      isLoadingMoreItems = false,
      hasMoreToLoad = false,
      manualSortBy,
      onSort,
      expandOneRowAtATime = false,
      isLoading = false,
    }: PropsWithChildren<TableProps<T>>,
    ref: Ref<ExposedUseTableProps<T>>
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      setGlobalFilter,
      setFilter,
      setAllFilters,
      setSortBy,
      toggleAllRowsSelected,
      toggleRowSelected,
      state: { selectedRowIds, sortBy },
      totalColumnsWidth,
    } = useTable<T>(
      {
        initialState,
        columns,
        data,
        disableSortBy: !sortable,
        autoResetExpanded,
        autoResetSelectedRows,
        autoResetSortBy,
        autoResetGlobalFilter,
        autoResetFilters,
        globalFilter,
        manualGlobalFilter,
        disableGlobalFilter,
        filterTypes,
        defaultColumn: DEFAULT_COLUMN,
        manualSortBy,
      },
      useRowState,
      useFlexLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      useRowSelect,
      (hooks) => {
        if (selectable) {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: (props) => {
                if (withSelectAll) {
                  const {
                    toggleRowSelected,
                    rows: rowsFromHeaderProps,
                  } = props;
                  const selectableRows = rowsFromHeaderProps.filter((row) =>
                    isRowSelectable({
                      row,
                      selectedFlatRows: props.selectedFlatRows,
                    })
                  );
                  const onChange = (
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    selectableRows.forEach((row) => {
                      toggleRowSelected(row.id, event.currentTarget.checked);
                    });
                  };

                  return (
                    <Checkbox
                      onChange={onChange}
                      checked={
                        !!props.selectedFlatRows.length &&
                        props.selectedFlatRows.length === selectableRows.length
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  );
                }

                return null;
              },
              Cell: (props: any) => {
                const isSelectable = isRowSelectable({
                  row: props.row,
                  selectedFlatRows: props.selectedFlatRows,
                });

                return (
                  <StaleTooltip
                    disabled={isSelectable}
                    content={disabledCheckboxHint}
                  >
                    <Checkbox
                      disabled={!isSelectable}
                      {...props.row.getToggleRowSelectedProps()}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </StaleTooltip>
                );
              },
              width: CELL_DEFAULT_WIDTH,
              minWidth: CELL_DEFAULT_WIDTH,
              canResize: false,
            },
            ...columns,
          ]);
        }

        if (isExpandable) {
          hooks.visibleColumns.push((columns) => [
            {
              Header: () => null,
              id: 'expander',
              Cell: (cellProps: CellProps<T>) => {
                const { row } = cellProps;
                const isRowExpandable = isExpandable(row.original);

                return isRowExpandable ? (
                  <ExpanderCell<T>
                    expandOneRowAtATime={expandOneRowAtATime}
                    {...cellProps}
                  />
                ) : null;
              },
              width: CELL_DEFAULT_WIDTH,
              minWidth: CELL_DEFAULT_WIDTH,
              canResize: false,
            },
            ...columns,
          ]);
        }
      }
    );

    const tableHeadRef = useRef<HTMLTableSectionElement>(null);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const listRef = useRef<VariableSizeList>(null);

    const [tableHeadHeight, setTableHeadHeight] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(0);
    const [expandedRowSizes, setExpandedRowSizes] = useState<
      Record<string, number>
    >({});

    const minBodyHeight =
      Math.min(minVisibleRows, rows.length) * defaultRowHeight;
    const maxBodyHeight = maxVisibleRows
      ? maxVisibleRows * defaultRowHeight
      : undefined;

    const getSizes = () => {
      if (tableBodyRef.current) {
        setTableBodyHeight(tableBodyRef.current.getBoundingClientRect().height);
      }

      if (tableHeadRef.current) {
        setTableHeadHeight(tableHeadRef.current.getBoundingClientRect().height);
      }
    };

    useLayoutEffect(() => {
      getSizes();
    }, []);

    /* 
      TODO: consider moving into useImperativeHandle ⬇
    */
    useEffect(() => {
      onSelect?.(selectedRowIds, selectedFlatRows);
    }, [onSelect, selectedFlatRows, selectedRowIds]);

    useEffect(() => {
      onSort?.(sortBy);
    }, [onSort, sortBy]);

    const getSelectedFlatRows = useCallback(() => selectedFlatRows, [
      selectedFlatRows,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        setFilter,
        setGlobalFilter,
        setAllFilters,
        setSortBy,
        toggleAllRowsSelected,
        toggleRowSelected,
        getSelectedFlatRows,
      }),
      [
        setAllFilters,
        setFilter,
        setGlobalFilter,
        setSortBy,
        toggleAllRowsSelected,
        toggleRowSelected,
        getSelectedFlatRows,
      ]
    );

    const isItemLoaded = useCallback(
      (index) => !hasMoreToLoad || index < rows.length,
      [hasMoreToLoad, rows.length]
    );

    return (
      <>
        <TableWrapper
          as="table"
          isVirtualized={isVirtualized}
          {...getTableProps({
            style: {
              minHeight: minBodyHeight + tableHeadHeight,
              maxHeight: maxBodyHeight
                ? maxBodyHeight + tableHeadHeight
                : 'unset',
              position: isLoading ? 'relative' : 'unset',
            },
          })}
        >
          {withHead && (
            <TableHead as="thead" ref={tableHeadRef}>
              {headerGroups.map((headerGroup) => {
                return (
                  <TableRow as="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const { style, ...rest } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );

                      return (
                        <TableHeaderCell
                          isSelectableCell={column.id === 'selection'}
                          as="th"
                          {...rest}
                          style={style}
                        >
                          {column.canSort && (
                            <SortIcon active={column.isSorted}>
                              <use xlinkHref="#sort-table" />
                            </SortIcon>
                          )}
                          {column.render('Header')}
                        </TableHeaderCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
          )}

          <TableBody
            as="tbody"
            tableHeadHeight={tableHeadHeight}
            {...getTableBodyProps({
              style: {
                minHeight: minBodyHeight,
                maxHeight: maxBodyHeight ?? 'unset',
              },
            })}
            ref={tableBodyRef}
            isVirtualized={isVirtualized}
          >
            {isVirtualized && !!tableBodyHeight ? (
              <InfiniteLoader
                enabled={withInfiniteLoading}
                isItemLoaded={isItemLoaded}
                itemCount={hasMoreToLoad ? itemsCount + 1 : itemsCount}
                loadMoreItems={onLoadMoreItems}
                threshold={loadingThreshold}
              >
                {(infiniteLoaderProps) => {
                  const { onItemsRendered } = infiniteLoaderProps || {};

                  return (
                    <VariableSizeList
                      ref={listRef}
                      height={tableBodyHeight}
                      itemCount={rows.length}
                      itemSize={(index) => {
                        const row = rows[index];

                        if (row.isExpanded) {
                          const expansionHeight = expandedRowSizes[index];

                          if (expansionHeight) {
                            return defaultRowHeight + expansionHeight;
                          }
                        }

                        return defaultRowHeight;
                      }}
                      width="100%"
                      style={{
                        maxHeight: '100%',
                        minWidth: totalColumnsWidth,
                        overflow: 'overlay',
                      }}
                      onItemsRendered={onItemsRendered}
                    >
                      {(data) => (
                        <VirtualizedRow
                          {...data}
                          rows={rows}
                          prepareRow={prepareRow}
                          isRowDisabled={isRowDisabled}
                          onRowClick={onRowClick}
                          expansionRender={expansionRender}
                          setExpandedRowSizes={setExpandedRowSizes}
                          expandedRowSizes={expandedRowSizes}
                          listRef={listRef}
                          rowHeight={defaultRowHeight}
                        />
                      )}
                    </VariableSizeList>
                  );
                }}
              </InfiniteLoader>
            ) : (
              rows.map((row) => (
                <NormalRow
                  key={row.id}
                  row={row}
                  prepareRow={prepareRow}
                  isRowDisabled={isRowDisabled}
                  onRowClick={onRowClick}
                  expansionRender={expansionRender}
                  rowHeight={defaultRowHeight}
                  minRowHeight={minRowHeight}
                />
              ))
            )}

            {withInfiniteLoading && isLoadingMoreItems && (
              <InfiniteLoadingIndicatorWrapper>
                <InlineLoader />
              </InfiniteLoadingIndicatorWrapper>
            )}
          </TableBody>
          {isLoading && (
            <Loader
              withBackdrop
              size="large"
              style={{
                position: 'absolute',
              }}
            />
          )}
        </TableWrapper>

        {renderFooterContent && (
          <TableFooter>{renderFooterContent}</TableFooter>
        )}
      </>
    );
  }
);

export default Table as <T extends object>(
  props: TableProps<T> & { ref?: Ref<ExposedUseTableProps<T>> }
) => JSX.Element;
