/**
 * Used to create a link to save text as a file. Can be used for saving csv files, for example.
 *
 * Auto creates an anchor element, clicking on it to open the file, then finally removes the anchor element from the DOM.
 *
 * @param data string, can be base64, plain text, or csv formated text
 * @param filename name of file to save the output.
 * @param mimeType e.g. text/csv, application/octect-stream
 */
export const createAndOpenDownloadLinkToInMemoryFileData = (
  data: string,
  filename: string,
  mimeType: string
) => {
  // Initiate variables
  const id = `id-download-attachment-${filename}`;
  const file = new File([data], filename, { type: mimeType });
  const url = window.URL.createObjectURL(file);

  // Initialise DOM node with attributes
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.setAttribute('id', id);

  // Download
  a.click();

  // Clean up
  const element = document.getElementById(id);
  element?.remove();
};
