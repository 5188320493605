import { action, Action } from 'easy-peasy';

export interface PaymentRunsStateModel {
  isUpdatingPaymentRun: boolean;
  setState: Action<PaymentRunsStateModel, [string, any]>;
}

export const PaymentRunsState: PaymentRunsStateModel = {
  isUpdatingPaymentRun: false,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
};
