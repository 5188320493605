import { FC, useEffect, useMemo, useState, useRef } from 'react';
import ReactSlider from 'react-slider';
import { isMobile } from 'react-device-detect';

import { Wrapper } from './StaleStaleHowRatesNow.styles';
import {
  StaleBtnGroup,
  Subtitle,
  Paragraph,
  TextHint,
  StaleOverflowWrap,
  Overflow,
  StaleHexagonRating,
  StaleInfo,
} from 'components';
import { ICurrency } from 'types';
import { useStoreState, useStoreActions } from 'state';
import { parseIntoCurrencyString } from 'utils';
import { useDebounce } from 'hooks';
import _get from 'lodash.get';
import { HELP_URL } from 'variables';
import { Row } from '../Row/Row';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import Button from '../Button/Button';

interface OwnProps {
  sellCurrencyCode: ICurrency['code'];
  buyCurrencyCode: ICurrency['code'];
  sellCurrencySymbol: ICurrency['symbol'];
  buyCurrencySymbol: ICurrency['symbol'];
  sellAmount: number;
  buyAmount: number;
  rate: UseCurrencyRateReturnValues['rate'];
  onClose?: () => void;
}

const StaleHowRatesNow: FC<OwnProps> = (props) => {
  const {
    sellAmount,
    buyAmount,
    sellCurrencyCode,
    buyCurrencyCode,
    sellCurrencySymbol,
    onClose = () => {},
  } = props;
  const { getRateAnalyses, getPotentialSavings } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const { rateAnalyses, potentialSavings } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const debouncSellAmount = useDebounce(sellAmount, 500);
  const debounceBuyAmount = useDebounce(buyAmount, 500);

  useEffect(() => {
    if (
      buyCurrencyCode &&
      sellCurrencyCode &&
      debounceBuyAmount &&
      debouncSellAmount
    ) {
      getRateAnalyses({
        buyCurrency: buyCurrencyCode,
        sellCurrency: sellCurrencyCode,
        buyAmount: debounceBuyAmount,
        sellAmount: debouncSellAmount,
      });
    }
  }, [
    debounceBuyAmount,
    debouncSellAmount,
    sellCurrencyCode,
    buyCurrencyCode,
    getRateAnalyses,
  ]);

  useEffect(() => {
    if (sellCurrencyCode && buyCurrencyCode && debounceBuyAmount) {
      getPotentialSavings({
        buyCurrency: buyCurrencyCode,
        sellCurrency: sellCurrencyCode,
        buyAmount: debounceBuyAmount,
      });
    }
  }, [
    debounceBuyAmount,
    sellCurrencyCode,
    buyCurrencyCode,
    getPotentialSavings,
  ]);

  const [rateAnalysesActiveIndex, setRateAnalysesActiveIndex] = useState(2);
  const [
    potentialSavingsActiveIndex,
    setPotentialSavingsActiveIndex,
  ] = useState(2);

  const firstSliderRef = useRef<HTMLDivElement>(null);
  const firstThumbRef = useRef<HTMLDivElement>(null);
  const firstSliderWidth = firstSliderRef.current
    ? firstSliderRef.current.getBoundingClientRect().width
    : 0;

  const secondSliderRef = useRef<HTMLDivElement>(null);
  const secondThumbRef = useRef<HTMLDivElement>(null);
  const secondSliderWidth = secondSliderRef.current
    ? secondSliderRef.current.getBoundingClientRect().width
    : 0;

  const thirdSliderRef = useRef<HTMLDivElement>(null);
  const thirdThumbRef = useRef<HTMLDivElement>(null);
  const thirdSliderWidth = thirdSliderRef.current
    ? thirdSliderRef.current.getBoundingClientRect().width
    : 0;

  const activeRateAnalysesResult = useMemo(
    () => rateAnalyses?.data[rateAnalysesActiveIndex],
    [rateAnalyses, rateAnalysesActiveIndex]
  );
  const activePotentialSavingsResult = useMemo(
    () => potentialSavings?.data[potentialSavingsActiveIndex],
    [potentialSavings, potentialSavingsActiveIndex]
  );
  const lastPotentialSavingsResult = useMemo(
    () => potentialSavings?.data[potentialSavings.data.length - 1],
    [potentialSavings]
  );

  return (
    <StaleOverflowWrap>
      <Overflow>
        <Wrapper>
          <Row mb>
            <Subtitle>
              {`The price of ${buyCurrencyCode} is `}
              <strong>{activeRateAnalysesResult?.rateRatingText}</strong>
            </Subtitle>
            <StaleHexagonRating rating={activeRateAnalysesResult?.rateRating} />
          </Row>

          <Subtitle mb>
            <strong>
              {buyCurrencyCode}{' '}
              {parseIntoCurrencyString(
                buyAmount,
                currencyByCode(buyCurrencyCode)?.precision
              )}
              {` `}costs{` `}
              {sellCurrencyCode}{' '}
              {parseIntoCurrencyString(
                sellAmount,
                currencyByCode(sellCurrencyCode)?.precision
              )}{' '}
              today
            </strong>{' '}
          </Subtitle>

          <Paragraph>
            Compared to rates over last {rateAnalysesActiveIndex + 1} months
            this is:
          </Paragraph>

          <div
            className="rates-slider"
            style={{ borderBottom: '1px solid #F2F2F2' }}
            ref={firstSliderRef}
          >
            <ReactSlider
              value={sellAmount && sellAmount * 100}
              min={_get(activeRateAnalysesResult, 'lowest', 0) * 100}
              max={_get(activeRateAnalysesResult, 'highest', 0) * 100}
              className="cost-range"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              disabled
              renderThumb={(props) => {
                let translateX = 0;
                let thumbWidth = 0;

                if (firstThumbRef.current) {
                  thumbWidth = firstThumbRef.current.getBoundingClientRect()
                    .width;

                  const styleLeft = parseFloat(
                    props.style?.left?.toString().replace('px', '') ?? '0'
                  );

                  const newPositionLeft = thumbWidth / 2 - styleLeft - 16;
                  const newPositionRight =
                    16 - thumbWidth / 2 + firstSliderWidth - styleLeft;

                  if (
                    styleLeft + 16 < thumbWidth / 2 &&
                    translateX !== newPositionLeft
                  ) {
                    translateX = newPositionLeft;
                  } else if (
                    styleLeft - 16 + thumbWidth / 2 >
                    firstSliderWidth
                  ) {
                    translateX = newPositionRight;
                  } else {
                    translateX = 0;
                  }
                }

                return (
                  <div {...props}>
                    <div
                      ref={firstThumbRef}
                      className="tooltip"
                      style={
                        translateX
                          ? {
                              transform: `translate(calc(-50% + ${translateX}px))`,
                            }
                          : {}
                      }
                    >
                      <TextHint>Cheaper than</TextHint>
                      <div className="cheaper-than-amount">
                        <span>{`${activeRateAnalysesResult?.percentile}% `}</span>
                        <TextHint> of time</TextHint>
                      </div>
                    </div>
                  </div>
                );
              }}
            />

            <Row mb>
              <div className="col">
                <TextHint>Lowest</TextHint>
                <Paragraph variant="bold">{`${sellCurrencySymbol}${parseIntoCurrencyString(
                  activeRateAnalysesResult?.lowest,
                  currencyByCode(sellCurrencyCode)?.precision
                )}`}</Paragraph>
              </div>
              <div className="col">
                <TextHint>Highest</TextHint>
                <Paragraph variant="bold">{`${sellCurrencySymbol}${parseIntoCurrencyString(
                  activeRateAnalysesResult?.highest,
                  currencyByCode(sellCurrencyCode)?.precision
                )}`}</Paragraph>
              </div>
            </Row>

            <div className="info" style={{ marginBottom: '20px' }}>
              <StaleInfo
                placement="bottom"
                labelContent={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Paragraph>How it works?</Paragraph>
                  </div>
                }
              >
                <Paragraph variant="bold" color="white">
                  Historic price analysis – how this works?
                </Paragraph>
                <Paragraph color="white">
                  We compare the cost of your exchange today to the cost of
                  doing the same on any previous day over the last 90 days (or
                  another period selected). We use official rates from the Bank
                  of England and apply our standard exchange fees to tell you
                  how much it would cost to exchange with us in the past and how
                  today’s cost ranks in comparison.{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      HELP_URL +
                      '/hc/en-gb/articles/1500004175181-Potential-savings-from-booking-FX-rates-early'
                    }
                  >
                    Read more
                  </a>
                </Paragraph>
              </StaleInfo>
            </div>

            <Paragraph variant="bold">
              Compare against any period up to 1 year:
            </Paragraph>

            <div
              ref={secondSliderRef}
              style={{
                width: '100%',
              }}
            >
              <ReactSlider
                value={rateAnalysesActiveIndex}
                min={0}
                max={11}
                invert
                className="time-range"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                onChange={(value) => {
                  if (Array.isArray(value)) {
                    setRateAnalysesActiveIndex(value[0]);
                  } else {
                    setRateAnalysesActiveIndex(value);
                  }
                }}
                renderThumb={(props, _) => {
                  let translateX = 0;
                  let thumbWidth = 0;
                  if (secondThumbRef.current) {
                    thumbWidth = secondThumbRef.current.getBoundingClientRect()
                      .width;

                    const styleRight = parseFloat(
                      props.style?.right?.toString().replace('px', '') ?? '0'
                    );

                    const newPositionLeft =
                      secondSliderWidth - styleRight + (isMobile ? -10 : 10);

                    const newPositionRight = styleRight - thumbWidth / 2 + 26;

                    if (
                      secondSliderWidth - styleRight + 16 < thumbWidth / 2 &&
                      translateX !== newPositionLeft
                    ) {
                      translateX = newPositionLeft;
                    } else if (styleRight + 16 < thumbWidth / 2) {
                      translateX = newPositionRight;
                    } else {
                      translateX = 0;
                    }
                  }

                  return (
                    <div {...props}>
                      <div
                        className="tooltip"
                        ref={secondThumbRef}
                        style={
                          translateX
                            ? {
                                transform: `translate(calc(-50% + ${translateX}px))`,
                              }
                            : {}
                        }
                      >
                        <Paragraph variant="bold" color="white">
                          Last {rateAnalysesActiveIndex + 1} months
                        </Paragraph>
                      </div>
                    </div>
                  );
                }}
              />
            </div>

            <Row mb>
              <Paragraph variant="bold">Last year</Paragraph>
              <Paragraph variant="bold">Last month</Paragraph>
            </Row>
          </div>

          {currencyByCode(buyCurrencyCode)?.tier1 && (
            <>
              <Row mb>
                <Subtitle>
                  Potential to move is{' '}
                  <strong>
                    {activePotentialSavingsResult?.rateRatingText}
                  </strong>
                </Subtitle>
                <StaleHexagonRating
                  rating={activePotentialSavingsResult?.rateRating}
                />
              </Row>

              <Paragraph>
                <strong>
                  Some currencies can move a lot and you can save by prebooking
                  early.
                </strong>{' '}
                If exchange rates move against you, this payment could cost you
                an extra{' '}
                <strong>{`${sellCurrencySymbol}${parseIntoCurrencyString(
                  activePotentialSavingsResult?.extraCost,
                  currencyByCode(sellCurrencyCode)?.precision
                )}`}</strong>{' '}
                or more.
              </Paragraph>

              <br />

              <Paragraph>
                Choose your period to see how much rates could move:
              </Paragraph>

              <div className="rates-slider">
                <div className="gear-rates">
                  <div className="gear">
                    {lastPotentialSavingsResult &&
                      activePotentialSavingsResult && (
                        <i
                          className="arrow"
                          style={{
                            transform: `rotate(${
                              activePotentialSavingsResult.extraCost /
                              (lastPotentialSavingsResult.extraCost / 236)
                            }deg)`,
                          }}
                        />
                      )}
                  </div>
                  <div className="text">
                    <Paragraph>extra</Paragraph>
                    <p className="amount">{`${sellCurrencySymbol}${parseIntoCurrencyString(
                      activePotentialSavingsResult?.extraCost,
                      currencyByCode(sellCurrencyCode)?.precision
                    )}`}</p>
                    <Subtitle>
                      {`${activePotentialSavingsResult?.extraCostPct}%`}
                    </Subtitle>
                  </div>
                </div>

                <div className="info">
                  <StaleInfo
                    placement="bottom"
                    labelContent={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Paragraph>How it works?</Paragraph>
                      </div>
                    }
                  >
                    <Paragraph variant="bold" color="white">
                      Potential savings – how this works?
                    </Paragraph>
                    <Paragraph color="white">
                      The longer the time period you have to wait to make you
                      transfer, more time there is for exchange rates to move
                      from where they currently are. This may lead to higher
                      costs for your currency transfers. We tell you how much
                      currency rates could move over selected period using 15
                      years of historical data from the Bank of England.{` `}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          HELP_URL +
                          '/hc/en-gb/articles/1500004175181-Potential-savings-from-booking-FX-rates-early'
                        }
                      >
                        Read more
                      </a>
                    </Paragraph>
                  </StaleInfo>
                </div>

                <div
                  ref={thirdSliderRef}
                  style={{
                    width: '100%',
                  }}
                >
                  <ReactSlider
                    value={potentialSavingsActiveIndex}
                    min={0}
                    max={11}
                    className="time-range"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    onChange={(value) => {
                      if (Array.isArray(value)) {
                        setPotentialSavingsActiveIndex(value[0]);
                      } else {
                        setPotentialSavingsActiveIndex(value);
                      }
                    }}
                    renderThumb={(props) => {
                      let translateX = 0;
                      let thumbWidth = 0;
                      if (thirdThumbRef.current) {
                        thumbWidth = thirdThumbRef.current.getBoundingClientRect()
                          .width;

                        const styleLeft = parseFloat(
                          props.style?.left?.toString().replace('px', '') ?? '0'
                        );

                        const newPositionLeft = thumbWidth / 2 - styleLeft - 16;
                        const newPositionRight =
                          -16 - thumbWidth / 2 + thirdSliderWidth - styleLeft;

                        if (
                          styleLeft + 10 < thumbWidth / 2 &&
                          translateX !== newPositionLeft
                        ) {
                          translateX = newPositionLeft;
                        } else if (
                          styleLeft - 16 + thumbWidth >
                          thirdSliderWidth
                        ) {
                          translateX = newPositionRight;
                        } else {
                          translateX = 0;
                        }
                      }
                      return (
                        <div {...props}>
                          <div
                            ref={thirdThumbRef}
                            className="tooltip"
                            style={
                              translateX
                                ? {
                                    transform: `translate(calc(-50% + ${translateX}px))`,
                                  }
                                : {}
                            }
                          >
                            <Paragraph variant="bold" color="white">
                              {potentialSavingsActiveIndex + 1} month
                            </Paragraph>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <Row>
                  <Paragraph variant="bold">Week ahead</Paragraph>
                  <Paragraph variant="bold">Year ahead</Paragraph>
                </Row>
              </div>
            </>
          )}
        </Wrapper>
      </Overflow>

      {isMobile && (
        <StaleBtnGroup>
          <Button onClick={onClose}>Continue booking process</Button>
        </StaleBtnGroup>
      )}
    </StaleOverflowWrap>
  );
};

export default StaleHowRatesNow;
