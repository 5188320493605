import { ButtonCopy, Loader, Paragraph } from 'components';
import { Row } from 'components/shared/Row/Row';
import { FC, useEffect, useState } from 'react';
import { updateTransferPaymentSubmissionDetails } from 'services/firebase';
import { ITransfer } from 'types';
import { Notify } from 'utils';

interface OwnProps {
  transfer: ITransfer;
}

const MT103Details: FC<OwnProps> = ({ transfer }) => {
  const [isLoading, setIsLoading] = useState(!transfer?.mt103);
  const onCopy = () => {
    navigator.clipboard.writeText(transfer.mt103 ?? '');
    Notify.success('Copied reference to clipboard!');
  };

  useEffect(() => {
    if (!transfer.mt103) {
      updateTransferPaymentSubmissionDetails(transfer.id)
        .catch((error) =>
          console.log(
            'Failed to update transfer payment submission details. Error: ',
            error
          )
        )
        .finally(() => setIsLoading(false));
    }
  }, [transfer.id, transfer.mt103]);

  return (
    <Row flex={1} alignItems="flex-start">
      {!!transfer.mt103 && (
        <>
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: transfer.mt103.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br />'),
            }}
          />
          <ButtonCopy showIcon onClick={onCopy} />
        </>
      )}

      {isLoading && <Loader size="medium" />}

      {!isLoading && !transfer.mt103 && (
        <Paragraph>
          Cannot display MT103 at the moment, please contact support.
        </Paragraph>
      )}
    </Row>
  );
};

export default MT103Details;
