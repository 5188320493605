import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import Button from 'components/shared/Button/Button';
import { IInvoice, IRecipient, TRANSFER_TYPE } from 'types';
import { useStoreState } from 'state';
import {
  Notify,
  getCurrencyExchangePageLink,
  getLink,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import { getInvoiceRemainingAmount } from 'utils/invoices';
import { useHistory } from 'react-router';
import { StyledForm } from 'components/shared/Form/Form.styles';
import {
  AddContact,
  Col,
  Icon,
  InlineLoader,
  Paragraph,
  PermissionsChecker,
  Row,
  StaleInputConvert,
} from 'components';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import useInvoiceRecord from 'hooks/useInvoiceRecord';
import { InvoiceDetailsFooterWrapper } from './InvoiceDetailsFooter.styles';
import Field from 'components/shared/Field/Field.styles';
import { useTheme } from 'styled-components';
import { updateInvoice } from 'services/firebase/invoices';
import { getInvoiceSellAmount } from './utils';

type TInputs = {
  amount: number;
};

interface OwnProps {
  invoice: IInvoice;
  getDetailedInvoice: () => Promise<void>;
  rate: number | null;
}

const InvoiceDetailsFooter: React.FC<OwnProps> = ({
  invoice,
  rate,
  getDetailedInvoice,
}) => {
  const theme = useTheme();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { transferById } = useStoreState((state) => state.TransfersState);
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const history = useHistory();
  const [
    shouldShowAddRecipientPopup,
    setShouldShowAddRecipientPopup,
  ] = useState(false);
  const [isAddingRecipient, setIsAddingRecipient] = useState(false);
  const { handleSubmit, control, watch } = useForm<TInputs>({
    defaultValues: {
      amount: getInvoiceRemainingAmount(invoice),
    },
  });
  const amountToPay = watch('amount');

  const onPay = (isSameCurrencyTransfer: boolean) => (data: TInputs) => {
    const { amount } = data;

    let link = getLink('/app/transfer', {
      step: '1',
      invoiceId: invoice.id,
      predefinedBuyCurrency: invoice.currency,
      predefinedBuyAmount: amount.toString(),
      predefinedRateContractId: invoice.contractId,
    });

    if (isSameCurrencyTransfer) {
      link = getLink('/app/simple-transfer', {
        invoiceId: invoice.id,
        predefinedCurrency: invoice.currency,
        predefinedAmount: amount.toString(),
      });
    }

    history.push(link);
  };

  const {
    isSameCurrency,
    isCanBePaid,
    isPayable,
    submittableForReview,
    isApprovable,
    currency,
  } = useInvoiceRecord({
    record: invoice,
  });
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const entityCurrency = currencyByCode(entityCurrencyCode);
  const sellCurrency = currencyByCode(systemVariables?.defaultSellCurrency);
  const invoiceTransferIds = invoice.transferIds ?? [];
  const invoiceTransfers = invoiceTransferIds.map(transferById);
  const prebookedRateContract = rateContractById(invoice.contractId);
  const showYouPayingNow =
    getInvoiceRemainingAmount(invoice) !== Number(amountToPay);

  const {
    isUpdatingInvoicesApprovalStatus: isUpdatingInvoiceApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();

  const goToCurrencyExchange = async () => {
    const link = getCurrencyExchangePageLink({
      predefinedSellAmount: invoice.amountDue.toString(),
      predefinedSellCurrency: invoice.currency,
      predefinedRateContractId: invoice.contractId,
      step: '2',
      invoiceId: invoice.id,
    });

    history.push(link);
  };

  const onAddRecipient = async (recipient: IRecipient) => {
    try {
      setIsAddingRecipient(true);
      await updateInvoice({
        id: invoice.id,
        data: {
          contactId: recipient.id,
        },
        updateForSameContact: true,
      });
      await getDetailedInvoice();
      setIsAddingRecipient(false);
    } catch (error: any) {
      Notify.error(`Failed to add recipient. Error: ${error}`);
      setIsAddingRecipient(false);
    }
  };

  const renderActionButtons = () => {
    return (
      <>
        {currency && isPayable && isCanBePaid && (
          <StyledForm
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            gap={theme.spacing.m}
          >
            <Field
              flexDirection="column"
              style={{
                maxWidth: 200,
              }}
            >
              <Controller
                control={control}
                name="amount"
                rules={{
                  max: {
                    value: getInvoiceRemainingAmount(invoice),
                    message: 'Amount is too high',
                  },
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <StaleInputConvert
                    id="amount"
                    value={value}
                    onChange={onChange}
                    currencies={[currency]}
                    selectedValue={currency}
                    readOnlyCurrency
                    hideSelectButton
                    error={error?.message}
                  />
                )}
              />
            </Field>
            {!isSameCurrency && (
              <Button
                onClick={handleSubmit(onPay(false))}
              >{`Pay in ${entityCurrencyCode}`}</Button>
            )}
            <Button
              variant={isSameCurrency ? 'primary' : 'secondary'}
              onClick={handleSubmit(onPay(true))}
            >{`Pay in ${invoice.currency}`}</Button>
          </StyledForm>
        )}

        {invoice.contractId && !isPayable && (
          <PermissionsChecker action="update" resource="balances">
            <Button mr onClick={goToCurrencyExchange}>
              Collect
            </Button>
          </PermissionsChecker>
        )}
        {submittableForReview && (
          <Button
            mr
            disabled={isUpdatingInvoiceApprovalStatus}
            onClick={async () => {
              await updateInvoicesApprovalStatus({
                invoiceIds: [invoice.id],
                approvalStatus: 'submitted',
              });
              getDetailedInvoice();
            }}
            isLoading={isUpdatingInvoiceApprovalStatus}
          >
            Submit
          </Button>
        )}

        {isApprovable && (
          <Button
            mr
            disabled={isUpdatingInvoiceApprovalStatus}
            onClick={async () => {
              await updateInvoicesApprovalStatus({
                invoiceIds: [invoice.id],
                approvalStatus: 'approved',
              });
              getDetailedInvoice();
            }}
            isLoading={isUpdatingInvoiceApprovalStatus}
          >
            Approve
          </Button>
        )}

        {!invoice.contactId && (
          <Button
            disabled={isAddingRecipient}
            isLoading={isAddingRecipient}
            onClick={(e: any) => {
              e.stopPropagation();

              setShouldShowAddRecipientPopup(true);
            }}
          >
            Add recipient
          </Button>
        )}
      </>
    );
  };

  return (
    <InvoiceDetailsFooterWrapper>
      {renderActionButtons()}

      <Col ml mlValue="auto">
        {!isSameCurrency && (
          <Row justifyContent="flex-end">
            <Paragraph whiteSpace="break-spaces" variant="bold">
              {showYouPayingNow ? `You paying now: ` : `Invoice total: `}
            </Paragraph>
            <Paragraph variant="bold">
              {`${currency?.symbol}${parseIntoCurrencyStringWithSymbol(
                showYouPayingNow ? amountToPay : invoice.totalAmount
              )}`}
            </Paragraph>
            <Icon icon={currency?.countryCode ?? ''} ml />
          </Row>
        )}

        <Row justifyContent="flex-end">
          <Paragraph variant="bold">
            {!showYouPayingNow &&
              rate &&
              getInvoiceSellAmount({
                invoice,
                entityCurrency,
                invoiceRateContract: prebookedRateContract,
                invoiceTransfer: invoiceTransfers[0],
                rate,
              })}
            {showYouPayingNow &&
              rate &&
              parseIntoCurrencyStringWithSymbol(
                amountToPay / rate,
                entityCurrency?.symbol,
                entityCurrency?.precision
              )}
            {!rate && <InlineLoader />}
          </Paragraph>
          <Icon icon={entityCurrency?.countryCode ?? ''} ml />
        </Row>
      </Col>

      {/* TODO: get pre-populate data and pass it as recipient for edit. It is not so fast because 
now it works only with invoice from search, and here we have invoice from the database only */}
      {shouldShowAddRecipientPopup && sellCurrency && currency && (
        <AddContact
          onClose={() => {
            setShouldShowAddRecipientPopup(false);
          }}
          disableCurrency
          withSaveAsDraft={false}
          sellCurrency={sellCurrency}
          initialCurrency={currency}
          initialTransferType={TRANSFER_TYPE.priority}
          setRecipient={(recipient) => {
            void onAddRecipient(recipient);
          }}
          onContinue={() => {
            setShouldShowAddRecipientPopup(false);
          }}
        />
      )}
    </InvoiceDetailsFooterWrapper>
  );
};

export default InvoiceDetailsFooter;
