import styled, { css } from 'styled-components';
import { Row } from '../Row/Row';
import { Col } from '../Col/Col';
import { StaleField } from '../StaleField/StaleField';
import { Paragraph, Subtitle } from '../Typography/Typography';

export const FormCol = styled(Col)`
  &:first-child {
    flex: 0 1 60%;
    max-width: 528px;
    padding-right: 32px;
  }

  &:last-child {
    flex: 0 1 40%;
    max-width: 40%;
  }
`;

export const CustomForm = styled.form`
  width: 100%;
  max-width: 100%;
  min-height: 516px;
  padding: 0;
`;

export const LoaderWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.color.white};
    z-index: 2;
  `
);

export const AdditionalRow = styled(Row)`
  margin-top: 18px;
  align-items: flex-start;
`;

export const AccountGroupRow = styled(Row)`
  flex: 1;
  margin-bottom: 18px;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const GroupRow = styled(Row)`
  flex-wrap: wrap;
  align-items: flex-start;
  grid-gap: 16px;
`;

export const SwiftTypeRow = styled(Row)`
  justify-content: flex-start;
  grid-gap: 24px;
`;

export const RadioRow = styled(Row)`
  justify-content: flex-start;
`;

export const ContactGroupCol = styled(Col)`
  margin-bottom: 12px;
  grid-gap: 16px;
`;

export const SwiftCol = styled(Col)`
  margin-top: 42px;
`;

export const CustomSubtitle = styled(Subtitle)`
  margin-bottom: 16px;
`;

export const TypesRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  margin-left: 24px;
  grid-gap: 24px;
`;

export const FieldWrapper = styled(StaleField)<{
  isCurrency?: boolean;
  accountGroup?: boolean;
}>(
  ({ isCurrency = false, accountGroup = false }) => css`
    ${accountGroup &&
    css`
      width: 100%;
      max-width: 100%;
      margin-left: 0px;

      ${isCurrency &&
      css`
        max-width: 138px;
        margin-left: 16px;
      `}
    `}
  `
);

export const FieldRow = styled.div<{
  fullWidth?: boolean;
}>(
  ({ fullWidth = false }) => css`
    display: flex;
    width: ${fullWidth ? '100% ' : 'calc(50% - 8px)'};
  `
);

export const DraftWarnParagraph = styled(Paragraph)(
  ({ theme }) => css`
    margin-top: 10px;
    b {
      color: ${theme.color.red};
    }
  `
);
