import styled from 'styled-components';
import { Col } from '../Col/Col';
import { Row } from '../Row/Row';

export const DrawerWrapper = styled(Col)<{ show: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${({ width, show }) => (show ? 0 : `-${width}`)};
  width: ${({ width }) => width};
  z-index: ${({ theme }) => theme.zIndex.drawer};
  transition: right 0.3s ease;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const DrawerHeader = styled(Row)`
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.xxl}`};
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.greyLight_2}`};
`;

export const DrawerContent = styled(Col)`
  padding: ${({ theme }) => `${theme.spacing.xxl}`};
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: auto;
  flex: 1;
`;

export const DrawerFooter = styled(Row)`
  padding: ${({ theme }) => `${theme.spacing.m}`};
  background-color: ${({ theme }) => theme.color.white};
  border-top: ${({ theme }) => `1px solid ${theme.color.greyLight_2}`};
  justify-content: flex-start;
`;
