export enum IEntityAccountDetailsType {
  priority = 'priority',
  regular = 'regular',
  regularSwift = 'regularSwift',
}

export interface IEntityPriorityAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.priority;
  accountHolder: string;
  iban: string;
  bankName: string;
  bic: string;
  bankAddress: string;
  bankCountry: string;
}

export interface IEntityRegularAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.regular;
  accountHolder: string;
  accountNumber: string;
  sortCode: string;
  currencyCode: string;
}

export interface IEntityRegularSwiftAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.regularSwift;
  accountHolder: string;
  iban: string;
  currencyCode: string;
}

export type OneOfEntityAccountDetails =
  | IEntityPriorityAccountDetails
  | IEntityRegularAccountDetails
  | IEntityRegularSwiftAccountDetails;

export interface IEntityDetails {
  address: IAddress;
  addressCorrespondence: IAddress;
  companyRegistrarId?: string;
  countriesSendingMoneyTo: string[];
  natureOfBusiness: string;
  natureOfBusinessCode: string;
  natureOfBusinessSicFromCh?: string[];
  purposeOfAccount: string;
  vat: boolean;
  vatNumber?: string;
  invoicesAndReceivesPayments?: boolean;
  canProvideInvoicesAndBankStatements?: boolean;
  name?: string; //You can optionally also store the name here
  creditLimit?: number;
}

export interface IAddress {
  addressLine1: string;
  addressLine2?: string; //Sometimes referred to as "Premises", e.g. "Lower Ground Floor" or "New Burlington House"
  postalCode: string;
  city: string;
  country: string; //alpha2 code
  stateOrProvince?: string; //Used for some countries, e.g. Companies House usese "region": "Hertfordshire"
}

export enum ENTITY_TYPE {
  soleTrader = 'soleTrader',
  limitedCompany = 'limitedCompany',
  publicLimitedCompany = 'publicLimitedCompany',
  simplePartnership = 'simplePartnership',
  limitedLiabilityPartnership = 'limitedLiabilityPartnership',
}

enum ENTITY_LOADING_STATE_NAMES {
  cashflowAtRisk = 'cashflowAtRisk',
  reports = 'reports',
}

export interface IEntityLoadingState
  extends Record<ENTITY_LOADING_STATE_NAMES, boolean> {
  id: string;
}

export interface IEntityPayoutAccount {
  id: string;
  currencyCode: string;
  accountNumber: string;
  routingNumber?: string;
  bicSwift?: string;
}

export interface IEntityDataStatuses {
  /** ISO date string */
  invoicesLastUpdated: string;
  /** ISO date string */
  newPaidAndDeletedInvoicesLastUpdated: string;
}

interface IAccountNumberAndRouting {
  type: 'local' | 'sepa' | 'swift';
  accountNumber: string;
  accountNumberType: 'accountNumber' | 'iban';
  routingCode: string;
  routingCodeType: 'sortCode' | 'bicSwift'; // | 'ach'
}

export interface IFundingAccount {
  id: string;
  currencies: string[];
  accountDetails: IAccountNumberAndRouting;
  secondaryAccountDetails?: IAccountNumberAndRouting;
  accountName: string;
  reference: string;
  bankName: string;
  bankAddress: string;
}
