import Button from 'components/shared/Button/Button';
import Field from 'components/shared/Field/Field.styles';
import { StyledForm } from 'components/shared/Form/Form.styles';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import Popup from 'components/shared/Popup/Popup';
import { Row } from 'components/shared/Row/Row';
import { Title } from 'components/shared/Typography/Typography';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import { updateInvoice } from 'services/firebase/invoices';
import { IInvoiceFromSearch } from 'types';
import { errorHandler } from 'utils/errors';
import { DATE_FORMAT, DB_DATE_FORMAT } from 'variables';

interface IFormValues {
  plannedPaymentDate: string;
}

interface IOwnProps {
  invoice: IInvoiceFromSearch;
  onClose: () => void;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
}

const PopupPlannedPaymentDateUpdate: FC<IOwnProps> = ({
  invoice,
  onClose,
  updateInMemoryInvoices,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit } = useForm<IFormValues>({
    defaultValues: {
      plannedPaymentDate:
        dayjs(invoice.plannedPaymentDate).format(DATE_FORMAT) ?? '',
    },
  });

  const onSubmit = async (values: IFormValues) => {
    try {
      setIsLoading(true);
      const response = await updateInvoice({
        id: invoice.id,
        transformToElastic: true,
        data: {
          plannedPaymentDate: dayjs(
            values.plannedPaymentDate,
            DATE_FORMAT
          ).format(DB_DATE_FORMAT),
        },
      });

      if (response.data.data) {
        updateInMemoryInvoices((invoices) =>
          invoices.map(
            (existingInvoice) =>
              response.data.data?.find(
                (updatedInvoice) => updatedInvoice.id === existingInvoice.id
              ) ?? existingInvoice
          )
        );
      }

      onClose();
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  return (
    <Popup
      width="539px"
      onClose={onClose}
      HeaderContent={<Title variant="h4">Change planned payment date</Title>}
      FooterContent={
        <Row flex={1} justifyContent="space-between">
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            form="plannedPaymentDate"
          >
            Confirm
          </Button>
          <Button disabled={isLoading} variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Row>
      }
    >
      <StyledForm id="plannedPaymentDate" onSubmit={handleSubmit(onSubmit)}>
        <Field fluid>
          <Controller
            control={control}
            name="plannedPaymentDate"
            render={({ field: { value, onChange, name } }) => (
              <InputDateUncontrolled
                id={name}
                label="Planned payment date"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Field>
      </StyledForm>
    </Popup>
  );
};

export default PopupPlannedPaymentDateUpdate;
