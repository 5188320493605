import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AxiosPrivateFirebaseInstance,
  AxiosPublicFirebaseInstance,
} from 'settings';
import { IResponse } from 'types';

const abortControllers: Record<string, AbortController> = {};

export const request = async <T extends object>(
  config: AxiosRequestConfig,
  withAuth?: boolean,
  withCancel?: boolean
): Promise<AxiosResponse<IResponse<T>>> => {
  const axiosInstance = withAuth
    ? AxiosPrivateFirebaseInstance
    : AxiosPublicFirebaseInstance;

  if (withCancel) {
    const urlWithParams = `${config.url}`;

    if (abortControllers[urlWithParams]) {
      abortControllers[urlWithParams].abort();
    }

    abortControllers[urlWithParams] = new AbortController();

    return axiosInstance.request({
      ...config,

      signal: abortControllers[urlWithParams].signal,
    });
  }

  return axiosInstance.request(config);
};
