import styled, { CSSProperties } from 'styled-components';
import { Row } from '../Row/Row';
import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';

const Field = styled(Row).attrs<SpacingCssProps>(({ theme }) => ({
  mbValue: theme.spacing.m,
  mtValue: theme.spacing.m,
  mrValue: theme.spacing.m,
  mlValue: theme.spacing.m,
}))<{
  fullWidth?: boolean;
  fluid?: boolean;
  flexDirection?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
}>`
  max-width: ${({ fluid }) => (fluid ? '100%' : '342px')};
  flex: ${({ flex = 1 }) => flex};
  width: ${({ fullWidth }) => (fullWidth ? '342px' : 'unset')};
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
  align-items: flex-start;
  align-self: stretch;

  ${spacingCss}
`;

export default Field;
