import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import EntityUserPopup from './components/EntityUserPopup/EntityUserPopup';
import Button from 'components/shared/Button/Button';
import { useStoreState } from 'state';
import { PermissionsChecker, Row, Loader, Table, Col } from 'components';
import { IEntityInvite, IEntityUser } from 'state/user';

import { IPermitRoleV2 } from 'types/permissions';
import { Notify } from 'utils';
import { Firebase } from 'services';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import {
  generateEntityInviteTableColumns,
  generateEntityUserTableColumns,
} from './tableColumnsGenerator';
import { useTheme } from 'styled-components';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import {
  WhiteFooterContainer,
  WhiteContentContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';

const TeamSettings: FC = () => {
  const theme = useTheme();
  const {
    userId,
    entityId,
    entityUsers: users,
    entityInvites: invites,
    isLoadingEntityInvites,
  } = useStoreState(({ UserState }) => UserState);
  const [roles, setRoles] = useState<IPermitRoleV2[]>([]);
  const [packageSpecificRoles, setPackageSpecificRoles] = useState<
    IPermitRoleV2[]
  >([]);
  const [userForEdit, setUserForEdit] = useState<IEntityUser | null>(null);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);

  const { featureFlagById } = useStoreState(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );

  const isPermitFeatureFlagEnabled = featureFlagById('permit');
  const [
    isUserEditOrInvitePopupOpen,
    setIsUserEditOrInvitePopupOpen,
  ] = useState(false);

  const getEntityRoles = useCallback(async () => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoadingRoles(true);
      const response = await Firebase.getEntityRoles(entityId);

      if (response?.data?.success && response.data?.data) {
        setRoles(response.data.data.filter((role) => !role.packageSpecific));
        setPackageSpecificRoles(
          response.data.data.filter((role) => !!role.packageSpecific)
        );
      } else {
        Notify.error(response?.data?.message ?? '');
      }
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoadingRoles(false);
    }
  }, [entityId]);

  const usersTableColumns = useMemo(
    () =>
      generateEntityUserTableColumns({
        isLoadingRoles,
        isPermitFeatureFlagEnabled,
        onEditEntityUser: (user) => {
          setUserForEdit(user);
          setIsUserEditOrInvitePopupOpen(true);
        },
        theme,
        userId,
      }),
    [userId, isLoadingRoles, theme, isPermitFeatureFlagEnabled]
  );

  const invitesTableColumns = useMemo(
    () =>
      generateEntityInviteTableColumns({
        theme,
      }),
    [theme]
  );

  useEffect(() => {
    getEntityRoles();
  }, [getEntityRoles]);

  return (
    <>
      <WhiteContentContainer gap={theme.spacing.xxl}>
        <Col gap={theme.spacing.l}>
          <CounterTitle title="Users" count={users.length} />

          {!!users.length ? (
            <Table<IEntityUser>
              data={users}
              columns={usersTableColumns}
              sortable
              defaultRowHeight={56}
              minRowHeight={56}
            />
          ) : (
            <Loader size="large" />
          )}
        </Col>

        <Col gap={theme.spacing.l}>
          <CounterTitle title="Invites" count={invites.length} />

          {!!invites.length && (
            <Table<IEntityInvite>
              data={invites}
              columns={invitesTableColumns}
              sortable
              defaultRowHeight={56}
              minRowHeight={56}
            />
          )}

          {!invites.length && (
            <>
              {isLoadingEntityInvites ? (
                <Loader size="large" />
              ) : (
                <Placeholder
                  fullWidth
                  description="You will see your invites here when you have some."
                />
              )}
            </>
          )}
        </Col>
      </WhiteContentContainer>

      <WhiteFooterContainer>
        <PermissionsChecker action="update" resource="settings">
          <Row>
            <Button
              variant="secondary"
              onClick={() => setIsUserEditOrInvitePopupOpen(true)}
            >
              + Add new
            </Button>
          </Row>
        </PermissionsChecker>
      </WhiteFooterContainer>
      {isUserEditOrInvitePopupOpen && (
        <EntityUserPopup
          userForEdit={userForEdit}
          packageSpecificRoles={packageSpecificRoles}
          roles={roles}
          onClose={() => {
            setUserForEdit(null);
            setIsUserEditOrInvitePopupOpen(false);
          }}
        />
      )}
    </>
  );
};

export default TeamSettings;
