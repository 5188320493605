import { ICurrency, IInvoice, IRateContract, ITransfer } from 'types';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

export const getInvoiceSellAmount = ({
  invoiceTransfer,
  invoiceRateContract,
  rate,
  invoice,
  entityCurrency,
}: {
  invoiceTransfer: ITransfer | null;
  invoiceRateContract: IRateContract | null;
  rate: number | null;
  invoice: IInvoice;
  entityCurrency: ICurrency | null;
}) => {
  let rateToUse: number | undefined;

  if (invoiceTransfer?.rate) {
    rateToUse = invoiceTransfer?.rate;
  } else if (invoiceRateContract?.rate) {
    rateToUse = invoiceRateContract.rate;
  } else if (rate) {
    rateToUse = rate;
  }

  if (rateToUse !== undefined) {
    if (invoice.type === 'Receivable') {
      return `${parseIntoCurrencyStringWithSymbol(
        invoice.totalAmount * rateToUse,
        entityCurrency?.symbol,
        entityCurrency?.precision
      )}`;
    }

    return `${parseIntoCurrencyStringWithSymbol(
      invoice.totalAmount / rateToUse,
      entityCurrency?.symbol,
      entityCurrency?.precision
    )}`;
  }

  return undefined;
};
