import { Row } from 'components';
import styled, { css } from 'styled-components';

export const AmountCellEditableWrapper = styled(Row)<{
  variant?: 'medium' | 'large';
}>(
  ({ theme, variant = 'medium' }) => css`
    > .iMaskInput {
      color: ${theme.color.black};
      padding: 0;
      margin: 0;
      border: 0;
      &:focus,
      &:active {
        text-decoration: underline;
      }

      ${variant === 'medium' &&
      css`
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      `}

      ${variant === 'large' &&
      css`
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;

        @media (min-width: 1025px) and (min-height: 568px) {
          font-size: 24px;
          line-height: 34px;
        }
      `}
    }
  `
);

export const EditButton = styled.button`
  height: 16px;
  opacity: 0;

  ${AmountCellEditableWrapper}:hover & {
    opacity: 1;
  }
`;
