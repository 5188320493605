import firebase from 'firebase/compat/app';
import { IReportsMonthlyData, IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import firestore from 'services/firestore';

import { openDoc, openQuery } from 'utils';
import {
  CashflowsRisksData,
  IPastPerformanceItem,
  IPnlDataPerMonth,
  ICashNeedsBffResponse,
  TCashflowAtRiskNew,
  INetworthValues,
  IPastPerformanceDataFromExternalSource,
} from 'types/analyses';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { ISearchResponse } from 'types/search';

export interface RefreshCashflowsRisksParams {
  entityId: string;
  sellCurrency: string;
}

export const refreshCashflowRisks = async ({
  entityId,
  sellCurrency,
}: RefreshCashflowsRisksParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `analysis/cashflow-risk/${entityId}/${sellCurrency}/refresh`
  );
};

export interface SubscribeToCashflowsRisksParams {
  entityId: string;
  callback: (cashflowsRisksData: CashflowsRisksData | null) => void;
}

export const subscribeToCashflowsRisks = ({
  entityId,
  callback,
}: SubscribeToCashflowsRisksParams) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('analyses')
    .doc('cashFlowRiskAnalysis')
    .onSnapshot(
      (doc) => {
        callback(openDoc(doc));
      },
      (error) =>
        console.log(
          `Failed to subscribe to cashflows risks for entity ID ${entityId}). Error: ${error}`
        )
    );

interface GetPastPerformanceDataForEntityParams {
  entityId: string;
}

export interface PastPerformanceDataForEntityResponse {
  worstCumulativeImpact: number;
  worstPotentialLoss: number;
}

export const getPastPerformanceDataForEntity = async ({
  entityId,
}: GetPastPerformanceDataForEntityParams) => {
  return AxiosPrivateFirebaseInstance.get<
    IResponse<PastPerformanceDataForEntityResponse>
  >(`analysis/past-performance/${entityId}`);
};

interface GetPastPerformancePerMonthByCurrencyParams {
  entityId: string;
  currency: string;
  from: string;
}

export const getPastPerformanceDataPerMonthByCurrency = async ({
  entityId,
  currency,
  from,
}: GetPastPerformancePerMonthByCurrencyParams): Promise<IPnlDataPerMonth[]> =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .doc(currency)
    .collection('perMonth')
    .where(firebase.firestore.FieldPath.documentId(), '>=', from)
    .get()
    .then(openQuery);

interface IGetCashflowsRisksPerCurrencyParams {
  currencyCode: string;
  period: TRisksDataPeriod;
}

export const getCashflowsRisksPerCurrency = async ({
  currencyCode,
  period,
}: IGetCashflowsRisksPerCurrencyParams) => {
  return AxiosPrivateFirebaseInstance.get<
    IResponse<{
      perPeriod: TCashflowAtRiskNew[];
      networthValues: INetworthValues;
    }>
  >(`bff/cashflow-risk/${currencyCode}/${period}`);
};

interface IUpdateEntityBudgetRatesParams {
  currencyCode: string;
  date: string; // YYYY-MM
  budgetRate: number;
}

export const updateEntityBudgetRates = async ({
  currencyCode,
  date,
  budgetRate,
}: IUpdateEntityBudgetRatesParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    `analysis/budget-rates/${currencyCode}/${date}`,
    {
      budgetRate,
    }
  );
};

interface GetPastPerformanceDataFromExternalSourceParams {
  entityId: string;
  from: string;
}

export const getPastPerformanceDataFromExternalSource = async ({
  entityId,
  from,
}: GetPastPerformanceDataFromExternalSourceParams): Promise<
  IPastPerformanceDataFromExternalSource[]
> =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('analyses')
    .doc('externalPastPerformance')
    .collection('perMonth')
    .where(firebase.firestore.FieldPath.documentId(), '>=', from)
    .get()
    .then(openQuery);

export const getCashNeeds = async (includeAllUserEntities = false) =>
  AxiosPrivateFirebaseInstance.get<IResponse<ICashNeedsBffResponse>>(
    `analysis/cash-needs?all=${includeAllUserEntities}`
  );

export const searchReports = async (searchQuery: string) => {
  return AxiosPrivateFirebaseInstance.get<
    IResponse<ISearchResponse<IPastPerformanceItem>>
  >(`analysis/reports/search${searchQuery}`);
};

export const getMonthlyReports = async (searchQuery: string) => {
  return AxiosPrivateFirebaseInstance.get<IResponse<IReportsMonthlyData>>(
    `analysis/reports/monthly${searchQuery}`
  );
};

export const updateLastPeriodReportsIfNeeded = async () =>
  AxiosPrivateFirebaseInstance.post<IResponse<void>>(
    `analysis/reports/generate-last-period`
  );
