import { useMemo } from 'react';
import { useStoreState } from 'state';
import { ITab } from 'components/shared/Tabs/Tabs';
import {
  mapStringToInvoicePageTabs,
  TInvoicePageTabs,
} from 'pages/Invoices/types';
import { getCountFromInvoicesAggregationPerFilter } from '../utils';
import useDeviceWidth from 'hooks/useDeviceWidth';

interface IUseTabsTab extends ITab<TInvoicePageTabs> {
  count?: number;
}

interface OwnProps {
  paymentRunInvoicesLength: number;
}

const useTabs = ({ paymentRunInvoicesLength }: OwnProps) => {
  const {
    hasApprovalFlow,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const { isMobile } = useDeviceWidth();

  const tabs = useMemo((): IUseTabsTab[] => {
    const outstanding = getCountFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      'outstanding'
    );
    const submitted = getCountFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      'submitted'
    );
    const approved = getCountFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      'approved'
    );
    const paid = getCountFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      'paid'
    );

    return [
      {
        id: mapStringToInvoicePageTabs['outstanding'],
        title: isMobile ? 'Outstanding' : `Outstanding (${outstanding})`,
        count: outstanding,
      },
      ...(hasApprovalFlow
        ? [
            {
              id: mapStringToInvoicePageTabs['submitted'],
              title: isMobile ? 'To approve' : `To approve (${submitted})`,
              count: submitted,
            },
            {
              id: mapStringToInvoicePageTabs['approved'],
              title: isMobile ? 'Approved' : `Approved (${approved})`,
              count: approved,
            },
          ]
        : []),
      ...(isAutomationPackageEnabled || isFxManagementPackageEnabled
        ? [
            {
              id: mapStringToInvoicePageTabs['paymentRun'],
              title: isMobile
                ? 'Draft run'
                : `Draft run (${paymentRunInvoicesLength})`,
              count: paymentRunInvoicesLength,
            },
          ]
        : []),
      {
        id: mapStringToInvoicePageTabs['paid'],
        title: isMobile ? 'Paid' : `Paid (${paid})`,
        count: paid,
      },
    ];
  }, [
    hasApprovalFlow,
    invoicesAggregations,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
    paymentRunInvoicesLength,
    isMobile,
  ]);

  return {
    tabs,
  };
};

export default useTabs;
