import { FC } from 'react';
import { Row } from '../../../../../../../Row/Row';
import { ITableFilterOption } from '../../../../../../types';
import StaleSwitch from '../../../../../../../StaleSwitch/StaleSwitch';
import { Paragraph } from '../../../../../../../Typography/Typography';
import { useTheme } from 'styled-components';

interface OwnProps {
  filterId: string;
  isActive: boolean;
  filterOption: ITableFilterOption;
  setFilter: (filterId: string, filterValue: ITableFilterOption) => void;
}

const FilterOptionRadio: FC<OwnProps> = ({
  filterId,
  isActive,
  filterOption,
  setFilter,
}) => {
  const { id, title } = filterOption;
  const theme = useTheme();

  return (
    <Row>
      <StaleSwitch
        id={id}
        isOn={isActive}
        handleToggle={() => setFilter(filterId, filterOption)}
      />
      <Paragraph ml mlValue={theme.spacing.xs}>
        {title}
      </Paragraph>
    </Row>
  );
};

export default FilterOptionRadio;
