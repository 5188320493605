import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Title, Button, Paragraph, Col, Row } from 'components';

interface IOwnProps {
  onRetry?: () => void;
}

const OpenBankingRedirectResponse: FC<IOwnProps> = ({ onRetry }) => {
  const theme = useTheme();

  return (
    <Col mt mtValue={theme.spacing.xl} gap={theme.spacing.m}>
      <Title variant="h5">Waiting for authorisation from your bank</Title>

      <Paragraph>
        This screen will change a few seconds after you've provided
        authorisation via your bank
      </Paragraph>

      {!!onRetry && (
        <Row>
          <Button variant="link" onClick={onRetry}>
            Start again
          </Button>
        </Row>
      )}
    </Col>
  );
};

export default OpenBankingRedirectResponse;
