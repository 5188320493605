import styled, { css } from 'styled-components';

export const StaleInputSearch = styled.input<{ isGray?: boolean }>(
  ({ theme, isGray = false }) => css`
    width: 100%;
    border-radius: 6px;
    padding: 12px 0 11px 16px;
    background: url('/assets/imgs/bg/search.svg') no-repeat calc(100% - 16px)
      center;
    background-color: ${theme.color.greyLight_3};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      border-radius: 6px 6px 0 0;
      border-bottom: ${!isGray && `1px solid ${theme.color.greyLight_1}`};
    }
  `
);
