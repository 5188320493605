import { Client } from '@ideal-postcodes/core-browser';

export const client = new Client({
  api_key: process.env.REACT_APP_POSTCODES_API_KEY ?? '',
});

export const searchAddressSuggestions = async (query: string) => {
  const response = await client.autocomplete.list({
    query: {
      query,
      api_key: process.env.REACT_APP_POSTCODES_API_KEY,
    },
  });

  return response.body.result.hits;
};

export const lookupUdprn = async (udprn: number) => {
  const response = await client.lookupUdprn({
    udprn,
    api_key: process.env.REACT_APP_POSTCODES_API_KEY,
  });

  return response;
};
