import { ISelectItem } from '../StaleInputSelect/StaleInputSelect';

export const SCAM_QUESTIONS_DATA: ISelectItem[] = [
  {
    id: 'SCAM_1',
    value: 'SuccessfullyTransferredToThisAccountBefore',
    name: 'Successfully transferred to this account before',
  },
  {
    id: 'SCAM_2',
    value: 'VerifiedByCallingRelevantGenuineNumber',
    name: 'Verified by calling relevant, genuine number',
  },
  {
    id: 'SCAM_3',
    value: 'VerifiedDetailsViaAlternativeSecureMethod',
    name: 'Verified details via alternative, secure method',
  },
  {
    id: 'SCAM_4',
    value: 'InternalAccountVerificationProcess',
    name: 'Internal account verification process',
  },
];
