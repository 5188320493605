import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  name: 'defaultTheme',
  breakpoint: {
    extraSmallMax: 374,
    extraSmallMin: 375,

    smallMax: 575,
    smallMin: 576,

    mediumMax: 767,
    mediumMin: 768,

    largeMax: 1024,
    largeMin: 1025,

    mediumLargeMax: 1279,
    mediumLargeMin: 1280,

    extraLargeMax: 1319,
    extraLargeMin: 1320,
  },

  cardSizes: {
    xxxs: '328px',
    xxs: '368px',
    xs: '388px',
  },

  iconSizes: {
    s: '20px',
    m: '24px',
    l: '40px',
    xl: '48px',
    xxl: '80px',
  },

  color: {
    black: '#000',
    dark: '#121213',
    darkLight: '#1F2022',
    greyDark: '#454D58',
    grey: '#8C9199',
    greyLight_1: '#DCDEE1',
    greyLight_2: '#F2F2F2',
    greyLight_3: '#F7F7F7',
    greyLight_4: '#FBFBFB',
    greyLight_5: '#fafafa',
    white: '#fff',
    transparent: 'rgba(0,0,0,0)',
    emeraldDark: '#1CBD85',
    emeraldLight: '#DBF4EB',
    green: '#3AA33A',
    yellow: '#EFB317',
    coral: '#E87C53',
    red: '#EC4B4B',
    redDark: '#ba1e1e',
    blue: '#2647BD',
    blueLight_1: 'rgba(122, 204, 222, 0.2)',
    blueLight_2: '#7ACCDE',
    blueLight: '#636ED4',
    backdrop: 'rgba(0,0,0,0.4)',
    backdropLight: 'rgba(250,250,250,0.4)',
    purple: '#A97CE4',
    purpleLight_1: 'rgba(83, 89, 232, 0.2)',
    purpleLight_2: 'rgba(83, 89, 232, 0.58)',
    purpleLight_3: '#A77AE234',
  },
  gradients: {
    blue: 'linear-gradient(90.44deg, #1F4EC8 0.44%, #2647BD 102.93%)',
  },
  transition: '0.3s',
  shadow: '0 0 9px rgba(0, 0, 0, 0.1)',

  fontSize: {
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '18px',
    xl: '20px',
    xxl: '24px',
    xxxl: '28px',
    xxxxl: '36px',
  },

  spacing: {
    xxs: '4px',
    xs: '8px',
    s: '12px',
    m: '16px',
    l: '20px',
    xl: '24px',
    xxl: '32px',
    xxxl: '48px',
  },

  borderRadius: {
    s: '3px',
    m: '6px',
    l: '20px',
    xl: '56px',
    round: '50%',
  },

  zIndex: {
    backdrop: 10,
    calendar: 20,
    popup: 30,
    drawer: 15,
    chartTooltip: 30,
  },

  minFlowStepFooterHeight: '114px',

  popupHeights: {
    xs: '400px',
    s: '600px',
  },
};
