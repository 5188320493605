import styled, { css } from 'styled-components';

export const ListItem = styled.div`
  position: relative;
  padding-left: 24px;
  list-style: none;
`;

export const ListItemIcon = styled.svg<{
  isValid: boolean;
}>(
  ({ theme, isValid }) => css`
    width: 12px;
    height: 12px;
    fill: none;
    stroke: ${isValid ? theme.color.emeraldDark : theme.color.grey};
    position: absolute;
    top: 6px;
    left: 4px;
  `
);
