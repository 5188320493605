import { ICustomCashflowDefinition } from 'state/user';
import { CASHFLOW_TYPE } from 'types';

export const getInflowCustomDefinitions = (
  customCashflowDefinitions?: ICustomCashflowDefinition[]
) =>
  customCashflowDefinitions?.filter(
    ({ type }) => type === CASHFLOW_TYPE.receivable
  ) || [];

export const getOutflowCustomDefinitions = (
  customCashflowDefinitions?: ICustomCashflowDefinition[]
) =>
  customCashflowDefinitions?.filter(
    ({ type }) => type === CASHFLOW_TYPE.payable
  ) || [];
