import { InputRadio } from './StaleRadioButton.styles';

interface Item {
  id: string | number;
  value: string | number | boolean;
  autoFocus?: boolean;
  checked?: boolean;
  name: any;
}

const StaleRadioButtonGroup = ({
  list,
  name = '',
  onChange,
  cardRow = false,
  cardCol = false,
  cardTransparent = false,
  cardRowRevert = false,
  disabled = false,
}: {
  list: Item[];
  name?: string;
  onChange: (item: Item) => void;
  cardRow?: boolean;
  cardCol?: boolean;
  cardTransparent?: boolean;
  cardRowRevert?: boolean;
  disabled?: boolean;
}) => (
  <>
    {list.map((item: any) =>
      item ? (
        <InputRadio
          key={item.id}
          cardRow={cardRow}
          cardRowRevert={cardRowRevert}
          cardCol={cardCol}
          cardTransparent={cardTransparent}
          disabled={disabled}
        >
          <input
            type="radio"
            id={item.id}
            name={name}
            value={item.value}
            checked={item.checked}
            onChange={() => !disabled && onChange(item)}
            autoFocus={item.autoFocus}
            disabled={disabled}
          />
          <label htmlFor={item.id}>{item.name}</label>
        </InputRadio>
      ) : null
    )}
  </>
);
export default StaleRadioButtonGroup;
