import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { IBulkPayment } from 'types';
import { Row, Icon, StaleInfo, Paragraph } from 'components';
import useDeviceWidth from 'hooks/useDeviceWidth';

interface OwnProps {
  buyAmountsBreakdown: IBulkPayment['buyAmountsBreakdown'];
}

const CurrenciesCell: FC<OwnProps> = ({ buyAmountsBreakdown }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  if (!buyAmountsBreakdown.length) {
    return null;
  }

  return (
    <Row
      columnGap={theme.spacing.s}
      alignItems="center"
      style={{ position: 'relative' }}
    >
      {buyAmountsBreakdown.slice(0, 3).map(({ currency: currencyCode }) => {
        const currency = currencyByCode(currencyCode);

        if (!currency) {
          return null;
        }

        return (
          <StaleInfo
            key={currencyCode}
            infoSize={theme.iconSizes.xl}
            mode="hover"
            strategy="fixed"
            portal
            placement="top"
            trigger={
              <Icon
                width={theme.iconSizes.xl}
                height={theme.iconSizes.xl}
                icon={currency.countryCode}
              />
            }
          >
            {!isMobile && <Paragraph color="white">{currencyCode}</Paragraph>}
          </StaleInfo>
        );
      })}

      {buyAmountsBreakdown.length > 3 && (
        <Paragraph>+{buyAmountsBreakdown.length - 3}</Paragraph>
      )}
    </Row>
  );
};

export default CurrenciesCell;
