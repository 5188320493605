import { ERROR_MESSAGES } from 'variables';
import {
  connectionDisconnect,
  connectionGetRedirectUrl,
  importAllData,
} from 'services/firebase';
import { useCallback, useMemo, useState } from 'react';
import { useStoreState } from 'state';
import {
  getIntegrationLogsPageLink,
  getIntegrationSettingsPageLink,
  Notify,
} from 'utils';
import { errorHandler } from 'utils/errors';
import { TAccountingSystem } from 'types';
import { useHistory } from 'react-router';
import { systemToPlatformNames } from 'routes/IntegrationsRouteUtil';

const openPopup = (url: string, platformName: string, isXero: boolean) => {
  if (!window || !window.top) {
    return;
  }
  const width = isXero ? 400 : 700;
  const height = isXero ? 500 : 1000;
  const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;

  const newWin = window.open(
    'about:blank',
    '',
    `resizable, scrollbars, status, width=${width}px, height=${height}px, left=${left}, top=${top}`
  );

  newWin?.document.write(
    `<!DOCTYPE html>
      <html lang="en">
      <title>Connect to ${platformName}</title>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet">
      <style text="text/css">
        * {
          font-family: 'Roboto', sans-serif;
        }
        h1, p {
          text-align: center;
        }
      </style>
      <body>
          <h1>We are loading your page</h1>
          <p>This process can take few seconds</p>
      </body>
      </html>
     `
  );

  setTimeout(() => {
    newWin?.location.assign(url);
  }, 100);
};

const useIntegrationEngine = () => {
  const history = useHistory();
  const {
    entityId,
    integrationEngineSettings,
    integrationsSummary,
  } = useStoreState((state) => state.UserState);
  const [windowUrl, setWindowUrl] = useState<string | null>(null);
  const [
    isLoadingConnectionRedirect,
    setIsLoadingConnectionRedirect,
  ] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const isLoading = isDisconnecting || isLoadingConnectionRedirect;
  const accountingIntegrationSystem = integrationsSummary?.system;
  const platformName = integrationsSummary?.platformName;

  const {
    isIntegratedWithXero,
    isIntegratedWithNetSuite,
    isIntegratedWithQuickBooks,
    isIntegratedWithDynamics365,
    reauthenticateXero,
    reauthenticateNetSuite,
    reauthenticateQuickBooks,
    reauthenticateDynamics365,
  } = useMemo(() => {
    const dataToReturn = {
      isIntegratedWithXero: false,
      isIntegratedWithNetSuite: false,
      isIntegratedWithQuickBooks: false,
      isIntegratedWithDynamics365: false,
      reauthenticateXero: false,
      reauthenticateNetSuite: false,
      reauthenticateQuickBooks: false,
      reauthenticateDynamics365: false,
    };
    if (integrationsSummary) {
      dataToReturn.isIntegratedWithXero =
        integrationsSummary.system === 'xero' && integrationsSummary.connected;
      dataToReturn.isIntegratedWithNetSuite =
        integrationsSummary.system === 'netsuite' &&
        integrationsSummary.connected;
      dataToReturn.isIntegratedWithQuickBooks =
        integrationsSummary.system === 'quickbooks' &&
        integrationsSummary.connected;
      dataToReturn.isIntegratedWithDynamics365 =
        integrationsSummary.system === 'dynamics' &&
        integrationsSummary.connected;

      dataToReturn.reauthenticateXero =
        integrationsSummary.system === 'xero' &&
        integrationsSummary.reauthenticate;
      dataToReturn.reauthenticateNetSuite =
        integrationsSummary.system === 'netsuite' &&
        integrationsSummary.reauthenticate;
      dataToReturn.reauthenticateQuickBooks =
        integrationsSummary.system === 'quickbooks' &&
        integrationsSummary.reauthenticate;
      dataToReturn.reauthenticateDynamics365 =
        integrationsSummary.system === 'dynamics' &&
        integrationsSummary.reauthenticate;
    }

    return dataToReturn;
  }, [integrationsSummary]);

  const isIntegrated =
    isIntegratedWithQuickBooks ||
    isIntegratedWithDynamics365 ||
    isIntegratedWithNetSuite ||
    isIntegratedWithXero;

  const reauthenticateIntegration =
    reauthenticateXero ||
    reauthenticateNetSuite ||
    reauthenticateQuickBooks ||
    reauthenticateDynamics365;

  const onImportIntegrationEngineData = useCallback(
    async (entityIdPassedIn?: string) => {
      const entityIdToUse = entityIdPassedIn || entityId;
      if (!entityIdToUse) {
        Notify.error(ERROR_MESSAGES.noEntityId);
        return;
      }

      try {
        setIsImporting(true);
        await importAllData(entityIdToUse);
        setTimeout(() => {
          setIsImporting(false);
        }, 5000);
      } catch (error: any) {
        setIsImporting(false);
        errorHandler(error);
      }
    },
    [entityId]
  );

  const onManageSettings = () => {
    if (integrationsSummary) {
      const url =
        !integrationEngineSettings.settings && !!integrationsSummary.connected
          ? getIntegrationSettingsPageLink(integrationsSummary.system, {
              step: '1',
              firstIntegration: true,
            })
          : getIntegrationSettingsPageLink(integrationsSummary.system, {
              step: '1',
            });

      history.push(url);
    } else {
      console.error(
        'Tried to call onManageSettings with no integrationsSummary'
      );
    }
  };

  const onManageLogs = () => {
    if (integrationsSummary) {
      history.push(getIntegrationLogsPageLink(integrationsSummary.system));
    } else {
      console.error('Tried to call onManageLogs with no integrationsSummary');
    }
  };

  const retrieveRedirectUri = async (system: TAccountingSystem) => {
    try {
      setIsLoadingConnectionRedirect(true);

      if (!entityId) {
        Notify.error(ERROR_MESSAGES.noEntityId);
        return;
      }

      if (!system) {
        Notify.error(ERROR_MESSAGES.noSystem);
        return;
      }

      const redirect = await connectionGetRedirectUrl(entityId, system);

      if (!redirect) {
        Notify.error(
          'Unable to establish connection at this time. If this persists then please contact support.'
        );
        return;
      }

      if (redirect) {
        setWindowUrl(redirect);
        return redirect;
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingConnectionRedirect(false);
    }
  };

  const onLogin = async (system?: TAccountingSystem) => {
    // We retrieve the company url here because
    // we don't want to unnecessarily create a new company
    // in lower environments, using up limits
    const systemToUse = integrationsSummary?.system || system;

    if (!systemToUse) {
      console.error(ERROR_MESSAGES.noSystem);
      return;
    }

    const url = windowUrl ?? (await retrieveRedirectUri(systemToUse));

    if (url) {
      openPopup(
        url,
        systemToPlatformNames[systemToUse],
        systemToUse === 'xero'
      );
    }
  };

  /** Called to disconnect connectivity with the source system. */
  const onDisconnect = async () => {
    if (!entityId) {
      Notify.error(ERROR_MESSAGES.noEntityId);
      return;
    }

    try {
      setIsDisconnecting(true);
      await connectionDisconnect(entityId);
      setIsDisconnecting(false);
    } catch (error: any) {
      setIsDisconnecting(false);
      throw error;
    }
  };

  return {
    isImporting,
    isIntegrated,
    isLoading,
    isDisconnecting,
    reauthenticateIntegration,
    onImportIntegrationEngineData,
    onManageSettings,
    onManageLogs,
    onLogin,
    onDisconnect,
    accountingIntegrationSystem,
    platformName,

    isIntegratedWithXero,
  };
};

export default useIntegrationEngine;
