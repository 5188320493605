import { FC } from 'react';

import { Col, Icon, Paragraph, Row } from 'components';
import { getTransferFeeLabel, parseIntoCurrencyString } from 'utils';
import { IContact, ICurrency, Nullable } from 'types';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';

export interface TransferSummaryProps {
  sellCurrency: ICurrency;
  sellAmount: number;
  fee?: number;
  recipient?: Nullable<IContact>;
  sellCurrencyBalance?: number;
}

const TransferSummaryInfo: FC<TransferSummaryProps> = ({
  sellCurrency,
  sellAmount,
  fee = 0,
  recipient,
  sellCurrencyBalance,
}) => {
  const {
    code: sellCurrencyCode,
    precision: sellCurrencyPrecision,
    countryCode: sellCurrencyCountryCode,
  } = sellCurrency;

  return (
    <Col>
      {!!sellCurrencyBalance && (
        <RowInfo>
          <Paragraph>Send up to</Paragraph>
          <Row>
            <Paragraph variant="bold" mr>
              {`${sellCurrency.code} ${parseIntoCurrencyString(
                sellCurrencyBalance
              )}`}
            </Paragraph>
            <Icon icon={`${sellCurrencyCountryCode}`} />
          </Row>
        </RowInfo>
      )}

      {!sellCurrencyBalance && (
        <RowInfo>
          <Paragraph>We send</Paragraph>
          <Row>
            <Paragraph variant="bold" mr>
              {`${sellCurrency.code} ${parseIntoCurrencyString(sellAmount)}`}
            </Paragraph>
            <Icon icon={`${sellCurrencyCountryCode}`} />
          </Row>
        </RowInfo>
      )}

      {recipient && (
        <>
          <RowInfo style={{ marginRight: 40 }}>
            <Paragraph>{`Payment fee ${getTransferFeeLabel(
              recipient.paymentType
            )}`}</Paragraph>
            <Paragraph variant="bold">
              {sellCurrency?.code} {parseIntoCurrencyString(fee)}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>You pay</Paragraph>
            <Row>
              <Paragraph variant="bold" mr>
                {`${sellCurrencyCode} ${parseIntoCurrencyString(
                  sellAmount + fee,
                  sellCurrencyPrecision
                )}`}
              </Paragraph>
              <Icon icon={`${sellCurrencyCountryCode}`} />
            </Row>
          </RowInfo>
        </>
      )}
    </Col>
  );
};

export default TransferSummaryInfo;
