import { Fragment, PropsWithChildren } from 'react';
import { Row } from 'react-table';
import { TableDataCell, TableRow } from '../../TableHorizontal.styles';
import { ITableHorizontalProps } from '../../types';

interface IOwnProps<T extends object>
  extends Pick<ITableHorizontalProps<T>, 'getTdCellTheme'> {
  row: Row<T>;
  prepareRow: (row: Row<T>) => void;
}

const NormalRow = <T extends object>({
  row,
  prepareRow,
  getTdCellTheme,
}: PropsWithChildren<IOwnProps<T>>) => {
  prepareRow(row);

  return (
    <Fragment key={row.getRowProps().key}>
      <TableRow as="tr">
        {row.cells.map((cell) => (
          <TableDataCell
            {...cell.getCellProps()}
            colorTheme={getTdCellTheme?.(cell)}
            as="td"
          >
            {cell.render('Cell')}
          </TableDataCell>
        ))}
      </TableRow>
    </Fragment>
  );
};

export default NormalRow;
