import { FC } from 'react';
import Button from '../Button/Button';
import { PlaceholderWrapper, PlaceholderParagraph } from './Placeholder.styles';

interface OwnProps {
  actionText?: string;
  onAction?: () => void;
  description?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  size?: 'small' | 'medium';
}

const Placeholder: FC<OwnProps> = ({
  actionText,
  onAction,
  description,
  fullWidth,
  disabled = false,
  isLoading = false,
  size = 'medium',
  children,
}) => (
  <PlaceholderWrapper fullWidth={fullWidth} size={size}>
    {children}
    {!!actionText && !!onAction && (
      <Button
        variant="link"
        onClick={onAction}
        disabled={disabled}
        isLoading={isLoading}
      >
        {actionText}
      </Button>
    )}
    {!!description && (
      <PlaceholderParagraph color="grey" mt>
        {description}
      </PlaceholderParagraph>
    )}
  </PlaceholderWrapper>
);
export default Placeholder;
