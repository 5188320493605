import { IRateContract, RATE_TYPE } from 'types';

// TODO: consider moving into shared place and use it from there
interface ISystemFields {
  id: string;
  _shortId: string;
  _created: string;
  _createdBy: string;
  _updated?: string;
  _updatedBy?: string;
  _version: number;
  _owner: string;
}

export enum CONVERSION_STATUS {
  awaitingPayment = 'awaiting_payment',
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = 'funding_initiated',
  funded = 'funded',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
}

export interface IConversionInput {
  sellAmount: number;
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  rate: number;
  rateType: RATE_TYPE;
  contractId?: IRateContract['id'];
  conversionFeeRate: number;
  invoiceId?: string;
  delayedFunding?: boolean;
}

export interface IConversion extends IConversionInput, ISystemFields {
  conversionDate?: string;
  status: CONVERSION_STATUS;
}

export interface IConversionDates {
  firstConversionCutoffDatetime: string;
  firstConversionDate: string;
  nextDayConversionDate: string;
}
