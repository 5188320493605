import { ISystemFields } from './systemFields';

export interface IExternalBalanceBase {
  currency: string;
  amount: number;
  accountId: string;
  accountName: string;
  source: 'xero';
}

export type TExternalBalance = IExternalBalanceBase & ISystemFields;

export const isExternalBalanceTypeGuard = (
  object: any
): object is TExternalBalance => object.accountName !== undefined;
