import { FC, useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';

import {
  StaleGetStartedPopup,
  Overflow,
  StaleCarousel,
  Title,
} from 'components';
import { Wrapper, LoginWrap, LoginNav, LoginContent } from './Login.styles';
import { useStoreActions, useStoreState } from 'state';
import { getDashboardPageLink, getMyClientsLink } from 'utils';
import EmailAndPassword from './components/EmailAndPassword/EmailAndPassword';
import { TInputs } from './components/EmailAndPassword/types';
import { MultiFactorError } from 'firebase/auth';
import MultiFactorAuth from './components/MultiFactorAuth/MultiFactorAuth';

const Login: FC<
  RouteComponentProps<
    {},
    any,
    {
      from: {
        pathname: string;
      };
    }
  >
> = ({ location }) => {
  const [isGetStartedShown, setIsGetStartedShown] = useState(false);
  const [mfaError, setMfaError] = useState<MultiFactorError>();
  const [
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  ] = useState(false);
  const { userId, isAuthLoading, isAccountant } = useStoreState(
    (state) => state.UserState
  );
  const { signIn, signInWithGoogle } = useStoreActions(
    (actions) => actions.UserState
  );

  const onPasswordSignIn = async (values: TInputs) => {
    try {
      const response = (await signIn(values)) as MultiFactorError | undefined;

      // user has 2FA enabled
      if (!!response) {
        setMfaError(response);
      }
    } catch (error: any) {
      if (error.code === 'auth/too-many-requests') {
        setIsTooManyPasswordAttemptsError(true);
      }
    }
  };

  const onSignInWithGoogle = async () => {
    const response = await signInWithGoogle();

    // user has 2FA enabled
    if (!!response) {
      setMfaError(response);
    }
  };

  const moveBackToSignIn = useCallback(() => {
    setMfaError(undefined);
  }, []);

  if (!isAuthLoading && userId) {
    if (location.state?.from?.pathname) {
      return <Redirect to={location.state?.from?.pathname} />;
    }

    return (
      <Redirect
        to={isAccountant ? getMyClientsLink() : getDashboardPageLink()}
      />
    );
  }

  return (
    <Wrapper>
      <LoginWrap>
        <LoginNav>
          {isMobile ? (
            <Title variant="h5" color="white">
              Sign in
            </Title>
          ) : (
            <>
              <Title variant="h5">Sign in to HedgeFlows to:</Title>

              <StaleCarousel />
            </>
          )}
        </LoginNav>

        <LoginContent className="rounded">
          <Overflow style={{ paddingBottom: 0 }}>
            <div className="block">
              {!!mfaError && (
                <MultiFactorAuth
                  multiFactorError={mfaError}
                  moveBackToSignIn={moveBackToSignIn}
                />
              )}

              {!mfaError && (
                <EmailAndPassword
                  onContinue={onPasswordSignIn}
                  onSignInWithGoogle={onSignInWithGoogle}
                />
              )}
            </div>
          </Overflow>
        </LoginContent>
      </LoginWrap>

      {isGetStartedShown && (
        <StaleGetStartedPopup onContinue={() => setIsGetStartedShown(false)} />
      )}

      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}
    </Wrapper>
  );
};

export default Login;
