import { Notify, openDoc, openQueryWithTimestamp } from 'utils';
import {
  ITransfer,
  IResponse,
  ITransferInput,
  ICurrency,
  ICountry,
} from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';
import { errorHandler } from 'utils/errors';

export const getTransfers = async () => {
  try {
    const data = await db.collection('transfers').get();

    return openQueryWithTimestamp(data);
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface SubscribeToTransfersParams {
  entityId: string;
  callback: (transfers: ITransfer[]) => void;
}

export const subscribeToTransfers = ({
  entityId,
  callback,
}: SubscribeToTransfersParams) =>
  db
    .collection('transfers')
    .where('_owner', '==', entityId)
    .onSnapshot((query) => callback(openQueryWithTimestamp(query)));

export interface SubscribeToTransferByIdParams {
  transferId: string;
  callback: (transfer: ITransfer | null) => void;
}

export const subscribeToTransferById = ({
  transferId,
  callback,
}: SubscribeToTransferByIdParams) =>
  db
    .collection('transfers')
    .doc(transferId)
    .onSnapshot((doc) => callback(openDoc(doc)));

export const createTransfer = async (payload: ITransferInput) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<ITransfer>
    >(`${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/transfers`, payload);

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    errorHandler(error);
  }
};

export const createSimpleTransfer = async (payload: ITransferInput) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<ITransfer>
    >(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/transfers/simple`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface GetTransferPurposesParams {
  currency: ICurrency['code'];
  accountCountry?: ICountry['alpha2'];
}

export const getTransferPurposes = async ({
  currency,
  accountCountry,
}: GetTransferPurposesParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/transfers/purpose/${currency}/${accountCountry}`
  );
};

export const updateTransferPaymentSubmissionDetails = async (
  transferId: string
) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/transfers/mt103/${transferId}`
  );
};

interface ITtransferSendRemittanceParams {
  transferId: string;
  emails: string;
  shouldSendRemittance: boolean;
}

export const transferSendRemittance = async ({
  transferId,
  ...rest
}: ITtransferSendRemittanceParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/bff/transfers/${transferId}/send-remittance`,
    rest
  );
};
