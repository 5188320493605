import { useEffect, useState } from 'react';
import { getAccounts } from 'services/firebase/integrationEngine';
import { useStoreState } from 'state';
import { IIntegrationSettingsAccount } from 'types';
import { IAccountData } from 'types/integrations';
import { Notify } from 'utils';
import { generateIntegrationEngineBankFeesAccountData } from 'utils/integrations';

interface IFilteredAccounts {
  nonXeroAccounts: IAccountData[];
  bankFeesAccounts: IAccountData[];
  journalAccounts: IAccountData[];
  bankTransferAccounts: Record<string, IAccountData[]>;
}

const useNonXeroAccounts = (fetchAccounts = false) => {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [hasEntityCurrencyAccount, setHasEntityCurrencyAccount] = useState(
    false
  );
  const { entityId } = useStoreState((state) => state.UserState);
  const [
    defaultBankFeesAccount,
    setDefaultBankFeesAccount,
  ] = useState<IIntegrationSettingsAccount | null>(null);
  const [nonXeroAccounts, setNonXeroAccounts] = useState<IAccountData[]>([]);
  const [journalAccounts, setJournalAccounts] = useState<IAccountData[]>([]);
  const [bankFeesAccounts, setBankFeesAccounts] = useState<IAccountData[]>([]);
  const [bankTransferAccounts, setBankTransferAccounts] = useState<
    Record<string, IAccountData[]>
  >({});
  const [accounts, setAccounts] = useState<IIntegrationSettingsAccount[]>();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);

  useEffect(() => {
    const fetchNonXeroAccounts = async () => {
      try {
        if (!entityId) {
          console.warn(`entityId is undefined`);
          return;
        }

        setIsLoadingAccounts(true);
        const response = await getAccounts(entityId);
        if (response.data?.success) {
          const accounts = response.data.data;
          setAccounts(accounts);

          const foundEntityCurrencyAccount = accounts?.some(
            (account) => account.name === `HedgeFlows ${entityCurrencyCode}}`
          );

          if (foundEntityCurrencyAccount) {
            setHasEntityCurrencyAccount(foundEntityCurrencyAccount);
          }

          const findBankFeesAccount = accounts?.find(
            (account) => account.id === '27'
          );
          if (findBankFeesAccount) {
            setDefaultBankFeesAccount(findBankFeesAccount);
          }

          const filteredAccounts = accounts?.reduce<IFilteredAccounts>(
            (total, el) => {
              const currencyCode = el.currency;

              if (
                el.type === 'Asset' ||
                el.category?.toUpperCase() === 'ASSETS' ||
                el.category?.toUpperCase() === 'ASSET'
              ) {
                total.nonXeroAccounts = [
                  ...total?.nonXeroAccounts,
                  generateIntegrationEngineBankFeesAccountData(el),
                ];

                if (currencyCode && el.isBankAccount === true) {
                  if (Array.isArray(total.bankTransferAccounts[currencyCode])) {
                    total.bankTransferAccounts[currencyCode].push(
                      generateIntegrationEngineBankFeesAccountData(el)
                    );
                  } else {
                    total.bankTransferAccounts[currencyCode] = [
                      generateIntegrationEngineBankFeesAccountData(el),
                    ];
                  }
                }
              }
              if (
                el.type === 'Expense' ||
                el.category?.toUpperCase() === 'EXPENSE'
              ) {
                total.bankFeesAccounts = [
                  ...total?.bankFeesAccounts,
                  // override the type to work with the new Integration Engine,
                  // which relies on the type to save it to the correct location
                  generateIntegrationEngineBankFeesAccountData({
                    ...el,
                    type: 'bankFeesAccount',
                  }),
                ];
              }
              if ((el.type as any) === 'journal') {
                total.journalAccounts = [
                  ...total?.journalAccounts,
                  generateIntegrationEngineBankFeesAccountData(el),
                ];
              }
              return total;
            },
            {
              nonXeroAccounts: [],
              bankFeesAccounts: [],
              journalAccounts: [],
              bankTransferAccounts: {},
            }
          );

          if (filteredAccounts?.nonXeroAccounts?.length) {
            setNonXeroAccounts(filteredAccounts.nonXeroAccounts);
          }
          if (filteredAccounts?.bankFeesAccounts?.length) {
            setBankFeesAccounts(filteredAccounts.bankFeesAccounts);
          }
          if (filteredAccounts?.journalAccounts?.length) {
            setJournalAccounts(filteredAccounts.journalAccounts);
          }
          if (
            Object.keys(filteredAccounts?.bankTransferAccounts ?? {}).length
          ) {
            setBankTransferAccounts(
              filteredAccounts?.bankTransferAccounts ?? {}
            );
          }
        }
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoadingAccounts(false);
      }
    };

    if (fetchAccounts) {
      fetchNonXeroAccounts();
    }
  }, [entityCurrencyCode, fetchAccounts, entityId]);

  return {
    allAccounts: accounts,
    isLoadingAccounts,
    hasEntityCurrencyAccount,
    nonXeroAccounts,
    bankFeesAccounts,
    defaultBankFeesAccount,
    journalAccounts,
    bankTransferAccounts,
  };
};

export default useNonXeroAccounts;
