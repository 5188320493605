import { FC } from 'react';
import { useStoreState } from 'state';
import { Col, Paragraph, Row, Loader } from 'components';
import DirectionIcon from 'components/shared/DirectionIcon/DirectionIcon';
import { parseIntoShortNumberWithSymbol } from 'utils';
import NetworthInfoRow from './components/NetworthInfoRow/NetworthInfoRow';
import { NetWrapper } from './Networth.styles';
import NetworthSection from './components/NetworthSection/NetworthSection';
import {
  getInflowCustomDefinitions,
  getOutflowCustomDefinitions,
} from 'pages/Risks/utils';
import { getCustomCashflowsByDefinitionId } from './utils';
import InflowsIcon from 'components/shared/InflowsIcon/InflowsIcon';
import OutflowsIcon from 'components/shared/OutflowsIcon/OutflowsIcon';
import HedgesIcon from '../HedgesIcon/HedgesIcon';
import { INetworthValues } from 'types';

interface IOwnProps {
  values?: INetworthValues;
  currencyCode: string | null;
}

const Networth: FC<IOwnProps> = ({ currencyCode, values }) => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const entityCurrencySymbol = currencyByCode(userEntity?.entityCurrency)
    ?.symbol;
  const currency =
    currencyByCode(currencyCode) || currencyByCode(userEntity?.entityCurrency);
  const isEntityCurrency = currency?.code === userEntity?.entityCurrency;
  const inflowCustomDefinitions = getInflowCustomDefinitions(
    userEntity?.customCashflowDefinitions
  );
  const outflowCustomDefinitions = getOutflowCustomDefinitions(
    userEntity?.customCashflowDefinitions
  );

  return (
    <Col alignSelf="stretch" justifyContent="flex-start" flex={0.4}>
      {!values ? (
        <Loader size="large" />
      ) : (
        <>
          <NetworthSection
            zIndex={4}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Cash balance"
                amount={values?.balance + values?.externalBalance}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="with HedgeFlows"
                  amount={values?.balance}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="with 3d parties"
                  amount={values?.externalBalance}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
          <NetworthSection
            zIndex={3}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Cash Inflows"
                amount={values?.receivables}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
                icon={<InflowsIcon />}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="Invoices"
                  amount={values?.receivableInvoices}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="Sales Orders"
                  amount={values?.saleOrders}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                {inflowCustomDefinitions.map((definition) => (
                  <NetworthInfoRow
                    key={definition.id}
                    title={definition.name}
                    amount={
                      getCustomCashflowsByDefinitionId(
                        values?.customValues,
                        definition.id
                      )?.sum
                    }
                    variant="grey"
                    currencySymbol={currency?.symbol}
                  />
                ))}
              </>
            }
          />
          <NetworthSection
            zIndex={2}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Cash Outflows"
                amount={values?.payables}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
                icon={<OutflowsIcon />}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="Suppliers"
                  amount={values?.payableInvoices}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="Purchase Orders"
                  amount={values?.purchaseOrders}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                {outflowCustomDefinitions.map((definition) => (
                  <NetworthInfoRow
                    key={definition.id}
                    title={definition.name}
                    amount={
                      getCustomCashflowsByDefinitionId(
                        values?.customValues,
                        definition.id
                      )?.sum
                    }
                    variant="grey"
                    currencySymbol={currency?.symbol}
                  />
                ))}
              </>
            }
          />
          <NetworthSection
            zIndex={1}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Hedges"
                amount={values?.prebooked + values?.externalHedges}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
                icon={<HedgesIcon />}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="with HedgeFlows"
                  amount={values?.prebooked}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="with 3d parties"
                  amount={values?.externalHedges}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
          <NetworthSection
            zIndex={0}
            renderContent={() => (
              <NetWrapper>
                <Row>
                  <Paragraph variant="bold">Net balance:</Paragraph>

                  <Row>
                    <DirectionIcon
                      direction={values?.netExposure >= 0 ? 'in' : 'out'}
                    />
                    <Paragraph variant="bold">
                      {parseIntoShortNumberWithSymbol(
                        values?.netExposure,
                        currency?.symbol
                      )}
                    </Paragraph>
                  </Row>
                </Row>
                {!isEntityCurrency && (
                  <Row>
                    <Paragraph>{userEntity.entityCurrency} value</Paragraph>
                    <Paragraph>
                      {parseIntoShortNumberWithSymbol(
                        values?.netExposureInSellCurrency,
                        entityCurrencySymbol
                      )}
                    </Paragraph>
                  </Row>
                )}
              </NetWrapper>
            )}
          />
        </>
      )}
    </Col>
  );
};

export default Networth;
