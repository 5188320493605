import { Column, FilterTypes } from 'react-table';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  Col,
  Paragraph,
  ProgressBar,
  Row,
  Title,
  Icon,
  ParagraphWithEllipsis,
  PermissionsChecker,
} from 'components';
import {
  camelCaseToSentenceCase,
  canUseRateContract,
  getPrebookStatusDetails,
  parseIntoCurrencyString,
} from 'utils';
import { CONTRACT_STATUS, IRateContract, ICurrency } from 'types';
import {
  getInvoiceDetailsLink,
  getInvoiceManualUploadLink,
  getTransfersPageLink,
} from 'utils/links';
import { IExternalHedge } from 'types/externalHedges';
import { isExternalHedge } from './utils';
import {
  isRateContractAvailable,
  isRateContractExpired,
  isRateContractUsed,
} from 'utils/rateContracts';
import CurrencyRateCell from './components/CurrencyRateCell/CurrencyRateCell';
import Button from '../Button/Button';
dayjs.extend(relativeTime);

export type TPrebookTableFilterValue =
  | 'default'
  | 'available'
  | 'expired'
  | 'used';

export const filterTypes: FilterTypes<IRateContract | IExternalHedge> = {
  filtering: (rows, _, filterValue) => {
    if (filterValue === 'default') {
      return rows;
    }

    if (filterValue === 'available') {
      return rows.filter(({ original }) => isRateContractAvailable(original));
    }

    if (filterValue === 'expired') {
      return rows.filter(({ original }) => isRateContractExpired(original));
    }

    if (filterValue === 'used') {
      return rows.filter(({ original }) => isRateContractUsed(original));
    }

    return rows;
  },
};

export const generatePrebookTableColumns = (
  currencyByCode: (currencyCode: string) => ICurrency | null,
  history: any
): Column<IRateContract | IExternalHedge>[] => {
  return [
    {
      id: 'rate',
      Header: 'Currency rate',
      disableSortBy: true,
      Cell: ({ row }: any) => <CurrencyRateCell data={row.original} />,
      width: 100,
    },
    {
      id: 'filtering',
      filter: 'filtering',
      Header: 'Buy amount',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const isRecordExternalHedge = isExternalHedge(row.original);
        const { buyCurrency, remainingBuyAmount, buyAmount } = row.original;

        return (
          <Col>
            <Paragraph>
              <Title as="span" style={{ lineHeight: '24px' }} variant="h3">
                {parseIntoCurrencyString(
                  isRecordExternalHedge ? buyAmount : remainingBuyAmount,
                  currencyByCode(buyCurrency)?.precision
                )}
              </Title>{' '}
              {buyCurrency}
            </Paragraph>

            {!isRecordExternalHedge && (
              <ProgressBar progress={remainingBuyAmount / (buyAmount / 100)} />
            )}
          </Col>
        );
      },
      width: 100,
    },
    {
      accessor: 'sellAmount',
      filter: 'filtering',
      Header: 'Sell amount',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { sellCurrency, sellAmount } = row.original;
        // external hedges do not have remaining total amount, switch to sellAmount in that case
        const amountToUse = !isExternalHedge(row.original)
          ? row.original.remainingTotalAmount
          : sellAmount;

        return (
          <Col>
            <Paragraph>
              <Title as="span" style={{ lineHeight: '24px' }} variant="h3">
                {parseIntoCurrencyString(
                  amountToUse,
                  currencyByCode(sellCurrency)?.precision
                )}
              </Title>{' '}
              {sellCurrency}
            </Paragraph>
          </Col>
        );
      },
      width: 100,
    },
    {
      id: 'use',
      Header: 'Use by',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { status, expiryDate } = row.original;
        const expiryDateTime = dayjs().isSame(expiryDate) ? '23:59:59' : '';
        const isExpiredDate = dayjs().isAfter(expiryDate);

        return (
          <Paragraph>
            {`${
              status === CONTRACT_STATUS.expired || isExpiredDate
                ? '0 days'
                : camelCaseToSentenceCase(
                    dayjs().to(dayjs(`${expiryDate} ${expiryDateTime}`), true)
                  )
            } left | ${dayjs(expiryDate).format('DD.MM.YYYY')}`}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const isRecordExternalHedge = isExternalHedge(row.original);
        const statusInfo = getPrebookStatusDetails(row.original);
        const { invoiceId, status } = row.original;

        const showInvoiceStatus =
          status !== CONTRACT_STATUS.expired &&
          status !== CONTRACT_STATUS.cancelled;

        return (
          <>
            {!isRecordExternalHedge &&
              ((!invoiceId && statusInfo) || !showInvoiceStatus) && (
                <Row>
                  <Paragraph
                    className="status"
                    style={{ color: statusInfo?.color }}
                  >
                    {statusInfo?.text}
                  </Paragraph>
                </Row>
              )}

            {!isRecordExternalHedge && !!invoiceId && showInvoiceStatus && (
              <Row>
                <Paragraph className="status">
                  Prebooked for an invoice
                </Paragraph>
              </Row>
            )}

            {isRecordExternalHedge && (
              <Row>
                <Paragraph className="status">External hedge</Paragraph>
              </Row>
            )}
          </>
        );
      },
      width: 100,
    },
    {
      id: 'notes',
      Header: 'Notes',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <ParagraphWithEllipsis title={row.original.notes}>
          {row.original.notes}
        </ParagraphWithEllipsis>
      ),
      width: 100,
    },
    {
      id: 'dropdown',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ row }: any) => {
        const {
          invoiceId,
          id,
          remainingBuyAmount,
          buyCurrency,
        }: IRateContract = row.original;

        return (
          <Row justifyContent="flex-end" flex={1}>
            {canUseRateContract(row.original) && (
              <PermissionsChecker action="create" resource="transfers">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();

                    history.push(
                      getTransfersPageLink({
                        predefinedBuyAmount: remainingBuyAmount.toString(),
                        predefinedBuyCurrency: buyCurrency,
                        predefinedRateContractId: id,
                      })
                    );
                  }}
                >
                  <Row alignItems="center">
                    <Paragraph>Transfer now</Paragraph>

                    <Icon
                      ml
                      width="20px"
                      height="20px"
                      icon="transfer-ico"
                      fill={'#1CBD85'}
                    />
                  </Row>
                </Button>
              </PermissionsChecker>
            )}

            {!!invoiceId && (
              <Button
                variant="link"
                onClick={(e) => {
                  e.stopPropagation();

                  history.push(getInvoiceDetailsLink({ invoiceId }));
                }}
              >
                <Icon
                  ml
                  width="20px"
                  height="20px"
                  icon="file-ico"
                  fill={'#1CBD85'}
                />
              </Button>
            )}

            {isExternalHedge(row.original) && (
              <Button
                variant="link"
                onClick={(e) => {
                  e.stopPropagation();

                  history.push(
                    getInvoiceManualUploadLink({
                      tab: 'hedges',
                      dataType: 'externalHedge',
                      currency: row.original.currency,
                      externalHedgeToEditId: row.original.id,
                    })
                  );
                }}
              >
                <Icon ml width="20px" height="20px" icon="edit-ico" />
              </Button>
            )}

            <Icon ml mr width="20px" height="20px" icon="info-ico" />
          </Row>
        );
      },
      width: 100,
    },
  ];
};
