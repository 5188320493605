import { FC } from 'react';
import { Icon } from 'components';
import { IInvoiceFromSearch } from 'types';
import { useTheme } from 'styled-components';
import { getInvoiceFromSearchSyncedToExternalSourceStatusDetails } from 'utils/invoices';
import { Row } from 'components/shared/Row/Row';
import { useInvoiceFromSearchLastSyncDetails } from 'hooks/useInvoiceFromSearchLastSyncDetails';

interface OwnProps {
  record: IInvoiceFromSearch;
}

const InvoiceSyncedToExternalSourceCell: FC<OwnProps> = ({ record }) => {
  const theme = useTheme();
  const {
    lastSyncToExternalSourceStatus,
  } = useInvoiceFromSearchLastSyncDetails(record);
  const statusDetails = getInvoiceFromSearchSyncedToExternalSourceStatusDetails(
    {
      lastSyncToExternalSourceStatus,
    }
  );

  if (!statusDetails) {
    return null;
  }

  const { icon, color } = statusDetails;

  return (
    <Row flex={1} justifyContent="center">
      <Icon icon={icon} fill={theme.color[color]} />
    </Row>
  );
};

export default InvoiceSyncedToExternalSourceCell;
