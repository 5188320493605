import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Paragraph, Popup, Title } from 'components';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import Button from '../Button/Button';

const IntegrationReauthenticatePopup: FC = () => {
  const history = useHistory();
  const {
    reauthenticateIntegration,
    onLogin: onReauthenticate,
    isLoading,
  } = useIntegrationEngine();

  const goToDashboard = () => history.push('/app/dashboard');

  if (!reauthenticateIntegration) {
    return null;
  }

  return (
    <Popup
      width="439px"
      height="324px"
      onClose={goToDashboard}
      HeaderContent={
        <Title variant="h3">Reauthenticate with integration</Title>
      }
      FooterContent={
        <Button onClick={() => onReauthenticate()} disabled={isLoading}>
          Reauthenticate with integration
        </Button>
      }
    >
      <Paragraph>
        We've recently made some improvements to this screen. Before you can use
        it, we need to ask you to reapprove the integration.
      </Paragraph>
    </Popup>
  );
};

export default IntegrationReauthenticatePopup;
