import { FC } from 'react';
import { StyledUnorderedList, ListItem } from './UnorderedList.styles';

interface OwnProps {
  data: string[];
}

const UnorderedList: FC<OwnProps> = ({ data }) => {
  return (
    <StyledUnorderedList>
      {data.map((text) => (
        <ListItem key={text}>{text}</ListItem>
      ))}
    </StyledUnorderedList>
  );
};

export default UnorderedList;
