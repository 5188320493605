import { ContextMenu, Paragraph, Row } from 'components';
import dayjs from 'dayjs';
import { Column } from 'react-table';
import { ICurrency, ITransfer } from 'types';
import {
  parseRateWithPrecision,
  parseIntoCurrencyString,
  getTransferStatusDetails,
} from 'utils';

export const generateTransferTableColumns = (
  transfersByBulkId: (bulkId: string) => ITransfer[],
  goToTransfer: (record: ITransfer) => void,
  currencyByCode: (currencyCode: string) => ICurrency | null
): Column<ITransfer>[] => {
  return [
    {
      id: 'recipient',
      Header: 'Recipient',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { recipient, id } = row.original;
        return (
          <Paragraph>
            {recipient?.recipientName ||
              `Bulk Payment (${transfersByBulkId(id).length || '-'} Transfers)`}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'amount',
      Header: 'Amount',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { buyAmount, buyCurrency } = row.original;
        return (
          <Paragraph>
            {`${parseIntoCurrencyString(
              buyAmount,
              currencyByCode(buyCurrency)?.precision
            )} ${buyCurrency}` || '-'}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'conversionRate',
      Header: 'Conversion rate',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { sellCurrency, buyCurrency, rate } = row.original;
        return (
          <Paragraph>
            {`1 ${sellCurrency} = ${parseRateWithPrecision(
              rate
            )} ${buyCurrency}`}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'date',
      Header: 'Payment date',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <Paragraph>
          {dayjs(
            row.original.scheduledPaymentDate ?? row.original._created
          ).format('DD MMM, YYYY')}
        </Paragraph>
      ),
      width: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const statusInfo = getTransferStatusDetails(row.original);

        return (
          statusInfo && (
            <Paragraph style={{ color: statusInfo.color }}>
              {statusInfo.text}
            </Paragraph>
          )
        );
      },
      width: 100,
    },
    {
      id: 'dropdown',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ row }: any) => (
        <Row className="delete" style={{ justifyContent: 'flex-end', flex: 1 }}>
          <ContextMenu
            list={[
              {
                id: 0,
                title: 'See details',
                onClick: () => goToTransfer(row.original),
              },
            ]}
          />
        </Row>
      ),
      width: 100,
    },
  ];
};
