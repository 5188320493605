import { ICurrency } from 'types';

export const getCurrencyDropdownValues = ({
  currencyCodes,
  currencyByCode,
  isIntegratedWithXero,
}: {
  currencyCodes: string[];
  currencyByCode: (currencyCode: string) => ICurrency | null;
  isIntegratedWithXero: boolean;
}) => {
  let values =
    currencyCodes?.map((currencyCode) => ({
      id: currencyCode,
      name: currencyCode,
      value: currencyCode,
      icon: currencyByCode(currencyCode)?.countryCode,
    })) || [];

  if (isIntegratedWithXero) {
    values = [
      {
        id: 'all',
        name: 'All currencies',
        value: 'all',
        icon: undefined,
      },
      ...values,
    ];
  }

  return values;
};
