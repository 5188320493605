import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AmountHeading, AmountWrapper, Wrapper } from './PopupTracking.styles';
import {
  ResponsiveCenteredContainer,
  StaleBtnGroup,
  Paragraph,
  TextHint,
  StalePopup,
  Row,
  InlineLoader,
  Icon,
} from 'components';
import InfoCard from '../../InfoCard/InfoCard';
import ReactSlider from 'react-slider';
import dayjs from 'dayjs';
import { Firebase } from 'services';
import { useStoreActions, useStoreState } from 'state';
import {
  parseIntoCurrencyString,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import { IInvoiceFromSearch } from 'types';
import useCurrencyRate from 'hooks/useCurrencyRate';
import { getInvoiceNumber } from 'utils/invoices';
import {
  getInvoicePrebookLink,
  getInvoiceTransferOrSimpleTransferLink,
} from 'utils/links';
import Button from 'components/shared/Button/Button';
import { DB_DATE_FORMAT } from 'variables';
import { errorHandler } from 'utils/errors';

interface OwnProps {
  invoice: IInvoiceFromSearch;
  onChangeTarget: (invoice: IInvoiceFromSearch) => void;
  onClose: () => void;
}

const PopupTracking: FC<OwnProps> = ({ onClose, onChangeTarget, invoice }) => {
  const history = useHistory();

  const [tracking, setTracking] = useState<any>(null);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { rate } = useCurrencyRate({
    buyCurrency: invoice.currency,
    sellCurrency: entityCurrencyCode,
  });
  const [bookingFeeRate, setBookingFeeRate] = useState<number>(0);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { rateAnalyses } = useStoreState((state) => state.ReferenceDataState);
  const { getRateAnalyses } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const { getInvoiceTracking } = useStoreActions(
    (actions) => actions.InvoicesState
  );

  const currency = currencyByCode(invoice.currency);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  useEffect(() => {
    if (invoice.trackingId) {
      getInvoiceTracking({
        trackingId: invoice.trackingId,
      }).then((data: any) => {
        setTracking(data);
      });
    }
  }, [invoice.trackingId, getInvoiceTracking]);

  useEffect(() => {
    if (invoice && rate) {
      getRateAnalyses({
        buyCurrency: invoice.currency,
        sellCurrency: entityCurrencyCode,
        buyAmount: invoice.totalAmount,
        sellAmount: invoice.totalAmount / rate,
      });
    }
  }, [invoice, rate, getRateAnalyses, entityCurrencyCode]);

  useEffect(() => {
    if (invoice) {
      Firebase.getForwardRateAndFees({
        sellCurrency: entityCurrencyCode,
        buyCurrency: invoice.currency,
        dateString: dayjs(invoice.dueDate).format(DB_DATE_FORMAT),
      })
        .then((response) => {
          if (response.data) {
            setBookingFeeRate(response.data.bookingFeeRate);
          }
        })
        .catch(errorHandler);
    }
  }, [entityCurrencyCode, invoice]);

  const activeRateAnalysesResult = useMemo(() => rateAnalyses?.data[2], [
    rateAnalyses,
  ]);

  const goToTransfer = () =>
    history.push(
      getInvoiceTransferOrSimpleTransferLink(invoice, entityCurrencyCode)
    );
  const goToPrebook = () => {
    if (entityCurrencyCode) {
      history.push(getInvoicePrebookLink(invoice, entityCurrencyCode));
    }
  };

  return (
    <StalePopup
      title={`Invoice ${getInvoiceNumber(invoice)}`}
      theme="grey"
      width="439px"
      headContentAdditional={
        <Paragraph style={{ marginLeft: 'auto', marginRight: 12 }}>
          {`Due date ${dayjs(invoice.dueDate).format('D MMM, YYYY')}`}
        </Paragraph>
      }
      onClose={onClose}
    >
      <Wrapper>
        <ResponsiveCenteredContainer>
          <Row
            style={{
              alignItems: 'flex-start',
            }}
          >
            <AmountWrapper>
              <AmountHeading>Amount due</AmountHeading>
              <Row>
                <svg width="24px" height="24px" style={{ marginRight: '8px' }}>
                  <use xlinkHref={`#${currency?.countryCode}`} />
                </svg>
                <Paragraph variant="bold">{`${
                  currency?.symbol
                }${parseIntoCurrencyString(
                  invoice.totalAmount,
                  currency?.precision
                )}`}</Paragraph>
              </Row>
            </AmountWrapper>

            <AmountWrapper>
              <AmountHeading>Current</AmountHeading>
              <Row>
                <Icon icon={entityCurrency?.countryCode ?? ''} />
                <Paragraph variant="bold">
                  {rate ? (
                    `${parseIntoCurrencyString(invoice.totalAmount / rate)}`
                  ) : (
                    <InlineLoader />
                  )}
                </Paragraph>
              </Row>
            </AmountWrapper>

            <AmountWrapper>
              <Row>
                <div
                  style={{
                    height: 16,
                    border: '2px dotted #8C9199',
                    marginRight: 8,
                  }}
                />
                <AmountHeading>Target</AmountHeading>
              </Row>
              <Paragraph variant="bold">{`${
                currency?.symbol
              }${parseIntoCurrencyString(
                tracking?.targetAmount,
                currency?.precision
              )}`}</Paragraph>

              <Button
                onClick={() => {
                  onChangeTarget(invoice);
                  onClose();
                }}
                variant="link"
              >
                Change
              </Button>
            </AmountWrapper>
          </Row>

          <InfoCard skin="border">
            <div className="tracked-block">
              <div className="col">
                <span>Lowest</span>

                <p>
                  <span>{`${parseIntoCurrencyStringWithSymbol(
                    activeRateAnalysesResult?.lowest,
                    entityCurrency?.symbol,
                    entityCurrency?.precision
                  )}`}</span>
                </p>
              </div>
              <div
                className="col"
                style={{
                  flex: '1 0 auto',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {rate ? (
                  <ReactSlider
                    value={[tracking?.targetAmount, invoice.totalAmount / rate]}
                    min={activeRateAnalysesResult?.lowest}
                    max={activeRateAnalysesResult?.highest}
                    className="cost-range"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    disabled
                    renderThumb={(props) => {
                      if (props.key === 'example-thumb-1') {
                        return (
                          <div {...props}>
                            <div className="tooltip">Current</div>
                          </div>
                        );
                      }

                      return (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            border: '2px dashed #8C9199',
                          }}
                        />
                      );
                    }}
                  />
                ) : (
                  <InlineLoader />
                )}
              </div>
              <div className="col">
                <span>Highest</span>

                <p>
                  <span>{`${parseIntoCurrencyStringWithSymbol(
                    activeRateAnalysesResult?.highest,
                    entityCurrency?.symbol,
                    entityCurrency?.precision
                  )}`}</span>
                </p>
              </div>
            </div>

            <TextHint>Last 90 days rate changes</TextHint>
          </InfoCard>

          <InfoCard icon="lock-ico">
            <Paragraph variant="bold">
              Current rate:{' '}
              <span>
                {`${entityCurrency?.symbol}`}1.00 ={' '}
                {`${currency?.symbol}${rate}`}
              </span>
            </Paragraph>
            <Paragraph>
              {`Booking fee: ${
                bookingFeeRate && rate
                  ? parseIntoCurrencyStringWithSymbol(
                      (invoice.totalAmount / rate) * bookingFeeRate,
                      entityCurrency?.symbol,
                      entityCurrency?.precision
                    )
                  : ''
              } to secure this this rate until ${dayjs(invoice.dueDate).format(
                'D MMM, YYYY'
              )}`}
            </Paragraph>
          </InfoCard>
        </ResponsiveCenteredContainer>

        <StaleBtnGroup container={false}>
          <Button onClick={goToPrebook}>Prebook</Button>

          <Button variant="link" onClick={goToTransfer}>
            Pay now
          </Button>
        </StaleBtnGroup>
      </Wrapper>
    </StalePopup>
  );
};

export default PopupTracking;
