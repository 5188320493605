import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: fixed;
    background: rgb(18, 18, 19, 0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    .popup {
      background: ${theme.color.white};
      padding: 16px;
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      position: relative;
    }
  `
);
