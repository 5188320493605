import styled from 'styled-components';
import { Col } from 'components/shared/Col/Col';

export const StyledQRCol = styled(Col)`
  max-width: 150px;
`;

export const StyledQRImage = styled.img`
  width: 100%;
`;
