import { CASHFLOW_TYPE_NEW } from 'services/cashflows';

export const CASHFLOW_TYPE_TO_TITLE: Record<CASHFLOW_TYPE_NEW, string> = {
  custom: 'Custom',
  balance: 'Balance',
  externalBalance: 'External Balance',
  externalHedge: 'External Hedge',
  hedge: 'Hedge',
  payableInvoice: 'Payable Invoice',
  receivableInvoice: 'Receivable Invoice',
  purchaseOrder: 'Purchase Order',
  salesOrder: 'Sales Order',
};
