import { useStoreState } from 'state';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from './useQuery';

/**
 * Redirect user to integration settings page flow if user has just integrated with accounting system
 */
const useOnIntegrationConnection = () => {
  const history = useHistory();
  const location = useLocation();
  const URLQuery = useQuery();
  const noSettingsRedirect = URLQuery.get('noSettingsRedirect');
  const {
    integrationsSummary,
    integrationEngineSettings,
    isLoadingIntegrationEngineSettings,
  } = useStoreState(({ UserState }) => UserState);

  useEffect(() => {
    if (!integrationsSummary) {
      return;
    }

    if (
      !isLoadingIntegrationEngineSettings &&
      !integrationEngineSettings.settings && // if user has gone through settings once, this will be true
      !!integrationsSummary.connected &&
      location.pathname !== `/signup/${integrationsSummary.system}` &&
      location.pathname !== `/app/new-client/${integrationsSummary.system}` &&
      location.pathname !== `/app/settings/${integrationsSummary.system}`
    ) {
      history.push(
        `/app/settings/${integrationsSummary.system}?step=1&firstIntegration=true`
      );
    }
  }, [
    history,
    integrationEngineSettings.settings,
    integrationsSummary,
    isLoadingIntegrationEngineSettings,
    location.pathname,
    noSettingsRedirect,
  ]);
};

export default useOnIntegrationConnection;
