import axios from 'axios';

import { openDoc, Notify, openQuery } from 'utils';
import { ICurrency, IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';
import { Deposit } from 'pages/Prebook/types';

export interface GetNonTradingDaysParams {
  ccyPair: string;
}

export const getNonTradingDays = async ({
  ccyPair,
}: GetNonTradingDaysParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get<IResponse>(
      `contracts/holidays/${ccyPair}`
    );

    if (data?.success) {
      return data;
    }
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export const getSystemVariables = async () => {
  try {
    const data = await db
      .collection('referenceData')
      .doc('systemVariables')
      .get()
      .then((doc) => openDoc(doc));

    return data;
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export const getNatureOfBusiness = async () => {
  try {
    const data = await db
      .collection('referenceData')
      .doc('natureOfBusiness')
      .get()
      .then((doc) => openDoc(doc));

    return data;
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export const getCountries = async () => {
  try {
    const data = await db
      .collection('referenceData')
      .doc('collections')
      .collection('countries')
      .get()
      .then((query) => openQuery(query));

    return data;
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export const getSwiftFees = () =>
  db
    .collection('referenceData')
    .doc('collections')
    .collection('fees')
    .get()
    .then((query) => openQuery(query));

export interface GetRatingsParams {
  sellCurrency: ICurrency['code'];
  buyCurrency: ICurrency['code'];
  rate: number;
}

export const getRatings = async ({
  rate,
  sellCurrency,
  buyCurrency,
}: GetRatingsParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/ratings?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&current_rate=${rate}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
    }
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export interface GetRateAnalysisParams {
  sellCurrency: ICurrency['code'];
  buyCurrency: ICurrency['code'];
  sellAmount: number;
  buyAmount: number;
}

export const getRateAnalysis = async ({
  sellAmount,
  buyAmount,
  sellCurrency,
  buyCurrency,
}: GetRateAnalysisParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/rate-analysis?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&sell_amount=${sellAmount}&buy_amount=${buyAmount}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
    }
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export interface GetPotentialSavingsParams {
  sellCurrency: ICurrency['code'];
  buyCurrency: ICurrency['code'];
  buyAmount: number;
}

export const getPotentialSavings = async ({
  buyAmount,
  sellCurrency,
  buyCurrency,
}: GetPotentialSavingsParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/potential-savings?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&buy_amount=${buyAmount}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
    }
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export interface GetPotentialSavingsWithDateParams
  extends GetPotentialSavingsParams {
  tenorDate: string;
}

export const getPotentialSavingsWithDate = async ({
  buyAmount,
  sellCurrency,
  buyCurrency,
  tenorDate,
}: GetPotentialSavingsWithDateParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/potential-savings?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&buy_amount=${buyAmount}&tenor_date=${tenorDate}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
    }
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export interface GetRateAnalysisTableParams {
  rate: number;
  deposit: Deposit['rate'];
  bookingFee: Deposit['fee'];
  buyAmount: number;
}

export const getRateAnalysisTable = async ({
  buyAmount,
  deposit,
  bookingFee,
  rate,
}: GetRateAnalysisTableParams) =>
  AxiosPrivateFirebaseInstance.get<IResponse>(
    `/analysis/rate-analysis-table?buy_amount=${buyAmount}&rate=${rate}&deposit=${deposit}&booking_fee=${bookingFee}&conversion_fee=0.004`
  );

export const getRiskContribution = async () => {
  return db
    .collection('referenceData')
    .doc('riskContribution')
    .get()
    .then(openDoc);
};
