import { IInvoice, INVOICE_STATUSES } from 'types';
import InvoiceLastSyncDetails from './components/InvoiceLastSyncDetails/InvoiceLastSyncDetails';
import { FC, useMemo, useState } from 'react';
import { Paragraph, Title } from '../Typography/Typography';
import { Row } from '../Row/Row';
import { useTheme } from 'styled-components';
import { Col } from '../Col/Col';
import InputBase from '../InputBase/InputBase';
import { Flex } from '../Flex/Flex.styles';
import dayjs from 'dayjs';
import { DAY_MONTH_DATE_FORMAT } from 'variables';
import Icon from '../Icon/Icon';
import InvoiceRate from './components/InvoiceRate/InvoiceRate';
import { isRateContractCanBeUsedNow, parseIntoCurrencyString } from 'utils';
import StalePrebookRateNotification from '../StalePrebookRateNotification/StalePrebookRateNotification';
import { OverflowXWrapper } from '../OverflowXWrapper/OverflowXWrapper.styles';
import LineItemsTable from './components/LineItemsTable/LineItemsTable';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { useStoreState } from 'state';
import { getInvoiceContactBillingAddress } from './utils';
import { orderBy } from 'lodash';
import useInvoiceRecord from 'hooks/useInvoiceRecord';
import useCurrencyRate from 'hooks/useCurrencyRate';
import UseExistingPrebook from './components/UseExistingPrebook/UseExistingPrebook';

interface IOwnProps {
  invoice: IInvoice;
}

const InvoiceDetailsContent: FC<IOwnProps> = ({ invoice }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { transferById } = useStoreState((state) => state.TransfersState);
  const { rateContractById, rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { recipientById } = useStoreState((state) => state.RecipientsState);
  const invoiceContact = recipientById(invoice.contactId);
  const currency = currencyByCode(invoice.currency);
  const entityCurrency = currencyByCode(entityCurrencyCode);
  const prebookedRateContract = rateContractById(invoice.contractId);
  const invoiceTransferIds = invoice.transferIds ?? [];
  const invoiceTransfers = invoiceTransferIds.map(transferById);
  const availablePrebookedRate = useMemo(
    () =>
      orderBy(
        rateContractsByCurrencyPair(
          entityCurrencyCode,
          invoice.currency
        ).filter(
          (item) =>
            item.remainingBuyAmount >= (invoice.amountDue ?? 0) &&
            isRateContractCanBeUsedNow(item)
        ),
        'rate',
        'desc'
      )[0],
    [
      entityCurrencyCode,
      invoice.amountDue,
      invoice.currency,
      rateContractsByCurrencyPair,
    ]
  );
  const { isCanBePaid, isPayable } = useInvoiceRecord({
    record: invoice,
  });
  const { rate } = useCurrencyRate({
    buyCurrency: isPayable ? invoice.currency : entityCurrencyCode,
    sellCurrency: isPayable ? entityCurrencyCode : invoice.currency,
  });
  const [existingPrebookCurrency, setExistingPrebookCurrency] = useState<
    string | null
  >(null);

  return (
    <>
      <InvoiceLastSyncDetails invoice={invoice} />
      <Paragraph mb variant="bold">
        Bill to
      </Paragraph>
      <Row
        mb
        alignItems="flex-start"
        flexDirection={isMobile ? 'column' : 'row'}
        gap={theme.spacing.m}
      >
        <Col flex={1} alignSelf="stretch" gap={theme.spacing.m}>
          <InputBase
            label="Company"
            type="text"
            disabled
            value={
              invoiceContact?.recipientName ||
              invoice.externalRefs?.sourceSystemContactName
            }
          />
          <InputBase
            label="Email"
            type="text"
            disabled
            value={invoiceContact?.recipientEmail || 'Not specified'}
          />
          <InputBase
            label="Billing address"
            type="text"
            disabled
            value={getInvoiceContactBillingAddress(invoiceContact)}
          />
        </Col>

        <Col flex={1} alignSelf="stretch">
          <Flex
            alignItems="flex-start"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Col flex={1} alignSelf="stretch" gap={theme.spacing.m}>
              <InputBase
                label="Invoice Date"
                type="text"
                disabled
                value={dayjs(invoice.date).format(DAY_MONTH_DATE_FORMAT)}
              />
              <InputBase
                label="Due Date"
                type="text"
                disabled
                value={dayjs(invoice.dueDate).format(DAY_MONTH_DATE_FORMAT)}
              />
            </Col>

            <Col flex={1}>
              {!!currency && (
                <Row
                  mb
                  mt={isMobile}
                  gap={theme.spacing.xs}
                  justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                >
                  <Paragraph>Invoice currency:</Paragraph>
                  <Icon icon={currency.countryCode} />
                  <Paragraph>{invoice.currency}</Paragraph>
                </Row>
              )}

              {invoiceTransfers.map((transfer) => (
                <InvoiceRate
                  prebookedRateContract={prebookedRateContract}
                  currency={invoice.currency}
                  invoiceType={invoice.type}
                  transfer={transfer}
                  rate={rate}
                />
              ))}

              {invoice?.status === INVOICE_STATUSES.partiallyPaid && (
                <Row
                  mb
                  gap={theme.spacing.xs}
                  justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                >
                  <Paragraph>Due amount:</Paragraph>
                  <Title variant="h5">
                    {currency?.symbol}
                    {parseIntoCurrencyString(invoice.amountDue)}
                  </Title>
                </Row>
              )}

              <Row
                mb
                gap={theme.spacing.xs}
                justifyContent={isMobile ? 'flex-start' : 'flex-end'}
              >
                <Paragraph>Total:</Paragraph>
                <Title variant="h5">
                  {currency?.symbol}
                  {parseIntoCurrencyString(invoice.totalAmount)}
                </Title>
              </Row>
            </Col>
          </Flex>

          {!invoice.contractId &&
            !!availablePrebookedRate &&
            isPayable &&
            isCanBePaid && (
              <StalePrebookRateNotification
                position="left"
                amount={invoice.amountDue || invoice.totalAmount}
                buyCurrency={currency}
                sellCurrency={{
                  code: entityCurrencyCode,
                  symbol: entityCurrency?.symbol,
                }}
                onSubmit={() => setExistingPrebookCurrency(invoice.currency)}
              />
            )}
        </Col>
      </Row>
      <OverflowXWrapper>
        <LineItemsTable invoice={invoice} currency={currency} />
      </OverflowXWrapper>

      {!!existingPrebookCurrency && (
        <UseExistingPrebook
          invoice={invoice}
          sellCurrency={entityCurrencyCode}
          onClose={() => setExistingPrebookCurrency(null)}
        />
      )}
    </>
  );
};

export default InvoiceDetailsContent;
