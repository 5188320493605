import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { TEntityPackageKeys } from 'types/permissions';

dayjs.extend(isSameOrBefore);

export const isPackageEnabled = (enabledUntil?: string | null) => {
  if (!enabledUntil) {
    return false;
  }

  return dayjs().isSameOrBefore(enabledUntil);
};

export const checkIsKeyInEntityPackageKeys = (key: string) => {
  if (key === 'global' || key === 'automation' || key === 'fxManagement') {
    return key as TEntityPackageKeys;
  }
};
