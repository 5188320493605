import db from 'services/firestore';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IEntityDataStatuses, IResponse } from 'types';
import {
  HF_GURU_ACCOUNTANT_TASKS,
  HF_GURU_TASKS,
  IHfAccountantGuru,
  IHfGuru,
} from 'types/hfGuru';
import { openDoc } from 'utils';

interface RegistrationSetting {
  entityCurrency: string;
  accountingSystem?: string;
  howDidYouFindUs?: string;
}

export const saveRegistrationSettings = async (
  payload: RegistrationSetting
) => {
  return AxiosPrivateFirebaseInstance.put<IResponse<RegistrationSetting>>(
    '/entities/settings',
    payload
  );
};

export const getHFGuruStatuses = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IHfGuru>>('/entities/guru');

interface UpdateHFGuruStatusesParams {
  task: HF_GURU_TASKS;
  close: boolean;
}

/**
 * @param {string} task - for healthCheck close === false => healthCheck.completed = true
 * @param {boolean} close - if close === true => task.deferUntil + 1 month
 */
export const updateHFGuruStatuses = async ({
  task,
  close,
}: UpdateHFGuruStatusesParams) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IHfGuru>>(
    `/entities/guru/${task}/${close}`
  );

export const getHFAccountantGuruStatuses = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IHfAccountantGuru>>(
    '/entities/accountant-guru'
  );

interface UpdateHFAccountantGuruStatusesParams {
  task: HF_GURU_ACCOUNTANT_TASKS;
  close: boolean;
}

/**
 * @param {string} task - completed = true
 * @param {boolean} close - if close === true => task.deferUntil + 1 month
 */
export const updateHFAccountantGuruStatuses = async ({
  task,
  close,
}: UpdateHFAccountantGuruStatusesParams) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IHfAccountantGuru>>(
    `/entities/accountant-guru/${task}/${close}`
  );

interface DeferSuggestedFollowedCurrencyParams {
  entityId: string;
  currencyCode: string;
}

export const deferSuggestedFollowedCurrency = async ({
  entityId,
  currencyCode,
}: DeferSuggestedFollowedCurrencyParams) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/entities/${entityId}/deferSuggestedFollowedCurrency/${currencyCode}`
  );

export const updateEntityDataStatuses = async ({
  entityId,
  payload,
}: {
  entityId: string;
  payload: Partial<IEntityDataStatuses>;
}) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IEntityDataStatuses>>(
    `/entities/${entityId}/dataStatuses`,
    payload
  );

export const subscribeToEntityDataStatuses = (
  entityId: string,
  callback: (data: IEntityDataStatuses | null) => void
) =>
  db
    .collection('entities')
    .doc(entityId)
    .collection('docs')
    .doc('dataStatuses')
    .onSnapshot((data) => {
      callback(openDoc(data));
    });
