import { Col, Paragraph, Row, Table } from 'components';
import Button from 'components/shared/Button/Button';
import dayjs from 'dayjs';
import useBalance from 'hooks/useBalance';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import {
  IPaymentRunItemSummary,
  IPaymentRunCurrencyTotal,
} from 'types/paymentRuns';
import { getDashboardPageLink, getInvoicesPageLink } from 'utils/links';
import { DATE_FORMAT } from 'variables';
import { generateConfirmationTableColumns } from '../../tableColumnsGenerator';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';

interface OwnProps {
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  data: IPaymentRunItemSummary[];
  paymentDate: string;
}

const ConfirmationStep: FC<OwnProps> = ({
  paymentRunTotals,
  data,
  paymentDate,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);

  const isSingleTransferPaymentRun =
    data.length === 1 && data[0].breakdownByRecipient.length === 1;
  const transferToUse = data[0].breakdownByRecipient[0].transferInput;

  const { isLoading, isEnoughBalance, onPayWithBalance } = useBalance({
    sellCurrency: transferToUse?.sellCurrency,
    sellAmount: transferToUse?.payAmount,
  });

  const canBePaidWithBalance =
    isEnoughBalance && isSingleTransferPaymentRun && transferToUse;

  const tableColumns = useMemo(
    () =>
      generateConfirmationTableColumns({
        currencyByCode,
        entityDefaultCurrencyCode: entityCurrencyCode,
      }),
    [currencyByCode, entityCurrencyCode]
  );

  return (
    <Col>
      <Table<IPaymentRunItemSummary>
        data={data}
        columns={tableColumns}
        defaultRowHeight={56}
        renderFooterContent={
          <Row alignItems="flex-end" gap={theme.spacing.m}>
            <Col flex={2.5}>
              <Paragraph mb>
                Your payment run has been booked. Please follow payment
                instructions provided and ensure sufficient funds are on your
                HedgeFlows account before 1pm on the Scheduled date.
              </Paragraph>
              <Row gap={theme.spacing.m} justifyContent="flex-start">
                {canBePaidWithBalance && (
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={() =>
                      onPayWithBalance({
                        payload: {
                          currency: transferToUse.sellCurrency,
                          amount: transferToUse.payAmount,
                        },
                        onSuccess: () => history.push(getDashboardPageLink()),
                      })
                    }
                  >
                    Use my balance
                  </Button>
                )}
                <Button
                  disabled={isLoading}
                  variant={canBePaidWithBalance ? 'secondary' : 'primary'}
                  onClick={() =>
                    history.replace(
                      getInvoicesPageLink({
                        currency: 'all',
                      })
                    )
                  }
                >
                  Go to invoices
                </Button>
                <Button
                  disabled={isLoading}
                  variant={isEnoughBalance ? 'link' : 'secondary'}
                  onClick={() => history.replace(getDashboardPageLink())}
                >
                  Go to dashboard
                </Button>
              </Row>
            </Col>

            <Col
              gap={theme.spacing.xs}
              alignItems="flex-start"
              justifyContent="space-between"
              alignSelf="stretch"
              flex={1}
            >
              <Row alignItems="flex-start" alignSelf="stretch">
                <Paragraph variant="bold">Payments scheduled for:</Paragraph>
                <Paragraph variant="bold">
                  {dayjs(paymentDate).format(DATE_FORMAT)}
                </Paragraph>
              </Row>
              <InvoicesCurrencyTotals
                title="Funds required:"
                currencyTotals={paymentRunTotals}
              />
            </Col>
          </Row>
        }
        sortable
      />
    </Col>
  );
};

export default ConfirmationStep;
