import styled from 'styled-components';
import { Title } from 'components/shared/Typography/Typography';

export const GradientTitle = styled(Title)`
  margin-top: ${({ theme }) => theme.spacing.xxxl};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  background: -webkit-linear-gradient(
    360deg,
    #cfeeea 1.31%,
    #e7edd0 27.56%,
    #facba0 70.76%,
    #ffb8bd 98.26%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
