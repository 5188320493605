export const getRateHint = ({
  isRateIndicative,
  isPrebooked,
}: {
  isRateIndicative?: boolean;
  isPrebooked?: boolean;
}) => {
  if (isRateIndicative) {
    return 'indic.';
  }
  if (isPrebooked) {
    return 'prebooked';
  }
  return 'live';
};
