import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Col, Row, Subtitle, Paragraph, TextHint } from 'components';
import {
  IOpenBankingInstitution,
  IOpenBankingPaymentAuthResponseData,
} from 'types';
import { StyledQRCol, StyledQRImage } from './InstitutionTile.styles';
import { parseIntoCurrencyString } from 'utils';
import { StyledInstitutionImage } from 'components/shared/StyledInstitutionImage/StyledInstitutionImage.styles';

interface IOwnProps {
  selectedInstitution: IOpenBankingInstitution;
  paymentAuthResponse: IOpenBankingPaymentAuthResponseData;
}

const InstitutionTile: FC<IOwnProps> = ({
  selectedInstitution,
  paymentAuthResponse,
}) => {
  const theme = useTheme();

  return (
    <>
      <Col mb gap={theme.spacing.m} alignItems="center">
        <StyledInstitutionImage
          src={
            selectedInstitution.media?.find((item) => item.type === 'icon')
              ?.source
          }
          alt={selectedInstitution.name}
        />
        <Subtitle ml variant="bold">
          {selectedInstitution.name}
        </Subtitle>
      </Col>

      <Paragraph mb textAlign="center">
        You will be redirected to the {selectedInstitution.name} securely to
        complete payment
      </Paragraph>

      <Col flex={1} alignSelf="stretch" justifyContent="center">
        <Row alignItems="flex-start">
          <Col mr mbValue={theme.spacing.xl} gap={theme.spacing.xxs}>
            <Subtitle mb variant="bold">
              TRANSFER DETAILS
            </Subtitle>

            <Paragraph variant="bold">Amount</Paragraph>
            <Paragraph>
              {parseIntoCurrencyString(paymentAuthResponse.amount)}{' '}
              {paymentAuthResponse.currency}
            </Paragraph>

            <Paragraph variant="bold">Beneficiary</Paragraph>
            <Paragraph>{paymentAuthResponse.recipientName}</Paragraph>

            {paymentAuthResponse.recipientSortCode && (
              <>
                <Paragraph variant="bold">Sort Code</Paragraph>
                <Paragraph>{paymentAuthResponse.recipientSortCode}</Paragraph>
              </>
            )}

            {paymentAuthResponse.recipientAccountNumber && (
              <>
                <Paragraph variant="bold">Account Number</Paragraph>
                <Paragraph>
                  {paymentAuthResponse.recipientAccountNumber}
                </Paragraph>
              </>
            )}

            {paymentAuthResponse.recipientReference && (
              <>
                <Paragraph variant="bold">Reference</Paragraph>
                <Paragraph>{paymentAuthResponse.recipientReference}</Paragraph>
              </>
            )}
          </Col>

          <StyledQRCol flex={1}>
            <StyledQRImage
              src={paymentAuthResponse.qrCodeUrl}
              alt={selectedInstitution.name}
            />
            <TextHint mt mtValue={theme.spacing.xs} textAlign="center">
              Scan with camera to continue on smartphone
            </TextHint>
          </StyledQRCol>
        </Row>
      </Col>
    </>
  );
};

export default InstitutionTile;
