import { Title } from 'components';
import Button from 'components/shared/Button/Button';
import { StyledForm } from 'components/shared/Form/Form.styles';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import { ERROR_MESSAGES } from 'variables';
import { TInputs } from './types';
import Field from 'components/shared/Field/Field.styles';
import InputBase from 'components/shared/InputBase/InputBase';
import { MultiFactorResolver, TotpMultiFactorGenerator } from 'firebase/auth';
import { Notify } from 'utils';

interface IOwnProps {
  factorUid: string;
  mfaResolver: MultiFactorResolver;
  onSwitchFactor: (factorId: string) => void;
}

const OneTimePassword: FC<IOwnProps> = ({
  factorUid,
  mfaResolver,
  onSwitchFactor,
}) => {
  const { control, handleSubmit, watch } = useForm<TInputs>({
    defaultValues: {
      verificationCode: '',
    },
  });

  const onSubmit = async ({ verificationCode }: TInputs) => {
    try {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
        factorUid,
        verificationCode
      );
      await mfaResolver.resolveSignIn(multiFactorAssertion);
    } catch (error: any) {
      Notify.error('Invalid or expired verification code');
    }
  };

  return (
    <>
      <Title variant="h5">
        Please enter a code from an authenticatior app:
      </Title>
      <StyledForm alignItems="stretch" onSubmit={handleSubmit(onSubmit)}>
        <Field mb fluid>
          <Controller
            name="verificationCode"
            control={control}
            rules={{
              required: ERROR_MESSAGES.requiredField,
            }}
            render={({ field: { value, onChange } }) => {
              return <InputBase value={value} onChange={onChange} autoFocus />;
            }}
          />
        </Field>

        <Button
          variant="primary"
          disabled={!watch('verificationCode')}
          type="submit"
        >
          Continue
        </Button>
      </StyledForm>

      {mfaResolver.hints.length > 1 && (
        <Button mt variant="link" onClick={() => onSwitchFactor(factorUid)}>
          Use code from SMS
        </Button>
      )}
    </>
  );
};

export default OneTimePassword;
