import { useMemo } from 'react';
import { useQuery } from 'hooks';
import { useRouteMatch } from 'react-router';
import { IInvoice, IRateContract, ITransfer } from 'types';
import MarketInvoiceRate from './components/MarketInvoiceRate/MarketInvoiceRate';
import TransferOrPrebookInvoiceRate from './components/TransferOrPrebookInvoiceRate/TransferOrPrebookInvoiceRate';
import { getTransactionPageLink } from 'utils/links';
import { Col } from 'components';
import { useStoreState } from 'state';
import useDeviceWidth from 'hooks/useDeviceWidth';

interface Props {
  prebookedRateContract: IRateContract | null;
  currency: string;
  transfer?: ITransfer | null;
  rate: number | null;
  invoiceType: IInvoice['type'];
}

const InvoiceRate: React.FC<Props> = ({
  prebookedRateContract,
  currency,
  transfer,
  rate,
  invoiceType,
}) => {
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const { isMobile } = useDeviceWidth();
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const showTransferRate = transfer?.rate !== undefined;
  const isSameCurrencyTransfer =
    !!transfer && transfer?.sellCurrency === transfer?.buyCurrency;
  const showPrebookedRate =
    !showTransferRate &&
    !!prebookedRateContract &&
    prebookedRateContract?.rate !== undefined;
  const showMarketRate =
    !showTransferRate && !showPrebookedRate && currency !== entityCurrencyCode;

  const prebookLink = useMemo(() => {
    if (prebookedRateContract) {
      URLQuery.append('contractId', prebookedRateContract.id);
    }
    return `${url}?${URLQuery.toString()}`;
  }, [prebookedRateContract, url, URLQuery]);

  return (
    <Col alignItems={isMobile ? 'flex-start' : 'flex-end'} mb>
      {showTransferRate && (
        <TransferOrPrebookInvoiceRate
          link={getTransactionPageLink({ transferId: transfer?.id })}
          rateText={
            isSameCurrencyTransfer
              ? ''
              : `Transfer rate: 1 ${transfer.sellCurrency} = ${transfer.rate} ${transfer.buyCurrency}`
          }
          buttonText="View transfer"
        />
      )}
      {showPrebookedRate && prebookedRateContract?.rate && (
        <TransferOrPrebookInvoiceRate
          link={prebookLink}
          rateText={`Prebooked rate: 1 ${prebookedRateContract.sellCurrency} = ${prebookedRateContract.rate} ${prebookedRateContract.buyCurrency}`}
          buttonText="View prebooking"
        />
      )}
      {showMarketRate && (
        <MarketInvoiceRate
          invoiceType={invoiceType}
          rate={rate}
          currency={currency}
        />
      )}
    </Col>
  );
};

export default InvoiceRate;
