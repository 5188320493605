import styled, { css } from 'styled-components';

interface StepItemProps {
  passed?: boolean;
  disabled?: boolean;
  current?: boolean;
}

export const StepperItem = styled.div<StepItemProps>(
  ({ theme, passed, disabled, current }) => css`
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.color.grey};
    position: relative;
    padding: 0 32px 8px 0;
    margin-bottom: 2px;
    cursor: pointer;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      font-size: 16px;
      min-width: 152px;
      height: 100%;
    }

    &:last-child {
      padding-right: 0;
    }
    &:not(:last-of-type):after {
      content: '';
    }

    &:after {
      width: 0;
      height: 0;
      position: absolute;
      right: 0;
      bottom: 1px;
      margin: auto;
    }

    border-bottom: ${`2px solid ${theme.color.grey}`};

    ${passed &&
    css`
      color: ${theme.color.dark};
      cursor: pointer;
      border-bottom: ${`2px solid ${theme.color.dark}`};

      &:hover {
        opacity: 0.8;
      }

      &:after {
        border-left-color: ${theme.color.white};
      }
    `}

    ${disabled &&
    css`
      pointer-events: none;
    `}

    ${current &&
    css`
      color: ${theme.color.emeraldDark};
      border-bottom: ${`2px solid ${theme.color.emeraldDark}`};
    `}
  `
);

export const StepNumber = styled.span`
  display: block;
  padding-right: 8px;
  font-weight: bold;
`;
