import styled from 'styled-components';
import { Row } from 'components';

export const StepAccountsSecondNotification = styled(Row)`
  align-items: flex-start;
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.xl}`};
  background: ${({ theme }) => theme.color.greyLight_2};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  margin-top: ${({ theme }) => theme.spacing.xl};
`;
