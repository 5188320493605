import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { Subtitle } from '../Typography/Typography';
import { TabItem, TabsWrapper } from './Tabs.styles';

export interface ITab<T> {
  id: T;
  title?: string;
  renderTabComponent?: (params: { active: boolean }) => ReactNode;
}

interface OwnProps<T> {
  setActiveTab: (tab: ITab<T>) => void;
  activeTab: ITab<T>['id'];
  data: ITab<T>[];
  whiteSpace?: CSSProperties['whiteSpace'];
}

const Tabs = <T extends string>({
  setActiveTab,
  activeTab,
  data,
  whiteSpace,
}: OwnProps<T>) => (
  <TabsWrapper>
    {data.map((tab) => (
      <TabItem
        key={tab.id}
        active={activeTab === tab.id}
        onClick={() => setActiveTab(tab)}
        whiteSpace={whiteSpace}
      >
        {tab?.renderTabComponent ? (
          tab.renderTabComponent({
            active: activeTab === tab.id,
          })
        ) : (
          <Subtitle color={activeTab === tab.id ? 'dark' : 'greyDark'}>
            {tab.title}
          </Subtitle>
        )}
      </TabItem>
    ))}
  </TabsWrapper>
);

export default Tabs;
