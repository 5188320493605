import { ChartData } from 'chart.js';
import Chart from 'components/shared/Chart/Chart';

export interface IChartDataRecord {
  date: string;
  sumOfBalances: number;
  balances: {
    date: string;
    amount: number;
    name: string;
    currencyCode: string;
  }[];
}

const ChartBalancesByAccount = ({
  data,
  labels,
}: {
  labels: Date[];
  data: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
}) => {
  const chartData: ChartData<'bar', number[], Date> = {
    labels,
    datasets: data,
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
          },
        },
        scales: {
          x: {
            type: 'time',
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              source: 'data',
            },
            time: {
              tooltipFormat: 'MMM DD',
              minUnit: 'day',
            },
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
      }}
    />
  );
};

export default ChartBalancesByAccount;
