import styled, { css } from 'styled-components';
import { MenuPlacement } from 'react-select';

export const Wrapper = styled.div<{
  open: boolean;
  filled: boolean;
  multi?: boolean;
  disabled?: boolean;
  menuPlacement?: MenuPlacement;
  withBackdrop?: boolean;
}>(
  ({
    theme,
    open,
    filled,
    multi,
    disabled = false,
    menuPlacement,
    withBackdrop,
  }) => css`
    border-radius: 6px;
    min-height: 52px;
    color: ${theme.color.dark};
    position: relative;
    overflow: ${open ? 'visible' : 'hidden'};
    z-index: ${open && 1};
    box-shadow: ${open &&
    withBackdrop &&
    '0px 0px 0px 200vw rgb(0, 0, 0, 0.3)'};
    pointer-events: ${disabled ? 'none' : 'auto'};

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${menuPlacement === 'top' ? '-56px' : 'auto'};
      top: ${menuPlacement === 'top' ? 'auto' : '0'};
      width: 100%;
      display: block;
      border-radius: 6px;
      height: ${menuPlacement === 'top' ? '300%' : '200%'};
      border: none;
      color: ${theme.color.dark};
      transition: ${theme.transition};
      background: ${!open ? theme.color.greyLight_3 : theme.color.white};
      transition: ${theme.transition};
      box-shadow: ${open && theme.shadow};
    }

    .label {
      position: absolute;
      left: 1px;
      right: 1px;
      padding: 0 16px;
      top: ${!open && filled ? '-10px' : 0};
      display: flex;
      align-items: center;
      height: 52px;
      cursor: text;
      transition: ${theme.transition};
      font-size: ${!open && filled ? '12px' : '14px'};
      opacity: ${disabled ? 0.5 : 1};
    }

    .select {
      &__input {
        margin: 2px 0 7px 0;
        position: ${!open && 'absolute'};
        opacity: ${!open && '0'};
      }

      &__control {
        background: ${theme.color.transparent};
        min-height: 52px;
        border-radius: 6px;
        border: none;
        display: flex;
        align-items: flex-end;
        box-shadow: none;

        &--is-focused:not(.select__control--menu-is-open) {
          box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
        }
      }

      &__value-container {
        background-color: ${theme.color.transparent};
        background-image: url(${open && '/assets/imgs/bg/search.svg'});
        background-position: calc(100% - 16px) center;
        background-repeat: no-repeat;
        border-radius: 6px;
        box-shadow: 0 0 0 1px
          ${open ? theme.color.emeraldDark : theme.color.transparent};
        position: relative;
        margin: ${multi && (!open && filled ? '24px 48px 6px 16px' : '0 16px')};
        margin: ${!multi &&
        (!open && filled ? '22px 48px 6px 16px' : '0 16px')};
        opacity: ${open || filled ? 1 : 0};
        top: ${open ? '38px' : '0'};
        padding: ${!open ? '0' : '6px 40px 0 6px'};
        padding-top: ${filled && '6px'};
        margin-top: ${open && '6px'};
        opacity: ${disabled ? 0.5 : 1};
      }

      &__clear-indicator,
      &__indicator-separator {
        display: none;
      }

      &__multi-value {
        height: 32px;
        background: ${theme.color.greyLight_1};
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin: 0 6px 6px 0;

        &__label {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: ${theme.color.dark};

          svg {
            margin-right: 6px;
          }
        }
      }

      &__single-value {
        display: flex;
        align-items: center;
        position: relative;
        transform: translateY(0);
        margin-bottom: ${open && '6px'};
        max-width: 95%;
        white-space: ${open ? 'initial' : 'nowrap'};
        text-overflow: ${!open && 'ellipsis'};
        overflow: ${!open && 'hidden'};

        div.row {
          margin: 0;
          flex-direction: column;
          justify-content: flex-start;
        }

        .inline {
          white-space: ${open ? 'initial' : 'nowrap'};
          text-overflow: ${!open && 'ellipsis'};
          overflow: ${!open && 'hidden'};
          margin-bottom: 0 !important;

          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: ${theme.color.greyDark};
            min-height: 18px;
          }
        }

        svg {
          margin-right: 6px;
        }
      }

      &__menu {
        border-radius: ${menuPlacement === 'top'
          ? '6px 6px 0 0'
          : '0 0 6px 6px'};
        border: none;
        box-shadow: ${open &&
        (menuPlacement === 'top'
          ? '0px -3px 5px 0px rgba(0, 0, 0, 0.1)'
          : '0px 5px 5px 0px rgba(0, 0, 0, 0.1)')};
        margin-top: ${!open && filled ? 0 : '39px'};
        position: ${!open && 'absolute'};
        margin-bottom: ${menuPlacement === 'top' ? '-6px' : 0};

        &-list {
          padding: 8px 0;

          .inline {
            white-space: initial;
            margin-bottom: 0;

            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: ${theme.color.greyDark};
              min-height: 18px;
            }
          }
        }
      }

      &__option {
        padding: 8px 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &.select__option--is-focused {
          background: ${theme.color.greyLight_3};
          opacity: 1;
        }
        &.select__option--is-selected {
          display: none;
        }

        div.row {
          flex-direction: column;
          align-items: flex-start;
        }

        &--is-selected {
          opacity: 0.5;
        }

        svg {
          margin-right: 6px;
        }
      }
    }

    .i-arrow {
      position: absolute;
      right: 8px;
      top: 16px;
      transition: ${theme.transition};
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: ${theme.color.grey};
      transform: rotate(${open ? '180deg' : '0'});
    }
  `
);
