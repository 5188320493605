import styled from 'styled-components';

export const TableSearchInput = styled.input<{ width?: string }>`
  width: ${({ width }) => width ?? '148px'};
  height: 36px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: ${({ theme }) =>
    `${theme.spacing.s} 0 ${theme.spacing.s} ${theme.spacing.m}`};
  background: url('/assets/imgs/bg/search.svg') no-repeat calc(100% - 16px)
    center;
  background-color: ${({ theme }) => theme.color.greyLight_3};
`;
