import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

export const Tab = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${theme.color.greyLight_2};
  `
);

interface TabsProps {
  largeTabNav?: boolean;
}

export const TabNav = styled.ul<TabsProps>(
  ({ theme, largeTabNav }) => css`
    display: flex;
    flex: 0 0 54px;
    margin: 0;
    padding: 0;

    li {
      flex: 0 0 50%;
      max-width: 50%;
      border-radius: 6px 6px 0 0;
      color: ${theme.color.greyDark};
      background: ${theme.color.greyLight_3};
      display: flex;
      align-items: center;
      text-align: center;
      transition: ${theme.transition};
      border: 1px solid ${theme.color.greyLight_1};
      font-weight: 400;
      justify-content: center;
      padding: 14px;
      font-size: 14px;
      line-height: 24px;

      ${largeTabNav &&
      css`
        border: 1px solid ${theme.color.transparent};
        justify-content: flex-start;
        padding: 28px 48px 24px;
        font-size: 24px;
        line-height: 34px;
        border-radius: 10px 10px 0 0;
      `}

      &.pull-left {
        align-items: flex-start;
        flex-direction: column;
      }

      &:not(.active) {
        border-color: ${theme.color.greyLight_1};
        color: ${theme.color.grey};
        cursor: ${!isMobile && 'pointer'};

        @media (min-width: ${theme.breakpoint.largeMin}px) {
          cursor: pointer;
        }
      }

      &:not(.disabled) {
        font-weight: 700;

        &:hover {
          color: ${theme.color.greyDark};
        }
      }

      svg {
        margin: -3px 8px 0 0;
        fill: ${theme.color.grey};
        transition: ${theme.transition};
      }

      .sub {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
      }

      &.active {
        color: ${theme.color.dark};
        background: ${theme.color.white};
        border-bottom: 1px solid ${theme.color.white};
        font-weight: 700;

        svg {
          fill: ${theme.color.emeraldDark};
        }
      }
    }
  `
);

export const TabContent = styled.div<TabsProps>(
  ({ theme, largeTabNav }) => css`
    display: flex;
    flex: 1 0 auto;
    padding: 46px 48px;
    background: ${theme.color.white};

    ${!largeTabNav &&
    css`
      flex: 0 0 auto;
      padding: 16px;
      border-radius: 0 0 6px 6px;
      border: 1px solid ${theme.color.greyLight_1};
      border-top-color: ${theme.color.white};
    `}

    .field {
      margin-bottom: 10px;
    }
  `
);
