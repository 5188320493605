import { IContact } from 'types';
import { ContactReviewStatusWrapper } from './ContactReviewStatus.styles';

const ContactReviewStatus = ({
  contact,
}: {
  contact: Partial<
    Pick<IContact, 'enabled' | 'reviewalStatus' | 'shouldPausePayments'>
  >;
}) => {
  if (!contact.enabled) {
    return (
      <ContactReviewStatusWrapper color="red">
        Incomplete
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.shouldPausePayments) {
    return (
      <ContactReviewStatusWrapper color="purple">
        Paused
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.reviewalStatus === 'updated') {
    return (
      <ContactReviewStatusWrapper color="coral">
        Updated
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.reviewalStatus === 'new') {
    return (
      <ContactReviewStatusWrapper color="blue">New</ContactReviewStatusWrapper>
    );
  }

  return null;
};

export default ContactReviewStatus;
