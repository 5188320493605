import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ bgColor: string }>(
  ({ theme, bgColor }) => css`
    padding: 9px 12px;
    border-radius: 8px;
    margin: 0 0 12px;
    position: relative;
    color: ${theme.color.white};
    background: ${bgColor};

    @media (min-width: ${theme.breakpoint.smallMin}px) {
      max-width: 308px;
    }

    a {
      font-size: inherit;
      line-height: 1;
      color: inherit;
      display: inline;

      &:hover {
        color: inherit;
        opacity: 0.8;
        border-color: ${theme.color.transparent};
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .close {
      position: absolute;
      right: 12px;
      top: 12px;
      width: 24px;
      height: 24px;

      svg {
        fill: ${theme.color.white};
      }
    }

    .row {
      justify-content: space-between;
      align-items: center;
    }
  `
);

export const Top = styled.div<{ cross: boolean }>(
  ({ cross }) => css`
    display: flex;
    padding-right: ${cross ? '30px' : '18px'};

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    svg {
      margin: 0 6px 0 0;
    }
  `
);

export const Bot = styled.div`
  margin-bottom: 7px;
`;
