import { Row, Paragraph } from 'components';
import Link from 'components/shared/Link/Link';
import Button from 'components/shared/Button/Button';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { getDashboardPageLink } from 'utils/links';
import { IOpenBankingPaymentAuthResponseData } from 'types';

interface IOwnProps {
  paymentAuthResponse?: IOpenBankingPaymentAuthResponseData;
  isLoadingPaymentAuthUrl: boolean;
  isRedirected: boolean;
  setIsRedirected: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  isDisabled: boolean;
}

const FundingBulkPaymentActions: FC<IOwnProps> = ({
  paymentAuthResponse,
  isLoadingPaymentAuthUrl,
  isRedirected,
  setIsRedirected,
  onClose,
  isDisabled,
}) => {
  const history = useHistory();
  const theme = useTheme();

  const onAuthorisePayment = (authorisationUrl: string) => {
    window.open(authorisationUrl, '_blank');
    setIsRedirected(true);
  };

  const onCloseHandler = !!onClose
    ? onClose
    : () => history.replace(getDashboardPageLink());

  return (
    <>
      {isRedirected && (
        <Row>
          <Button onClick={() => history.replace(getDashboardPageLink())}>
            Back to dashboard
          </Button>
        </Row>
      )}

      {!isRedirected && (
        <Row gap={theme.spacing.m}>
          <Row gap={theme.spacing.m}>
            <Button
              disabled={
                isLoadingPaymentAuthUrl || !paymentAuthResponse || isDisabled
              }
              onClick={() =>
                paymentAuthResponse &&
                onAuthorisePayment(paymentAuthResponse.authorisationUrl)
              }
            >
              Pay via Open Banking
            </Button>
            <Button variant="secondary" onClick={onCloseHandler}>
              Pay later
            </Button>
          </Row>
          <Row ml flex={1}>
            <Paragraph>
              By clicking 'Pay via Open Banking' you agree that Yapily Connect
              Ltd can initiate this payment for you in accordance with their{' '}
              <Link
                href="https://www.yapily.com/legal/end-user-terms"
                target="_blank"
                rel="noreferrer"
                display="inline"
              >
                Terms & Conditions
              </Link>{' '}
              and{' '}
              <Link
                href="https://www.yapily.com/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
                display="inline"
              >
                Privacy Notice
              </Link>
              .
            </Paragraph>
          </Row>
        </Row>
      )}
    </>
  );
};

export default FundingBulkPaymentActions;
