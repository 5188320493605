import { FC } from 'react';
import useUrlValues from 'hooks/useUrlValues';
import { Paragraph } from 'components';
import Button from '../Button/Button';
import Popup from '../Popup/Popup';
import Link from '../Link/Link';
import { Title } from '../Typography/Typography';

interface OwnProps {
  onClose?: () => void;
}

const CancellingBalance: FC<OwnProps> = ({ onClose }) => {
  const { setUrlValue } = useUrlValues();

  const onCloseHandler = () => {
    setUrlValue({ cancelContractId: '' });
    onClose?.();
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Cancel prebooking</Title>}
      FooterContent={<Button onClick={onCloseHandler}>Close</Button>}
      width="439px"
      onClose={onCloseHandler}
    >
      <Paragraph>
        Please contact support{' '}
        <Link
          href="mailto:support@hedgeflows.com"
          target="_blank"
          rel="noreferrer"
          display="inline"
        >
          (support@hedgeflows.com)
        </Link>{' '}
        to cancel prebooking
      </Paragraph>
    </Popup>
  );
};

export default CancellingBalance;
