import { IManualPoSo } from 'pages/ManualUpload/hooks/useBulkUploadPoSo';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';

export const purchaseOrSalesOrderUpload = ({ data }: { data: IManualPoSo[] }) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/purchase_and_sales_orders`,
    data
  );

export const updatePurchaseOrSalesOrder = (payload: {
  id: string;
  data: {
    excludeFromRisk?: boolean;
  };
}) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(
    `/purchase_and_sales_orders/${payload.id}`,
    payload.data
  );
