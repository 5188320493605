import { FC, useState } from 'react';
import {
  ButtonCopy,
  Paragraph,
  StalePopup,
  PopupContentContainerWrapped,
  PopupContentWrapper,
} from 'components';
import { useStoreState } from 'state';
import {
  AccountDetailsFooter,
  AccountDetailsHeadingWrapper,
  AccountDetailsTitle,
  AccountDetailsWrapper,
  AccountDetailWrapper,
} from './StaleAllAccountDetails.styles';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import {
  copyTextToClipboard,
  getAccountDetailsForCopy,
  Notify,
  getSwiftAccountDetails,
  getRegularAccountDetailsEUR,
  getRegularAccountDetailsGBP,
} from 'utils';
import Button from '../Button/Button';

interface OwnProps {
  onClose: () => void;
}

const StaleAllAccountDetails: FC<OwnProps> = ({ onClose }) => {
  const { entityAccountDetails } = useStoreState((state) => state.UserState);
  const { countryByCode } = useStoreState((state) => state.ReferenceDataState);

  const [detailsForCopyIds, setDetailsForCopyIds] = useState<string[]>([]);

  const selectDetailsForCopy = (detailsId: string) => {
    if (detailsForCopyIds.includes(detailsId)) {
      setDetailsForCopyIds((prevState) =>
        prevState.filter((id) => id !== detailsId)
      );

      return;
    }

    setDetailsForCopyIds((prevState) => [...prevState, detailsId]);
  };

  const swiftAccountDetails = getSwiftAccountDetails(entityAccountDetails);
  const regularAccountDetailsEUR = getRegularAccountDetailsEUR(
    entityAccountDetails
  );
  const regularAccountDetailsGBP = getRegularAccountDetailsGBP(
    entityAccountDetails
  );

  const onCopyText = (text: string) => {
    copyTextToClipboard(text);

    Notify.success('Account details copied!');
  };

  const getDetailsTextToCopy = (detailsId: string, forMail = false) => {
    if (detailsId === 'SWIFT' && swiftAccountDetails) {
      return getAccountDetailsForCopy(
        [
          ['Account holder', swiftAccountDetails.accountHolder],
          ['IBAN', swiftAccountDetails.iban],
          ['Bank', swiftAccountDetails.bankName],
          ['BIC', swiftAccountDetails.bic],
          ['Bank address', swiftAccountDetails.bankAddress],
          [
            'Bank country',
            countryByCode(swiftAccountDetails.bankCountry)?.name ??
              swiftAccountDetails.bankCountry,
          ],
        ],
        `For SWIFT Payments in USD, GBP, EUR or AUD
`,
        forMail
      );
    }

    if (detailsId === 'EUR' && regularAccountDetailsEUR) {
      return getAccountDetailsForCopy(
        [
          ['Account holder', regularAccountDetailsEUR.accountHolder],
          ['IBAN', regularAccountDetailsEUR.iban],
        ],
        `For SEPA Payments in EUR
`,
        forMail
      );
    }

    if (detailsId === 'GBP' && regularAccountDetailsGBP) {
      return getAccountDetailsForCopy(
        [
          ['Account holder', regularAccountDetailsGBP.accountHolder],
          ['Sort Code', regularAccountDetailsGBP.sortCode],
          ['Account number', regularAccountDetailsGBP.accountNumber],
        ],
        `For fast payments in GBP
`,
        forMail
      );
    }
  };

  const onCopySelected = ({ viaMail = false } = {}) => {
    let textToCopy = '';

    if (viaMail) {
      detailsForCopyIds.forEach((detailsId) => {
        const detailsText = getDetailsTextToCopy(detailsId, true);

        textToCopy = textToCopy + detailsText + '%0D%0A';
      });

      window.open(`mailto:?subject=My account details&body=${textToCopy}`);
      return;
    }

    detailsForCopyIds.forEach((detailsId) => {
      const detailsText = getDetailsTextToCopy(detailsId);

      textToCopy =
        textToCopy +
        `${detailsText}
`;
    });

    onCopyText(textToCopy);
  };

  return (
    <StalePopup
      title="My account details"
      theme="grey"
      width="908px"
      minHeight="auto"
      onClose={onClose}
    >
      <PopupContentWrapper>
        <PopupContentContainerWrapped>
          {swiftAccountDetails && (
            <AccountDetailsWrapper>
              <AccountDetailsHeadingWrapper>
                <Checkbox
                  checked={detailsForCopyIds.includes(swiftAccountDetails.id)}
                  onChange={() => selectDetailsForCopy(swiftAccountDetails.id)}
                />
                <AccountDetailsTitle variant="h5">
                  For SWIFT Payments in USD, GBP, EUR or AUD
                </AccountDetailsTitle>

                <ButtonCopy
                  showIcon
                  onClick={() =>
                    onCopyText(getDetailsTextToCopy('SWIFT') ?? '')
                  }
                >
                  <Paragraph>Copy</Paragraph>
                </ButtonCopy>
              </AccountDetailsHeadingWrapper>

              <AccountDetailWrapper>
                <Paragraph>Account holder</Paragraph>
                <Paragraph variant="bold">
                  {swiftAccountDetails.accountHolder}
                </Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>IBAN</Paragraph>
                <Paragraph variant="bold">{swiftAccountDetails.iban}</Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>Bank</Paragraph>
                <Paragraph variant="bold">
                  {swiftAccountDetails.bankName}
                </Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>BIC</Paragraph>
                <Paragraph variant="bold">{swiftAccountDetails.bic}</Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>Bank address</Paragraph>
                <Paragraph variant="bold">
                  {swiftAccountDetails.bankAddress}
                </Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>Bank country</Paragraph>
                <Paragraph variant="bold">
                  {countryByCode(swiftAccountDetails.bankCountry)?.name ??
                    swiftAccountDetails.bankCountry}
                </Paragraph>
              </AccountDetailWrapper>
            </AccountDetailsWrapper>
          )}

          {regularAccountDetailsEUR && (
            <AccountDetailsWrapper>
              <AccountDetailsHeadingWrapper>
                <Checkbox
                  checked={detailsForCopyIds.includes(
                    regularAccountDetailsEUR.id
                  )}
                  onChange={() =>
                    selectDetailsForCopy(regularAccountDetailsEUR.id)
                  }
                />
                <AccountDetailsTitle variant="h5">
                  For SEPA Payments in EUR
                </AccountDetailsTitle>

                <ButtonCopy
                  showIcon
                  onClick={() => onCopyText(getDetailsTextToCopy('EUR') ?? '')}
                >
                  <Paragraph>Copy</Paragraph>
                </ButtonCopy>
              </AccountDetailsHeadingWrapper>

              <AccountDetailWrapper>
                <Paragraph>Account holder</Paragraph>
                <Paragraph variant="bold">
                  {regularAccountDetailsEUR.accountHolder}
                </Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>IBAN</Paragraph>
                <Paragraph variant="bold">
                  {regularAccountDetailsEUR.iban}
                </Paragraph>
              </AccountDetailWrapper>
            </AccountDetailsWrapper>
          )}

          {regularAccountDetailsGBP && (
            <AccountDetailsWrapper>
              <AccountDetailsHeadingWrapper>
                <Checkbox
                  checked={detailsForCopyIds.includes(
                    regularAccountDetailsGBP.id
                  )}
                  onChange={() =>
                    selectDetailsForCopy(regularAccountDetailsGBP.id)
                  }
                />
                <AccountDetailsTitle variant="h5">
                  For fast payments in GBP
                </AccountDetailsTitle>

                <ButtonCopy
                  showIcon
                  onClick={() => onCopyText(getDetailsTextToCopy('GBP') ?? '')}
                >
                  <Paragraph>Copy</Paragraph>
                </ButtonCopy>
              </AccountDetailsHeadingWrapper>

              <AccountDetailWrapper>
                <Paragraph>Account holder</Paragraph>
                <Paragraph variant="bold">
                  {regularAccountDetailsGBP.accountHolder}
                </Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>Sort Code</Paragraph>
                <Paragraph variant="bold">
                  {regularAccountDetailsGBP.sortCode}
                </Paragraph>
              </AccountDetailWrapper>
              <AccountDetailWrapper>
                <Paragraph>Account number</Paragraph>
                <Paragraph variant="bold">
                  {regularAccountDetailsGBP.accountNumber}
                </Paragraph>
              </AccountDetailWrapper>
            </AccountDetailsWrapper>
          )}
        </PopupContentContainerWrapped>

        <AccountDetailsFooter>
          <Button
            onClick={() => onCopySelected()}
            disabled={!detailsForCopyIds.length}
            mr
          >
            Copy selected
          </Button>

          <Button
            onClick={() => onCopySelected({ viaMail: true })}
            variant="link"
            disabled={!detailsForCopyIds.length}
            icon="mail"
          >
            Send via email
          </Button>
        </AccountDetailsFooter>
      </PopupContentWrapper>
    </StalePopup>
  );
};

export default StaleAllAccountDetails;
