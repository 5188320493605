import useEntityLoadingState from './useEntityLoadingState';
import { InlineLoader } from 'components/shared/';

import { ReportCalculationWrapper } from './ReportCalculationStatus.styles';

const ReportCalculationStatus = () => {
  const entityLoadingState = useEntityLoadingState();

  if (!entityLoadingState?.reports) {
    return null;
  }

  return (
    <ReportCalculationWrapper>
      <InlineLoader /> Updating your reports
    </ReportCalculationWrapper>
  );
};

export default ReportCalculationStatus;
