import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StalePreviewInvoiceWrapper = styled.div`
  margin: ${isMobile && '16px'};
  padding: 16px 24px;
  background-color: #f2f2f2;
  border-radius: 6px;

  .invoice-link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-decoration: underline;
    transition: 0.3s;

    :hover {
      opacity: 0.6;
    }
  }
`;
