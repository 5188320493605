import styled from 'styled-components';
import { Row } from 'components/shared/Row/Row';
import { Flex } from '../Flex/Flex.styles';
import { Paragraph, ParagraphWithEllipsis } from '../Typography/Typography';

export const ProfileNavWrapper = styled(Row)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

export const CompanyNameParagraph = styled(ParagraphWithEllipsis)`
  color: ${({ theme }) => theme.color.greyDark};
  transition: ${({ theme }) => theme.transition};
`;

const FlexWrapper = styled(Flex)`
  height: 32px;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing.s};
  background-color: ${({ theme }) => theme.color.greyLight_1};
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.emeraldDark};
  }
`;

export const CompanyName = styled(FlexWrapper)`
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.s} 0px 0px ${theme.borderRadius.s}`};

  &:hover {
    ${CompanyNameParagraph} {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const CopySwift = styled(FlexWrapper)`
  border-radius: ${({ theme }) =>
    `0px ${theme.borderRadius.s} ${theme.borderRadius.s} 0px `};

  &:hover {
    svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

export const UserCircleParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.color.greyDark};
  transition: ${({ theme }) => theme.transition};
`;

export const UserCircle = styled(FlexWrapper)`
  width: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  padding: 0;

  &:hover {
    ${UserCircleParagraph} {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
