import { FC } from 'react';

import {
  SidebarMenuIcon,
  SidebarMenuItemLink,
  SidebarMenuParagraph,
} from './SidebarMenuItem.styles';

interface OwnProps {
  to: string;
  title: string;
  active: boolean;
  isIconsOnly: boolean;
  icon: string;
  text: string;
}

const SidebarMenuItem: FC<OwnProps> = ({
  to,
  title,
  active,
  isIconsOnly,
  icon,
  text,
}) => {
  return (
    <SidebarMenuItemLink
      key={to}
      to={to}
      title={title}
      active={active}
      isIconsOnly={isIconsOnly}
    >
      <SidebarMenuIcon active={active} isIconsOnly={isIconsOnly} icon={icon} />
      <SidebarMenuParagraph active={active} isIconsOnly={isIconsOnly}>
        {text}
      </SidebarMenuParagraph>
    </SidebarMenuItemLink>
  );
};

export default SidebarMenuItem;
