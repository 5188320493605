import { IAddress } from 'types';

export interface IOwner {
  name: string;
  role?: string;
  email?: string; //Required if isRepresentingEntity==true
  dob?: string;
  isRepresentingEntity: boolean; //One of max 2 owners representing this company registration
  isUser: boolean; //True if this owner is the user registering the company - There needs to be only one with isUser==true
  isPsc: boolean; //True if this individual or entity is a person with significant control
  isCompany?: boolean;
  pscNaturesOfControl?: string[]; //Required if isPsc==true
  address?: IAddress; //Pulled in from the Director's data when possible
  businessName?: string; //Used for partnerships
}

export enum BUSINESS_TYPE {
  soleTrader = 'soleTrader',
  limitedCompany = 'limitedCompany',
  limitedLiabilityPartnership = 'limitedLiabilityPartnership',
  simplePartnership = 'simplePartnership',
  PLC = 'PLC',
}
