import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { RouteComponentProps } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Title, StaleInput, Paragraph, StaleCarousel } from 'components';
import {
  Wrapper,
  InnerWrap,
  NavWrap,
  ContentWrap,
} from './ResetPassword.styles';
import { useForm } from 'react-hook-form';
import { Firebase } from 'services';
import { Notify } from 'utils';
import { useQuery } from 'hooks';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { GradientTitle } from 'components/shared/GradientTitle/GradientTitle.styles';
import { StyledButton } from 'components/shared/Button/Button.styles';

const ResetPassword: FC<RouteComponentProps> = ({ history }) => {
  const theme = useTheme();
  const { control, handleSubmit } = useForm();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSentErrors, setIsSentErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const URLQuery = useQuery();
  const changePasswordError = URLQuery.get('change_password_error');

  const onSubmit = async ({ email }: any) => {
    try {
      setIsLoading(true);
      const response = await Firebase.sendResetPasswordLink({
        email,
      });

      if (response?.exception || !response.success) {
        setIsSentErrors(response?.message || 'Failure');
      }
      setIsEmailSent(true);
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <InnerWrap>
        <NavWrap>
          {isMobile ? (
            <>
              <StyledButton
                variant="link"
                className="back previous"
                onClick={history.goBack}
              >
                <svg width="24px" height="24px">
                  <use xlinkHref="#arrow-left" />
                </svg>
                Back
              </StyledButton>

              <Title variant="h5" color="white">
                Reset password
              </Title>
            </>
          ) : (
            <>
              <GradientTitle>Sign in to HedgeFlows to:</GradientTitle>

              <StaleCarousel />
            </>
          )}
        </NavWrap>

        <ContentWrap className="rounded">
          {!isMobile && (
            <StyledButton
              variant="link"
              className="cross"
              onClick={history.goBack}
            >
              <svg width="24" height="24">
                <use xlinkHref="#cross-ico" />
              </svg>
            </StyledButton>
          )}
          <div className="block">
            {isEmailSent ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  flex: 1,
                }}
              >
                <Title mb mbValue={theme.spacing.xs} variant="h3">
                  {isSentErrors
                    ? isSentErrors
                    : 'Email with further instructions was sent to your email address.'}
                </Title>

                <Button
                  mt
                  mtValue="auto"
                  onClick={() => {
                    if (isSentErrors) {
                      setIsEmailSent(false);
                      setIsSentErrors(null);
                    } else {
                      history.push('/login');
                    }
                  }}
                >
                  {isSentErrors ? 'Back to Reset password' : 'Back to Sign In'}
                </Button>
              </div>
            ) : (
              <>
                {!changePasswordError && (
                  <Title mb mbValue={theme.spacing.xl}>
                    {isMobile ? 'Enter your email' : 'Reset password'}
                  </Title>
                )}

                {changePasswordError && (
                  <Title variant="h5">
                    You've entered the wrong password too often. Please request
                    a password reset or you can try again later.
                  </Title>
                )}

                <Paragraph>
                  {isMobile
                    ? 'And we will send you reset link'
                    : 'Enter your email and we will send you reset link'}
                </Paragraph>

                <form
                  id="reset-password-form"
                  style={{ flex: 1 }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="field">
                    <StaleInput
                      id="email"
                      name="email"
                      label="Email"
                      view="moving"
                      type="email"
                      control={control}
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                      autoFocus
                    />
                  </div>
                </form>

                <Button
                  mt
                  mtValue="auto"
                  isLoading={isLoading}
                  disabled={isLoading}
                  form="reset-password-form"
                  type="submit"
                >
                  Reset password
                </Button>
              </>
            )}
          </div>
        </ContentWrap>
      </InnerWrap>
    </Wrapper>
  );
};

export default ResetPassword;
