import styled from 'styled-components';
import Card from 'components/shared/Card/Card.styles';

export const CardWrapper = styled(Card)`
  flex: 1 1 100%;
  align-self: stretch;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    max-width: 48.6%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    max-width: 49%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    max-width: 32.4%;
  }
`;
