import { FC, useEffect, useState, useMemo } from 'react';
import {
  StaleCheckboxControlled,
  StaleInputCurrency,
  StaleInputSelect,
  Paragraph,
  PermissionsChecker,
} from 'components';
import { Row } from 'components/shared/Row/Row';
import { Controller, useForm } from 'react-hook-form';
import { EntityInputField } from './RiskSettings.styles';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import { updateEntitySettings } from 'services/firebase';
import { IEntitySettings } from 'state/user';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import {
  COMMITTED_CURRENCY_QUESTIONS,
  FUTURE_CURRENCY_QUESTIONS,
} from 'variables';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import { SettingsField } from 'pages/Settings/Settings.styles';
import {
  WhiteContentContainer,
  WhiteFooterContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import Button from 'components/shared/Button/Button';
import { errorHandler } from 'utils/errors';

interface Inputs
  extends Omit<
    IEntitySettings,
    'riskSettingCommittedCashFlows' | 'riskSettingExpectedCashFlows'
  > {
  riskSettingCommittedCashFlows?: ISelectItem;
  riskSettingExpectedCashFlows?: ISelectItem;
}

const RiskSettings: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { userEntity, entityCurrencyCode } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [isLoading, setIsLoading] = useState(false);
  const defaultCurrency = currencyByCode(entityCurrencyCode);

  const defaultRiskSettingCommittedCashFlows = useMemo(
    () =>
      COMMITTED_CURRENCY_QUESTIONS.find(
        (el) => el.value === userEntity?.riskSettingCommittedCashFlows
      ),
    [userEntity]
  );

  const defaultRiskSettingExpectedCashFlows = useMemo(
    () =>
      FUTURE_CURRENCY_QUESTIONS.find(
        (el) => el.value === userEntity?.riskSettingExpectedCashFlows
      ),
    [userEntity]
  );

  const defaultValues = useMemo(() => {
    return {
      ...userEntity,
      riskSettingCommittedCashFlows: defaultRiskSettingCommittedCashFlows,
      riskSettingExpectedCashFlows: defaultRiskSettingExpectedCashFlows,
    };
  }, [
    userEntity,
    defaultRiskSettingCommittedCashFlows,
    defaultRiskSettingExpectedCashFlows,
  ]);

  const { control, handleSubmit, reset } = useForm<Inputs>({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (userEntity) {
      reset(defaultValues);
    }
  }, [userEntity, reset, defaultValues]);

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);

      const {
        riskSettingCommittedCashFlows,
        riskSettingExpectedCashFlows,
      } = values;

      const request = {
        ...values,
        riskSettingCommittedCashFlows: riskSettingCommittedCashFlows?.value,
        riskSettingExpectedCashFlows: riskSettingExpectedCashFlows?.value,
      };
      const response = await updateEntitySettings(request);

      if (response?.data?.success) {
        reset(values);
        Notify.success('Risk limits settings saved successfully!');
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="entity-form" onSubmit={handleSubmit(onSubmit)}>
      <WhiteContentContainer>
        <EntityInputField>
          <StaleInputCurrency
            id="riskTolerance"
            label="Your risk tolerance"
            view="moving"
            type="text"
            name="riskTolerance"
            control={control}
            currencyPrefix={defaultCurrency?.symbol}
          />
        </EntityInputField>

        <SettingsField>
          <StaleCheckboxControlled
            id="shouldSendEmailIfRiskToleranceExceeded"
            name="shouldSendEmailIfRiskToleranceExceeded"
            control={control}
            Label={<>Send an email if the limit is exceeded (coming soon)</>}
            disabled={true}
          />
        </SettingsField>
        <EntityInputField large>
          <Paragraph mb mbValue={theme.spacing.xs}>
            Committed cashflows
          </Paragraph>
          <Controller
            id="riskSettingCommittedCashFlows"
            name="riskSettingCommittedCashFlows"
            control={control}
            render={({ onChange, value, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label="Committed cashflows"
                  view="moving"
                  data={COMMITTED_CURRENCY_QUESTIONS}
                  selected={value}
                  onSelect={onChange}
                  strategy="fixed"
                />
              );
            }}
          />
        </EntityInputField>

        <EntityInputField large>
          <Paragraph mb mbValue={theme.spacing.xs}>
            Expected cashflows
          </Paragraph>
          <Controller
            id="riskSettingExpectedCashFlows"
            name="riskSettingExpectedCashFlows"
            control={control}
            render={({ onChange, value, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label="Expected cashflows"
                  view="moving"
                  data={FUTURE_CURRENCY_QUESTIONS}
                  selected={value}
                  onSelect={onChange}
                  strategy="fixed"
                />
              );
            }}
          />
        </EntityInputField>
      </WhiteContentContainer>
      <WhiteFooterContainer>
        <PermissionsChecker action="update" resource="settings_risk">
          <Row columnGap={theme.spacing.m} justifyContent="flex-start">
            <Button
              variant="secondary"
              form="entity-form"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Save
            </Button>

            <Button
              variant="link"
              disabled={isLoading}
              onClick={() => history.push('/app/risk-settings')}
            >
              Manage risk settings
            </Button>
          </Row>
        </PermissionsChecker>
      </WhiteFooterContainer>
    </form>
  );
};

export default RiskSettings;
