interface ICallExternalApiWithLoadingParams<T> {
  externalApiCall: () => Promise<T>;
  responseHandler?: (data: T) => void;
  loadingHandler: (loading: boolean) => void;
  errorHandler?: (error: any) => void;
}

export const callExternalApiWithLoading = async <T extends any>({
  externalApiCall,
  responseHandler,
  loadingHandler,
  errorHandler,
}: ICallExternalApiWithLoadingParams<T>) => {
  try {
    loadingHandler(true);
    const response = await externalApiCall();
    responseHandler?.(response);
  } catch (error: any) {
    errorHandler?.(error);
  } finally {
    loadingHandler(false);
  }
};
