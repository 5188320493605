import { IContractInput, IRateContract, ICurrency, IResponse } from 'types';
import { Notify, openQueryWithTimestamp } from 'utils';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';
import { errorHandler } from 'utils/errors';

export const createRateContract = async (
  payload: IContractInput
): Promise<IResponse | undefined> => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/contracts`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface SubscribeToRateContractsParams {
  entityId: string;
  callback: (rateContracts: IRateContract[]) => void;
}

export const subscribeToRateContracts = ({
  entityId,
  callback,
}: SubscribeToRateContractsParams) =>
  db
    .collection('rateContracts')
    .where('_owner', '==', entityId)
    .onSnapshot((query) => callback(openQueryWithTimestamp(query)));

export const cancelContract = async (contractId: string) =>
  await AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/cancel`
  );

export interface HedgeCashflowsParams {
  buyCurrency: ICurrency['code'];
}
export const hedgeCashflows = async ({ buyCurrency }: HedgeCashflowsParams) => {
  await AxiosPrivateFirebaseInstance.post(
    `/contracts/hedgecashflows/${buyCurrency}`
  );
};

export interface HedgeCashflowsByIdsParams {
  invoiceIds: string[];
}
export const hedgeCashflowsByIds = async (
  payload: HedgeCashflowsByIdsParams
) => {
  return AxiosPrivateFirebaseInstance.post<
    IResponse<{
      successes: {
        invoiceId: string;
        prebook: IRateContract | undefined;
      }[];
      failures: {
        invoiceId: string;
        error: string | undefined;
      }[];
    }>
  >(`/contracts/hedgecashflows`, payload);
};

export const exchangeCurrency = async (payload: IContractInput) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    '/contracts/exchange-currency',
    payload
  );
};

interface GetConversionDatesParams {
  currencyPair: string;
}

export const getConversionDates = async ({
  currencyPair,
}: GetConversionDatesParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/contracts/conversion-dates/${currencyPair}`
  );
};

export interface IUpdateRateContractParams {
  id: string;
  data: Partial<
    Pick<IRateContract, 'whenFundsArriveAction' | 'excludeFromRisk'>
  >;
}

export const updateRateContract = async ({
  id,
  data,
}: IUpdateRateContractParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(`/contracts/${id}`, data);

export const moveContractExpiryDate = async (contractId: string) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IRateContract>>(
    `/contracts/${contractId}/date`
  );
