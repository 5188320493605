export const getCustomCashflowsByDefinitionId = (
  customCashflows: Record<
    string,
    {
      sum: number;
      details: {
        amount: number;
        externalRef: string;
        id: string;
      }[];
    }
  >,
  definitionId: string
) => customCashflows[definitionId];
