import { ExposedUseTableProps } from 'components/shared/Table/types';
import { useDebounce } from 'hooks';
import { useEffect, useRef, useState } from 'react';

interface UseFilteringParams<T> {
  filterTypeName: string | null;
  initialFilterValue?: T;
}

const useTableFiltering = <T extends string, K extends {}>({
  filterTypeName,
  initialFilterValue,
}: UseFilteringParams<T>) => {
  const [filter, setFilter] = useState<T | undefined>(initialFilterValue);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounce(searchValue, 500);
  const tableRef = useRef<ExposedUseTableProps<K>>(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.setGlobalFilter(debounceSearchValue);
    }
  }, [debounceSearchValue]);

  useEffect(() => {
    if (filterTypeName && filter !== undefined && tableRef.current) {
      tableRef.current.setFilter(filterTypeName, filter);
    }
  }, [filter, filterTypeName]);

  return {
    tableRef,
    searchValue,
    setSearchValue,
    filter,
    setFilter,
  };
};

export default useTableFiltering;
