import { FC, useState } from 'react';
import { Paragraph, Popup, Row, Title } from 'components';
import { useStoreActions } from 'state';
import { IInvoiceFromSearch } from 'types';
import { errorHandler } from 'utils/errors';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  invoice: IInvoiceFromSearch;
  onClose: () => void;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
}

const RemoveExistingPrebook: FC<OwnProps> = ({
  invoice,
  onClose,
  updateInMemoryInvoices,
}) => {
  const { unbindContractRateToInvoice } = useStoreActions(
    (actions) => actions.InvoicesState
  );
  const [isLoading, setIsLoading] = useState(false);

  const onUnbindContractFromInvoice = async () => {
    if (!invoice.contractId) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await unbindContractRateToInvoice({
        contractId: invoice.contractId,
        payload: { invoiceId: invoice.id },
      });

      if (data.success) {
        updateInMemoryInvoices((invoices) =>
          invoices.map((invoice) => {
            if (invoice.id === data.data.id) {
              return {
                ...invoice,
                contractId: undefined,
                contractAssignment: false,
              };
            }
            return invoice;
          })
        );
      } else {
        errorHandler(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Remove prebooked rate</Title>}
      FooterContent={
        <Row flex={1}>
          <Button variant="secondary" onClick={onClose} disabled={isLoading}>
            Close
          </Button>

          <Button
            onClick={onUnbindContractFromInvoice}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Yes, remove
          </Button>
        </Row>
      }
      width="439px"
      onClose={onClose}
    >
      <Paragraph>Are you sure you want to remove the prebooked rate?</Paragraph>
    </Popup>
  );
};

export default RemoveExistingPrebook;
