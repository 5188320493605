import { IEntitySettings } from 'state/user';
import { IRiskDashboardItem, Nullable } from 'types';
import { parseIntoShortNumberString, roundToPrecision } from 'utils';

export const getReduceRiskRatingForHexagons = (value: number) =>
  Math.ceil(value * 5);

interface IGetRiskCalculationsText {
  cashflowRisksByCurrency?: IRiskDashboardItem;
  riskTolerance: IEntitySettings['riskTolerance'];
  symbol: Nullable<string>;
}

export const getRiskCalculationsText = ({
  cashflowRisksByCurrency,
  riskTolerance,
  symbol,
}: IGetRiskCalculationsText) => {
  if (
    !cashflowRisksByCurrency ||
    isNaN(cashflowRisksByCurrency.cashflowAtRisk)
  ) {
    return '';
  }

  const cashflowAtRiskRelativeToRiskTolerance =
    (cashflowRisksByCurrency.cashflowAtRisk / (riskTolerance || 10000) + 1) *
    100;

  const isCashflowAtRiskAboveRiskTolerance =
    Math.abs(cashflowRisksByCurrency.cashflowAtRisk) > (riskTolerance || 10000);

  const percentText = roundToPrecision(
    Math.abs(cashflowAtRiskRelativeToRiskTolerance),
    0
  );

  const lossesText = parseIntoShortNumberString(
    Math.abs(cashflowRisksByCurrency.cashflowAtRisk),
    0
  );

  if (!!lossesText && cashflowAtRiskRelativeToRiskTolerance) {
    return ` ${symbol}${lossesText}, ${percentText}% ${
      isCashflowAtRiskAboveRiskTolerance ? `above` : `below`
    } `;
  }

  return '';
};
