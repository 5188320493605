import { FC } from 'react';
import { Icon, Paragraph, Row } from 'components';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'variables';
import { isDateOverdue } from 'utils/dates';
import { useTheme } from 'styled-components';

interface IOwnProps {
  dueDate: string;
  plannedPaymentDate?: string;
  withOverdueMarker?: boolean;
}

const InvoiceDateCell: FC<IOwnProps> = ({
  dueDate,
  plannedPaymentDate,
  withOverdueMarker,
}) => {
  const theme = useTheme();
  const isOverdue = isDateOverdue(dueDate);
  const showOverdueMarker =
    isOverdue && withOverdueMarker && !plannedPaymentDate;
  const dateToUse = plannedPaymentDate ?? dueDate;

  return (
    <Row flex={1} gap={theme.spacing.xs}>
      <Paragraph color={showOverdueMarker ? 'red' : 'dark'}>
        {dayjs(dateToUse).format(DATE_FORMAT)}
      </Paragraph>
      {plannedPaymentDate ? <Icon icon="calendar-colorized" /> : ''}
    </Row>
  );
};

export default InvoiceDateCell;
