import { Firebase } from 'services';

export const displayChatWidget = async (userEmail?: string | null) => {
  const chatToken = await Firebase.getChatToken().catch(() => undefined);
  if ((window as any).HubSpotConversations) {
    loadChatWidget(userEmail, chatToken);
  } else {
    (window as any).hsConversationsOnReady = [
      () => {
        loadChatWidget(userEmail, chatToken);
      },
    ];
  }
};

const loadChatWidget = (email?: string | null, chatToken?: string | null) => {
  if (email && chatToken) {
    // TODO: look at adding this to a index.d.ts file to remove need for as any.
    (window as any).hsConversationsSettings = {
      identificationEmail: email,
      identificationToken: chatToken,
    };
  } else {
    console.log('Loading chat widget without settings ');
  }
  (window as any).HubSpotConversations.widget.load();
};
