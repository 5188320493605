import dayjs from 'dayjs';
import { useStoreState } from 'state';
import { IInvoice } from 'types';
import { isContactValid } from 'utils';
import {
  isInvoiceDisabled,
  isInvoiceStatusInPayableState,
  isPayableInvoice,
  isReceivableInvoice,
  isInvoicePrebookable,
  isInvoiceSubmittableForReview,
  isInvoiceApprovable,
  isApprovalFlowAllowToPayInvoice,
  isInvoiceApprovableByUser,
} from 'utils/invoices';

interface UseInvoiceRecordParams {
  record?: IInvoice;
}

const useInvoiceRecord = ({ record }: UseInvoiceRecordParams) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const {
    hasApprovalFlow,
    isUserApprover,
    isUserSubmitter,
    entityCurrencyCode,
    userId,
  } = useStoreState((state) => state.UserState);
  const { recipientById } = useStoreState((state) => state.RecipientsState);

  const currency = currencyByCode(record?.currency);
  const isDisabled = record ? isInvoiceDisabled(record) : false;
  const isSameCurrency = record?.currency === entityCurrencyCode;
  const daysUntilDueDate = dayjs(record?.dueDate).diff(dayjs(), 'days');
  const isPayable = record ? isPayableInvoice(record) : false;
  const isReceivable = record ? isReceivableInvoice(record) : false;
  const contact = recipientById(record?.contactId);
  const hasValidContact = isContactValid(contact);
  const isCanBePaid = record
    ? hasValidContact &&
      isInvoiceStatusInPayableState(record) &&
      isApprovalFlowAllowToPayInvoice({
        hasApprovalFlow,
        isUserApprover,
        record,
      })
    : false;
  const isPrebookable = record
    ? isInvoicePrebookable(record, entityCurrencyCode)
    : false;
  const hasTracking = !!record?.trackingId;
  const hasPrebook = !!record?.contractId;
  const submittableForReview = record
    ? hasValidContact &&
      hasApprovalFlow &&
      isUserSubmitter &&
      isInvoiceSubmittableForReview(record)
    : false;
  const isApprovable = record
    ? hasValidContact &&
      hasApprovalFlow &&
      isUserApprover &&
      isInvoiceApprovable(record)
    : false;
  const isApprovableByCurrentUser = record
    ? hasValidContact &&
      isApprovable &&
      isInvoiceApprovableByUser(record, userId)
    : false;
  const isInvoiceInEntityCurrency = record?.currency === entityCurrencyCode;

  return {
    currency,
    isDisabled: !!isDisabled,
    isSameCurrency,
    daysUntilDueDate,
    isPayable,
    isReceivable,
    hasTracking,
    hasPrebook,
    hasValidContact,
    isCanBePaid,
    isPrebookable,
    submittableForReview,
    isApprovable,
    isApprovableByCurrentUser,
    isInvoiceInEntityCurrency,
  };
};

export default useInvoiceRecord;
