import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import useUrlValues from 'hooks/useUrlValues';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { Col, Paragraph, Row, Subtitle, Title } from 'components';
import Button from 'components/shared/Button/Button';
import useTabs from 'pages/Invoices/hooks/useTabs';
import { ITab } from 'components/shared/Tabs/Tabs';
import { TInvoicePageTabs } from 'pages/Invoices/types';
import Card from 'components/shared/Card/Card.styles';
import { OverflowXWrapper } from 'components/shared/OverflowXWrapper/OverflowXWrapper.styles';

interface IOwnProps {
  paymentRunInvoicesQuantity: number;
}

const MobileTabs: FC<IOwnProps> = ({ paymentRunInvoicesQuantity }) => {
  const theme = useTheme();
  const { setUrlValue, tab } = useUrlValues('tab');

  const {
    onImportIntegrationEngineData,
    isImporting,
    isIntegrated,
  } = useIntegrationEngine();

  const { tabs } = useTabs({
    paymentRunInvoicesLength: paymentRunInvoicesQuantity,
  });

  const [activeTab, setActiveTab] = useState(
    (tab as TInvoicePageTabs) ?? tabs[0].id
  );

  const onSetTab = ({ id }: ITab<TInvoicePageTabs>) => {
    setActiveTab(id);
    setUrlValue({
      tab: id,
      currency: 'all',
      filter: '',
      dueDateFrom: '',
      dueDateTo: '',
      fullyPaidOnDateFrom: '',
      fullyPaidOnDateTo: '',
    });
  };

  return (
    <Col>
      <Row>
        <Subtitle variant="bold">Bills to pay</Subtitle>

        {isIntegrated && (
          <Button
            disabled={isImporting}
            onClick={() => onImportIntegrationEngineData()}
            variant="secondary"
            isLoading={isImporting}
          >
            Refresh
          </Button>
        )}
      </Row>

      <OverflowXWrapper>
        <Row mt mb justifyContent="flex-start" gap={theme.spacing.xs}>
          {tabs.map(({ id, title, count }) => (
            <Card
              flexDirection="column"
              key={id}
              onClick={() => onSetTab({ id })}
              padding={theme.spacing.m}
              style={{ minWidth: '136px' }}
            >
              <Paragraph
                whiteSpace="nowrap"
                color={activeTab === id ? 'emeraldDark' : 'dark'}
              >
                {title}
              </Paragraph>
              <Row
                justifyContent="flex-start"
                alignItems="flex-end"
                gap={theme.spacing.xxs}
              >
                <Title variant="h2">{count}</Title>
                <Subtitle mb mbValue={theme.spacing.xxs}>
                  bills
                </Subtitle>
              </Row>
            </Card>
          ))}
        </Row>
      </OverflowXWrapper>
    </Col>
  );
};

export default MobileTabs;
