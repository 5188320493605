import { IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';

export const readBulkContactsFile = async (payload: FormData) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/recipients/bulk/upload`,
    payload
  );
};

export const bulkContactsUpload = async (payload: any) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/recipients/bulk`,
    payload
  );
};
