import { ButtonCopy, Col, Paragraph } from 'components';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { FC } from 'react';
import { useStoreState } from 'state';
import { Notify } from 'utils';

interface OwnProps {
  sellCurrency: string;
}

const getColumnLabel = (columnType: string) => {
  switch (columnType) {
    case 'accountName':
      return 'Account name';
    case 'bicSwift':
      return 'SWIFT / BIC';
    case 'iban':
      return 'IBAN';
    case 'accountNumber':
      return 'Account Number';
    case 'sortCode':
      return 'Sort Code';
    default:
      return '';
  }
};

const FundingBankTransfer: FC<OwnProps> = ({ sellCurrency }) => {
  const { fundingAccountByCurrency } = useStoreState(
    ({ ReferenceDataState }) => ReferenceDataState
  );

  const fundingAccount = fundingAccountByCurrency(sellCurrency);

  if (!fundingAccount) {
    return null;
  }

  return (
    <Col>
      <RowInfo>
        <Paragraph>Account name</Paragraph>
        <Paragraph variant="bold">{fundingAccount.accountName}</Paragraph>
      </RowInfo>

      <RowInfo>
        <Paragraph>
          {getColumnLabel(fundingAccount.accountDetails.accountNumberType)}
        </Paragraph>
        <ButtonCopy
          showIcon
          onClick={() => {
            navigator.clipboard.writeText(
              fundingAccount.accountDetails.accountNumber
            );
            Notify.success(
              `Copied ${getColumnLabel(
                fundingAccount.accountDetails.accountNumberType
              )} to clipboard!`
            );
          }}
        >
          <Paragraph variant="bold">
            {fundingAccount.accountDetails.accountNumber}
          </Paragraph>
        </ButtonCopy>
      </RowInfo>

      {fundingAccount.accountDetails.type !== 'sepa' && (
        <RowInfo>
          <Paragraph>
            {getColumnLabel(fundingAccount.accountDetails.routingCodeType)}
          </Paragraph>
          <ButtonCopy
            showIcon
            onClick={() => {
              navigator.clipboard.writeText(
                fundingAccount.accountDetails.routingCode
              );
              Notify.success(
                `Copied ${getColumnLabel(
                  fundingAccount.accountDetails.routingCodeType
                )} to clipboard!`
              );
            }}
          >
            <Paragraph variant="bold">
              {fundingAccount.accountDetails.routingCode}
            </Paragraph>
          </ButtonCopy>
        </RowInfo>
      )}

      <RowInfo>
        <Paragraph>Reference</Paragraph>
        <ButtonCopy
          showIcon
          onClick={() => {
            navigator.clipboard.writeText(fundingAccount.reference);
            Notify.success('Copied reference to clipboard!');
          }}
        >
          <Paragraph variant="bold">{fundingAccount.reference}</Paragraph>
        </ButtonCopy>
      </RowInfo>
      <RowInfo justifyContent="flex-end">
        <Paragraph error>
          Please ensure the reference is added to your transfer details
        </Paragraph>
      </RowInfo>

      <RowInfo>
        <Paragraph>Bank name</Paragraph>
        <Paragraph>{fundingAccount.bankName}</Paragraph>
      </RowInfo>
      <RowInfo>
        <Paragraph>Bank address</Paragraph>
        <Paragraph>{fundingAccount.bankAddress}</Paragraph>
      </RowInfo>

      {fundingAccount.secondaryAccountDetails && (
        <>
          {fundingAccount.secondaryAccountDetails.type !== 'sepa' && (
            <RowInfo>
              <Paragraph>
                {getColumnLabel(
                  fundingAccount.secondaryAccountDetails.routingCodeType
                )}
              </Paragraph>
              <Paragraph variant="bold">
                {fundingAccount.secondaryAccountDetails.routingCode}
              </Paragraph>
            </RowInfo>
          )}

          <RowInfo>
            <Paragraph>
              {getColumnLabel(
                fundingAccount.secondaryAccountDetails.accountNumberType
              )}
            </Paragraph>
            <Paragraph variant="bold">
              {fundingAccount.secondaryAccountDetails.accountNumber}
            </Paragraph>
          </RowInfo>
        </>
      )}
    </Col>
  );
};

export default FundingBankTransfer;
