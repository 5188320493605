import { IInvoice, TInvoicesAggregations } from 'types';

export const detectCurrenciesFromInvoices = (
  invoices: IInvoice[],
  entityCurrencyCode?: string | null
) => {
  const currencies = new Set<string>(
    new Set(invoices.map((invoice) => invoice.currency))
  );

  if (entityCurrencyCode && currencies.has(entityCurrencyCode)) {
    currencies.delete(entityCurrencyCode);
  }

  return Array.from(currencies);
};

export const getInvoicesFilters = ({
  tab,
  filterName,
  currency,
  dueDateFrom,
  dueDateTo,
  fullyPaidOnDateFrom,
  fullyPaidOnDateTo,
  invoiceContactRecipientName,
  contractId,
  paymentRunId,
}: {
  tab?: string | null;
  filterName?: string | null;
  currency?: string | null;
  dueDateFrom?: string | null;
  dueDateTo?: string | null;
  fullyPaidOnDateFrom?: string | null;
  fullyPaidOnDateTo?: string | null;
  invoiceContactRecipientName?: string | null;
  contractId?: string | null;
  paymentRunId?: string;
}) => {
  let filtersString: string = '';

  if (tab) {
    filtersString = filtersString.concat(`,tab=${tab}`);
  }

  if (filterName) {
    filtersString = filtersString.concat(`,filter=${filterName}`);
  }

  if (currency) {
    filtersString = filtersString.concat(`,currency=${currency}`);
  }

  if (dueDateFrom) {
    filtersString = filtersString.concat(`,dueDateFrom=${dueDateFrom}`);
  }

  if (dueDateTo) {
    filtersString = filtersString.concat(`,dueDateTo=${dueDateTo}`);
  }

  if (contractId) {
    filtersString = filtersString.concat(`,contractId=${contractId}`);
  }

  if (fullyPaidOnDateFrom) {
    filtersString = filtersString.concat(
      `,fullyPaidOnDateFrom=${fullyPaidOnDateFrom}`
    );
  }

  if (fullyPaidOnDateTo) {
    filtersString = filtersString.concat(
      `,fullyPaidOnDateTo=${fullyPaidOnDateTo}`
    );
  }

  if (invoiceContactRecipientName) {
    filtersString = filtersString.concat(
      `,invoiceContactRecipientName=${invoiceContactRecipientName}`
    );
  }

  if (paymentRunId) {
    filtersString = filtersString.concat(`,paymentRunId=${paymentRunId}`);
  }

  if (filtersString) {
    filtersString = filtersString.replace(',', '');
  }

  return filtersString;
};

export const getElasticQueryFilters = (filters: {
  [key: string]: string | null | undefined;
}) => {
  let filtersArray: string[] = [];

  for (const [key, value] of Object.entries(filters)) {
    if (value) {
      filtersArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }

  return filtersArray.join(',');
};

export const getCountFromInvoicesAggregationPerFilter = (
  invoicesAggregations: TInvoicesAggregations | null,
  filterName: keyof TInvoicesAggregations['perFilter']
) => invoicesAggregations?.perFilter[filterName]?.count ?? 0;

export const getCurrenciesFromInvoicesAggregationPerFilter = (
  invoicesAggregations: TInvoicesAggregations | null,
  filterName: keyof TInvoicesAggregations['perFilter']
) => invoicesAggregations?.perFilter[filterName]?.currencies ?? [];

export const getTabNameByKey = (tab: string | null) => {
  switch (tab) {
    case 'outstanding':
      return 'Outstanding';
    case 'submitted':
      return 'To approve';
    case 'approved':
      return 'Approved';
    case 'paymentRun':
      return 'Payment run';
    case 'paid':
      return 'Paid';
    default:
      return tab;
  }
};
