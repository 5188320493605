import { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Main, StepTwo, StepThree, StepFour } from './components';
import { StepOne, StepSix } from '../CompanyRegistration/components';
import { Wrapper } from './SignUpSoleTrader.styles';
import { useStoreState } from 'state';
import { useQuery } from 'hooks';
import { SignUpWrap } from 'pages/SignUp/components';
import { StaleRegistrationNav } from 'components';

const NAV_MENU = [
  {
    id: 1,
    name: 'Business info',
    description: 'The nature of your business, annual turnover and so on',
    step: '01',
  },
  {
    id: 2,
    name: 'Your personal entity data',
    description: 'Your personal information and VAT number if you have one',
    step: '02',
  },
  {
    id: 3,
    name: 'ID check',
    description: 'Please make sure you have one of your documents with you',
    step: '03',
  },
  {
    id: 4,
    name: 'Docs',
    description: 'Be ready to answer couple of questions',
    step: '04',
  },
];

const NAV_MENU_MOB = [
  {
    id: 1,
    name: 'Dates',
  },
  {
    id: 2,
    name: 'Terms',
  },
  {
    id: 3,
    name: 'Review',
  },
  {
    id: 4,
    name: 'Book',
  },
];

const SignUpSoleTrader: FC<RouteComponentProps> = ({ history }) => {
  const [stepOneValues, setStepOneValues] = useState<any>({});
  const [stepTwoValues, setStepTwoValues] = useState<any>({});
  const [onfidoApplicantId, setOnfidoApplicantId] = useState<string | null>(
    null
  );

  const { userEntity } = useStoreState((state) => state.UserState);
  const isStep2Completed = useMemo(
    () => userEntity?.status === 'onboardingStep2Completed',
    [userEntity]
  );
  const isStep3Completed = useMemo(
    () => userEntity?.status === 'onboardingStep3Completed',
    [userEntity]
  );
  const isStep4Completed = useMemo(
    () => userEntity?.status === 'onboardingStep4Completed',
    [userEntity]
  );

  const URLQuery = useQuery();
  const activeStep = URLQuery.get('step') ?? '';

  const { url } = useRouteMatch();

  const startingStep = useMemo(() => (isMobile ? '1' : '2'), []);

  useEffect(() => {
    if (isStep2Completed && (!activeStep || parseInt(activeStep) <= 2)) {
      history.replace(`${url}?step=3`);
    }

    if (isStep3Completed && (!activeStep || parseInt(activeStep) <= 3)) {
      history.replace(`${url}?step=4`);
    }

    if (isStep4Completed && (!activeStep || parseInt(activeStep) <= 4)) {
      history.replace(`${url}?step=5`);
    }
  }, [
    isStep2Completed,
    isStep3Completed,
    isStep4Completed,
    userEntity,
    activeStep,
    history,
    url,
  ]);

  const onContinueHandler = () => {
    if (!activeStep) {
      URLQuery.append('step', startingStep);
      history.push(`${url}?${URLQuery.toString()}`);
    } else if (parseInt(activeStep) < 4) {
      URLQuery.set('step', (parseInt(activeStep) + 1).toString());
      history.push(`${url}?${URLQuery.toString()}`);
    }
  };

  const renderContent = () => {
    switch (parseInt(activeStep)) {
      case 1:
        return (
          <StepOne
            onSaveValues={(values) => setStepOneValues(values)}
            savedValues={stepOneValues}
            onContinue={onContinueHandler}
          />
        );
      case 2:
        return (
          <StepTwo
            stepOneValues={stepOneValues}
            stepTwoValues={stepTwoValues}
            onSaveValues={(values) => setStepTwoValues(values)}
            onContinue={onContinueHandler}
            // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<string | null>>' is ... Remove this comment to see the full error message
            setOnfidoApplicantId={setOnfidoApplicantId}
          />
        );
      case 3:
        return (
          <StepThree
            onContinue={onContinueHandler}
            onfidoApplicantId={onfidoApplicantId}
          />
        );
      case 4:
        return (
          <StepFour
            stepOneValues={stepOneValues}
            stepTwoValues={stepTwoValues}
          />
        );
      default:
        // we skip Main for dekstop
        return isMobile ? (
          <Main />
        ) : (
          <StepOne
            savedValues={stepOneValues}
            onSaveValues={(values) => setStepOneValues(values)}
            onContinue={onContinueHandler}
          />
        );
    }
  };

  return (
    <Wrapper>
      {isStep4Completed ? (
        <StepSix />
      ) : (!activeStep || !parseInt(activeStep)) && isMobile ? ( // we skip Main for desktop
        <Main />
      ) : (
        <SignUpWrap>
          <StaleRegistrationNav
            title={
              isMobile ? 'Company registration' : 'Your company registration'
            }
            description="Here’s what you need to complete the application and get full access to HedgeFlows"
            navMenu={isMobile ? NAV_MENU_MOB : NAV_MENU}
            activeStep={activeStep ? parseInt(activeStep) : 1}
          />
          {renderContent()}
        </SignUpWrap>
      )}
    </Wrapper>
  );
};

export default SignUpSoleTrader;
