import { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import {
  Main,
  StepOne,
  StepTwo,
  StepThree,
  StepFive,
  StepSix,
  SignUpWrap,
} from './components';
import { StaleRegistrationNav } from 'components';
import { Wrapper } from './CompanyRegistration.styles';
import { useStoreState } from 'state';
import { useQuery } from 'hooks';
import { isMobile } from 'react-device-detect';

const NAV_MENU = [
  {
    id: 1,
    name: 'Business info',
    description: 'The nature of your business, annual turnover and so on',
    step: '01',
  },
  {
    id: 2,
    name: 'Details',
    description: 'Details about it’s registration, owners contact info, VAT',
    step: '02',
  },
  {
    id: 3,
    name: 'Owners',
    description:
      'Details about directors and owners who will be representing this company',
    step: '03',
  },
  {
    id: 4,
    name: 'Docs',
    description: 'Be ready to answer couple of questions',
    step: '04',
  },
];

const NAV_MENU_MOB = [
  {
    id: 1,
    name: 'Business',
  },
  {
    id: 2,
    name: 'Details',
  },
  {
    id: 3,
    name: 'Owners',
  },
  {
    id: 4,
    name: 'Docs',
  },
];

const SignUpCompany: FC<RouteComponentProps> = ({ history }) => {
  const [stepOneValues, setStepOneValues] = useState<any>({});
  const [stepTwoValues, setStepTwoValues] = useState<any>({});

  const { userEntity } = useStoreState((state) => state.UserState);

  const isStep2Completed = useMemo(
    () => userEntity?.status === 'onboardingStep2Completed',
    [userEntity]
  );
  const isStep3Completed = useMemo(
    () => userEntity?.status === 'onboardingStep3Completed',
    [userEntity]
  );
  const isStep4Completed = useMemo(
    () => userEntity?.status === 'onboardingStep4Completed',
    [userEntity]
  );

  const URLQuery = useQuery();
  const activeStep = URLQuery.get('step') ?? '';

  const { url } = useRouteMatch();

  const startingStep = useMemo(() => (isMobile ? '1' : '2'), []);

  useEffect(() => {
    if (isStep2Completed && (!activeStep || parseInt(activeStep) <= 2)) {
      history.replace(`${url}?step=3`);
    }

    if (isStep3Completed && (!activeStep || parseInt(activeStep) <= 3)) {
      history.replace(`${url}?step=4`);
    }

    if (isStep4Completed && (!activeStep || parseInt(activeStep) <= 4)) {
      history.replace(`${url}?step=5`);
    }
  }, [
    isStep2Completed,
    isStep3Completed,
    isStep4Completed,
    userEntity,
    activeStep,
    history,
    url,
  ]);

  const onContinueHandler = () => {
    if (!activeStep) {
      URLQuery.append('step', startingStep);
      history.push(`${url}?${URLQuery.toString()}`);
    } else if (parseInt(activeStep) < 6) {
      URLQuery.set('step', (parseInt(activeStep) + 1).toString());
      history.push(`${url}?${URLQuery.toString()}`);
    }
  };

  const renderContent = () => {
    switch (parseInt(activeStep)) {
      case 1:
        return (
          <StepOne
            onSaveValues={(values) => setStepOneValues(values)}
            savedValues={stepOneValues}
            onContinue={onContinueHandler}
          />
        );
      case 2:
        return (
          <StepTwo
            stepTwoValues={stepTwoValues}
            onSaveValues={(values) => setStepTwoValues(values)}
            onContinue={onContinueHandler}
          />
        );
      case 3:
        return (
          <StepThree
            onContinue={onContinueHandler}
            stepOneValues={stepOneValues}
            stepTwoValues={stepTwoValues}
          />
        );
      case 4:
        return <StepFive />;
      default:
        // we skip Main for dekstop
        return isMobile ? (
          <Main />
        ) : (
          <StepOne
            savedValues={stepOneValues}
            onSaveValues={(values) => setStepOneValues(values)}
            onContinue={onContinueHandler}
          />
        );
    }
  };

  return (
    <Wrapper>
      {isStep4Completed ? (
        <StepSix />
      ) : !parseInt(activeStep) && isMobile ? ( // we skip Main for desktop
        <Main />
      ) : (
        <SignUpWrap>
          <StaleRegistrationNav
            title={
              isMobile ? 'Company registration' : 'Your company registration'
            }
            description="Here’s what you need to complete the application and get full access to HedgeFlows"
            navMenu={isMobile ? NAV_MENU_MOB : NAV_MENU}
            activeStep={parseInt(activeStep) || 1}
          />
          {renderContent()}
        </SignUpWrap>
      )}
    </Wrapper>
  );
};

export default SignUpCompany;
