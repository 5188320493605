import { CurrencyRate, IRateContract, CurrencyRateFulfilled } from 'types';
import { parseRate } from 'utils';

export const getFlexCurrentBestRateUnparsed = (
  item?: IRateContract,
  marketRate?: number | null
) => {
  if (!item) {
    return marketRate;
  }

  if (item.flexFeeAmount !== undefined) {
    return Math.max(item.rate, marketRate ?? 0);
  }

  return item.rate;
};

/**
 * For flex prebooked rates, this selects the max
 * between the rate on contract, and market rate passed in.
 */
export const getFlexCurrentBestRate = (
  item?: IRateContract,
  marketRate?: number | null
) => parseRate(getFlexCurrentBestRateUnparsed(item, marketRate));

export const checkIfRatesFulfilled = (
  item: CurrencyRate
): item is CurrencyRateFulfilled => {
  return (item as CurrencyRateFulfilled)?.value !== undefined;
};
