import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import { isMobile } from 'react-device-detect';

import { Paragraph, Overflow, Row, Title } from 'components';
import { BUSINESS_TYPE } from 'types';
import { IInputs } from './types';
import Field from 'components/shared/Field/Field.styles';
import Button from 'components/shared/Button/Button';
import RadioButtons from 'components/shared/RadioButtons/RadioButtons';
import EntitySignUpFormFields from 'components/shared/EntitySignUpFormFields/EntitySignUpFormFields';

interface OwnProps {
  onContinueHandler: (values: {
    firstName: string;
    lastName: string;
    companyCountry: string;
    companyType: BUSINESS_TYPE;
    companyName: string;
    isAccountant: boolean;
  }) => void;
}

const StepThree: FC<OwnProps> = ({ onContinueHandler }) => {
  const { handleSubmit, control, watch } = useForm<IInputs>({
    defaultValues: {
      isAccountant: undefined,
      isCompany: undefined,
      firstName: '',
      lastName: '',
      country: undefined,
      businessType: undefined,
    },
  });

  const isCompanyWatch = watch('isCompany');
  const isCompanyAnswered = isCompanyWatch !== undefined;
  const isAccountantWatch = watch('isAccountant');
  const isAccountantAnswered = isAccountantWatch !== undefined;

  const onSubmit = async ({
    firstName,
    lastName,
    country,
    businessType,
    companyName,
    isAccountant,
    isCompany,
  }: IInputs) => {
    onContinueHandler({
      firstName,
      lastName,
      companyCountry: isCompany === 'true' ? 'gb' : country.alpha2,
      companyType:
        isCompany === 'true' ? BUSINESS_TYPE.limitedCompany : businessType,
      isAccountant: isAccountant === 'true',
      companyName,
    });
  };

  return (
    <Overflow>
      {isMobile ? (
        <Title variant="h5">Basic info</Title>
      ) : (
        <Title mb>Basic info</Title>
      )}

      <form id="basic-info-form" onSubmit={handleSubmit(onSubmit)}>
        <EntitySignUpFormFields control={control} watch={watch} />

        <Paragraph mt mb>
          Are you an external accountant or part-time CFO?
        </Paragraph>

        <Field fluid>
          <Controller
            name="isAccountant"
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <RadioButtons
                flex={1}
                data={[
                  {
                    id: 'isAccountant-yes',
                    value: 'true',
                    checked: value === 'true',
                    name,
                    displayName: <p>Yes</p>,
                    onChange,
                  },
                  {
                    id: 'isAccountant-no',
                    value: 'false',
                    checked: value === 'false',
                    name,
                    displayName: <p>No</p>,
                    onChange,
                  },
                ]}
              />
            )}
          />
        </Field>
      </form>

      <Row alignSelf="stretch" mt>
        <Button
          disabled={!isCompanyAnswered || !isAccountantAnswered}
          flex={1}
          form="basic-info-form"
        >
          Continue
        </Button>
      </Row>
    </Overflow>
  );
};

export default StepThree;
