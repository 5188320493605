import {
  Icon,
  InlineLoader,
  Paragraph,
  PermissionsChecker,
  StaleInfo,
  StaleSwitch,
} from 'components';
import { set } from 'lodash';
import { PropsWithChildren, useState } from 'react';
import { CellProps, Row } from 'react-table';
import { useTheme } from 'styled-components';
import { IPaymentRunItemSummary } from 'types/paymentRuns';
import { showLockIcon } from './utils';

interface OwnProps
  extends Pick<CellProps<IPaymentRunItemSummary>, 'row' | 'value'> {
  onEdit: (
    recordId: string,
    updatedRowData: Row<IPaymentRunItemSummary>['original']
  ) => Promise<void>;
  isLoadingBuyFxAll: boolean;
  paymentDate: string;
}

const BuyFxCell = ({
  row,
  value,
  isLoadingBuyFxAll,
  onEdit,
  paymentDate,
}: PropsWithChildren<OwnProps>) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = async () => {
    setIsLoading(true);

    const updatedData = set({ ...row.original }, 'buyFx', !value);

    await onEdit(row.original.id, updatedData);

    setIsLoading(false);
  };

  const { prebooked, canChooseBuyFx, id } = row.original;

  return (
    <>
      <StaleInfo
        portal
        disabled={!(value && !prebooked)}
        mode="hover"
        infoStyle={{
          marginRight: theme.spacing.s,
        }}
        trigger={
          <Icon
            icon={
              showLockIcon({
                value,
                prebooked,
                paymentDate,
              })
                ? 'lock-ico'
                : 'arrow-growth'
            }
            fill={value && !prebooked ? theme.color.coral : theme.color.black}
          />
        }
      >
        <Paragraph color="white">
          Required currency exchange will be booked once you authorise the
          transfer(s).
        </Paragraph>
      </StaleInfo>

      <PermissionsChecker action="create" resource="prebooks">
        {(isLoading || isLoadingBuyFxAll) && !prebooked && <InlineLoader />}
        {!isLoading && !isLoadingBuyFxAll && canChooseBuyFx && (
          <StaleSwitch id={id} isOn={value} handleToggle={handleToggle} />
        )}
      </PermissionsChecker>
    </>
  );
};

export default BuyFxCell;
