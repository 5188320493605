import styled from 'styled-components';
import { Col } from 'components/shared/Col/Col';

export const TitleLoaderWrapper = styled(Col)`
  align-self: stretch;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.white};
`;
