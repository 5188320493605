import { DefaultTheme } from 'styled-components';
import { InlineLoader, Paragraph, PermissionsChecker, Row } from 'components';
import { Column } from 'react-table';
import { Nullable } from 'types';
import { IEntityInvite, IEntityUser } from 'state/user';
import Button from 'components/shared/Button/Button';
import Pill from 'components/shared/Pill/Pill';
import { filterOutPackageSpecificRoles } from './utils';
import { IPermitRoleV2 } from 'types/permissions';
import { firstCharacterToUppercase } from 'utils';
import InvitesRolesCell from './components/InvitesRolesCell/InvitesRolesCell';

interface IGenerateEntityUserTableColumnsProps {
  userId: Nullable<string>;
  onEditEntityUser: (member: IEntityUser) => void;
  isLoadingRoles: boolean;
  theme: DefaultTheme;
  isPermitFeatureFlagEnabled: boolean;
}

export const generateEntityUserTableColumns = ({
  isLoadingRoles,
  onEditEntityUser,
  isPermitFeatureFlagEnabled,
  theme,
  userId,
}: IGenerateEntityUserTableColumnsProps): Column<IEntityUser>[] => {
  const columns: Column<IEntityUser>[] = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ row, value }) => (
        <Paragraph
          variant="bold"
          color={userId === row.original.id ? 'emeraldDark' : 'dark'}
        >
          {value}
        </Paragraph>
      ),
      width: 100,
    },
    {
      accessor: 'email',
      Header: 'Mail',
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
    },
    {
      accessor: 'roles',
      Header: `Roles (${isPermitFeatureFlagEnabled ? 'Beta' : 'coming soon'})`,
      disableSortBy: true,
      Cell: ({ value }) => (
        <Row
          flex={1}
          flexWrap="wrap"
          gap={theme.spacing.s}
          justifyContent="flex-start"
        >
          {filterOutPackageSpecificRoles(value ?? []).map(
            (role: IPermitRoleV2) => (
              <Pill text={role.role ?? ''} />
            )
          )}
        </Row>
      ),
      width: 120,
    },
    {
      id: 'id',
      accessor: 'id',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ row }) => {
        return (
          <Row flex={1} justifyContent="flex-end">
            <PermissionsChecker action="update" resource="settings">
              {isLoadingRoles && <InlineLoader />}
              {!isLoadingRoles && (
                <Button
                  onClick={() => onEditEntityUser(row.original)}
                  variant="link"
                  disabled={!isPermitFeatureFlagEnabled}
                  icon="edit-ico"
                />
              )}
            </PermissionsChecker>
          </Row>
        );
      },
      width: 60,
      minWidth: 55,
    },
  ];

  return columns;
};

interface IGenerateEntityInviteTableColumnsProps {
  theme: DefaultTheme;
}

export const generateEntityInviteTableColumns = ({
  theme,
}: IGenerateEntityInviteTableColumnsProps): Column<IEntityInvite>[] => {
  const columns: Column<IEntityInvite>[] = [
    {
      id: 'name',
      Header: 'Name',
      Cell: ({
        row: {
          original: { firstName, lastName },
        },
      }: any) => (
        <Paragraph variant="bold">
          {firstName} {lastName}
        </Paragraph>
      ),
      width: 100,
    },
    {
      accessor: 'email',
      Header: 'Mail',
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
    },
    {
      accessor: 'roles',
      Header: `Roles`,
      disableSortBy: true,
      Cell: ({ value }) => <InvitesRolesCell roles={value} />,
      width: 120,
    },
    {
      accessor: 'status',
      Header: `Status`,
      disableSortBy: true,
      Cell: ({ value }) => (
        <Paragraph>{firstCharacterToUppercase(value)}</Paragraph>
      ),
      width: 120,
    },
  ];

  return columns;
};
