import styled, { css } from 'styled-components';
import { Paragraph } from 'components';

export const Wrapper = styled.div<{ hasIcon: boolean; skin: string }>(
  ({ theme, hasIcon, skin }) => css`
    padding: 16px;
    padding-left: ${hasIcon && '50px'};
    border-radius: 4px;
    margin: 16px 0;
    position: relative;
    border: ${skin === 'border' && `1px solid ${theme.color.greyLight_2}`};
    background: ${skin === 'green' && theme.color.emeraldLight};
    background: ${skin === 'grey' && theme.color.greyLight_2};

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 16px;
      top: 16px;
    }

    ${Paragraph} span {
      margin-left: 12px;
    }

    p:not(:last-child) {
      margin-bottom: 6px;
    }

    .row.rate-wrap {
      justify-content: flex-start;

      > div {
        margin: 0 24px 6px 0;
      }
    }

    .cost-range {
      background: linear-gradient(
        90deg,
        #cfeeea 1.31%,
        #e7edd0 27.56%,
        #facba0 70.76%,
        #ffb8bd 98.26%
      );
      height: 18px;
      border-bottom: 2px solid ${theme.color.dark};
      margin: 7px 0 0 0;

      .example-thumb {
        bottom: -2px;
      }

      .tooltip {
        color: ${theme.color.dark};
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }

    .example-thumb {
      height: 23px;
      border-radius: 40px;
      border: 2px solid ${theme.color.dark};
    }

    .tooltip {
      position: absolute;
      bottom: calc(100% + 6px);
      padding: 6px 10px;
      border-radius: 6px;
      left: 50%;
      transform: translate(-50%, 0);
      white-space: nowrap;
    }
  `
);
