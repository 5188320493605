import { IBalance } from 'types';
import { TExternalBalance } from './externalBalances';

export interface IRiskDashboardItem {
  buyCurrency: string;
  sellCurrency: string;
  netExposure: number;
  netExposureInSellCurrency: number;
  payables: number;
  receivables: number;
  receivableInvoices: number;
  payableInvoices: number;
  prebooked: number;
  externalHedges: number;
  saleOrders: number;
  purchaseOrders: number;
  balance: number;
  externalBalance: number;
  cashflowAtRisk: number;
  cashflowAtRiskPayablesReceivables14: number;
  cashflowAtRiskPayablesReceivables14Plus: number;
  cashflowAtRiskPoSo: number;
  cashflowAtRiskCash: number;
  cashflowAtRiskCustomCashflow: number;
}

export enum CASHFLOW_TYPE {
  payable = 'payable',
  receivable = 'receivable',
  prebook = 'prebook',
  externalHedge = 'externalHedge',
  externalBalance = 'externalBalance',
  balance = 'balance',
  salesOrder = 'salesOrder',
  purchaseOrder = 'purchaseOrder',
}

interface ICashFlowAtRisk {
  id: string;
  label: string;
  value: number;
}

export interface CashflowsRisksData {
  currencyRisk: Record<string, IRiskDashboardItem>;
  totalCashflowAtRisk: Array<ICashFlowAtRisk>;
}

export interface IPnlDataPerMonth {
  averageOutstandingBalance: number;
  turnover: number;
  fxImpactTotal: number;
  /**
   * fxImpact for past performance items with status not equal to PAID
   */
  fxImpactForUnpaid: number;
  /**
   * fxImpact from paid invoices with monthly contribution in this month
   */
  fxImpactForPaid: number;
  /**
   * sum of invoices fxImpact paid this month
   */
  totalFxImpactForPaid: number;
  fxCost: number;
  /**
   * fxCost from cashflows paid this month
   */
  transactionCost: number;
  id: string; // YYYY-MM
  [key: string]: number | string;
}

export interface IPastPerformanceItem {
  id: string;
  entityId: string;
  recordType: 'invoice' | 'bankTransfer';
  recordId: string;
  recordCreationDate: string;
  recordPaidDate?: string;
  /** Start date for which period this record is created */
  periodStartDate: string;
  /** End date for which period this record is created */
  periodEndDate?: string;
  direction: 'IN' | 'OUT';
  recordStatus: string;
  totalAmount: number;
  totalAmountWithDirection: number;
  currency: string;
  /** calculated on invoice creation and present only in first period of invoice records */
  inceptionPnl?: number;
  inceptionPnlPercentage?: number;
  unrealisedPnl: number;
  /** Only paid records can have this, calculated based on PnL from previous periods */
  finalUnrealisedPnl?: number;
  /** Only paid records can have this in last period */
  realisedPnl?: number;
  /** realisedPnl + unrealisedPnl */
  fxImpact: number;
  fxImpactPercentage: number;
  reference?: string;
  /** Only paid records can have this in last period */
  fxCost?: number;
  /** Only paid records can have this in last period */
  fxCostPercentage?: number;
  recordRate: number;
  payRate?: number;
  paidDateSystemRate?: number;
  previousPeriodSystemRate?: number;
  creationDateSystemRate?: number;
  creationDateRecordRateAmount?: number;
  creationDateSystemRateAmount?: number;
  creationDateOrPreviousPeriodAmount: number;
  endOfCurrentPeriodAmount: number;
  amountPaidWithSystemRate?: number;
  amountPaidWithCreationDateSystemRate?: number;
  amountPaidWithPayRate?: number;
  endOfPeriodSystemRate: number;
}

export const isPastPerformanceItemInvoice = (item: IPastPerformanceItem) =>
  item.recordType === 'invoice';

export const isPastPerformanceItemTransfer = (item: IPastPerformanceItem) =>
  item.recordType === 'bankTransfer';

export type TCustomCashflowAggregation = {
  sum: number;
  details: {
    amount: number;
    externalRef: string;
    id: string;
  }[];
};

export type TCashflowAtRiskNew = {
  startingBalance: number;
  payables: number;
  receivables: number;
  salesOrders: number;
  purchaseOrders: number;
  receivableInvoices: number;
  payableInvoices: number;
  hedges: number;
  externalHedges: number;
  internalHedges: number;
  hedgesAllocated: number;
  hedgeRate: number | null;
  hedgeRatio: number;
  hedgeRatioMin: number;
  hedgeRatioMax: number;
  budgetRate: number | null;
  closingBalance: number;
  marketRate: number;
  /** YYYY-MM */
  date: string;
  minEffectiveRate: number | null;
  maxEffectiveRate: number | null;
  customValues: Record<string, TCustomCashflowAggregation>;
};

export interface IPastPerformanceDataFromExternalSource {
  id: string;
  pnl: number;
}

interface ICashByAccountOrEntity {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface ICashByCurrency extends ICashByAccountOrEntity {
  valueInBalanceCurrency: number;
}

export interface ICashNeedsBffResponse {
  entityId: string;
  entityName: string;
  currencyCode: string;
  shortTermNeeds: number;
  balancesSum: number;
  cashByAccount: ICashByAccountOrEntity[];
  cashByEntity?: ICashByAccountOrEntity[];
  cashByCurrency: ICashByCurrency[];
  /** Similar to all balances, excluding balances based on entitySettings.excludedAccountIds */
  allBalancesToUse: (IBalance | TExternalBalance)[];
  /** All balances excluding our own balances from external balances (HedgeFlows GBP etc.) */
  allBalances: (IBalance | TExternalBalance)[];
}

export interface INetworthValues {
  netExposure: number;
  netExposureInSellCurrency: number;
  payables: number;
  receivables: number;
  receivableInvoices: number;
  payableInvoices: number;
  prebooked: number;
  externalHedges: number;
  saleOrders: number;
  purchaseOrders: number;
  balance: number;
  externalBalance: number;
  riskRating: number;
  customValues: Record<
    string,
    {
      sum: number;
      details: {
        amount: number;
        externalRef: string;
        id: string;
      }[];
    }
  >;
}
