import { FC } from 'react';
import { InlineLoader } from 'components';
import {
  Paragraph,
  ParagraphWithEllipsis,
} from 'components/shared/Typography/Typography';
import { IInvoice } from 'types';
import { useGetInvoiceAttachment } from './useGetInvoiceAttachments';
import { StyledButton } from 'components/shared/Button/Button.styles';

interface Props {
  invoice: IInvoice;
}

/**
 * This component handles attachments for either:
 *
 * 1. Entities integrated using the new integration engine.
 * 2. Entities integrated using the old xero integration system.
 */
const Attachments: FC<Props> = ({ invoice }) => {
  const {
    onFileClickHandlerNew,
    isLoadingAttachment,
  } = useGetInvoiceAttachment();

  const onClickHandler = ({
    invoiceId,
    fileName,
    attachmentId,
  }: {
    invoiceId: string;
    fileName: string;
    attachmentId: string;
  }) => {
    onFileClickHandlerNew({
      invoiceId,
      attachmentId,
      fileName,
    });
  };

  return (
    <>
      <Paragraph variant="bold">Attachments</Paragraph>

      {invoice.attachments?.map((attachment) => (
        <StyledButton
          variant="link"
          key={attachment.fileName}
          onClick={() =>
            onClickHandler({
              invoiceId: invoice.id,
              fileName: attachment.fileName,
              attachmentId: attachment.id as string,
            })
          }
        >
          <ParagraphWithEllipsis maxWidth="250px">
            {attachment.fileName}
            {isLoadingAttachment === attachment.fileName && <InlineLoader />}
          </ParagraphWithEllipsis>
        </StyledButton>
      ))}
    </>
  );
};

export default Attachments;
