import { FC, useEffect, useState } from 'react';
import ChartBalancesByAccount from './components/ChartBalancesByAccount/ChartBalancesByAccount';
import { getDailyBalanceBetweenDates } from 'services/balances';
import { CashNeedsCard } from '../CashNeedsCard/CashNeedsCard.styles';
import { useTheme } from 'styled-components';
import { Title } from 'components/shared/Typography/Typography';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';
import { DB_DATE_FORMAT, GRAPH_COLORS } from 'variables';
import { errorHandler } from 'utils/errors';
import { useStoreState } from 'state';
import Loader from 'components/shared/Loader/Loader';

const BalancesByAccount: FC = () => {
  const { entityId } = useStoreState((state) => state.UserState);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<{
    labels: Date[];
    data: {
      label: string;
      data: number[];
      backgroundColor: string[];
    }[];
  }>({
    labels: [],
    data: [],
  });
  useEffect(() => {
    if (entityId) {
      getDailyBalanceBetweenDates({
        dateFrom: dayjs().subtract(30, 'days').format(DB_DATE_FORMAT),
        dateTo: dayjs().format(DB_DATE_FORMAT),
      })
        .then((resp) => {
          const { data } = resp;

          if (!data.data) {
            return;
          }

          const endOfDayBalances = data.data
            .map((dailyBalance) => dailyBalance.endOfDayBalances)
            .flat();
          const groupedBySourceSystemIdEodBalances = groupBy(
            endOfDayBalances,
            'sourceSystemId'
          );
          const datasets = Object.values(
            groupedBySourceSystemIdEodBalances
          ).map<{
            label: string;
            data: number[];
            backgroundColor: string[];
            totalAmount?: number;
          }>((balances) => {
            return {
              label: balances[0].name,
              data: balances.map(
                (balance) => balance.translatedToEntityCurrency?.amount ?? 0
              ),
              backgroundColor: [], // This will be set later
            };
          });

          // Assign colors to datasets based on their sorted order
          datasets.forEach((dataset) => {
            dataset.backgroundColor = dataset.data.map((value, index) => {
              const valuesForIndexFromAllDatasets = datasets
                .map((dataset) => dataset.data[index])
                .sort((a, b) => b - a);
              const valueIndex = valuesForIndexFromAllDatasets.indexOf(value);

              return GRAPH_COLORS[valueIndex];
            });
          });

          const labels = data.data.map((dailyBalance) =>
            dayjs(dailyBalance.date, DB_DATE_FORMAT).toDate()
          );

          setData({
            labels,
            data: datasets,
          });
          setIsLoading(false);
        })
        .catch(errorHandler);
    }
  }, [entityId]);

  return (
    <CashNeedsCard flex={1} alignSelf="stretch" padding={theme.spacing.m}>
      <Title variant="h5">Balances by account</Title>
      {isLoading && <Loader />}
      {!isLoading && (
        <ChartBalancesByAccount data={data.data} labels={data.labels} />
      )}
    </CashNeedsCard>
  );
};

export default BalancesByAccount;
