import { FC, useEffect, useState } from 'react';
import {
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
  getAuth,
  User,
} from 'firebase/auth';
import { Controller, useForm } from 'react-hook-form7';
import { StyledForm } from 'components/shared/Form/Form.styles';
import Field from 'components/shared/Field/Field.styles';
import InputBase from 'components/shared/InputBase/InputBase';
import { Col } from 'components/shared/Col/Col';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import { Notify } from 'utils';
import Button from 'components/shared/Button/Button';
import { useTheme } from 'styled-components';
import QRCode from 'react-qr-code';
import { Loader } from 'components';
import authOld from 'services/auth';
import { useHistory } from 'react-router';
import { useStoreActions } from 'state';
import { errorHandler } from 'utils/errors';

const TotpSetup: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { signOut } = useStoreActions(({ UserState }) => UserState);
  const [isLoading, setIsLoading] = useState(true);
  const [totpSecret, setTotpSecret] = useState<TotpSecret | null>(null);
  const [requiresLogin, setRequiresLogin] = useState(false);
  const { control, handleSubmit } = useForm<{
    verificationCode: string;
  }>({
    defaultValues: {
      verificationCode: '',
    },
  });

  useEffect(() => {
    const isTotpEnrolled = authOld.currentUser?.multiFactor.enrolledFactors.find(
      (item) => item.factorId === TotpMultiFactorGenerator.FACTOR_ID
    );

    if (isTotpEnrolled) {
      Notify.info('2nd factor code via an authenticator app is already setup');
      history.goBack();
    } else {
      const getTotpSecret = async (user: User) => {
        const multiFactorSession = await multiFactor(user).getSession();
        const totpSecret = await TotpMultiFactorGenerator.generateSecret(
          multiFactorSession
        );

        return totpSecret;
      };

      const auth = getAuth();

      if (auth.currentUser) {
        getTotpSecret(auth.currentUser)
          .then(setTotpSecret)
          .catch((error) => {
            if (error.code === 'auth/requires-recent-login') {
              setRequiresLogin(true);
            }
            errorHandler(error);
          })
          .finally(() => setIsLoading(false));
      }
    }
  }, [history]);

  const onEnrollTotp = async (values: { verificationCode: string }) => {
    if (!totpSecret) {
      return;
    }

    const auth = getAuth();

    if (!auth.currentUser) {
      return;
    }

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
      totpSecret,
      values.verificationCode
    );
    await multiFactor(auth.currentUser).enroll(
      multiFactorAssertion,
      'One Time Password'
    );

    Notify.success('Time based one time password setup successfully');

    history.goBack();
  };

  return (
    <Col
      justifyContent="center"
      alignItems="center"
      flex={1}
      style={{
        backgroundColor: 'white',
      }}
    >
      {isLoading && <Loader size="large" />}

      {!isLoading && requiresLogin && (
        <>
          <Title variant="h4" mb>
            In order to enable 2nd factor code via an authenticator app, you
            need to log in again.
          </Title>

          <Paragraph>
            You need to log in again and repeat the action. If you are still
            facing issues, please contact support.
          </Paragraph>

          <Button mt onClick={() => signOut()}>
            Go to log in
          </Button>
        </>
      )}

      {!isLoading && totpSecret && (
        <>
          <Title variant="h4" mb>
            Scan this QR code with your authenticator app:
          </Title>

          {totpSecret && (
            <QRCode
              value={totpSecret.generateQrCodeUrl(undefined, 'HedgeFlows')}
            />
          )}

          <Title variant="h5" mt>
            Or enter this secret key manually:
          </Title>

          <Paragraph
            mt
            variant="bold"
            style={{
              backgroundColor: theme.color.greyLight_1,
              padding: theme.spacing.xl,
              borderRadius: theme.borderRadius.m,
            }}
          >
            {totpSecret?.secretKey}
          </Paragraph>

          <Title variant="h5" mt>
            Once you are done, enter verification code from your authenticator
            app and click submit:
          </Title>

          <StyledForm
            onSubmit={handleSubmit(onEnrollTotp)}
            style={{
              alignItems: 'center',
            }}
          >
            <Field mb mt>
              <Controller
                name="verificationCode"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <InputBase
                    type="number"
                    value={value}
                    onChange={onChange}
                    placeholder="Verification code"
                  />
                )}
              />
            </Field>

            <Button type="submit">Submit</Button>
          </StyledForm>
        </>
      )}
    </Col>
  );
};

export default TotpSetup;
