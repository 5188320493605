import styled, { css } from 'styled-components';

export const RadioButtonWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.color.greyLight_4};
    transition: ${theme.transition};
    box-shadow: inset 0 0 40px 15px ${theme.color.transparent};
    border: 1px solid ${theme.color.greyLight_2};
    overflow: hidden;
    flex: 1;
    border-radius: ${theme.borderRadius.m};

    &:hover {
      background: ${theme.color.white};
      box-shadow: inset 0 0 5px 1px ${theme.color.greyLight_2};
    }
  `
);

export const RadioButtonInput = styled.input(
  () => css`
    position: absolute;
    opacity: 0;
  `
);

export const RadioButtonLabel = styled.label<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    position: relative;
    padding-left: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 12px 16px 12px 40px;

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      left: 16px;
      top: 14px;
      border-radius: ${theme.borderRadius.round};
      background: ${!disabled
        ? theme.color.emeraldDark
        : theme.color.greyLight_2};
      border: 2px solid
        ${!disabled ? theme.color.emeraldDark : theme.color.greyLight_2};
      box-shadow: inset 0 0 0 7px ${theme.color.white};
      transition: box-shadow 0.2s linear;

      ${RadioButtonInput}:checked + & {
        box-shadow: inset 0 0 0 2px ${theme.color.white};
      }

      ${RadioButtonInput}:focus-visible + & {
        box-shadow: inset 0 0 0 7px ${theme.color.white},
          0 0 16px 1px ${theme.color.emeraldDark};
      }

      ${RadioButtonInput}:focus-visible:checked + & {
        box-shadow: inset 0 0 0 2px ${theme.color.white},
          0 0 16px 1px ${theme.color.emeraldDark};
      }
    }

    ${RadioButtonInput}:focus-visible + & {
      background: ${theme.color.white};
    }

    ${RadioButtonInput}:focus-visible:checked + & {
      background: ${theme.color.white};
    }
  `
);
