import useNonXeroAccounts from 'hooks/useNonXeroAccounts';
import useXeroAccounts from 'hooks/useXeroAccounts';
import {
  TPlatformNames,
  isXeroPlatform,
  isNonXeroPlatform,
} from 'types/integrations';

/**
 * Fetches accounts from either Xero or non Xero.
 */
const useIntegrationAccounts = (platformName: TPlatformNames) => {
  const fetchXeroAccounts = isXeroPlatform(platformName);
  const fetchNonXeroAccounts = isNonXeroPlatform(platformName);

  const {
    allAccounts,
    isLoadingAccounts: isLoadingNonXeroAccounts,
    hasEntityCurrencyAccount: hasEntityCurrencyAccountNonXero,
    nonXeroAccounts,
    bankFeesAccounts: nonXeroBankFeesAccounts,
    defaultBankFeesAccount: defaultNonXeroBankFeesAccount,
    journalAccounts,
    bankTransferAccounts: nonXeroBankTransferAccounts,
  } = useNonXeroAccounts(fetchNonXeroAccounts);

  const {
    isLoadingAccounts: isLoadingXeroAccounts,
    hasEntityCurrencyAccount: hasEntityCurrencyAccountXero,
    xeroAccounts,
    bankFeesAccounts: xeroBankFeesAccounts,
    defaultBankFeesAccount404: defaultXeroBankFeesAccount404,
    bankTransferAccounts: xeroBankTransferAccounts,
  } = useXeroAccounts(fetchXeroAccounts);

  return fetchXeroAccounts
    ? {
        isLoadingAccounts: isLoadingXeroAccounts,
        hasEntityCurrencyAccount: hasEntityCurrencyAccountXero,
        accounts: xeroAccounts,
        bankFeesAccounts: xeroBankFeesAccounts,
        defaultBankFeesAccount: defaultXeroBankFeesAccount404,
        bankTransferAccounts: xeroBankTransferAccounts,
      }
    : {
        allAccounts,
        isLoadingAccounts: isLoadingNonXeroAccounts,
        hasEntityCurrencyAccount: hasEntityCurrencyAccountNonXero,
        accounts: nonXeroAccounts,
        bankFeesAccounts: nonXeroBankFeesAccounts,
        defaultBankFeesAccount: defaultNonXeroBankFeesAccount,
        journalAccounts,
        bankTransferAccounts: nonXeroBankTransferAccounts,
      };
};

export default useIntegrationAccounts;
