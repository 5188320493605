import { Icon, InlineLoader, Paragraph, Row } from 'components';
import { FC, ReactNode } from 'react';
import { parseIntoShortNumberWithSymbol } from 'utils';
import {
  NetworthInfoRowWrapper,
  NetworthInfoRowWrapperProps,
} from './NetworthInfoRow.styles';

interface OwnProps extends NetworthInfoRowWrapperProps {
  title: string;
  amount?: number;
  currencySymbol?: string;
  expandable?: boolean;
  onArrowClick?: () => void;
  rotateArrow?: boolean;
  icon?: ReactNode;
}

const NetworthInfoRow: FC<OwnProps> = ({
  title,
  amount,
  currencySymbol,
  variant = 'white',
  onArrowClick,
  rotateArrow,
  icon,
}) => {
  return (
    <NetworthInfoRowWrapper variant={variant}>
      <Row flex={1}>
        <Paragraph>{title}</Paragraph>
      </Row>

      <Row flex={1.2}>
        {amount === undefined ? (
          <InlineLoader />
        ) : (
          <>
            {icon}
            <Paragraph ml mlValue="auto">
              {parseIntoShortNumberWithSymbol(amount, currencySymbol)}
            </Paragraph>
            {onArrowClick && (
              <Icon
                ml
                icon="arrow-down"
                onClick={onArrowClick}
                style={{
                  transform: rotateArrow ? 'rotate(180deg)' : '',
                  cursor: 'pointer',
                }}
              />
            )}
          </>
        )}
      </Row>
    </NetworthInfoRowWrapper>
  );
};

export default NetworthInfoRow;
