import { FC, useState } from 'react';
import {
  TextHint,
  StaleInputRadio,
  Paragraph,
  Row,
  Popup,
  Title,
  Col,
} from 'components';
import Button from 'components/shared/Button/Button';
import { useStoreActions, useStoreState } from 'state';
import { IInvoiceFromSearch, IRateContract } from 'types';
import {
  isRateContractCanBeUsedNow,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import dayjs from 'dayjs';
import _orderBy from 'lodash.orderby';
import { DATE_FORMAT } from 'variables';
import { errorHandler } from 'utils/errors';
import { sum } from 'lodash';

interface IOwnProps {
  invoices: IInvoiceFromSearch[];
  sellCurrency: string;
  onClose: () => void;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
}

const AllocateFxForInvoices: FC<IOwnProps> = ({
  invoices,
  sellCurrency,
  onClose,
  updateInMemoryInvoices,
}) => {
  const { rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { bindContractRateToInvoice } = useStoreActions(
    (actions) => actions.InvoicesState
  );

  const invoicesAmountDueSum = sum(invoices.map((item) => item.amountDue));
  // we only allow to allocate FX for same currency invoices, so it is safe to take currency from the first invoice
  const invoicesCurrency = invoices[0].currency;
  const rateContractsToUse = _orderBy(
    rateContractsByCurrencyPair(sellCurrency, invoicesCurrency).filter(
      (item) =>
        item.remainingBuyAmount >= invoicesAmountDueSum &&
        isRateContractCanBeUsedNow(item)
    ),
    'rate',
    'desc'
  );
  const [rateContract, setRateContract] = useState<IRateContract | null>(
    rateContractsToUse[0] || null
  );
  const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onBindContractRateToInvoice = async () => {
    if (!rateContract) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await bindContractRateToInvoice({
        contractId: rateContract.id,
        payload: {
          invoiceIds: invoices.map((invoice) => invoice.id),
        },
      });

      if (!response) {
        return;
      }

      const { data } = response;

      if (data.success) {
        updateInMemoryInvoices((invoices) =>
          invoices.map((invoice) => {
            const updatedInvoice = data.data?.find(
              (item) => item.id === invoice.id
            );
            if (updatedInvoice) {
              return {
                ...invoice,
                contractId: updatedInvoice.contractId,
                contractAssignment: true,
              };
            }
            return invoice;
          })
        );
      } else {
        errorHandler(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const invoiceWithDueDateAfterRateContractExpiry = invoices.find((invoice) =>
    dayjs(invoice.dueDate).isAfter(dayjs(rateContract?.expiryDate))
  );
  const contractExpiryDate = dayjs(rateContract?.expiryDate).format(
    DATE_FORMAT
  );

  return (
    <>
      <Popup
        HeaderContent={<Title variant="h4">Allocate FX</Title>}
        FooterContent={
          <Row flex={1}>
            <Button variant="secondary" onClick={onClose} disabled={isLoading}>
              Not this time
            </Button>

            <Button
              disabled={!rateContract || isLoading}
              isLoading={isLoading}
              onClick={() =>
                !!invoiceWithDueDateAfterRateContractExpiry
                  ? setIsOpenConfirmationPopup(true)
                  : onBindContractRateToInvoice()
              }
            >
              Allocate FX
            </Button>
          </Row>
        }
        onClose={onClose}
        width="439px"
      >
        <Paragraph>
          {!invoiceWithDueDateAfterRateContractExpiry &&
            `You have previously prebooked the rate for this currency pair.
              Would you like to use one of them for this ${
                invoices.length > 1 ? 'invoices' : 'invoice'
              } amount of ${parseIntoCurrencyString(
              invoicesAmountDueSum
            )} ${invoicesCurrency}?`}

          {!!invoiceWithDueDateAfterRateContractExpiry &&
            `Contract expires on ${contractExpiryDate}, which is before the invoice due date on ${dayjs(
              invoiceWithDueDateAfterRateContractExpiry?.dueDate
            ).format(DATE_FORMAT)}`}
        </Paragraph>

        <Col flex={1} overflow="auto">
          {rateContractsToUse.map((item) => (
            <StaleInputRadio
              id={item.id}
              key={item.id}
              label={
                <Col>
                  <Paragraph>{`1 ${item.buyCurrency} = ${parseRateWithPrecision(
                    item.rate
                  )} ${item.sellCurrency}`}</Paragraph>
                  <Paragraph>{`${parseIntoCurrencyString(
                    item.remainingBuyAmount
                  )} ${item.buyCurrency}`}</Paragraph>
                  <TextHint>{`pay by ${dayjs(item.expiryDate).format(
                    'DD MMM YY'
                  )}`}</TextHint>
                </Col>
              }
              cardTransparent
              checked={item.id === rateContract?.id}
              onChange={() => {
                setRateContract(item);
              }}
            />
          ))}
        </Col>
      </Popup>

      {isOpenConfirmationPopup && (
        <Popup
          HeaderContent={<Title variant="h4">Allocate FX</Title>}
          FooterContent={
            <Row flex={1}>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsOpenConfirmationPopup(false);
                  onClose();
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>

              <Button
                disabled={!rateContract || isLoading}
                isLoading={isLoading}
                onClick={onBindContractRateToInvoice}
              >
                Confirm
              </Button>
            </Row>
          }
          width="439px"
          onClose={onClose}
        >
          <Paragraph>
            Do you still want to allocate prebooked FX and use it before the
            expiry date on {contractExpiryDate}?
          </Paragraph>
        </Popup>
      )}
    </>
  );
};

export default AllocateFxForInvoices;
