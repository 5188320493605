import { FC } from 'react';

import { ProgressWrapper, ProgressValue } from './ProgressBar.styles';

interface OwnProps {
  fullWidth?: boolean;
  progress: number;
  color?: string;
}

const ProgressBar: FC<OwnProps> = ({ progress, color, fullWidth }) => {
  return (
    <ProgressWrapper fullWidth={fullWidth} color={color}>
      <ProgressValue progress={progress} />
    </ProgressWrapper>
  );
};

export default ProgressBar;
