import { useTheme } from 'styled-components';
import { Col } from '../../../../../Col/Col';
import { Title } from '../../../../../Typography/Typography';
import { ITableFilter, ITableFilterOption } from '../../../../types';
import { FC, Fragment } from 'react';
import FilterOptionCheckbox from './components/FilterOptionChecbox/FilterOptionCheckbox';
import FilterOptionRadio from './components/FilterOptionRadio/FilterOptionRadio';
import FilterOptionDate from './components/FilterOptionDate/FilterOptionDate';
import FilterOptionNumber from './components/FilterOptionNumber/FilterOptionNumber';

interface OwnProps {
  filter: ITableFilter;
  setFilter: (filterId: string, filterValue: ITableFilterOption) => void;
}

const Filter: FC<OwnProps> = ({ filter, setFilter }) => {
  const {
    id: filterId,
    title: filterTypeTitle,
    options,
    value: filterValue,
  } = filter;
  const theme = useTheme();

  return (
    <Fragment>
      <Title variant="h5" mb>
        {filterTypeTitle}
      </Title>
      <Col
        mb
        flexWrap="wrap"
        justifyContent="flex-start"
        rowGap={theme.spacing.xs}
      >
        {options.map((option) => {
          const { id: filterOptionId, type } = option;
          const isActive = !!filterValue.find(
            (val) => val.id === filterOptionId
          );
          const filterOptionValue =
            filterValue.find((val) => val.id === filterOptionId)?.value ?? '';

          if (type === 'checkbox') {
            return (
              <FilterOptionCheckbox
                key={filterOptionId}
                filterId={filterId}
                setFilter={setFilter}
                filterOption={option}
                isActive={isActive}
              />
            );
          }

          if (type === 'radio') {
            return (
              <FilterOptionRadio
                key={filterOptionId}
                filterId={filterId}
                setFilter={setFilter}
                filterOption={option}
                isActive={isActive}
              />
            );
          }

          if (type === 'date') {
            return (
              <FilterOptionDate
                key={filterOptionId}
                filterId={filterId}
                setFilter={setFilter}
                filterOption={option}
                filterOptionValue={filterOptionValue}
              />
            );
          }

          if (type === 'number') {
            return (
              <FilterOptionNumber
                key={filterOptionId}
                filterId={filterId}
                setFilter={setFilter}
                filterOption={option}
              />
            );
          }

          return null;
        })}
      </Col>
    </Fragment>
  );
};

export default Filter;
