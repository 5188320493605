import useUrlValues from 'hooks/useUrlValues';
import { FC, useMemo } from 'react';
import TableHorizontal from 'components/shared/TableHorizontal/TableHorizontal';
import { TCashflowAtRiskNew } from 'types';
import { generateTableColumns } from './tableColumnsGenerator';
import { getTdCellTheme, getThCellTheme } from '../../utils';
import { useStoreActions, useStoreState } from 'state';
import {
  HEDGES_COLUMNS_TO_HIDE,
  INFLOWS_COLUMNS_TO_HIDE,
  OUTFLOWS_COLUMNS_TO_HIDE,
} from './consts';
import { TRisksDataPeriod } from 'pages/Risks/types';

interface IOwnProps {
  cashflowsRisksData: TCashflowAtRiskNew[];
  period: TRisksDataPeriod;
}

const CashflowsTable: FC<IOwnProps> = ({ cashflowsRisksData, period }) => {
  const { currency } = useUrlValues('currency');
  const { userEntity } = useStoreState(({ UserState }) => UserState);
  const { customCashflowDefinitions = [] } = userEntity;
  const { setShowSpinnerInRecalculateButton } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );

  const columns = useMemo(
    () =>
      currency
        ? generateTableColumns({
            currencyCode: currency,
            customCashflowDefinitions,
            setShowSpinnerInRecalculateButton,
            entityCurrency: userEntity?.entityCurrency,
            hasCashflowsCsvId: !!userEntity?.cashflowsFileShareUrl,
            period,
          })
        : [],
    [
      currency,
      customCashflowDefinitions,
      period,
      setShowSpinnerInRecalculateButton,
      userEntity?.cashflowsFileShareUrl,
      userEntity?.entityCurrency,
    ]
  );
  const hiddenColumns = useMemo(
    () => [
      ...INFLOWS_COLUMNS_TO_HIDE,
      ...OUTFLOWS_COLUMNS_TO_HIDE,
      ...HEDGES_COLUMNS_TO_HIDE,
      ...customCashflowDefinitions.map(({ id }) => id),
    ],
    [customCashflowDefinitions]
  );

  return (
    <TableHorizontal<TCashflowAtRiskNew>
      getTdCellTheme={getTdCellTheme}
      getThCellTheme={getThCellTheme}
      columns={columns}
      initialState={{
        hiddenColumns,
      }}
      data={cashflowsRisksData}
    />
  );
};

export default CashflowsTable;
