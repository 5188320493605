import { FC, InputHTMLAttributes, ReactNode } from 'react';
import {
  RadioButtonInput,
  RadioButtonLabel,
  RadioButtonWrapper,
} from './RadioButton.styles';

export interface IRadioButtonProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'disabled' | 'name'
  > {
  id: string;
  value: string | number;
  autoFocus?: boolean;
  checked?: boolean;
  displayName: ReactNode;
}

const RadioButton: FC<IRadioButtonProps> = ({
  id,
  value,
  checked,
  autoFocus,
  name = '',
  displayName,
  onChange,
  disabled = false,
}) => (
  <RadioButtonWrapper>
    <RadioButtonInput
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      autoFocus={autoFocus}
      disabled={disabled}
    />
    <RadioButtonLabel htmlFor={id} disabled={disabled}>
      {displayName}
    </RadioButtonLabel>
  </RadioButtonWrapper>
);

export default RadioButton;
