import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Controller, useForm } from 'react-hook-form7';
import Integrations from 'pages/Settings/components/Integrations/Integrations';
import { Paragraph, Subtitle } from '../Typography/Typography';
import { WhiteContentContainer } from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import { EntityInputField } from 'pages/Settings/components/NotificationSettings/NotificationSettings.styles';
import { useStoreState } from 'state';
import {
  refreshCashflowFromCsv,
  uploadFileWithCustomCashflows,
} from 'services/cashflows';
import Button from '../Button/Button';
import { errorHandler } from 'utils/errors';
import InputBase from '../InputBase/InputBase';
import { Row } from '../Row/Row';
import { StyledForm } from '../Form/Form.styles';
import { Col } from '../Col/Col';
import RadioButtons from '../RadioButtons/RadioButtons';
import StaleInputFile from '../StaleInputFile/StaleInputFile';
import Field from '../Field/Field.styles';
import { IIntegrationSettingsFormValues } from './types';
import { Notify, getDashboardPageLink } from 'utils';
import { updateEntitySettings } from 'services/firebase';
import { extractFileNameFromGcsPublicUrl } from './utils';
import { useHistory } from 'react-router';

const IntegrationSettings: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowBackToDashboard, setIsShowBackToDashboard] = useState(false);
  const [isLoadingCsvRefresh, setIsLoadingCsvRefresh] = useState(false);
  const { userEntity } = useStoreState((state) => state.UserState);

  const {
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm<IIntegrationSettingsFormValues>({
    mode: 'all',
    defaultValues: {
      cashflowsFileShareSystem: 'google',
      cashflowsFileShareUrl: undefined,
      cashflowsFiles: [],
    },
  });
  const cashflowsFileShareSystem = watch('cashflowsFileShareSystem');
  const existingCashflowsFileName = userEntity.cashflowsFileShareUrl
    ? extractFileNameFromGcsPublicUrl(userEntity.cashflowsFileShareUrl)
    : '';

  useEffect(() => {
    if (userEntity) {
      reset({
        cashflowsFileShareSystem:
          userEntity.cashflowsFileShareSystem ?? 'google',
        cashflowsFileShareUrl: userEntity.cashflowsFileShareUrl,
      });
    }
  }, [userEntity, reset]);

  const onSubmit = async (values: IIntegrationSettingsFormValues) => {
    try {
      setIsLoading(true);
      const {
        cashflowsFiles,
        cashflowsFileShareUrl,
        cashflowsFileShareSystem,
      } = values;

      /** It is important to update settings first and upload files after,
       * because file upload updates settings at the end and if we do it first,
       * then update settings call may override file upload call */
      const response = await updateEntitySettings({
        cashflowsFileShareSystem,
        cashflowsFileShareUrl,
      });

      if (cashflowsFiles?.length) {
        const formData = new FormData();
        formData.append('file', cashflowsFiles[0].file);
        await uploadFileWithCustomCashflows(formData);
      }

      if (response?.data?.success) {
        Notify.success('Saved successfully');
        setIsShowBackToDashboard(true);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshFromCsv = async () => {
    try {
      setIsLoadingCsvRefresh(true);
      await refreshCashflowFromCsv();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingCsvRefresh(false);
    }
  };

  return (
    <WhiteContentContainer>
      <Subtitle variant="bold">Accounting system integrations</Subtitle>

      <Row flexWrap="wrap" mt>
        <Integrations variant="accounting" />
      </Row>

      <StyledForm id="cashflowsCsv-form" onSubmit={handleSubmit(onSubmit)}>
        <Subtitle mb variant="bold">
          Spreadsheet integrations
        </Subtitle>

        <Row alignSelf="stretch" alignItems="flex-end">
          <Col flex={1}>
            <EntityInputField large>
              <Paragraph mb mbValue={theme.spacing.xs}>
                Cashflows file share system
              </Paragraph>
              <Controller
                name="cashflowsFileShareSystem"
                control={control}
                render={({ field: { value, name, onChange } }) => (
                  <RadioButtons
                    flex={1}
                    data={[
                      {
                        id: 'google',
                        value: 'google',
                        checked: value === 'google',
                        name,
                        displayName: <p>Google</p>,
                        onChange,
                      },
                      {
                        id: 'microsoftFileUpload',
                        value: 'microsoftFileUpload',
                        checked: value === 'microsoftFileUpload',
                        name,
                        displayName: <p>Microsoft</p>,
                        onChange,
                      },
                    ]}
                  />
                )}
              />
            </EntityInputField>
            {cashflowsFileShareSystem === 'google' && (
              <EntityInputField large>
                <Paragraph mb mbValue={theme.spacing.xs}>
                  Cashflows file share URL
                </Paragraph>

                <Controller
                  name="cashflowsFileShareUrl"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <InputBase id={name} value={value} onChange={onChange} />
                    );
                  }}
                />
              </EntityInputField>
            )}

            {cashflowsFileShareSystem === 'microsoftFileUpload' && (
              <Field flexDirection="column">
                <Paragraph mb mbValue={theme.spacing.xs}>
                  Cashflows file upload
                </Paragraph>
                <Paragraph mb>
                  {existingCashflowsFileName
                    ? `Current file: ${existingCashflowsFileName}`
                    : 'No file uploaded'}
                </Paragraph>

                <Controller
                  control={control}
                  name="cashflowsFiles"
                  render={({ field: { value, onChange } }) => (
                    <StaleInputFile
                      accept=".xlsx"
                      files={value}
                      onChange={(e) => {
                        onChange(e);
                        setIsShowBackToDashboard(false);
                      }}
                      onRemoveFile={() => {
                        onChange([]);
                        setIsShowBackToDashboard(false);
                      }}
                      multiple={false}
                      trigger={
                        <Button
                          variant="primary"
                          disabled={isLoading}
                          isLoading={isLoading}
                        >
                          Select a file to upload
                        </Button>
                      }
                      id="file"
                    />
                  )}
                />
              </Field>
            )}
          </Col>

          <Row gap={theme.spacing.m}>
            {!!userEntity.cashflowsFileShareUrl &&
              userEntity.cashflowsFileShareSystem === 'google' && (
                <Button
                  type="button"
                  variant="link"
                  onClick={refreshFromCsv}
                  disabled={isLoadingCsvRefresh}
                  isLoading={isLoadingCsvRefresh}
                >
                  Sync with CSV
                </Button>
              )}

            {isShowBackToDashboard ? (
              <Button
                variant="secondary"
                onClick={() => history.push(getDashboardPageLink())}
              >
                Back to dashboard
              </Button>
            ) : (
              <Button
                variant="secondary"
                form="cashflowsCsv-form"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Save
              </Button>
            )}
          </Row>
        </Row>
      </StyledForm>
    </WhiteContentContainer>
  );
};

export default IntegrationSettings;
