import styled, {
  css,
  CSSProperties,
  DefaultThemeColorKey,
} from 'styled-components';
import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';

export const TextHint = styled.p.attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
  })
)<ParagraphProps & SpacingCssProps>(
  ({ variant = 'medium', color = 'greyDark', textAlign, theme }) => css`
    text-align: ${textAlign};

    ${variant === 'medium' &&
    css`
      font-weight: 500;
    `}

    ${variant === 'bold' &&
    css`
      font-weight: 700;
    `}

    font-size: 12px;
    line-height: 18px;
    color: ${theme.color[color]};

    ${spacingCss}
  `
);

export const TextHintWithEllipsis = styled(TextHint)<{
  maxWidth?: string;
  minWidth?: string;
}>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
`;

export interface ParagraphProps
  extends Pick<CSSProperties, 'textAlign' | 'whiteSpace'> {
  variant?: 'medium' | 'bold' | 'thin';
  error?: boolean;
  color?: DefaultThemeColorKey;
}

export const Paragraph = styled.p.attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
  })
)<ParagraphProps & SpacingCssProps>(
  ({
    variant = 'medium',
    color = 'black',
    error,
    theme,
    textAlign,
    whiteSpace,
  }) => css`
    max-width: 100%;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.color[color]};
    text-align: ${textAlign};
    white-space: ${whiteSpace};

    ${variant === 'thin' &&
    css`
      font-weight: 400;
    `}

    ${variant === 'medium' &&
    css`
      font-weight: 500;
    `}

    ${variant === 'bold' &&
    css`
      font-weight: 700;
    `}

    ${error &&
    css`
      color: ${theme.color.red};
    `}

    ${spacingCss}
  `
);

export const ParagraphWithEllipsis = styled(Paragraph)<{
  maxWidth?: string;
  minWidth?: string;
}>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
`;

interface SpanProps {
  variant?: 'medium' | 'bold' | 'thin';
  color?: DefaultThemeColorKey;
}

export const Span = styled.span.attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
  })
)<SpanProps & SpacingCssProps>(
  ({ variant = 'medium', color = 'black', theme }) => css`
    font-size: 14px;
    line-height: 21px;
    color: ${theme.color[color]};

    ${variant === 'thin' &&
    css`
      font-weight: 400;
    `}

    ${variant === 'medium' &&
    css`
      font-weight: 500;
    `}

    ${variant === 'bold' &&
    css`
      font-weight: 700;
    `}

    ${spacingCss}
  `
);

interface SubtitleProps
  extends Pick<CSSProperties, 'textAlign' | 'whiteSpace'> {
  variant?: 'medium' | 'bold';
  color?: DefaultThemeColorKey;
}

export const Subtitle = styled.p.attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.xs,
    mtValue: mtValue || theme.spacing.xs,
    mrValue: mrValue || theme.spacing.xs,
    mlValue: mlValue || theme.spacing.xs,
  })
)<SubtitleProps & SpacingCssProps>(
  ({
    variant = 'medium',
    color = 'black',
    theme,
    textAlign,
    whiteSpace,
  }) => css`
    max-width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.color[color]};
    text-align: ${textAlign};
    white-space: ${whiteSpace};

    ${variant === 'medium' &&
    css`
      font-weight: 500;
    `}

    ${variant === 'bold' &&
    css`
      font-weight: 700;
    `}

    ${spacingCss}
  `
);

export interface TitleProps extends Pick<CSSProperties, 'textAlign'> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  color?: DefaultThemeColorKey;
}

export const Title = styled.p.attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.s,
    mtValue: mtValue || theme.spacing.s,
    mrValue: mrValue || theme.spacing.s,
    mlValue: mlValue || theme.spacing.s,
  })
)<TitleProps & SpacingCssProps>(
  ({ theme, variant = 'h1', color = 'black', textAlign }) => css`
    max-width: 100%;
    font-weight: 700;
    color: ${theme.color[color]};
    text-align: ${textAlign};

    ${variant === 'h1' &&
    css`
      font-size: 28px;
      line-height: 36px;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 36px;
        line-height: 42.2px;
      }
    `}

    ${variant === 'h2' &&
    css`
      font-size: 24px;
      line-height: 36px;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 28px;
        line-height: 36px;
      }
    `}

    ${variant === 'h3' &&
    css`
      font-size: 20px;
      line-height: 24px;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 24px;
        line-height: 34px;
      }
    `}

    ${variant === 'h4' &&
    css`
      font-size: 18px;
      line-height: 24px;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 20px;
        line-height: 36px;
      }
    `}

    ${variant === 'h5' &&
    css`
      font-size: 16px;
      line-height: 24px;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 18px;
        line-height: 24px;
      }
    `}


    ${spacingCss}
  `
);
