import styled from 'styled-components';
import { StyledButton } from 'components/shared/Button/Button.styles';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';

export const TwoSidesTemplateWrapper = styled(Row)`
  flex: 1;
  align-self: stretch;
`;

export const TwoSidesTemplateContentContainer = styled(Col)`
  flex: 1;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: 10%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    padding: 12% 15%;
  }
`;

export const TwoSidesTemplateLeftCol = styled(Col)`
  flex: 1;
  align-self: stretch;
  justify-content: center;
  background: ${({ theme }) => theme.color.dark};
  color: ${({ theme }) => theme.color.white};
`;

export const TwoSidesTemplateRightCol = styled(Col)`
  flex: 1;
  justify-content: center;
  align-self: stretch;
  overflow: auto;
  background: ${({ theme }) => theme.color.white};
`;

export const TwoSidesTemplateCrossButton = styled(StyledButton)`
  position: absolute;
  right: 28px;
  top: 28px;
  z-index: 10;

  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    right: 48px;
    top: 48px;
  }
`;
