import { CSSProperties } from 'react';
import { css } from 'styled-components';

export interface SpacingCssProps {
  mb?: boolean;
  mt?: boolean;
  mr?: boolean;
  ml?: boolean;
  ma?: boolean;
  mbValue?: CSSProperties['marginBottom'];
  mtValue?: CSSProperties['marginTop']
  mrValue?: CSSProperties['marginRight']
  mlValue?: CSSProperties['marginLeft']
}

export const spacingCss = css<SpacingCssProps>`
  margin-bottom: ${({ mb, ma, mbValue }) => (ma || mb) && mbValue};
  margin-top: ${({ mt, ma, mtValue }) => (ma || mt) && mtValue};
  margin-left: ${({ ml, ma, mlValue }) => (ma || ml) && mlValue};
  margin-right: ${({ mr, ma, mrValue }) => (ma || mr) && mrValue};
`;
