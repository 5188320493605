import styled, { DefaultThemeColorKey } from 'styled-components';
import { Flex } from 'components/shared/Flex/Flex.styles';

export const ProgressWrapper = styled(Flex)<{ fullWidth?: boolean }>`
  height: ${({ theme }) => theme.spacing.xxs};
  background: ${({ theme }) => theme.color.greyLight_2};
  max-width: ${({ fullWidth }) => !fullWidth && '154px'};
  margin-top: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

export const ProgressValue = styled(Flex)<{
  progress: number;
  color?: DefaultThemeColorKey;
}>`
  width: ${({ progress }) => `${progress}%`};
  background: ${({ theme, color }) => color || theme.color.emeraldDark};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;
