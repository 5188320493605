import { Row } from 'components';
import { FC, useState } from 'react';
import { IInvoiceFromSearch } from 'types';
import Button from 'components/shared/Button/Button';
import Popup from 'components/shared/Popup/Popup';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { errorHandler } from 'utils/errors';
import { deleteInvoice } from 'services/firebase/invoices';

interface OwnProps {
  onClose: () => void;
  invoice: IInvoiceFromSearch;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
}

const DeleteManualInvoice: FC<OwnProps> = ({
  onClose,
  invoice,
  updateInMemoryInvoices,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmManualInvoiceDelete = async () => {
    callExternalApiWithLoading({
      externalApiCall: () => deleteInvoice({ invoiceId: invoice.id }),
      loadingHandler: setIsLoading,
      responseHandler: (response) => {
        if (response.data.success && response.data.data) {
          updateInMemoryInvoices((invoices) =>
            invoices.filter(
              (iinMemoryInvoice) => iinMemoryInvoice.id !== invoice.id
            )
          );

          onClose();
        }
      },
      errorHandler,
    });
  };

  return (
    <Popup
      HeaderContent={<Title variant="h4">Delete invoice</Title>}
      FooterContent={
        <Row flex={1}>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>

          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onConfirmManualInvoiceDelete}
          >
            Confirm
          </Button>
        </Row>
      }
      width="439px"
      onClose={onClose}
    >
      <Paragraph>
        Do you want to delete invoice {invoice.invoiceNumber}?
      </Paragraph>
    </Popup>
  );
};

export default DeleteManualInvoice;
