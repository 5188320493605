import styled from 'styled-components';

export const StyledUnorderedList = styled.ul`
  list-style-type: disc;
  margin-left: ${({ theme }) => theme.spacing.xl};
`;

export const ListItem = styled.li`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.s};
  line-height: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.color.black};
  margin-bottom: ${({ theme }) => theme.spacing.s};

  &:last-of-type {
    margin: 0;
  }
`;
