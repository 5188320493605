export type TEntityPackageKeys = 'global' | 'automation' | 'fxManagement';

const GLOBAL_LIST_OF_ATTRIBUTES = ['approvers', 'submitters'] as const;
export type TEntityUserAttributes = typeof GLOBAL_LIST_OF_ATTRIBUTES[number];

// Permit has multiple interfaces...
// TODO Normalise
export interface IPermitRoleV2 {
  id: string;
  key: string;
  name: string;
  description: string | null;
  permissions: string[];
  environmentId: string;
  extends: string[];
  organization_id: string;
  project_id: string;
  environment_id: string;
  created_at: string;
  updated_at: string;
  packageSpecific?: boolean;
  role?: TPermissionRoles;
}

type TPermissionRoles =
  | 'admin'
  | 'approver'
  | 'fx_user'
  | 'member'
  | 'payment_user'
  | 'risk_manager'
  | 'submitter';

export type TPermissionResources =
  | 'balances'
  | 'cashflows_manual'
  | 'cashflows'
  | 'entities'
  | 'integrations'
  | 'payment_runs'
  | 'prebooks'
  | 'recipients'
  | 'settings_risk'
  | 'settings'
  | 'transfers'
  | 'subscriptions';

export type TPermissionActions =
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'approve'
  | 'submit'
  | 'allocate'
  | 'invoice_actions';
