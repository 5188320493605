import axios from 'axios';
import auth from 'services/auth';

export const AxiosPublicFirebaseInstance = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL,
});

export const AxiosPublicWest3FirebaseInstance = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL?.replace(
    'west2',
    'west3'
  ),
});

export const AxiosPrivateFirebaseInstance = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL,
});

export const AxiosIntegrationEnginePrivateInstance = axios.create({
  baseURL: process.env.REACT_APP_INTEGRATION_ENGINE_BASE_URL,
});

AxiosPrivateFirebaseInstance.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      return config;
    }

    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

AxiosIntegrationEnginePrivateInstance.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      return config;
    }

    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// TODO: remove this hack when we add reverse proxy
export const AxiosWest3Instance = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL?.replace(
    'west2',
    'west3'
  ),
});

AxiosWest3Instance.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      return config;
    }

    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
