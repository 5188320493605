import { FC } from 'react';

import { Icon, Row, TextHint } from 'components';
import { ICurrency } from 'types';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import Rate from '../Rate/Rate';
import { useTheme } from 'styled-components';

interface OwnProps {
  buyCurrencyCode: ICurrency['code'];
  sellCurrencyCode: ICurrency['code'];
  rate: UseCurrencyRateReturnValues['rate'];
}

const StaleCurrencyRate: FC<OwnProps> = ({
  buyCurrencyCode,
  sellCurrencyCode,
  rate,
}) => {
  const theme = useTheme();

  return (
    <Row gap={theme.spacing.xs} justifyContent="flex-start" alignItems="center">
      <Icon
        width="24"
        height="24"
        fill={theme.color.greyDark}
        icon="convert-ico"
      />
      <TextHint>
        {`1 ${sellCurrencyCode} = `}
        <Rate rate={rate} currencyCode={buyCurrencyCode} />
      </TextHint>
    </Row>
  );
};

export default StaleCurrencyRate;
