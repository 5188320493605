import styled, { css } from 'styled-components';
import { Col } from 'components/shared';
import { StyledLink } from 'components/shared/Link/Link.styles';

interface IsIconsOnlyProps {
  isIconsOnly: boolean;
}

export const SidebarWrapper = styled(Col)<IsIconsOnlyProps>(
  ({ theme, isIconsOnly }) => css`
    height: 100%;
    align-items: end;
    justify-content: flex-start;
    min-width: ${isIconsOnly ? '64px' : '154px'};
    width: ${isIconsOnly && '64px'};
    padding: ${({ theme }) =>
      `${theme.spacing.xxl} ${theme.spacing.l} ${theme.spacing.xxl}`};
    background: ${theme.color.dark};
    position: relative;
    transition: ${theme.transition};
  `
);

export const LogoLink = styled(StyledLink)<IsIconsOnlyProps>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  font-weight: 700;
  font-size: ${({ theme, isIconsOnly }) => (isIconsOnly ? 0 : theme.spacing.m)};
  line-height: ${({ theme }) => theme.fontSize.xxl};
  color: ${({ theme }) => theme.color.emeraldDark};
  margin: ${({ isIconsOnly }) =>
    isIconsOnly ? '0 -7px 33px 0' : '0 10px 24px'};
`;

export const LogoImage = styled.img<IsIconsOnlyProps>`
  height: 35px;
  width: ${({ theme }) => theme.iconSizes.l};
  margin: ${({ isIconsOnly }) => (isIconsOnly ? '-2px 0 0' : '-2px 0 9px')};
`;
