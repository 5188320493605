import { useEffect, useState, useCallback, useMemo } from 'react';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import { validateFilterOptionDate } from '../../Controls/utils';
import { useDebounce } from 'hooks';
import { ITableFilter } from 'components/shared/Filters/types';
import { TInvoicesAggregations } from 'types';
import { getCurrenciesFromInvoicesAggregationPerFilter } from 'pages/Invoices/utils';

const useControls = () => {
  const { setUrlValue, tab, currency, filter, search, grouped } = useUrlValues(
    'tab',
    'currency',
    'filter',
    'search',
    'grouped'
  );
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { hasApproverRole } = useStoreState(({ UserState }) => UserState);
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const [searchInputValue, setSearchInputValue] = useState(search || '');
  const debouncedSetSearchValue = useDebounce(searchInputValue, 500);

  const currenciesSelectData = useMemo(() => {
    const detectedCurrencies = getCurrenciesFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      (tab as keyof TInvoicesAggregations['perFilter']) || 'outstanding'
    );
    const sortedDetectedCurrencies = detectedCurrencies.sort();

    return [
      {
        id: 'all',
        name: 'All CCY',
        value: 'all',
      },
      ...sortedDetectedCurrencies.map((currencyCode) => ({
        id: currencyCode,
        name: currencyCode,
        value: currencyCode,
        icon: currencyByCode(currencyCode)?.countryCode,
      })),
    ];
  }, [currencyByCode, invoicesAggregations, tab]);

  const onFilterChange = useCallback(
    (filters: ITableFilter[]) => {
      const urlValuePayload: Record<string, string> = {};
      const dateFilter = filters.find((item) => item.id === 'date');

      if (!dateFilter) {
        return;
      }

      const dueDateFromFilterOptionValue = dateFilter.value.find(
        (filterOption) => filterOption.id === 'dueDateFrom'
      )?.value;

      urlValuePayload.dueDateFrom = validateFilterOptionDate(
        dueDateFromFilterOptionValue
      );

      const dueDateToFilterOptionValue = dateFilter.value.find(
        (filterOption) => filterOption.id === 'dueDateTo'
      )?.value;

      urlValuePayload.dueDateTo = validateFilterOptionDate(
        dueDateToFilterOptionValue
      );

      const fullyPaidOnDateFromFilterOptionValue = dateFilter.value.find(
        (filterOption) => filterOption.id === 'fullyPaidOnDateFrom'
      )?.value;

      urlValuePayload.fullyPaidOnDateFrom = validateFilterOptionDate(
        fullyPaidOnDateFromFilterOptionValue
      );

      const fullyPaidOnDateToFilterOptionValue = dateFilter.value.find(
        (filterOption) => filterOption.id === 'fullyPaidOnDateTo'
      )?.value;

      urlValuePayload.fullyPaidOnDateTo = validateFilterOptionDate(
        fullyPaidOnDateToFilterOptionValue
      );

      setUrlValue(urlValuePayload);
    },
    [setUrlValue]
  );

  // TODO: Remove after refactor of useUrlValues (add initial values for props to avoid useEffect)
  useEffect(() => {
    setUrlValue({
      search: debouncedSetSearchValue,
    });
  }, [debouncedSetSearchValue, setUrlValue]);

  useEffect(() => {
    if (!tab) {
      if (!filter) {
        setUrlValue({ tab: 'outstanding', filter: 'payables' });
      } else {
        setUrlValue({ tab: 'outstanding' });
      }
    }

    if (tab === 'outstanding' && !filter) {
      setUrlValue({ filter: 'payables' });
    }
  }, [tab, setUrlValue, filter, hasApproverRole]);

  return {
    currenciesSelectData,
    searchInputValue,
    setSearchInputValue,
    onFilterChange,
    urlValues: { setUrlValue, tab, currency, filter, search, grouped },
  };
};

export default useControls;
