import { HELP_URL } from 'variables';

export const WHAT_HAPPENS_NEXT_FX_DATA = [
  {
    id: 0,
    text:
      'We will email you the confirmation number and payment instructions reminder.',
  },
  {
    id: 1,
    text:
      'Once we receive and exchange your funds, we will process your transfer and inform you.',
  },
  {
    id: 2,
    text:
      'Depending on the destination your transfer will usually reach the recipient in 1 - 2 business days.',
  },
  {
    id: 3,
    text:
      'We will contact you if we believe it may take any longer and track your payment for you.',
  },
  {
    id: 4,
    text: (
      <>
        For more information, visit our{' '}
        <a href={HELP_URL} target="_blank" rel="noreferrer">
          FAQ’s{' '}
        </a>
        or contact our support team{' '}
        <a
          href="mailto:support@hedgeflows.com"
          target="_blank"
          rel="noreferrer"
        >
          support@hedgeflows.com
        </a>
      </>
    ),
  },
];

export const WHAT_HAPPENS_NEXT_SAME_CURRENCY_DATA = [
  {
    id: 0,
    text:
      'We will email you the confirmation number and payment instructions reminder.',
  },
  {
    id: 1,
    text:
      'Once we receive your funds, we will process your transfer and inform you.',
  },
  {
    id: 2,
    text:
      'Depending on the destination your transfer will usually reach the recipient in 1 - 2 business days.',
  },
  {
    id: 3,
    text:
      'We will contact you if we believe it may take any longer and track your payment for you.',
  },
  {
    id: 4,
    text: (
      <>
        For more information, visit our{' '}
        <a href={HELP_URL} target="_blank" rel="noreferrer">
          FAQ’s{' '}
        </a>
        or contact our support team{' '}
        <a
          href="mailto:support@hedgeflows.com"
          target="_blank"
          rel="noreferrer"
        >
          support@hedgeflows.com
        </a>
      </>
    ),
  },
];
