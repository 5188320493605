import { FC } from 'react';
import { Paragraph } from 'components';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'variables';
import { isDateOverdue } from 'utils/dates';

interface OwnProps {
  value: string;
  withOverdueMarker?: boolean;
}

const DateCell: FC<OwnProps> = ({ value, withOverdueMarker }) => {
  const isOverdue = isDateOverdue(value);

  return (
    <Paragraph color={isOverdue && withOverdueMarker ? 'red' : 'dark'}>
      {value && dayjs(value).format(DATE_FORMAT)}
    </Paragraph>
  );
};

export default DateCell;
