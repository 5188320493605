import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Wrapper } from './PageNotFound.styles';
import { Title } from 'components';
import Button from 'components/shared/Button/Button';

const PageNotFound: FC<RouteComponentProps> = ({ history }) => (
  <Wrapper>
    <Title>Page not found.</Title>

    <Button onClick={history.goBack}>Go back</Button>
  </Wrapper>
);

export default PageNotFound;
