import { Col, Loader, Paragraph, Row, StaleSwitch, Table } from 'components';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import Button from 'components/shared/Button/Button';
import { Controller, useForm } from 'react-hook-form7';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  deletePaymentRunInvoice,
  deletePaymentRunTransfer,
  getPaymentRunReviewAsCSVFile,
  updatePaymentRunTransfer,
} from 'services/paymentRuns';
import {
  IPaymentRun,
  IPaymentRunBreakdownByRecipient,
  IPaymentRunBreakdownByRecipientInvoice,
} from 'types/paymentRuns';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils';
import { errorHandler } from 'utils/errors';
import {
  generatePaymentRunInvoicesTableColumns,
  generatePaymentRunTransfersTableColumns,
} from '../../tableColumnsGenerator';
import ReviewStepForm from './components/ReviewStepForm/ReviewStepForm';
import { useTheme } from 'styled-components';
import { IOpenBankingInstitution, IOpenBankingSettings } from 'types';

interface OwnProps {
  paymentRun: IPaymentRun;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun | undefined>>;
  onContinue: (openBanking: boolean) => void;
  openBankingSettings?: IOpenBankingSettings;
  setOpenBankingSettings: Dispatch<
    SetStateAction<IOpenBankingSettings | undefined>
  >;
  getPaymentRunHandler: (openBanking?: boolean) => Promise<void>;
  institutions: IOpenBankingInstitution[];
  isLoadingInstitutions: boolean;
}

const ReviewStep: FC<OwnProps> = ({
  paymentRun,
  setPaymentRun,
  onContinue,
  openBankingSettings,
  setOpenBankingSettings,
  getPaymentRunHandler,
  institutions,
  isLoadingInstitutions,
}) => {
  const theme = useTheme();
  const [isUpdatingPaymentRun, setIsUpdatingPaymentRun] = useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const paymentRunId = paymentRun.id;
  const isCombineSameContacts = !!paymentRun.instructions.combine;
  const localCurrencyCode = paymentRun.localCurrency;
  const data = paymentRun.paymentRunItemSummary.reduce<
    IPaymentRunBreakdownByRecipient[]
  >((acc, item) => [...acc, ...item.breakdownByRecipient], []);

  const onEditReference = useCallback(
    async (recordId: string, updatedData: IPaymentRunBreakdownByRecipient) => {
      try {
        const { data: response } = await updatePaymentRunTransfer({
          paymentRunId,
          paymentRunTransferId: recordId,
          reference: updatedData.reference,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error: any) {
        errorHandler(error);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onRemoveTransfer = useCallback(
    async (paymentRunTransferId: string) => {
      try {
        const { data: response } = await deletePaymentRunTransfer({
          paymentRunId,
          paymentRunTransferId,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error: any) {
        errorHandler(error);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onRemoveInvoice = useCallback(
    async (paymentRunInvoiceId: string) => {
      try {
        const { data: response } = await deletePaymentRunInvoice({
          paymentRunId,
          paymentRunInvoiceId,
          regeneratePaymentRun: true,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error: any) {
        errorHandler(error);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const paymentRunTransfersTableColumns = useMemo(
    () =>
      generatePaymentRunTransfersTableColumns({
        onEditReference,
        onRemoveTransfer,
        localCurrencyCode,
      }),
    [localCurrencyCode, onEditReference, onRemoveTransfer]
  );
  const paymentRunInvoicesTableColumnsExpansion = useMemo(
    () =>
      generatePaymentRunInvoicesTableColumns({
        onRemoveInvoice,
        localCurrencyCode,
      }),
    [localCurrencyCode, onRemoveInvoice]
  );

  const getPaymentRunReviewAsCSV = async () => {
    try {
      setIsLoadingCSV(true);
      const response = await getPaymentRunReviewAsCSVFile(paymentRunId);
      createAndOpenDownloadLinkToInMemoryFileData(
        response.data,
        `payment_run_review_${paymentRunId}.csv`,
        'text/csv'
      );
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingCSV(false);
    }
  };

  const { control, handleSubmit, watch } = useForm<{ isCombine: boolean }>({
    defaultValues: {
      isCombine: isCombineSameContacts,
    },
  });
  const isCombine = watch('isCombine');

  return (
    <Col style={{ position: 'relative' }}>
      {isUpdatingPaymentRun && (
        <Loader
          withBackdrop
          size="large"
          style={{ position: 'absolute', inset: 0, zIndex: 3 }}
        />
      )}

      <TableHeader justifyContent="flex-end">
        <Row gap={theme.spacing.s} justifyContent="flex-start">
          <Controller
            name="isCombine"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Row>
                <Paragraph mr>Group invoices</Paragraph>

                <StaleSwitch
                  id={name}
                  isOn={value}
                  handleToggle={() => onChange(!value)}
                />
              </Row>
            )}
          />
        </Row>

        <Button
          onClick={getPaymentRunReviewAsCSV}
          variant="link"
          mlValue={theme.spacing.xs}
          isLoading={isLoadingCSV}
          icon="download-ico"
        />
      </TableHeader>
      <Table<IPaymentRunBreakdownByRecipient>
        data={data}
        columns={paymentRunTransfersTableColumns}
        isExpandable={(record) => !!record}
        isRowDisabled={(record) => !record.valid}
        autoResetExpanded={false}
        expansionRender={(record) => (
          <Table<IPaymentRunBreakdownByRecipientInvoice>
            data={record.invoices}
            defaultRowHeight={50}
            isExpandable={() => false}
            isRowDisabled={(record) => !record.valid}
            columns={paymentRunInvoicesTableColumnsExpansion}
            autoResetExpanded={false}
            withHead={false}
          />
        )}
        renderFooterContent={
          <ReviewStepForm
            paymentRunId={paymentRunId}
            paymentRunError={paymentRun.error}
            paymentRunTotals={paymentRun.paymentRunTotals}
            estimatedCost={paymentRun.paymentRunTotals.reduce(
              (acc, paymentRunTotal) =>
                acc + paymentRunTotal.amountInLocalCurrency,
              0
            )}
            openBankingPaymentEligibility={
              paymentRun.openBankingPaymentEligibility
            }
            isCombineSameContacts={isCombineSameContacts}
            localCurrencyCode={localCurrencyCode}
            setPaymentRun={setPaymentRun}
            setIsUpdatingPaymentRun={setIsUpdatingPaymentRun}
            onSubmit={onContinue}
            openBankingSettings={openBankingSettings}
            setOpenBankingSettings={setOpenBankingSettings}
            getPaymentRunHandler={getPaymentRunHandler}
            institutions={institutions}
            isLoadingInstitutions={isLoadingInstitutions}
            handleSubmit={handleSubmit}
            isCombine={isCombine}
          />
        }
        sortable
      />
    </Col>
  );
};

export default ReviewStep;
