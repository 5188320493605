import { Paragraph } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { IInvoice } from 'types';

interface OwnProps {
  rate: number | null;
  currency: string;
  invoiceType: IInvoice['type'];
}

const MarketInvoiceRate: FC<OwnProps> = ({ rate, currency, invoiceType }) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);

  return (
    <div className="row-info">
      <Paragraph>Current rate:</Paragraph>
      <Paragraph
        style={{
          opacity: 1,
        }}
      >
        {` 1 ${
          invoiceType === 'Payable' ? entityCurrencyCode : currency
        } = ${rate} ${
          invoiceType === 'Payable' ? currency : entityCurrencyCode
        }`}
      </Paragraph>
    </div>
  );
};

export default MarketInvoiceRate;
