import styled from 'styled-components';
import { Row } from 'components/shared/Row/Row';

export const CardWrapper = styled(Row)`
  height: 96px;
  border: ${({ theme }) => `1px solid ${theme.color.greyLight_2}`};
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme }) => theme.color.greyLight_4};
`;
