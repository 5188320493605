import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing.s};
    }

    label {
      position: relative;
      padding-left: 28px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: inline-block;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        &:hover {
          cursor: pointer;
        }
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 2px;
        border-radius: ${theme.borderRadius.s};
        background: ${theme.color.transparent};
        border: 1px solid ${theme.color.emeraldDark};
        transition: background-color 0.2s linear;
      }

      &:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 6px;
        width: 10px;
        height: 5px;
        border-bottom: 1px solid ${theme.color.transparent};
        border-left: 1px solid ${theme.color.transparent};
        transform: rotate(-45deg);
        border-color: ${theme.color.white};
      }

      a {
        display: inline;
        border-bottom: 1px solid ${theme.color.dark};
        padding-bottom: 1px;
        transition: ${theme.transition};

        &:hover {
          border-color: ${theme.color.transparent};
        }

        &:focus,
        &:active {
          color: ${theme.color.emeraldDark};
          border-color: ${theme.color.emeraldDark};

          svg {
            fill: ${theme.color.black};
          }
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      max-width: 0;
      max-height: 0;

      // common disabled styling
      &:disabled + label {
        pointer-events: none;
      }

      &:focus,
      &:active {
        & + label:before {
          box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
        }
      }

      // checked and not disabled
      &:checked:not(:disabled) + label:before {
        background: ${theme.color.emeraldDark};
      }

      // disabled and not checked
      &:disabled:not(:checked) + label:before {
        background: ${theme.color.white};
        border-color: ${theme.color.greyLight_1};
      }

      // checked and disabled
      &:checked:disabled + label:before {
        background: ${theme.color.greyLight_1};
        border: ${theme.color.greyLight_1};
      }
    }
  `
);
