import { Col, Loader } from 'components';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import Card from 'components/shared/Card/Card.styles';
import { CashNeedsSkeletonCard } from './CashNeedsSkeleton.styles';

const CashNeedsSkeleton: FC = () => {
  const theme = useTheme();

  return (
    <>
      <Col flex={0.6} gap={theme.spacing.s} alignSelf="stretch">
        <Card flex={1}>
          <Loader size="large" />
        </Card>
        <Card flex={1}>
          <Loader size="large" />
        </Card>
      </Col>
      <CashNeedsSkeletonCard flex={0.75} alignSelf="stretch">
        <Loader size="large" />
      </CashNeedsSkeletonCard>
      <CashNeedsSkeletonCard flex={1} alignSelf="stretch">
        <Loader size="large" />
      </CashNeedsSkeletonCard>

      <CashNeedsSkeletonCard flex={1} alignSelf="stretch">
        <Loader size="large" />
      </CashNeedsSkeletonCard>
    </>
  );
};

export default CashNeedsSkeleton;
