import { Row, Col, Subtitle, Icon } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface IOwnProps {
  title: string;
  currencyTotals?: Pick<
    IPaymentRunCurrencyTotal,
    'amount' | 'currency' | 'amountInLocalCurrency'
  >[];
}

const InvoicesCurrencyTotals: FC<IOwnProps> = ({ title, currencyTotals }) => {
  const theme = useTheme();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );

  if (!currencyTotals?.length) {
    return null;
  }

  const entityCurrency = currencyByCode(entityCurrencyCode);
  const estimatedCost = currencyTotals.reduce(
    (acc, currencyTotal) => acc + currencyTotal.amountInLocalCurrency,
    0
  );

  return (
    <Col>
      <Row alignItems="flex-start" alignSelf="stretch">
        <Subtitle variant="bold">{title}</Subtitle>

        <Col alignItems="flex-end" ml>
          {currencyTotals.map(({ currency, amount }) => {
            const totalCurrency = currencyByCode(currency);

            return (
              <Row justifyContent="flex-end">
                <Subtitle variant="bold">
                  {parseIntoCurrencyStringWithSymbol(
                    amount,
                    totalCurrency?.symbol,
                    totalCurrency?.precision
                  )}
                </Subtitle>
                {!!totalCurrency && (
                  <Icon icon={totalCurrency.countryCode} ml />
                )}
              </Row>
            );
          })}
        </Col>
      </Row>

      {entityCurrency && estimatedCost && (
        <Row mt gap={theme.spacing.xs}>
          <Subtitle variant="bold">
            Estimated Cost:{' '}
            {parseIntoCurrencyStringWithSymbol(
              estimatedCost,
              entityCurrency.symbol,
              entityCurrency.precision
            )}
          </Subtitle>
          <Icon icon={entityCurrency.countryCode} />
        </Row>
      )}
    </Col>
  );
};

export default InvoicesCurrencyTotals;
