import { useCallback, useEffect, useState, useMemo } from 'react';
import * as FirebasePaymentInitiation from 'services/firebase/paymentInitiation';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { errorHandler } from 'utils/errors';
import { Notify } from 'utils';
import {
  ICashNeedsBffResponse,
  IOpenBankingAccount,
  IOpenBankingIntegration,
  TOpenBankingAccountMappingData,
} from 'types';
import { getCashNeeds } from 'services/firebase/analysis';
import { isExternalBalanceTypeGuard } from 'types/externalBalances';

export interface IOpenBankingTableAccount {
  id: string;
  isConnected: boolean;
  erpAccount: string;
  balance: number;
  currency: string;
  accountNumber?: string;
  refuseDisconnect?: boolean;
  institution?: string;
}

const useOpenBankingSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountsMapping, setAccountsMapping] = useState<
    TOpenBankingAccountMappingData[]
  >();
  const [openBankingIntegrations, setOpenBankingIntegrations] = useState<
    IOpenBankingIntegration[]
  >();
  const [cashNeeds, setCashNeeds] = useState<ICashNeedsBffResponse>();

  const fetchAccountsMapping = useCallback(
    async () =>
      callExternalApiWithLoading({
        externalApiCall: () =>
          FirebasePaymentInitiation.getOpenBankingAccountsMapping(),
        loadingHandler: setIsLoading,
        responseHandler: (response) => {
          if (response.data) {
            setAccountsMapping(response.data.data);
          }
        },
      }),
    []
  );
  const fetchAccountsIntegrations = useCallback(
    async () =>
      callExternalApiWithLoading({
        externalApiCall: () =>
          FirebasePaymentInitiation.getAccountsIntegrations(),
        loadingHandler: setIsLoading,
        responseHandler: (response) => {
          if (response.data) {
            setOpenBankingIntegrations(response.data.data);
          }
        },
      }),
    []
  );
  const fetchGetCashNeeds = useCallback(
    async () =>
      callExternalApiWithLoading({
        externalApiCall: () => getCashNeeds(false),
        loadingHandler: setIsLoading,
        responseHandler: (response) => {
          if (response.data) {
            setCashNeeds(response.data.data);
          }
        },
      }),
    []
  );

  useEffect(() => {
    fetchAccountsMapping();
    fetchAccountsIntegrations();
    fetchGetCashNeeds();
  }, [fetchAccountsMapping, fetchAccountsIntegrations, fetchGetCashNeeds]);

  const { connectedAccounts, notConnectedAccounts } = useMemo(() => {
    const connectedAccounts: IOpenBankingTableAccount[] = [];
    const notConnectedAccounts: IOpenBankingTableAccount[] = [];

    const openBankingAccountsMap = new Map<
      string,
      { institutionName: string; account: IOpenBankingAccount }
    >();

    openBankingIntegrations?.forEach((integration) => {
      integration.accounts?.forEach((account) => {
        openBankingAccountsMap.set(account.id, {
          institutionName: integration.institutionName,
          account,
        });
      });
    });

    cashNeeds?.allBalancesToUse.forEach((balance) => {
      if (!isExternalBalanceTypeGuard(balance)) {
        connectedAccounts.push({
          id: balance.id,
          institution: 'HedgeFlows',
          erpAccount: `HedgeFlows ${balance.currency}`,
          balance: balance.amount,
          currency: balance.currency,
          refuseDisconnect: true,
          isConnected: true,
        });
      }
    });

    accountsMapping?.forEach((mappedAccount) => {
      const doc = {
        erpAccount: mappedAccount.accountName,
        balance: mappedAccount.amount,
        currency: mappedAccount.currency,
      };

      if (mappedAccount.openBankingAccount) {
        const integrationInfo = openBankingAccountsMap.get(
          mappedAccount.openBankingAccount.id
        );

        if (integrationInfo) {
          connectedAccounts.push({
            id: integrationInfo.account.id,
            institution: integrationInfo.institutionName,
            accountNumber: integrationInfo.account.accountNumber,
            isConnected: true,
            ...doc,
          });
        }
      } else {
        notConnectedAccounts.push({
          id: mappedAccount.id,
          isConnected: false,
          ...doc,
        });
      }
    });

    return { connectedAccounts, notConnectedAccounts };
  }, [accountsMapping, cashNeeds?.allBalancesToUse, openBankingIntegrations]);

  const onDisconnectAccountIntegration = async (integrationId: string) => {
    try {
      setIsLoading(true);
      const {
        data,
      } = await FirebasePaymentInitiation.deleteAccountsIntegration(
        integrationId
      );
      if (data.success) {
        Notify.success('Account integration disconnected successfully');
        await fetchAccountsMapping();
        await fetchAccountsIntegrations();
      } else {
        errorHandler(data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const hasConnectedAccounts = connectedAccounts.length > 0;
  const hasNotConnectedAccounts = notConnectedAccounts.length > 0;
  const hasAccounts = hasConnectedAccounts || hasNotConnectedAccounts;

  return {
    connectedAccounts,
    notConnectedAccounts,
    isLoading,
    onDisconnectAccountIntegration,
    hasConnectedAccounts,
    hasNotConnectedAccounts,
    hasAccounts,
  };
};

export default useOpenBankingSettings;
