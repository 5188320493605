import { FC } from 'react';
import { Wrapper, ContentWrapper } from './StaleGetStartedPopup.styles';
import { Paragraph } from '../Typography/Typography';
import Button from '../Button/Button';

interface OwnProps {
  onContinue: () => void;
}

const StaleGetStartedPopup: FC<OwnProps> = ({ onContinue }) => (
  <Wrapper onClick={onContinue}>
    <ContentWrapper>
      <Paragraph>
        Sign up will open to the public in the near future. To learn a better
        way to do currency payments for businesses and entrepreneurs email us on
        hello@hedgeflows.com
      </Paragraph>

      <Button onClick={onContinue}>Ok</Button>
    </ContentWrapper>
  </Wrapper>
);

export default StaleGetStartedPopup;
