import { TEntityPackageKeys } from 'types';
import {
  getSubscriptionSessionUrl,
  getCustomerPortalUrl,
} from 'services/billing';
import { errorHandler } from 'utils/errors';
interface IGetSubscriptionActionProps {
  key: TEntityPackageKeys;
  packageName?: string;
  isAutomationPackageEnabled: boolean;
  isFxManagementPackageEnabled: boolean;
  isAutomationPackageFreeTrialUsed: boolean;
  isFxManagementPackageFreeTrialUsed: boolean;
}
const openStripePackageSubscription = async (
  entityId: string,
  packageName: string
) => {
  try {
    const subscriptionSessionResponse = await getSubscriptionSessionUrl({
      entityId,
      packageName,
      redirectUrl: window.location.href,
    });
    const stripeRedirectUrl =
      subscriptionSessionResponse.data.data?.redirectUrl;
    if (!stripeRedirectUrl) {
      throw new Error('Unable to establish a connection with Stripe');
    }
    window.location.href = stripeRedirectUrl;
  } catch (error: any) {
    errorHandler(error);
  }
};

const openStripeCustomerPortal = async (entityId: string) => {
  try {
    const customerPortalResponse = await getCustomerPortalUrl({
      entityId,
      redirectUrl: window.location.href,
    });
    const stripeRedirectUrl = customerPortalResponse.data.data?.redirectUrl;
    if (!stripeRedirectUrl) {
      throw new Error('Unable to establish a connection with Stripe');
    }
    window.location.href = stripeRedirectUrl;
  } catch (error: any) {
    errorHandler(error);
  }
};

export const getSubscriptionAction = ({
  key,
  packageName,
  isAutomationPackageEnabled,
  isFxManagementPackageEnabled,
  isAutomationPackageFreeTrialUsed,
  isFxManagementPackageFreeTrialUsed,
}: IGetSubscriptionActionProps) => {
  if (key === 'automation') {
    if (isFxManagementPackageEnabled) {
      return {
        actionText: 'Included',
        isActionDisabled: true,
      };
    }

    if (isAutomationPackageEnabled) {
      return {
        actionText: 'Active - Manage',
        isActionDisabled: false,
        onActionClickHandler: openStripeCustomerPortal,
      };
    }

    if (isAutomationPackageFreeTrialUsed) {
      return {
        actionText: 'Subscribe',
        isActionDisabled: false,
        onActionClickHandler: (entityId: string) =>
          packageName && openStripePackageSubscription(entityId, packageName),
      };
    }
  }

  if (key === 'fxManagement') {
    if (isFxManagementPackageEnabled) {
      return {
        actionText: 'Active - Manage',
        isActionDisabled: false,
        onActionClickHandler: openStripeCustomerPortal,
      };
    }

    if (isAutomationPackageEnabled) {
      return {
        actionText: 'Upgrade',
        isActionDisabled: false,
        onActionClickHandler: openStripeCustomerPortal,
      };
    }

    if (isFxManagementPackageFreeTrialUsed) {
      return {
        actionText: 'Subscribe',
        isActionDisabled: false,
        onActionClickHandler: (entityId: string) =>
          packageName && openStripePackageSubscription(entityId, packageName),
      };
    }
  }

  return {
    actionText: 'Free trial',
    isActionDisabled: false,
    onActionClickHandler: (entityId: string) =>
      packageName && openStripePackageSubscription(entityId, packageName),
  };
};
