import { useCallback, useEffect, useState } from 'react';
import { Firebase } from 'services';
import { useStoreState } from 'state';
import { IInvoice } from 'types';
import { errorHandler } from 'utils/errors';

const useGetDetailedInvoice = (invoiceId: string | null) => {
  const { entityId } = useStoreState(({ UserState }) => UserState);

  const [invoice, setDetailedInvoice] = useState<IInvoice>();
  const [isLoadingDetailedInvoice, setIsLoadingDetailedInvoice] = useState(
    false
  );

  const getDetailedInvoice = useCallback(
    async (setIsLoading = false) => {
      try {
        if (entityId && invoiceId) {
          if (setIsLoading) {
            setIsLoadingDetailedInvoice(true);
          }
          const response = await Firebase.Invoices.getDetailedInvoice({
            invoiceId,
          });
          const detailedInvoice = response.data.data;

          if (detailedInvoice) {
            setDetailedInvoice(detailedInvoice);
          }

          if (setIsLoading) {
            setIsLoadingDetailedInvoice(false);
          }
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        if (setIsLoading) {
          setIsLoadingDetailedInvoice(false);
        }
      }
    },
    [entityId, invoiceId]
  );

  useEffect(() => {
    if (invoiceId) {
      getDetailedInvoice(true);
    }
  }, [getDetailedInvoice, invoiceId]);

  return {
    isLoading: isLoadingDetailedInvoice,
    detailedInvoice: invoice,
    getDetailedInvoice,
  };
};

export default useGetDetailedInvoice;
