import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form7';
import { useStoreState } from 'state';
import { getTimePeriodByMonths, Notify } from 'utils';
import { updateEntitySettings } from 'services/firebase';
import { TRiskSettingHedgeRatio, TRiskSettingHedgeRatioKeys } from 'state/user';
import { getRiskSettingsPageLink } from 'utils/links';
import { defaultHedgeRatioValues, hedgeRatioInputRules } from './constants';
import { Paragraph, Col, Title, Loader, Row } from 'components';
import Button from 'components/shared/Button/Button';
import Field from 'components/shared/Field/Field.styles';
import InputBase from 'components/shared/InputBase/InputBase';

const StepFour: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { userEntity } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(!userEntity);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TRiskSettingHedgeRatio>({
    mode: 'all',
    defaultValues: userEntity.riskSettingHedgeRatio || defaultHedgeRatioValues,
  });

  const onContinue = () => history.push(getRiskSettingsPageLink({ step: '5' }));

  const onSubmit = async (values: TRiskSettingHedgeRatio) => {
    try {
      setIsLoading(true);

      const response = await updateEntitySettings({
        riskSettingHedgeRatio: values,
      });

      if (response?.data?.success) {
        onContinue();
      } else {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader size="large" />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Your Hedge Ratio range
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        Do you have target range for your hedge ratios? Hedge ratio is a
        proportion of currency exposures that is protected from financial risks
        using hedges.
      </Paragraph>
      <form id="save-hedge-ratio" onSubmit={handleSubmit(onSubmit)}>
        <Row
          mt
          mb
          mbValue={theme.spacing.xxxl}
          gap={theme.spacing.xxl}
          justifyContent="flex-start"
        >
          <Col gap={theme.spacing.s} alignSelf="stretch">
            <Paragraph>Period</Paragraph>

            {Object.keys(defaultHedgeRatioValues).map((key) => (
              <Row key={key} flex={1} alignSelf="stretch">
                <Paragraph>{getTimePeriodByMonths(+key)}</Paragraph>
              </Row>
            ))}
          </Col>

          <Col gap={theme.spacing.s} alignItems="center">
            <Paragraph>Min %</Paragraph>

            {Object.keys(defaultHedgeRatioValues).map((key) => (
              <Field key={key} flexDirection="column" fluid>
                <Controller
                  name={`${key as TRiskSettingHedgeRatioKeys}.min`}
                  control={control}
                  rules={hedgeRatioInputRules}
                  render={({ field: { onChange, value, name } }) => (
                    <InputBase
                      id={name}
                      type="number"
                      value={value}
                      onChange={(e) =>
                        onChange(e.target.value && parseInt(e.target.value))
                      }
                      showSpinButtons
                      error={
                        errors?.[`${key as TRiskSettingHedgeRatioKeys}`]?.min
                          ?.message
                      }
                    />
                  )}
                />
              </Field>
            ))}
          </Col>

          <Col gap={theme.spacing.s} alignItems="center">
            <Paragraph>Max %</Paragraph>

            {Object.keys(defaultHedgeRatioValues).map((key) => (
              <Field key={key} flexDirection="column" fluid>
                <Controller
                  name={`${key as TRiskSettingHedgeRatioKeys}.max`}
                  control={control}
                  rules={hedgeRatioInputRules}
                  render={({ field: { onChange, value, name } }) => (
                    <InputBase
                      id={name}
                      type="number"
                      value={value}
                      onChange={(e) =>
                        onChange(e.target.value && parseInt(e.target.value))
                      }
                      showSpinButtons
                      error={
                        errors?.[`${key as TRiskSettingHedgeRatioKeys}`]?.max
                          ?.message
                      }
                    />
                  )}
                />
              </Field>
            ))}
          </Col>
        </Row>
      </form>

      <Col justifyContent="center" gap={theme.spacing.xl}>
        <Button variant="secondary" onClick={onContinue}>
          Skip
        </Button>

        <Button form="save-hedge-ratio">Continue</Button>
      </Col>
    </>
  );
};

export default StepFour;
