import { FC } from 'react';
import { Row, Paragraph, Title } from 'components';
import { useTheme } from 'styled-components';
import { parseIntoCurrencyString } from 'utils';

interface OwnProps {
  totalAmount: number;
  currencyCode: string;
}

const AuthoriseAmountDueCell: FC<OwnProps> = ({
  totalAmount,
  currencyCode,
}) => {
  const theme = useTheme();

  return (
    <Row columnGap={theme.spacing.xs} alignItems="flex-end">
      <Title style={{ lineHeight: theme.fontSize.xxxl }} variant="h3">
        {parseIntoCurrencyString(totalAmount)}
      </Title>
      <Paragraph>{currencyCode}</Paragraph>
    </Row>
  );
};

export default AuthoriseAmountDueCell;
