import { useState, useEffect } from 'react';
import { subscribeToEntityLoadingState } from 'services/firebase';
import { useStoreState } from 'state';
import { IEntityLoadingState } from 'types';

const useEntityLoadingState = () => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [
    entityLoadingState,
    setEntityLoadingState,
  ] = useState<IEntityLoadingState | null>(null);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (entityId) {
      unsubscribe = subscribeToEntityLoadingState({
        entityId,
        callback: setEntityLoadingState,
      });
    }

    return () => unsubscribe?.();
  }, [entityId]);

  return entityLoadingState;
};

export default useEntityLoadingState;
