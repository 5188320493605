import { SortingRule } from 'react-table';

export interface IGenerateSearchQueryParams {
  searchQuery?: string;
  page?: number;
  size?: number;
  /** Comma separated key value pairs e.g. tab=outstanding,currency=USD etc. */
  filters?: string;
  /** Comma separated key value pairs e.g. date:desc,amount:asc etc.
   Values can be asc or desc only. */
  sortFields?: string;
  grouped?: boolean;
  afterKey?: string;
}

export const generateInvoicesSearchQuery = (
  params: IGenerateSearchQueryParams
) => {
  const query = new URLSearchParams();

  if (params.searchQuery) {
    query.append('searchQuery', params.searchQuery);
  }

  if (params.page) {
    query.append('page', params.page.toString());
  }

  if (params.size) {
    query.append('size', params.size.toString());
  }

  if (params.filters) {
    query.append('filters', params.filters);
  }

  if (params.sortFields) {
    query.append('sortFields', params.sortFields);
  }

  if (params.grouped) {
    query.append('grouped', params.grouped.toString());
  }

  if (params.afterKey) {
    query.append('afterKey', params.afterKey);
  }

  return `?${query.toString()}`;
};

export const getSortQueryStringFromTableSort = <T extends object>(
  sortState: SortingRule<T>[]
) =>
  sortState
    .map((sortItem) => `${sortItem.id}:${sortItem.desc ? 'desc' : 'asc'}`)
    .join(',');
