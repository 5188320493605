import { FC, useEffect } from 'react';
import { Title } from 'components';
import { Wrapper } from './StaleSessionTimeout.styles';
import { useStoreActions } from 'state';
import Button from '../Button/Button';

const StaleSessionTimeout: FC = () => {
  const { signOut } = useStoreActions((actions) => actions.UserState);
  const { setIsSessionExpired } = useStoreActions(
    (actions) => actions.SessionState
  );
  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <Wrapper>
      <div className="popup">
        <Title variant="h4" mb>
          Session is expired due to inactivity.
        </Title>

        <Button onClick={() => setIsSessionExpired(false)}>Close</Button>
      </div>
    </Wrapper>
  );
};

export default StaleSessionTimeout;
