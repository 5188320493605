import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  ResponsiveCenteredContainer,
  Paragraph,
  Subtitle,
  StaleNotification,
  StaleHexagonList,
  Overflow,
} from 'components';

import { MainContent } from './Main.styles';
import Button from 'components/shared/Button/Button';

const Main = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <>
      <MainContent className="signup-company">
        <Overflow>
          <ResponsiveCenteredContainer>
            <StaleNotification
              title="You are halfway there!"
              cross={false}
              icon="look-smile"
              bgColor="#121213"
              titleColor="white"
            >
              <Paragraph color="white">
                Now you have limited access to HedgeFlows platform.
              </Paragraph>
            </StaleNotification>

            <Subtitle variant="bold">
              Here’s what you need to complete the application and get full
              access to HedgeFlows
            </Subtitle>

            <StaleHexagonList color="#121213">
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <Paragraph variant="bold">Your business info</Paragraph>
                The nature of your business, annual turnover and so on
              </li>
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <Paragraph variant="bold">Your company details</Paragraph>
                Details about it’s registration, owners contact info, VAT
              </li>
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <Paragraph variant="bold">eID Verification</Paragraph>
                Please make sure you have one of your documents with you
              </li>
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <Paragraph variant="bold">Proof of Funds</Paragraph>
                Be rady to answer couple of questions
              </li>
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <Paragraph>Done!</Paragraph>
                We will let you know of the results of the registration right
                away
              </li>
            </StaleHexagonList>
          </ResponsiveCenteredContainer>
        </Overflow>
      </MainContent>

      <div className="btns-group">
        <Button onClick={() => history.push(`${url}?step=1`)}>Continue</Button>

        <Button
          variant="secondary"
          onClick={() => history.push('/app/dashboard')}
        >
          Skip for now
        </Button>
      </div>
    </>
  );
};

export default Main;
