import styled from 'styled-components';

export const SliderWrapper = styled.div`
  display: flex;
  flex: 1;

  > div {
    display: flex;
    flex: 1;
    width: 0px;
  }
  /* TODO: slider related, move to the slider */
  .icon {
    margin: 68px auto 65px;
    fill: ${({ theme }) => theme.color.white};
  }
`;
