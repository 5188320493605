import { FC } from 'react';
import {
  StepperItem,
  StepsClose,
  Content,
  StepsHorizontalWrapper,
  StepsHorizontalHeader,
  Stepper,
} from './StaleStepsHorizontal.styles';
import { Title } from '../Typography/Typography';
import Icon from '../Icon/Icon';

interface OwnProps {
  activeStep: number;
  title?: string;
  onClose?: () => void;
  data:
    | {
        title: string;
        disabled?: boolean;
        onClick?: () => void;
      }[]
    | null;
}

const StaleStepsHorizontal: FC<OwnProps> = ({
  activeStep,
  data,
  onClose,
  title,
  children,
}) => (
  <StepsHorizontalWrapper>
    <StepsHorizontalHeader>
      <Title variant="h3" color="white">
        {title}
      </Title>

      <StepsClose onClick={onClose}>
        <Icon icon="cross-ico" />
      </StepsClose>
    </StepsHorizontalHeader>

    <Stepper>
      {data?.map(({ title, disabled, onClick }, i) => (
        <StepperItem
          onClick={activeStep > i ? onClick : undefined}
          key={title}
          current={activeStep === i + 1}
          passed={activeStep > i + 1}
          disabled={disabled}
        >
          <span>0{i + 1}</span>
          {title}
        </StepperItem>
      ))}
    </Stepper>

    <Content>{children}</Content>
  </StepsHorizontalWrapper>
);

export default StaleStepsHorizontal;
