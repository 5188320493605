import styled from 'styled-components';
import { Row } from 'components/shared/Row/Row';
import { Paragraph } from 'components/shared/Typography/Typography';

export const RecipientOptionWrapper = styled(Row)`
  justify-content: flex-start;
`;

export const RecipientOptionText = styled(Paragraph)`
  margin-left: 12px;
`;
