import styled from 'styled-components';

import { SpacingCssProps } from '../Spacing/Spacing.styles';
import { Row } from '../Row/Row';

export const RowInfo = styled(Row).attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
  })
)`
  padding: ${({ theme }) => `${theme.spacing.xxs} 0`};
`;
