import { useState } from 'react';
import { IContact, IInvoiceFromSearch, PartialContact } from 'types';

const useInvoicePopups = () => {
  const [
    changeTargetInvoice,
    setChangeTargetInvoice,
  ] = useState<IInvoiceFromSearch | null>(null);
  const [
    existingInvoiceTracking,
    setExistingInvoiceTracking,
  ] = useState<IInvoiceFromSearch | null>(null);
  const [invoiceDecide, setInvoiceDecide] = useState<IInvoiceFromSearch | null>(
    null
  );
  const [showAllDecideFields, setShowAllDecideFields] = useState(false);
  const [
    cancelPrebookInvoice,
    setCancelPrebookInvoice,
  ] = useState<IInvoiceFromSearch | null>(null);
  const [invoicesForAllocateFx, setInvoicesForAllocateFx] = useState<
    IInvoiceFromSearch[]
  >([]);
  const [
    deleteManualInvoice,
    setDeleteManualInvoice,
  ] = useState<IInvoiceFromSearch | null>(null);

  const [
    transferIdToShowPaymentDetails,
    setTransferIdToShowPaymentDetails,
  ] = useState<string | null>(null);
  const [
    removeExistingPrebookInvoice,
    setRemoveExistingPrebookInvoice,
  ] = useState<IInvoiceFromSearch | null>(null);
  const [contactForEdit, setContactForEdit] = useState<
    IContact | PartialContact | null
  >(null);
  const [invoiceForAddContact, setInvoiceForAddContact] = useState<
    IInvoiceFromSearch | IInvoiceFromSearch | null
  >(null);
  const [showBulkPrebook, setShowBulkPrebook] = useState(false);

  return {
    changeTargetInvoice,
    setChangeTargetInvoice,
    existingInvoiceTracking,
    setExistingInvoiceTracking,
    invoiceDecide,
    setInvoiceDecide,
    showAllDecideFields,
    setShowAllDecideFields,
    cancelPrebookInvoice,
    setCancelPrebookInvoice,
    invoicesForAllocateFx,
    setInvoicesForAllocateFx,
    deleteManualInvoice,
    setDeleteManualInvoice,
    transferIdToShowPaymentDetails,
    setTransferIdToShowPaymentDetails,
    removeExistingPrebookInvoice,
    setRemoveExistingPrebookInvoice,
    contactForEdit,
    setContactForEdit,
    invoiceForAddContact,
    setInvoiceForAddContact,
    showBulkPrebook,
    setShowBulkPrebook,
  };
};

export default useInvoicePopups;
