import {
  Col,
  Row,
  Loader,
  StaleNotification,
  Table,
  Subtitle,
  Paragraph,
} from 'components';
import Button from 'components/shared/Button/Button';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { confirmPaymentRun } from 'services/paymentRuns';
import { useTheme } from 'styled-components';
import { IOpenBankingInstitution } from 'types';
import { IPaymentRunItemSummary, IPaymentRun } from 'types/paymentRuns';
import { getDashboardPageLink, Notify } from 'utils';
import { errorHandler } from 'utils/errors';
import { generatePaymentRunOpenBankingTableColumns } from '../../tableColumnsGenerator';
import OpenBankingInstitutionTile from './components/OpenBankingInstitutionTile/OpenBankingInstitutionTile';

interface IOwnProps {
  paymentRun: IPaymentRun;
  onContinue: () => void;
  institution?: IOpenBankingInstitution;
  setOpenBankingBulkPaymentId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  isLoading: boolean;
}

const AuthoriseOpenBankingStep: FC<IOwnProps> = ({
  paymentRun,
  onContinue,
  institution,
  setOpenBankingBulkPaymentId,
  isLoading,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const paymentRunError = paymentRun.error;

  const tableColumns = useMemo(
    () => generatePaymentRunOpenBankingTableColumns(),
    []
  );

  const onSubmit = async (isPayLater: boolean) => {
    try {
      setIsLoadingSubmission(true);

      const { data } = await confirmPaymentRun({
        paymentRunId: paymentRun.id,
        paymentRunTotals: paymentRun.paymentRunTotals,
        openBanking: true,
      });

      if (data.data?.bulkPaymentIds) {
        setOpenBankingBulkPaymentId(data.data.bulkPaymentIds[0]);
      }

      setIsLoadingSubmission(false);
      if (isPayLater) {
        Notify.success('Payment run successfully scheduled');
        return history.replace(getDashboardPageLink());
      }
      onContinue();
    } catch (error: any) {
      errorHandler(error);
      setIsLoadingSubmission(false);
    }
  };

  return (
    <Col style={{ position: 'relative' }}>
      {(isLoading || isLoadingSubmission) && (
        <Loader
          withBackdrop
          size="large"
          style={{ position: 'absolute', inset: 0, zIndex: 3 }}
        />
      )}
      <Table<IPaymentRunItemSummary>
        data={paymentRun.paymentRunItemSummary || []}
        columns={tableColumns}
        isRowDisabled={(record) => !record.valid}
        defaultRowHeight={56}
        renderFooterContent={
          <Col>
            <Row mb mbValue={theme.spacing.l}>
              <OpenBankingInstitutionTile institution={institution} />

              <Row mt flex={1} justifyContent="flex-end" alignSelf="stretch">
                <Col alignItems="flex-start" alignSelf="stretch">
                  <InvoicesCurrencyTotals
                    title="Payment amount:"
                    currencyTotals={paymentRun.paymentRunTotals}
                  />
                  <Row mt alignSelf="stretch">
                    <Subtitle variant="bold">Beneficiary:</Subtitle>
                    <Paragraph>Multiple recipients</Paragraph>
                  </Row>
                </Col>
              </Row>
            </Row>

            {paymentRunError && (
              <Row>
                <StaleNotification
                  title="Issue with the transfers"
                  cross={false}
                  bgColor={theme.color.red}
                  style={{ maxWidth: 'unset' }}
                >
                  {paymentRunError}
                </StaleNotification>
              </Row>
            )}
            {!isLoading && !institution && (
              <Row>
                <StaleNotification
                  title="Issue with the Open Banking institution"
                  cross={false}
                  bgColor={theme.color.red}
                  style={{ maxWidth: 'unset' }}
                />
              </Row>
            )}
            <Row gap={theme.spacing.m} justifyContent="flex-start">
              {!!paymentRun.valid ? (
                <>
                  <Button
                    onClick={() => onSubmit(false)}
                    isLoading={isLoading || isLoadingSubmission}
                    disabled={isLoading || isLoadingSubmission || !institution}
                  >
                    Continue now
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => onSubmit(true)}
                    isLoading={isLoading || isLoadingSubmission}
                    disabled={isLoading || isLoadingSubmission}
                  >
                    Schedule for later
                  </Button>
                </>
              ) : (
                <Button onClick={history.goBack} type="button">
                  Back to review
                </Button>
              )}
            </Row>
          </Col>
        }
        sortable
      />
    </Col>
  );
};

export default AuthoriseOpenBankingStep;
