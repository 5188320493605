import { TAccountingSystem } from 'types';
import { TNonXeroPlatformNames, TPlatformNames } from 'types/integrations';

/** Setting path helpers */
export type TSettingsPaths =
  | '/app/settings/quickbookssandbox'
  | '/app/settings/quickbooks'
  | '/app/settings/dynamics'
  | '/app/settings/netsuite'
  | '/app/settings/xero';

export const platformNamesToSettingsPaths: Record<
  TPlatformNames,
  TSettingsPaths
> = {
  'QuickBooks Online Sandbox': '/app/settings/quickbookssandbox',
  'QuickBooks Online': '/app/settings/quickbooks',
  'Dynamics 365 Business Central': '/app/settings/dynamics',
  'Oracle NetSuite': '/app/settings/netsuite',
  Xero: '/app/settings/xero',
};

export const systemToPlatformNames: Record<
  TAccountingSystem,
  TPlatformNames
> = {
  quickbooks: 'QuickBooks Online',
  dynamics: 'Dynamics 365 Business Central',
  netsuite: 'Oracle NetSuite',
  xero: 'Xero',
};

/** Log path helpers */
type TNonXeroLogsPaths =
  | '/app/logs/quickbookssandbox'
  | '/app/logs/quickbooks'
  | '/app/logs/dynamics'
  | '/app/logs/netsuite';

export type TLogsPaths = TNonXeroLogsPaths | '/app/logs/xero';

export const nonXeroPlatformNamesToLogsPaths: Record<
  TNonXeroPlatformNames,
  TNonXeroLogsPaths
> = {
  'QuickBooks Online Sandbox': '/app/logs/quickbookssandbox',
  'QuickBooks Online': '/app/logs/quickbooks',
  'Dynamics 365 Business Central': '/app/logs/dynamics',
  'Oracle NetSuite': '/app/logs/netsuite',
};

export const platformNamesToLogsPaths: Record<TPlatformNames, TLogsPaths> = {
  'QuickBooks Online Sandbox': '/app/logs/quickbookssandbox',
  'QuickBooks Online': '/app/logs/quickbooks',
  'Dynamics 365 Business Central': '/app/logs/dynamics',
  'Oracle NetSuite': '/app/logs/netsuite',
  Xero: '/app/logs/xero',
};

export const isStringSettingPath = (
  pathname: string | TSettingsPaths
): pathname is TSettingsPaths =>
  Object.values(platformNamesToSettingsPaths).includes(
    pathname as TSettingsPaths
  );

export const isStringLogsPath = (
  pathname: string | TLogsPaths
): pathname is TLogsPaths =>
  Object.values(platformNamesToLogsPaths).includes(pathname as TLogsPaths);

export const settingPathsToPlatformNames = Object.keys(
  platformNamesToSettingsPaths
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
).reduce((accumulator, platformName: TPlatformNames) => {
  const settingsPath: TSettingsPaths =
    platformNamesToSettingsPaths[platformName];
  accumulator[settingsPath] = platformName;
  return accumulator;
}, {} as Record<TSettingsPaths, keyof typeof platformNamesToSettingsPaths>);

export const logsPathsToPlatformNames = Object.keys(
  platformNamesToLogsPaths
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
).reduce((accumulator, platformName: TPlatformNames) => {
  const logsPaths: TLogsPaths = platformNamesToLogsPaths[platformName];
  accumulator[logsPaths] = platformName;
  return accumulator;
}, {} as Record<TLogsPaths, keyof typeof platformNamesToLogsPaths>);
