import { FC } from 'react';
import PaymentsRunTableTile from 'components/shared/PaymentsRunTableTile/PaymentsRunTableTile';
import TransferPrebookPopups from 'components/shared/TransferPrebookPopups/TransferPrebookPopups';

const PaymentRuns: FC = () => (
  <>
    <PaymentsRunTableTile />
    <TransferPrebookPopups />
  </>
);

export default PaymentRuns;
