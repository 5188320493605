import { useState, useEffect, Dispatch, SetStateAction } from 'react';

import { Nullable, RATE_TYPE } from 'types';

interface UseRateTypeParams {
  predefinedRateContractId?: Nullable<string>;
}

export interface UseRateTypeReturnValues {
  rateType: RATE_TYPE;
  setRateType: Dispatch<SetStateAction<RATE_TYPE>>;
}

const useRateType = ({
  predefinedRateContractId,
}: UseRateTypeParams): UseRateTypeReturnValues => {
  const [rateType, setRateType] = useState(RATE_TYPE.market);

  useEffect(() => {
    if (predefinedRateContractId) {
      setRateType(RATE_TYPE.prebooked);
    }
  }, [predefinedRateContractId]);

  return {
    rateType,
    setRateType,
  };
};

export default useRateType;
