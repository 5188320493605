import { FC, useState } from 'react';
import Drawer from '../Drawer/Drawer';
import { Title } from '../Typography/Typography';
import Button from '../Button/Button';
import useFilters from './hooks/useFilters';
import Controls from './components/Controls/Controls';
import Icon from '../Icon/Icon';
import { useTheme } from 'styled-components';
import { ITableFilter } from './types';
import { Row } from '../Row/Row';
import useDeviceWidth from 'hooks/useDeviceWidth';

interface OwnProps {
  initialFilters: ITableFilter[];
  onFilterChange?: (filters: ITableFilter[]) => void;
  renderCustomFilters?: () => JSX.Element;
}

const Filters: FC<OwnProps> = ({
  initialFilters,
  onFilterChange,
  renderCustomFilters,
}) => {
  const theme = useTheme();
  const [showFilter, setShowFilter] = useState(false);
  const { filters, setFilter, resetFilters } = useFilters(initialFilters);
  const { isPhone } = useDeviceWidth();

  const onReset = () => {
    resetFilters();
    onFilterChange?.(initialFilters);
  };

  return (
    <>
      <Button variant="link" onClick={() => setShowFilter(true)}>
        Filter
        <Icon
          width="16px"
          height="16px"
          icon="filter-ico"
          ml
          mlValue={theme.spacing.xxs}
        />
      </Button>
      <Drawer
        show={showFilter}
        withBackdrop={false}
        onClose={() => setShowFilter(false)}
        width={isPhone ? '100%' : '400px'}
        HeaderContent={<Title variant="h3">Filters</Title>}
        FooterContent={
          <Row flex={1}>
            <Button variant="primary" onClick={() => onFilterChange?.(filters)}>
              Apply
            </Button>
            <Button variant="secondary" onClick={onReset}>
              Reset
            </Button>
          </Row>
        }
      >
        {renderCustomFilters?.()}
        <Controls filters={filters} setFilter={setFilter} />
      </Drawer>
    </>
  );
};

export default Filters;
