import { Col, Loader, Table } from 'components';
import { FC, useMemo, useState } from 'react';
import { useStoreState } from 'state';
import {
  IPaymentRunItemSummary,
  IPaymentRunCurrencyTotal,
} from 'types/paymentRuns';
import { generatePaymentRunOpenBankingTableColumns } from '../../tableColumnsGenerator';
import {
  BULK_PAYMENT_STATUS,
  IOpenBankingInstitution,
  IOpenBankingPaymentAuthResponseData,
} from 'types';

import FundingBulkPayment from 'components/shared/FundingBulkPayment/FundingBulkPayment';
import FundingBulkPaymentActions from 'components/shared/FundingBulkPaymentActions/FundingBulkPaymentActions';

interface IOwnProps {
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  data: IPaymentRunItemSummary[];
  institution?: IOpenBankingInstitution;
  paymentAuthResponse?: IOpenBankingPaymentAuthResponseData;
  isLoadingPaymentAuthUrl: boolean;
  onPaymentAuthorisationInstitutionSelect: (
    institution: IOpenBankingInstitution
  ) => Promise<void>;
  bulkPaymentId?: string;
}

const ConfirmationOpenBankingStep: FC<IOwnProps> = ({
  paymentRunTotals,
  data,
  institution,
  paymentAuthResponse,
  isLoadingPaymentAuthUrl,
  onPaymentAuthorisationInstitutionSelect,
  bulkPaymentId,
}) => {
  const [isRedirected, setIsRedirected] = useState(false);

  const { bulkPaymentById } = useStoreState((state) => state.BulkPaymentsState);
  const selectedBulkPayment = bulkPaymentById(bulkPaymentId);
  const isFundingInitiated =
    selectedBulkPayment?.status !== BULK_PAYMENT_STATUS.awaitingPayment;

  const tableColumns = useMemo(
    () => generatePaymentRunOpenBankingTableColumns(),
    []
  );

  return (
    <Col>
      <Table<IPaymentRunItemSummary>
        data={data}
        columns={tableColumns}
        defaultRowHeight={56}
        renderFooterContent={
          <Col style={{ position: 'relative' }}>
            {isLoadingPaymentAuthUrl && (
              <Loader
                withBackdrop
                size="large"
                style={{ position: 'absolute', inset: 0, zIndex: 3 }}
              />
            )}
            <FundingBulkPayment
              isFundingInitiated={isFundingInitiated}
              isRedirected={isRedirected}
              institution={institution}
              paymentAuthResponse={paymentAuthResponse}
              totals={paymentRunTotals}
              setIsRedirected={setIsRedirected}
              onSelectInstitution={onPaymentAuthorisationInstitutionSelect}
            />

            <FundingBulkPaymentActions
              paymentAuthResponse={paymentAuthResponse}
              isLoadingPaymentAuthUrl={isLoadingPaymentAuthUrl}
              isRedirected={isRedirected}
              setIsRedirected={setIsRedirected}
              isDisabled={!selectedBulkPayment}
            />
          </Col>
        }
        sortable
      />
    </Col>
  );
};

export default ConfirmationOpenBankingStep;
