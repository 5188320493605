import { FC, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useStoreActions, useStoreState } from 'state';
import useReduceRisks from './hooks/useReduceRisks';
import useUrlValues from 'hooks/useUrlValues';
import { Col, Row, Title, Subtitle, Loader, InlineLoader } from 'components';
import ReduceRiskCard from './components/ReduceRiskCard/ReduceRiskCard';
import { getRiskCalculationsText } from './utils';
import { INetworthValues, TCashflowAtRiskNew } from 'types';

interface IOwnProps {
  cashflowsRisksData: {
    perPeriod: TCashflowAtRiskNew[];
    networthValues: INetworthValues;
  };
}

const ReduceRisks: FC<IOwnProps> = ({ cashflowsRisksData }) => {
  const theme = useTheme();
  const { currency } = useUrlValues('currency');
  const { entityCurrencyCode, entityId, userEntity } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  const { cashflowRisksByCurrency, cards, isLoading } = useReduceRisks(
    cashflowsRisksData
  );
  const { refreshCashflowsRisksData } = useStoreActions(
    ({ ReferenceDataState }) => ReferenceDataState
  );

  useEffect(() => {
    if (entityId && entityCurrencyCode) {
      refreshCashflowsRisksData({
        entityId,
        sellCurrency: entityCurrencyCode,
      });
    }
  }, [entityCurrencyCode, entityId, refreshCashflowsRisksData]);

  return (
    <>
      <Col mb gap={theme.spacing.xs}>
        <Title variant="h4">Consider reducing risks</Title>
        <Subtitle color="greyDark">
          Your exposure to swings in {currency} exchange rates can result in
          potential currency losses of up to
          {isLoading && <InlineLoader ml />}
          {!isLoading && (
            <b>
              {getRiskCalculationsText({
                cashflowRisksByCurrency,
                riskTolerance: userEntity?.riskTolerance,
                symbol: entityCurrency?.symbol,
              })}
              your risk tolerance.
            </b>
          )}
        </Subtitle>
      </Col>

      <Title variant="h5">Below are some ways to reduce it:</Title>

      {isLoading && <Loader size="large" />}
      {!isLoading && (
        <Row flexWrap="wrap" gap={theme.spacing.m} mt>
          {cards.map((card) => (
            <ReduceRiskCard key={card.id} {...card} />
          ))}
        </Row>
      )}
    </>
  );
};

export default ReduceRisks;
