export const IMPORT_DATA = [
  {
    id: 'importInvoices',
    disabled: false,
    title: 'Invoices',
  },
  {
    id: 'importBills',
    disabled: false,
    title: 'Bills',
  },
  {
    id: 'importPayments',
    disabled: false,
    title: 'Payments',
  },
  {
    id: 'importContactDetails',
    disabled: false,
    title: 'Contact details',
  },
  {
    id: 'importBankBalances',
    disabled: false,
    title: 'Bank balances',
  },
  {
    id: 'importPurchaseAndSalesOrders',
    disabled: false,
    title: 'Purchase and sales orders',
  },
] as const;
