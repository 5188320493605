import { TEntityPackageKeys } from 'types/permissions';

export interface ISubscriptionCard {
  id: TEntityPackageKeys;
  icon: string;
  title: string;
  text: string;
  price: string;
  packageName?: string;
  advantages: string[];
}

export const SUBSCRIPTION_CARDS = [
  {
    id: 'global',
    icon: 'transfer',
    title: 'Basic',
    text:
      'A multi-currency account to plan, manage and save on foreign payments and collections',
    price: 'Free',
    advantages: [
      'Book guaranteed FX rates in advance',
      '0.40% exchange fee',
      '50 free local payments',
      '£5 - SWIFT payments',
    ],
  },
  {
    id: 'automation',
    icon: 'track',
    title: 'Multi-currency automation',
    text:
      'Save time with multi-currency payment runs. Work in a team with approval process and notes.',
    price: '£99/month after 14 days trial',
    packageName: 'automation',
    advantages: [
      'Payment runs in 30 currencies',
      'Role-based approval process',
      'Schedule up to 3 months in advance',
      'Auditable trial',
    ],
  },
  {
    id: 'fxManagement',
    icon: 'hf-guru-past-performance',
    title: 'Financial planning',
    text:
      'Take control of your costs in foreign currencies. Plan & manage foreign cashflows with confidence.',
    price: '£499/month after 14 days trial',
    packageName: 'fxmanagement',
    advantages: [
      'Live data from your accounting/ERP',
      'Quantify foreign exchange risks',
      'Manage and track FX hedges',
      'Automated reports and alerts',
    ],
  },
] as ISubscriptionCard[];
