import { FC } from 'react';
import { Icon, Paragraph } from 'components';
import { useHistory } from 'react-router';
import Button from 'components/shared/Button/Button';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { useTheme } from 'styled-components';

interface TransferOrPrebookInvoiceRateProps {
  link: string;
  rateText?: string;
  buttonText: string;
}

const TransferOrPrebookInvoiceRate: FC<TransferOrPrebookInvoiceRateProps> = ({
  link,
  rateText,
  buttonText,
}) => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      {!!rateText && (
        <RowInfo justifyContent="flex-end">
          <Paragraph>{rateText}</Paragraph>

          <Icon
            icon="lock-ico"
            width="18px"
            height="18px"
            fill={theme.color.red}
            ml
            mlValue={theme.spacing.xxs}
          />
        </RowInfo>
      )}

      <Button
        variant="link"
        onClick={() => {
          history.push(link);
        }}
        mb
      >
        {buttonText}
      </Button>
    </>
  );
};

export default TransferOrPrebookInvoiceRate;
