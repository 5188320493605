import { action, Action, thunk, Thunk, computed, Computed } from 'easy-peasy';
import {
  SubscribeToExternalHedgesParams,
  subscribeToExternalHedges,
} from 'services/firebase/externalHedges';
import { ICurrency, Nullable } from 'types';
import { IExternalHedge } from 'types/externalHedges';

export interface ExternalHedgesStateModel {
  externalHedges: IExternalHedge[];
  isLoadingExternalHedges: boolean;
  setState: Action<ExternalHedgesStateModel, [string, any]>;
  subscribeToExternalHedges: Thunk<
    ExternalHedgesStateModel,
    Omit<SubscribeToExternalHedgesParams, 'callback'>,
    null,
    object
  >;
  externalHedgesByCurrency: Computed<
    ExternalHedgesStateModel,
    (currencyCode: ICurrency['code']) => IExternalHedge[]
  >;
  externalHedgesByBuyCurrency: Computed<
    ExternalHedgesStateModel,
    (currencyCode: ICurrency['code']) => IExternalHedge[]
  >;
  externalHedgeById: Computed<
    ExternalHedgesStateModel,
    (id: Nullable<IExternalHedge['id']>) => IExternalHedge | null
  >;
}

export const ExternalHedgesState: ExternalHedgesStateModel = {
  externalHedges: [],
  isLoadingExternalHedges: true,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToExternalHedges: thunk(({ setState }, payload) => {
    return subscribeToExternalHedges({
      ...payload,
      callback: (externalHedges) => {
        setState(['externalHedges', externalHedges]);
        setState(['isLoadingExternalHedges', false]);
      },
    });
  }),
  externalHedgesByCurrency: computed(
    [(state) => state.externalHedges],
    (externalHedges) => (currencyCode) =>
      externalHedges.filter(
        (hedge) =>
          hedge.buyCurrency === currencyCode ||
          hedge.sellCurrency === currencyCode
      ) || []
  ),
  externalHedgesByBuyCurrency: computed(
    [(state) => state.externalHedges],
    (externalHedges) => (currencyCode) =>
      externalHedges.filter((hedge) => hedge.buyCurrency === currencyCode) || []
  ),
  externalHedgeById: computed(
    [(state) => state.externalHedges],
    (externalHedges) => (id) =>
      externalHedges.find((hedge) => hedge.id === id) || null
  ),
};
