import {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  LegacyRef,
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  FocusEvent,
} from 'react';
import { UseControllerOptions, useController } from 'react-hook-form';
import cx from 'classnames';

import { Wrapper } from './StaleInput.styles';
import { usePrevious } from 'hooks';

export interface InputProps extends UseControllerOptions {
  id: string;
  type?: HTMLInputElement['type'];
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  maxLength?: InputHTMLAttributes<HTMLInputElement>['maxLength'];
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
  label?: string;
  view?: 'static' | 'moving';
  icon?: string;
  isLoading?: boolean;
  isDone?: boolean;
  onActionIcon?: () => any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChangeCallback?: (event: ChangeEvent<HTMLInputElement>) => any;
  inputRef?: LegacyRef<HTMLInputElement> | null;
  locked?: boolean;
  disabled?: boolean;
  error?: string;
  renderIcon?: ReactNode;
  prefix?: string;
  validateOnMount?: boolean;
}

const StaleInput: FC<InputProps> = ({
  id,
  name = '',
  label = '',
  view = 'static',
  type = 'text',
  inputMode = 'text',
  autoFocus = false,
  defaultValue = '',
  icon = '',
  isLoading = false,
  isDone = false,
  onActionIcon = (e: any) => e,
  onChangeCallback,
  locked = false,
  disabled = false,
  control,
  rules,
  error = '',
  maxLength,
  renderIcon = true,
  onFocus,
  onBlur,
  prefix = '',
  validateOnMount = false,
}) => {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const [isFocused, setIsFocused] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const prevValidateOnMount = usePrevious(validateOnMount);

  useEffect(() => {
    if (autoFocus) {
      setIsFocused(true);
    }
  }, [autoFocus]);

  useEffect(() => {
    if (
      validateOnMount ||
      (typeof prevValidateOnMount !== 'undefined' &&
        validateOnMount !== prevValidateOnMount)
    ) {
      control?.trigger(name);
    }
  }, [name, validateOnMount, prevValidateOnMount, control]);

  return (
    <Wrapper
      className={cx(view, value && 'filled', isFocused && 'focused')}
      icon={!!icon}
      locked={locked}
      disabled={disabled}
      error={!!error}
      isFocused={isFocused}
      isPrefix={!!prefix}
    >
      {label && view === 'static' && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}

      <div className="label-wrap">
        {label && view === 'moving' && (
          <label className="label" htmlFor={id}>
            {label}
          </label>
        )}

        {!!prefix && <div className="prefix">{prefix}</div>}

        <input
          ref={ref}
          id={id}
          className="input"
          inputMode={inputMode}
          type={type === 'password' ? (isShowPassword ? 'text' : type) : type}
          placeholder={view === 'static' ? label : ''}
          autoComplete="new-password"
          maxLength={maxLength}
          disabled={locked || disabled}
          autoFocus={autoFocus}
          value={value}
          onChange={(event) => {
            onChange(event);
            onChangeCallback?.(event);
          }}
          onFocus={() => {
            setIsFocused(true);
            onFocus && onFocus();
          }}
          onBlur={(event) => {
            setIsFocused(false);
            onBlur && onBlur(event);
          }}
        />
      </div>

      <div className="icons-wrapper">
        {icon && onActionIcon && (
          <button type="button" className="icon" onClick={onActionIcon}>
            <svg width="24px" height="24px">
              <use xlinkHref={`#${icon}`} />
            </svg>
          </button>
        )}

        {icon && !onActionIcon && (
          <svg className="icon" width="24px" height="24px">
            <use xlinkHref={`#${icon}`} />
          </svg>
        )}

        {isLoading && (
          <svg className="icon loader" width="24px" height="24px">
            <use xlinkHref={`#loading-ico`} />
          </svg>
        )}

        {isDone && (
          <svg
            className="icon"
            width="24px"
            height="24px"
            style={{
              fill: '#3AA33A',
            }}
          >
            <use xlinkHref={`#tick-ico`} />
          </svg>
        )}

        {type === 'password' && (
          <button
            type="button"
            className="icon"
            onClick={() => setIsShowPassword(!isShowPassword)}
          >
            {renderIcon && (
              <svg width="24px" height="24px">
                <use
                  xlinkHref={`#${
                    isShowPassword ? 'eye-closed-ico' : 'eye-open-ico'
                  }`}
                />
              </svg>
            )}
          </button>
        )}

        {renderIcon && <div className="icon">{renderIcon}</div>}
      </div>

      {error && <span className="error">{error}</span>}
    </Wrapper>
  );
};

export default StaleInput;
