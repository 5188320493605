import dayjs from 'dayjs';
import { useState, useEffect, useCallback } from 'react';
import { getHFGuruStatuses, updateHFGuruStatuses } from 'services/entities';
import { Nullable } from 'types';
import {
  HF_GURU_TASKS,
  IGuruCard,
  IHfGuru,
  IHfGuruStatuses,
  isStringInHfGuruTaskKeys,
} from 'types/hfGuru';
import { getSettingsPageLink } from 'utils';
import { errorHandler } from 'utils/errors';

const GURU_CARDS = [
  {
    id: HF_GURU_TASKS.integrate,
    icon: 'track',
    title: 'Integrate accounting system',
    text: 'Integrate with your system to enable our automation and analytics',
    link: getSettingsPageLink({ tab: 'integrations' }),
    buttonText: 'Connect systems',
    order: 1,
  },
  {
    id: HF_GURU_TASKS.healthCheck,
    icon: 'hf-guru-past-performance',
    title: 'Review your past performance',
    text: 'Periodically check how well we manage our currency risks and costs',
    link: '/app/reports',
    buttonText: 'Run currency health-check',
    order: 2,
  },
  {
    id: HF_GURU_TASKS.register,
    icon: 'complete',
    title: 'Register your business',
    text: 'To get full access to HedgeFlows only takes a few minutes',
    link: '/app/company-registration',
    buttonText: 'Register your company ',
    order: 3,
  },
  {
    id: HF_GURU_TASKS.riskPreference,
    icon: 'rates',
    title: 'Assess your risk tolerance',
    text:
      'Tell us about your preferences for financial risks from foreign trade',
    link: '/app/risk-settings',
    buttonText: 'Short risk assessment',
    order: 4,
  },
  {
    id: HF_GURU_TASKS.manageRisk,
    icon: 'hf-guru-exposure',
    title: 'Manage your currency risks',
    text:
      'Transact to manage currency risks, and make or collect foreign payments',
    link: '/app/invoices',
    buttonText: 'Manage risks',
    order: 5,
  },
  {
    id: HF_GURU_TASKS.automateProcesses,
    icon: 'prebook',
    title: 'Automate processes',
    text: 'Plan in advance or bulk process foreign cashflows to save time',
    link: '/app/invoices',
    buttonText: 'Manage invoices ',
    order: 6,
  },
];

const getHfGuruActiveTasks = (hfGuruStatuses: IHfGuruStatuses) => {
  const hfGuruActiveTasks: IGuruCard<HF_GURU_TASKS>[] = [];
  let shouldShowRemainingTasksLink = false;

  Object.keys(hfGuruStatuses).forEach((statusName) => {
    if (!isStringInHfGuruTaskKeys(statusName)) {
      return;
    }

    const hfGuruStatus = hfGuruStatuses[statusName];

    const isDeferredUntil =
      hfGuruStatus?.deferUntil && dayjs().isBefore(hfGuruStatus.deferUntil);
    const isCompleted = hfGuruStatus.completed;

    if (!isCompleted && !isDeferredUntil) {
      const taskCard = GURU_CARDS.find((el) => el.id === statusName);

      if (taskCard) {
        hfGuruActiveTasks.push(taskCard);
      }
    }

    if (!isCompleted && isDeferredUntil && !shouldShowRemainingTasksLink) {
      shouldShowRemainingTasksLink = true;
    }
  });

  return {
    tasks: hfGuruActiveTasks.sort((a, b) => a.order - b.order),
    shouldShowRemainingTasksLink,
  };
};

const useHfGuru = () => {
  const [isHfGuruLoading, setIsHfGuruLoading] = useState(true);
  const [hfGuruData, setHfGuruData] = useState<Nullable<IHfGuru>>(null);
  const [hfGuruActiveTasks, setHfGuruActiveTasks] = useState<
    Nullable<IGuruCard<HF_GURU_TASKS>[]>
  >(null);
  const [showRemainingTasksLink, setShowRemainingTasksLink] = useState(false);

  useEffect(() => {
    const getStatuses = async () => {
      try {
        setIsHfGuruLoading(true);
        const { data } = await getHFGuruStatuses();

        if (data && data.success) {
          setHfGuruData(data.data);
        } else {
          console.warn(data.message);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsHfGuruLoading(false);
      }
    };

    getStatuses();
  }, []);

  useEffect(() => {
    if (hfGuruData?.statuses) {
      const { tasks, shouldShowRemainingTasksLink } = getHfGuruActiveTasks(
        hfGuruData.statuses
      );

      setHfGuruActiveTasks(tasks);
      setShowRemainingTasksLink(shouldShowRemainingTasksLink);
    }
  }, [hfGuruData]);

  const updateHfGuruStatus = useCallback(
    async (id: HF_GURU_TASKS, close = true) => {
      try {
        const { data } = await updateHFGuruStatuses({
          task: id,
          close,
        });

        if (data && data.success) {
          setHfGuruData(data.data);
        } else {
          console.warn(data.message);
        }
      } catch (error: any) {
        errorHandler(error);
      }
    },
    []
  );

  return {
    isHfGuruLoading,
    hfGuruData,
    updateHfGuruStatus,
    showRemainingTasksLink,
    setShowRemainingTasksLink,
    hfGuruActiveTasks,
  };
};

export default useHfGuru;
