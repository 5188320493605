import { FC } from 'react';
import { IInvoiceFromSearch } from 'types';
import { useStoreState } from 'state';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import useInvoicesFooterActions from './useInvoicesFooterActions';
import Button from 'components/shared/Button/Button';
import { PermissionsChecker, Row } from 'components';
import { useTheme } from 'styled-components';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { getCurrencyTotalsFromInvoices } from 'utils/invoices';
import {
  getUpdatedApprovedInvoices,
  getUpdatedSubmittedInvoices,
} from 'pages/Invoices/components/GroupedInvoicesTable/components/ViewDetailsPopup/utils';

interface IOwnProps {
  selectedInvoices: IInvoiceFromSearch[];
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
  unselectInvoice: (invoiceId: string) => void;
  setInvoicesForAllocateFx: (invoices: IInvoiceFromSearch[]) => void;
}

const InvoicesTableFooterContent: FC<IOwnProps> = ({
  selectedInvoices,
  updateInMemoryInvoices,
  unselectInvoice,
  setInvoicesForAllocateFx,
}) => {
  const theme = useTheme();
  const { isUserApprover, hasApprovalFlow, user, userEntity } = useStoreState(
    (state) => state.UserState
  );
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);

  const requiredNumberOfApprovalsOnUserEntity =
    userEntity?.approvalSettings?.requiredNumberOfApprovals || 1;
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();
  const {
    invoicesForApproval,
    invoicesForSubmission,
    invoicesForPayment,
    invoicesForAllocateFx,
    hasReceivableInvoices,
    shouldShowAllocatedFxButton,
    onAddToPaymentRun,
  } = useInvoicesFooterActions({
    selectedInvoices,
  });

  const currencyTotals = getCurrencyTotalsFromInvoices({
    invoices: selectedInvoices,
    transferById,
    rateContractById,
  });

  return (
    <Row justifyContent="flex-start" gap={theme.spacing.s}>
      {hasApprovalFlow && !!invoicesForSubmission.length && (
        <>
          <Button
            mr
            onClick={() => {
              const invoiceIds = invoicesForSubmission.map(
                (invoice) => invoice.id
              );

              updateInvoicesApprovalStatus({
                invoiceIds,
                approvalStatus: 'submitted',
              }).then(() => {
                updateInMemoryInvoices((invoices) =>
                  getUpdatedSubmittedInvoices({
                    invoices,
                    invoicesForSubmission,
                  })
                );

                invoiceIds.forEach((invoiceId) => {
                  unselectInvoice(invoiceId);
                });
              });
            }}
            disabled={isUpdatingInvoicesApprovalStatus}
            isLoading={isUpdatingInvoicesApprovalStatus}
          >
            {`Submit ${invoicesForSubmission.length} selected`}
          </Button>
        </>
      )}

      {hasApprovalFlow && isUserApprover && !!invoicesForApproval.length && (
        <Button
          mr
          onClick={() => {
            const invoiceIds = invoicesForApproval.map((invoice) => invoice.id);

            updateInvoicesApprovalStatus({
              invoiceIds,
              approvalStatus: 'approved',
            }).then(() => {
              updateInMemoryInvoices((invoices) =>
                getUpdatedApprovedInvoices({
                  invoices,
                  invoicesForApproval,
                  requiredNumberOfApprovalsOnUserEntity,
                  userId: user?.id,
                })
              );

              invoiceIds.forEach((invoiceId) => {
                unselectInvoice(invoiceId);
              });
            });
          }}
          disabled={isUpdatingInvoicesApprovalStatus}
          isLoading={isUpdatingInvoicesApprovalStatus}
        >
          {`Approve ${invoicesForApproval.length} selected`}
        </Button>
      )}

      {!!invoicesForPayment.length && (
        <PermissionsChecker action="update" resource="payment_runs">
          <Button
            onClick={onAddToPaymentRun}
            disabled={hasReceivableInvoices || isUpdatingPaymentRun}
            isLoading={isUpdatingPaymentRun}
          >
            Add to draft run
          </Button>
        </PermissionsChecker>
      )}

      {shouldShowAllocatedFxButton && (
        <Button
          onClick={() => {
            setInvoicesForAllocateFx(invoicesForAllocateFx);
          }}
          variant="secondary"
        >
          Allocate FX
        </Button>
      )}

      {!!selectedInvoices.length && (
        <Row flex={1} justifyContent="flex-end">
          <InvoicesCurrencyTotals
            title="Totals:"
            currencyTotals={currencyTotals}
          />
        </Row>
      )}
    </Row>
  );
};

export default InvoicesTableFooterContent;
