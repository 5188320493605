export type TInvoicePageTabs =
  | 'outstanding'
  | 'paid'
  | 'submitted'
  | 'approved'
  | 'paymentRun';

export const mapStringToInvoicePageTabs: Record<string, TInvoicePageTabs> = {
  outstanding: 'outstanding',
  paid: 'paid',
  submitted: 'submitted',
  approved: 'approved',
  paymentRun: 'paymentRun',
};
