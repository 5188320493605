import { Notify } from 'utils';

export const errorHandler = (error: any) => {
  if (error.response?.data?.error?.includes(`\n`)) {
    const splitMessages = error.response.data.error.split('\n');
    Notify.error(splitMessages);
  } else {
    Notify.error(error.response?.data?.error || error.message);
  }
};
