import styled from 'styled-components';

import { Flex, FlexProps } from '../Flex/Flex.styles';

export const Row = styled(Flex).attrs<FlexProps>(
  ({
    theme,
    mbValue,
    mlValue,
    mtValue,
    mrValue,
    justifyContent = 'space-between',
    alignItems = 'center',
  }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
    justifyContent,
    alignItems,
  })
)``;
