type TSettingsTabIds =
  | 'general'
  | 'approval'
  | 'notifications'
  | 'risk'
  | 'integrations'
  | 'packages'
  | 'subscriptions'
  | 'team'
  | 'bankAccounts';

export const SETTINGS_TABS: Array<{ id: TSettingsTabIds; title: string }> = [
  { id: 'general', title: 'General' },
  { id: 'approval', title: 'Approvals' },
  { id: 'bankAccounts', title: 'Bank Accounts' },
  { id: 'notifications', title: 'Notifications' },
  { id: 'risk', title: 'Risk settings' },
  { id: 'integrations', title: 'Integrations' },
  { id: 'packages', title: 'Packages' },
  { id: 'subscriptions', title: 'Subscriptions' },
  { id: 'team', title: 'Team' },
];
