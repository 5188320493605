import { FC, useEffect, useState } from 'react';
import { FundingAccountCredentials, Subtitle } from 'components';
import ExchangeSummary from '../ExchangeSummary/ExchangeSummary';
import { StepsNotification } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { CONVERSION_STATUS, IConversion } from 'types/conversions';
import StaleInfoBox from 'components/shared/StaleInfoBox/StaleInfoBox';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { useStoreState } from 'state';
import { DAY_MONTH_DATE_FORMAT } from 'variables';
import { subscribeToConversionById } from 'services/firebase/conversions';
import useFundingAccountCredentialsActions from 'components/shared/FundingAccountCredentials/useFundingAccountCredentialsActions';
import FundingAccountCredentialsActions from 'components/shared/FundingAccountCredentialsActions/FundingAccountCredentialsActions';

dayjs.extend(isToday);

interface IOwnProps {
  conversionId: string;
  onContinue: () => void;
}

const Exchange: FC<IOwnProps> = ({ onContinue, conversionId }) => {
  const [
    createdConversion,
    setCreatedConversion,
  ] = useState<IConversion | null>(null);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const fundingAccountCredentialsActions = useFundingAccountCredentialsActions();

  useEffect(() => {
    let unsubscribe: () => void;

    if (conversionId) {
      unsubscribe = subscribeToConversionById({
        conversionId: conversionId,
        callback: (data) => setCreatedConversion(data),
      });
    }

    return () => unsubscribe?.();
  }, [conversionId]);

  if (!createdConversion) {
    return null;
  }

  const {
    conversionDate,
    sellCurrency: sellCurrencyCode,
    buyCurrency: buyCurrencyCode,
    buyAmount,
    sellAmount,
    rate,
    conversionFeeRate,
    delayedFunding,
    status,
  } = createdConversion;
  const sellCurrency = currencyByCode(sellCurrencyCode);
  const buyCurrency = currencyByCode(buyCurrencyCode);

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          {!delayedFunding && (
            <>
              {dayjs(conversionDate).isToday() ? (
                <StepsNotification>
                  <Subtitle color="white">
                    Exchange went successfully! Funds are available on your
                    balance.
                  </Subtitle>
                </StepsNotification>
              ) : (
                <StaleInfoBox
                  text={
                    <>
                      {`Your funds will be available on your balance on `}
                      <b>{`${dayjs(conversionDate).format(
                        DAY_MONTH_DATE_FORMAT
                      )}`}</b>
                    </>
                  }
                />
              )}
            </>
          )}

          {delayedFunding && status !== CONVERSION_STATUS.awaitingPayment && (
            <StepsNotification>
              <Subtitle color="white">
                Conversion registered. We are awaiting your payment
              </Subtitle>
            </StepsNotification>
          )}

          {delayedFunding && (
            <FundingAccountCredentials
              assetId={createdConversion.id}
              sellCurrency={createdConversion.sellCurrency}
              fundingAccountCredentialsActions={
                fundingAccountCredentialsActions
              }
              status={createdConversion.status}
              isFundingInitiated={
                createdConversion.status !== CONVERSION_STATUS.awaitingPayment
              }
            />
          )}
        </FlowContentLeft>
        <FlowContentRight>
          <Subtitle variant="bold">Summary</Subtitle>

          {sellCurrency && buyCurrency && (
            <ExchangeSummary
              sellCurrency={sellCurrency}
              buyCurrency={buyCurrency}
              buyAmountAsNumber={buyAmount}
              sellAmountAsNumber={sellAmount}
              rate={rate}
              conversionFeeRate={conversionFeeRate}
            />
          )}
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <FundingAccountCredentialsActions
          isFundingInitiated={
            delayedFunding && status !== CONVERSION_STATUS.awaitingPayment
          }
          fundingAccountCredentialsActions={fundingAccountCredentialsActions}
          onSecondaryButtonClick={onContinue}
          secondaryButtonText={
            fundingAccountCredentialsActions.isSingleFundingMethod ||
            (delayedFunding && status !== CONVERSION_STATUS.awaitingPayment)
              ? 'Back to Dashboard'
              : 'Skip for now'
          }
        />
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Exchange;
