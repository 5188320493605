import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { CalendarButtonWrapper, CalendarWrapper } from './Calendar.styles';
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
} from 'react-calendar';
import Button from '../Button/Button';

interface OwnProps extends ReactCalendarProps {
  onSave: (value: Date | Date[]) => void;
  onClose: () => void;
}

const Calendar: FC<OwnProps> = ({ onSave, onClose, ...rest }) => {
  const theme = useTheme();

  const [selectedValue, setSelectedValue] = useState<Date | Date[]>();

  return (
    <CalendarWrapper>
      <ReactCalendar
        locale={'en-EN'}
        {...rest}
        onChange={(value: Date) => setSelectedValue(value)}
      />
      <CalendarButtonWrapper columnGap={theme.spacing.xl}>
        <Button
          onClick={selectedValue ? () => onSave(selectedValue) : undefined}
        >
          Select
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </CalendarButtonWrapper>
    </CalendarWrapper>
  );
};

export default Calendar;
