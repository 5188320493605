import { FC } from 'react';
import {
  Col,
  Paragraph,
  Row,
  StaleInputSelect,
  Subtitle,
  TableSearchInput,
} from 'components';
import { useTheme } from 'styled-components';
import Filters from 'components/shared/Filters/Filters';
import {
  invoiceDateFilters,
  invoicePaymentDateFilters,
} from '../Controls/consts';
import useControls from '../GroupedInvoicesTable/hooks/useControls';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { getCountFromInvoicesAggregationPerFilter } from 'pages/Invoices/utils';
import { useStoreState } from 'state';

const MobileControls: FC = () => {
  const theme = useTheme();
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const {
    currenciesSelectData,
    searchInputValue,
    setSearchInputValue,
    onFilterChange,
    urlValues: { setUrlValue, filter, tab, currency },
  } = useControls();

  return (
    <>
      <Row
        mt
        mtValue={theme.spacing.s}
        justifyContent="flex-start"
        gap={theme.spacing.xl}
      >
        <TableSearchInput
          width="100%"
          placeholder="Search"
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
        />

        {tab === 'paid' && (
          <Filters
            renderCustomFilters={() => (
              <Col mb>
                <Subtitle mb variant="bold">
                  Currency
                </Subtitle>
                <StaleInputSelect
                  selected={currency}
                  data={currenciesSelectData}
                  onSelect={(item) => setUrlValue({ currency: item.value })}
                  id="invoices-currency"
                  inputHeight="32px"
                />
              </Col>
            )}
            initialFilters={invoicePaymentDateFilters}
            onFilterChange={onFilterChange}
          />
        )}

        {tab !== 'paid' && (
          <Filters
            renderCustomFilters={() => (
              <Col mb gap={theme.spacing.m}>
                <Col>
                  <Subtitle mb variant="bold">
                    Currency
                  </Subtitle>
                  <StaleInputSelect
                    selected={currency}
                    data={currenciesSelectData}
                    onSelect={(item) => setUrlValue({ currency: item.value })}
                    id="invoices-currency"
                  />
                </Col>

                {tab === 'outstanding' && (
                  <Col>
                    <Subtitle mb variant="bold">
                      Invoices filters
                    </Subtitle>
                    <Row
                      gap={theme.spacing.xs}
                      flexWrap="wrap"
                      justifyContent="flex-start"
                    >
                      {tab === 'outstanding' && (
                        <>
                          <FilterButton
                            onClick={() => setUrlValue({ filter: 'default' })}
                            active={filter === 'default'}
                          >
                            <Paragraph>All</Paragraph>
                          </FilterButton>

                          <FilterButton
                            onClick={() =>
                              setUrlValue({
                                filter:
                                  filter !== 'payables'
                                    ? 'payables'
                                    : 'default',
                              })
                            }
                            active={filter === 'payables'}
                          >
                            <Row gap={theme.spacing.xs}>
                              <Paragraph>Payables</Paragraph>
                              <NumberCircle
                                value={getCountFromInvoicesAggregationPerFilter(
                                  invoicesAggregations,
                                  'payable'
                                )}
                              />
                            </Row>
                          </FilterButton>

                          <FilterButton
                            onClick={() =>
                              setUrlValue({
                                filter:
                                  filter !== 'overdue' ? 'overdue' : 'default',
                              })
                            }
                            active={filter === 'overdue'}
                          >
                            <Row gap={theme.spacing.xs}>
                              <Paragraph>Overdue</Paragraph>
                              <NumberCircle
                                value={getCountFromInvoicesAggregationPerFilter(
                                  invoicesAggregations,
                                  'overdue'
                                )}
                              />
                            </Row>
                          </FilterButton>

                          <FilterButton
                            onClick={() =>
                              setUrlValue({
                                filter:
                                  filter !== 'dueIn14Days'
                                    ? 'dueIn14Days'
                                    : 'default',
                              })
                            }
                            active={filter === 'dueIn14Days'}
                          >
                            <Row gap={theme.spacing.xs}>
                              <Paragraph>Due soon</Paragraph>
                              <NumberCircle
                                value={getCountFromInvoicesAggregationPerFilter(
                                  invoicesAggregations,
                                  'dueWithin14Days'
                                )}
                              />
                            </Row>
                          </FilterButton>
                        </>
                      )}
                    </Row>
                  </Col>
                )}
              </Col>
            )}
            initialFilters={invoiceDateFilters}
            onFilterChange={onFilterChange}
          />
        )}
      </Row>
    </>
  );
};

export default MobileControls;
