import { CSSProperties, FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Backdrop } from '../Backdrop/Backdrop.styles';
import ButtonClose from '../ButtonClose/ButtonClose';
import { Row } from '../Row/Row';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerWrapper,
} from './Drawer.styles';

interface OwnProps extends Pick<CSSProperties, 'width'> {
  show: boolean;
  withBackdrop?: boolean;
  HeaderContent: ReactNode;
  FooterContent: ReactNode;
  onClose?: () => void;
}

const Drawer: FC<OwnProps> = ({
  show,
  withBackdrop = true,
  width,
  HeaderContent,
  FooterContent,
  children,
  onClose,
}) => {
  const onCloseHandler = () => onClose?.();

  return createPortal(
    <>
      {show && withBackdrop && (
        <Backdrop transparent onClick={onCloseHandler} />
      )}

      <DrawerWrapper show={show} width={width}>
        <DrawerHeader>
          <Row flex={1} alignItems="center" justifyContent="space-between">
            {HeaderContent}

            <ButtonClose onClick={onCloseHandler} />
          </Row>
        </DrawerHeader>
        <DrawerContent>{children}</DrawerContent>
        <DrawerFooter>{FooterContent}</DrawerFooter>
      </DrawerWrapper>
    </>,
    document.body
  );
};

export default Drawer;
