import { FC } from 'react';
import { Loader, Title } from 'components';
import { Row } from 'components/shared/Row/Row';
import { TitleLoaderWrapper } from './TitleLoader.styles';

interface OwnProps {
  title?: string;
}

const TitleLoader: FC<OwnProps> = ({ title }) => {
  return (
    <TitleLoaderWrapper>
      <Row mb>
        <Loader size="large" />
      </Row>
      <Title variant="h5" mt>
        {title ? title : 'Loading...'}
      </Title>
    </TitleLoaderWrapper>
  );
};

export default TitleLoader;
