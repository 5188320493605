import { Row } from 'components';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
  justify-content: flex-start;
`;

export const EditableCellInput = styled.input`
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: 0;
  &:focus,
  &:active {
    text-decoration: underline;
  }
`;
