import { isMobile } from 'react-device-detect';

import { useStoreState } from 'state';
import { Wrapper } from './StaleInfoHeader.styles';
import { Firebase } from 'services';
import Button from '../Button/Button';
import { errorHandler } from 'utils/errors';

const StaleInfoHeader = () => {
  // TODO: add username as a computed value
  const { user, userEntity } = useStoreState((state) => state.UserState);

  const closeSuperAdminConnection = async () => {
    try {
      const { data } = await Firebase.closeSuperAdminConnection();

      if (data.success) {
        return window.location.reload();
      } else {
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'Notify'.
        Notify.error(data.message);
      }
    } catch (error: any) {
      errorHandler(error);
    }
  };

  return (
    <Wrapper>
      {!isMobile && user && user.isSuperAdmin && userEntity?.name && (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            You are currently logged in as a super admin, operating on behalf of{' '}
            {userEntity.name}
            <Button
              onClick={() => closeSuperAdminConnection()}
              variant="link"
              icon="cross-ico"
            >
              Close Super Admin Connection
            </Button>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default StaleInfoHeader;
