import { useMemo } from 'react';
import { IGroupedInvoicesFromSearch } from 'types';
import {
  isInvoiceFromSearchApprovableByUser,
  isInvoiceDisabled,
  isInvoiceFromSearchSubmittableForReview,
} from 'utils/invoices';
import { isContactValid } from 'utils';
import { useStoreState } from 'state';

interface IOwnProps {
  invoices: IGroupedInvoicesFromSearch;
}

interface IGroupedInvoiceIds {
  approvable: string[];
  submittable: string[];
}

const useGroupGroupedInvoicesFromSearchByApprovalActions = ({
  invoices,
}: IOwnProps) => {
  const {
    hasApprovalFlow,
    isUserApprover,
    isUserSubmitter,
    userId,
  } = useStoreState((state) => state.UserState);
  const { recipientById } = useStoreState((state) => state.RecipientsState);

  const { submittable, approvable } = useMemo(
    () =>
      invoices.results.reduce<IGroupedInvoiceIds>(
        (acc, invoice) => {
          const isDisabled = isInvoiceDisabled(invoice);

          if (isDisabled) {
            return acc;
          }

          const contact = recipientById(invoice.contactId);
          const hasValidContact = isContactValid(contact);

          const isApprovable =
            hasValidContact &&
            hasApprovalFlow &&
            isUserApprover &&
            isInvoiceFromSearchApprovableByUser(invoice, userId);

          const isSubmittable =
            hasValidContact &&
            hasApprovalFlow &&
            isUserSubmitter &&
            isInvoiceFromSearchSubmittableForReview(invoice);

          if (isApprovable) {
            acc.approvable.push(invoice.id);
          }

          if (isSubmittable) {
            acc.submittable.push(invoice.id);
          }

          return acc;
        },
        {
          approvable: [],
          submittable: [],
        }
      ),
    [
      invoices,
      recipientById,
      userId,
      hasApprovalFlow,
      isUserApprover,
      isUserSubmitter,
    ]
  );

  return {
    submittable,
    approvable,
  };
};

export default useGroupGroupedInvoicesFromSearchByApprovalActions;
