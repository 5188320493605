import { FC, useState } from 'react';
import { Icon } from 'components';
import Button from 'components/shared/Button/Button';
import CreateEntityPopup from './components/CreateEntityPopup/CreateEntityPopup';

interface IOwnProps {
  getUserClients: () => Promise<void>;
}

const AddAnotherCompany: FC<IOwnProps> = ({ getUserClients }) => {
  const [openCreateEntityPopup, setOpenCreateEntityPopup] = useState(false);

  return (
    <>
      <Button variant="link" onClick={() => setOpenCreateEntityPopup(true)}>
        <Icon width="16px" height="16px" icon="show-more-ico" />
        Add another company
      </Button>

      {openCreateEntityPopup && (
        <CreateEntityPopup
          onClose={() => setOpenCreateEntityPopup(false)}
          getUserClients={getUserClients}
        />
      )}
    </>
  );
};

export default AddAnotherCompany;
