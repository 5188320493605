import { Col, Paragraph, Row, Title } from 'components';
import Field from 'components/shared/Field/Field.styles';
import { StyledForm } from 'components/shared/Form/Form.styles';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import { ERROR_MESSAGES } from 'variables';
import { TInputs } from './types';
import InputBase from 'components/shared/InputBase/InputBase';
import Button from 'components/shared/Button/Button';
import InternalLink from 'components/shared/InternalLink/InternalLink.styles';
import StaleGoogleSignInButton from 'components/shared/StaleGoogleLoginButton/StaleGoogleSignInButton';

interface IOwnProps {
  onContinue: (values: TInputs) => Promise<void>;
  onSignInWithGoogle: () => Promise<void>;
}

const EmailAndPassword: FC<IOwnProps> = ({
  onContinue,
  onSignInWithGoogle,
}) => {
  const { control, handleSubmit, watch } = useForm<TInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <>
      <Title variant="h5">Please enter your email and password</Title>

      <StyledForm alignItems="stretch" onSubmit={handleSubmit(onContinue)}>
        <Col>
          <Field fluid mb>
            <Controller
              name="email"
              control={control}
              rules={{
                required: ERROR_MESSAGES.requiredField,
                maxLength: 255,
              }}
              render={({ field: { value, onChange } }) => (
                <InputBase
                  value={value}
                  onChange={onChange}
                  type="email"
                  label="Email"
                  autoFocus
                />
              )}
            />
          </Field>

          <Field fluid mb>
            <Controller
              name="password"
              control={control}
              rules={{
                required: ERROR_MESSAGES.requiredField,
                maxLength: 50,
              }}
              render={({ field: { value, onChange } }) => (
                <InputBase
                  value={value}
                  onChange={onChange}
                  type="password"
                  label="Password"
                />
              )}
            />
          </Field>

          <InternalLink to="/reset-password">
            Forgot your password?
          </InternalLink>

          <Button
            mt
            disabled={!watch('email') || !watch('password')}
            type="submit"
          >
            Log in
          </Button>

          <Row mb mt justifyContent="center">
            <Paragraph>or</Paragraph>
          </Row>

          <StaleGoogleSignInButton onSignInWithGoogle={onSignInWithGoogle} />
        </Col>
      </StyledForm>

      <Row justifyContent="center" alignItems="baseline">
        <Paragraph mr>Don't have an account?</Paragraph>
        <InternalLink to="/sign-up">Sign up</InternalLink>
      </Row>
    </>
  );
};

export default EmailAndPassword;
