import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { Controller, useForm } from 'react-hook-form7';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import { errorHandler } from 'utils/errors';
import { BUSINESS_TYPE } from 'types';
import { ERROR_MESSAGES } from 'variables';
import { IInputs } from './types';
import { Row, Title } from 'components';
import Popup from 'components/shared/Popup/Popup';
import Button from 'components/shared/Button/Button';
import Field from 'components/shared/Field/Field.styles';
import { StyledForm } from 'components/shared/Form/Form.styles';
import EntitySignUpFormFields from 'components/shared/EntitySignUpFormFields/EntitySignUpFormFields';
import StaleInputSelect, {
  ISelectItem,
} from 'components/shared/StaleInputSelect/StaleInputSelect';
import { createAnotherEntityForUser } from 'services/firebase';

interface IOwnProps {
  onClose: () => void;
  getUserClients: () => Promise<void>;
}

const CreateEntityPopup: FC<IOwnProps> = ({ onClose, getUserClients }) => {
  const theme = useTheme();
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { user, userEmail } = useStoreState((state) => state.UserState);
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { featureFlagById } = useStoreState(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );
  const [isLoading, setLsLoading] = useState(false);

  const isMultipleCurrenciesEnabled = featureFlagById(
    `multipleEntityCurrencies`
  );

  const entityCurrencies = currencies.reduce<ISelectItem[]>((acc, value) => {
    if (value.canBeEntityCurrency) {
      acc.push({
        name: value.name,
        id: value.code,
        icon: value.countryCode,
        value: { ...value },
      });
    }

    return acc;
  }, []);

  const { control, watch, handleSubmit } = useForm<IInputs>({
    defaultValues: {
      isCompany: undefined,
      country: undefined,
      businessType: undefined,
      entityCurrency: currencyByCode(systemVariables?.defaultSellCurrency),
    },
  });

  const isCompanyWatch = watch('isCompany');
  const isCompanyAnswered = isCompanyWatch !== undefined;
  const isCompanyFromUK = isCompanyWatch === 'true';

  const onSubmit = async ({
    country,
    businessType,
    companyName,
    isCompany,
    entityCurrency,
  }: IInputs) => {
    if (
      !user ||
      !user.firstName ||
      !user.lastName ||
      !userEmail ||
      !entityCurrency
    ) {
      return;
    }
    try {
      setLsLoading(true);

      const { data } = await createAnotherEntityForUser({
        email: userEmail,
        firstName: user.firstName,
        lastName: user.lastName,
        companyCountry: isCompany === 'true' ? 'gb' : country.alpha2,
        companyType:
          isCompany === 'true' ? BUSINESS_TYPE.limitedCompany : businessType,
        companyName,
        entityCurrency: entityCurrency.code,
      });

      if (data?.success) {
        Notify.success('New company created successfully');
        getUserClients();
        onClose();
      } else {
        errorHandler(data);
      }
      setLsLoading(false);
    } catch (error) {
      errorHandler(error);
      setLsLoading(false);
    }
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Add new company</Title>}
      FooterContent={
        <Row gap={theme.spacing.m} flex={1}>
          <Button variant="secondary" disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={isLoading || !isCompanyAnswered}
            isLoading={isLoading}
            form="create-entity-form"
          >
            Submit
          </Button>
        </Row>
      }
      width="680px"
      onClose={onClose}
    >
      <StyledForm id="create-entity-form" onSubmit={handleSubmit(onSubmit)}>
        <EntitySignUpFormFields
          control={control}
          watch={watch}
          withNameFields={false}
        />

        <Field fluid mt={!isCompanyFromUK}>
          <Controller
            control={control}
            name="entityCurrency"
            rules={{
              required: ERROR_MESSAGES.requiredField,
            }}
            render={({
              field: { name, onChange, value },
              fieldState: { error },
            }) => {
              return (
                <StaleInputSelect
                  id={name}
                  label="What is your main currency?"
                  view="moving"
                  data={entityCurrencies}
                  selected={value}
                  onSelect={(item) => onChange(item.value)}
                  strategy="fixed"
                  style={{
                    flex: 1,
                  }}
                  error={error?.message}
                  disabled={!isMultipleCurrenciesEnabled}
                />
              );
            }}
          />
        </Field>
      </StyledForm>
    </Popup>
  );
};

export default CreateEntityPopup;
