import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { ACCOUNTING_SYSTEMS, HOW_DID_YOU_FIND_US_DATA } from './variables';
import { Title, Col, StaleInputSelect } from 'components';
import Field from '../Field/Field.styles';
import { ISelectItem } from '../StaleInputSelect/StaleInputSelect';
import Button from '../Button/Button';
import { ICurrency } from 'types';

export interface SignUpStepInfoFormInputs {
  entityCurrency: ICurrency | null;
  accountingSystem?: string;
  howDidYouFindUs?: string;
}

interface OwnProps {
  isLoading: boolean;
  onSubmit: (values: SignUpStepInfoFormInputs) => Promise<void>;
}

const SignUpStepInfoForm: FC<OwnProps> = ({ isLoading, onSubmit }) => {
  const theme = useTheme();
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { featureFlagById } = useStoreState(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );
  const isMultipleCurrenciesEnabled = featureFlagById(
    `multipleEntityCurrencies`
  );

  const entityCurrencies = currencies.reduce<ISelectItem[]>((acc, value) => {
    if (value.canBeEntityCurrency) {
      acc.push({
        name: value.name,
        id: value.code,
        icon: value.countryCode,
        value: { ...value },
      });
    }

    return acc;
  }, []);

  const { control, handleSubmit } = useForm<SignUpStepInfoFormInputs>({
    mode: 'all',
    defaultValues: {
      entityCurrency: currencyByCode(systemVariables?.defaultSellCurrency),
      accountingSystem: undefined,
      howDidYouFindUs: undefined,
    },
  });

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        Few more questions
      </Title>
      <form id="info-form" onSubmit={handleSubmit(onSubmit)}>
        <Col mb gap={theme.spacing.m}>
          <Field fluid>
            <Controller
              control={control}
              name="entityCurrency"
              render={({ field: { name, onChange, value } }) => {
                return (
                  <StaleInputSelect
                    id={name}
                    label="What is your main currency?"
                    view="moving"
                    data={entityCurrencies}
                    selected={value}
                    onSelect={(item) => onChange(item.value)}
                    autoFocus
                    strategy="fixed"
                    style={{
                      flex: 1,
                    }}
                    disabled={!isMultipleCurrenciesEnabled}
                  />
                );
              }}
            />
          </Field>
          <Field fluid>
            <Controller
              control={control}
              name="accountingSystem"
              render={({ field: { name, onChange, value } }) => {
                return (
                  <StaleInputSelect
                    id={name}
                    label="What accounting system do you use?"
                    view="moving"
                    data={ACCOUNTING_SYSTEMS}
                    selected={ACCOUNTING_SYSTEMS.find(
                      (accountingSystem) => accountingSystem.value === value
                    )}
                    onSelect={(item) => onChange(item.value)}
                    strategy="fixed"
                    style={{
                      flex: 1,
                    }}
                  />
                );
              }}
            />
          </Field>
          <Field fluid>
            <Controller
              control={control}
              name="howDidYouFindUs"
              render={({ field: { name, onChange, value } }) => {
                return (
                  <StaleInputSelect
                    id={name}
                    label="How did you find out about HedgeFlows?"
                    view="moving"
                    data={HOW_DID_YOU_FIND_US_DATA}
                    selected={HOW_DID_YOU_FIND_US_DATA.find(
                      (howDidYouFindUs) => howDidYouFindUs.value === value
                    )}
                    onSelect={(item) => onChange(item.value)}
                    strategy="fixed"
                    style={{
                      flex: 1,
                    }}
                  />
                );
              }}
            />
          </Field>
        </Col>
      </form>
      <Button
        form="info-form"
        disabled={isLoading}
        isLoading={isLoading}
        mt
        mtValue={theme.spacing.l}
        mb
        mbValue={theme.spacing.l}
      >
        Continue
      </Button>
    </>
  );
};

export default SignUpStepInfoForm;
