import { FC } from 'react';
import { parseRateWithPrecision } from 'utils';
import InlineLoader from '../InlineLoader/InlineLoader';

interface OwnProps {
  rate?: number | null;
  currencyCode?: string;
}

const Rate: FC<OwnProps> = ({ rate, currencyCode }) => {
  if (rate) {
    return <>{`${parseRateWithPrecision(rate)} ${currencyCode ?? ''}`}</>;
  }

  return <InlineLoader width="12px" height="12px" />;
};

export default Rate;
