import { FC } from 'react';
import { Icon, Paragraph } from 'components';
import { CardWrapper } from './CongratulationsCard.styles';

interface OwnProps {
  title: string;
  icon: string;
  location: string;
}

const CongratulationsCard: FC<OwnProps> = ({ title, icon, location }) => (
  <CardWrapper>
    <Icon icon={icon} width="65px" height="65px" />
    <Paragraph variant="bold">{title}</Paragraph>
    <a href={location} rel="noreferrer" target="_blank">
      <Icon icon="video" width="48px" height="48px" />
    </a>
  </CardWrapper>
);

export default CongratulationsCard;
