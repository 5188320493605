import { ICurrency } from 'types/currencies';

interface IIsCurrencyEnabledForBuyingParams {
  currencyCode: ICurrency['code'];
  currencies: ICurrency[];
}

export const isCurrencyEnabledForBuying = ({
  currencyCode,
  currencies,
}: IIsCurrencyEnabledForBuyingParams) =>
  currencies.find(({ code, buy }) => code === currencyCode && buy);

export const getDefaultBuyCurrencyCode = (sellCurrency?: string) =>
  sellCurrency === 'USD' ? 'GBP' : 'USD';
