import { BetaTag, Col, Loader, Title } from 'components';
import SidebarPageTemplate from 'components/pageTemplates/SidebarPageTemplate/SidebarPageTemplate';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Field from 'components/shared/Field/Field.styles';
import { StyledForm } from 'components/shared/Form/Form.styles';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import {
  getTransactionCurrencies,
  getTransactionsCsvByCurrency,
} from 'services/transactions';
import { useTheme } from 'styled-components';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils';
import { errorHandler } from 'utils/errors';
import { DATE_FORMAT } from 'variables';

const TransactionsDownload: FC = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { control, watch, handleSubmit } = useForm<{
    currencies: string[];
    dateFrom: string;
    dateTo: string;
  }>({
    defaultValues: {
      currencies: [],
    },
  });
  const currencies = watch('currencies');
  const [transactionCurrencies, setTransactionCurrencies] = useState<string[]>(
    []
  );
  const [
    isLoadingTransactionCurrencies,
    setIsLoadingTransactionCurrencies,
  ] = useState(true);

  useEffect(() => {
    getTransactionCurrencies()
      .then((result) => {
        if (result.data.data) {
          setTransactionCurrencies(result.data.data);
        }
        setIsLoadingTransactionCurrencies(false);
      })
      .catch(errorHandler);
  }, []);

  const onSubmit = async (data: {
    currencies: string[];
    dateFrom: string;
    dateTo: string;
  }) => {
    try {
      setIsLoading(true);
      const result = await getTransactionsCsvByCurrency(
        data.currencies.join(','),
        dayjs(data.dateFrom, DATE_FORMAT).toISOString(),
        dayjs(data.dateTo, DATE_FORMAT).toISOString()
      );

      if (result.data.data && result.data.success) {
        result.data.data.forEach(({ currency, csv }) => {
          createAndOpenDownloadLinkToInMemoryFileData(
            csv,
            `transactions_${currency}.csv`,
            'text/csv'
          );
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SidebarPageTemplate>
      <Col flex={1} justifyContent="center" alignItems="center">
        {isLoadingTransactionCurrencies && <Loader size="large" />}

        {!isLoadingTransactionCurrencies && (
          <>
            <Title mb variant="h3">
              Select currencies and date range for CSV download
              <BetaTag top={-theme.spacing.l} right={theme.spacing.xs} />
            </Title>
            <StyledForm
              gap={theme.spacing.xs}
              alignItems="center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Field flexDirection="column">
                <Controller
                  name="currencies"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {transactionCurrencies.map((currency) => (
                        <Checkbox
                          key={currency}
                          label={currency}
                          checked={value.includes(currency)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            onChange(
                              isChecked
                                ? [...value, currency]
                                : value.filter((v) => v !== currency)
                            );
                          }}
                        />
                      ))}
                    </>
                  )}
                />
              </Field>

              <Field flexDirection="column">
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDateUncontrolled
                        id={name}
                        view="moving"
                        label="Date from"
                        error={error?.message}
                        value={value}
                        calendarProps={{
                          defaultActiveStartDate: new Date(),
                          minDetail: 'month',
                        }}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </Field>

              <Field flexDirection="column">
                <Controller
                  name="dateTo"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDateUncontrolled
                        id={name}
                        view="moving"
                        label="Date to"
                        error={error?.message}
                        value={value}
                        calendarProps={{
                          defaultActiveStartDate: new Date(),
                          minDetail: 'month',
                        }}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </Field>

              <Button
                mt
                isLoading={isLoading}
                disabled={!currencies.length || isLoading}
              >
                Download
              </Button>
            </StyledForm>
          </>
        )}
      </Col>
    </SidebarPageTemplate>
  );
};

export default TransactionsDownload;
