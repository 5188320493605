import dayjs from 'dayjs';
import { IInvoiceFromSearch } from 'types';

interface IGetUpdatedSubmittedInvoicesProps {
  invoices: IInvoiceFromSearch[];
  invoicesForSubmission: IInvoiceFromSearch[];
}

export const getUpdatedSubmittedInvoices = ({
  invoices,
  invoicesForSubmission,
}: IGetUpdatedSubmittedInvoicesProps): IInvoiceFromSearch[] =>
  invoices.map((invoiceFromMemory) => {
    const invoice = invoicesForSubmission.find(
      (invoiceForApproval) => invoiceForApproval.id === invoiceFromMemory.id
    );

    if (invoice) {
      return {
        ...invoiceFromMemory,
        approvalStatus: 'submitted',
      };
    }

    return invoiceFromMemory;
  });

interface IGetUpdatedApprovedInvoicesProps {
  invoices: IInvoiceFromSearch[];
  invoicesForApproval: IInvoiceFromSearch[];
  requiredNumberOfApprovalsOnUserEntity: number;
  userId?: string;
}

export const getUpdatedApprovedInvoices = ({
  invoices,
  invoicesForApproval,
  requiredNumberOfApprovalsOnUserEntity,
  userId,
}: IGetUpdatedApprovedInvoicesProps): IInvoiceFromSearch[] =>
  invoices.map((invoiceFromMemory) => {
    const invoice = invoicesForApproval.find(
      (invoiceForApproval) => invoiceForApproval.id === invoiceFromMemory.id
    );

    if (invoice) {
      return {
        ...invoiceFromMemory,
        approvalStatus:
          requiredNumberOfApprovalsOnUserEntity === 2 && invoice.approvedBy
            ? 'approved'
            : 'submitted',
        approvedBy: invoice.approvedBy ? invoice.approvedBy : userId,
        approvedAt: invoice.approvedAt
          ? invoice.approvedAt
          : dayjs().toISOString(),
        approvedBy2: invoice.approvedBy ? userId : '',
        approvedAt2: invoice.approvedAt2 ? dayjs().toISOString() : '',
      };
    }

    return invoiceFromMemory;
  });
