import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';

import { Firebase } from 'services';
import { CheckRecipientBankDetailsPayload } from 'services/firebase';
import {
  getRecipientCreationMetadata,
  checkRecipientBankDetails,
  CreateRecipientParams,
} from 'services/firebase/recipients';
import { RECIPIENT_STATUS, IContact, IRecipient, Nullable } from 'types';
import { Notify } from 'utils';

export interface RecipientsStateModel {
  recipients: IContact[];
  setState: Action<RecipientsStateModel, [string, any]>;
  subscribeToRecipients: Thunk<
    RecipientsStateModel,
    Omit<Firebase.SubscribeToRecipientsParams, 'callback'>,
    null,
    object,
    (() => void) | undefined
  >;
  createRecipient: Thunk<RecipientsStateModel, CreateRecipientParams>;
  updateRecipient: Thunk<RecipientsStateModel, Firebase.UpdateRecipientParams>;
  deleteRecipient: Thunk<RecipientsStateModel, IRecipient['id']>;
  checkRecipientBankDetails: Thunk<
    RecipientsStateModel,
    CheckRecipientBankDetailsPayload
  >;
  getRecipientCreationMetadata: Thunk<
    RecipientsStateModel,
    Firebase.GetRecipientCreationMetadataParams
  >;
  recipientById: Computed<
    RecipientsStateModel,
    (id: Nullable<string>) => IRecipient | undefined
  >;
  recipientByName: Computed<
    RecipientsStateModel,
    (recipientName: Nullable<string>) => IRecipient | undefined
  >;
}

export const RecipientsState: RecipientsStateModel = {
  recipients: [],
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToRecipients: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToRecipients({
      ...payload,
      callback: (recipients) =>
        setState([
          'recipients',
          recipients.filter(
            (recipient) => recipient.status !== RECIPIENT_STATUS.deleted
          ),
        ]),
    });

    return subscriber;
  }),
  createRecipient: thunk(async (_, payload) => {
    const data = await Firebase.createRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to create recipient');
    }
  }),
  updateRecipient: thunk(async (_, payload) => {
    const data = await Firebase.updateRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to update recipient');
    }
  }),
  deleteRecipient: thunk(async (_, payload) => {
    const data = await Firebase.deleteRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to delete recipient');
    }
  }),
  checkRecipientBankDetails: thunk(async (_, payload) => {
    try {
      const { data } = await checkRecipientBankDetails(payload);

      return data;
    } catch (error: any) {
      Notify.error(error.message);
    }
  }),
  getRecipientCreationMetadata: thunk(async (_, payload) => {
    try {
      const { data } = await getRecipientCreationMetadata(payload);

      return data;
    } catch (error: any) {
      Notify.error(error.message);
    }
  }),
  recipientById: computed(
    [(state) => state.recipients],
    (recipients) => (id) => {
      if (id) {
        return recipients.find((item) => item.id.toString() === id.toString());
      }
    }
  ),
  recipientByName: computed(
    [(state) => state.recipients],
    (recipients) => (recipientName) => {
      if (recipientName) {
        return recipients.find(
          (item) => item.recipientName.toString() === recipientName.toString()
        );
      }
    }
  ),
};
