import {
  HexagonLine,
  StaleInput,
  Title,
  Subtitle,
  Row,
  Paragraph,
  Col,
} from 'components';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import auth from 'services/auth';
import { finishSignUp } from 'services/firebase';
import { Notify } from 'utils';
import { ERROR_MESSAGES, VALIDATION_MESSAGES } from 'variables';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { useTheme } from 'styled-components';
import { useQuery } from 'hooks';
import { ValidationItem, ValidationWrapper } from './StepPassword.styles';
import Button from 'components/shared/Button/Button';

const StepPassword: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const email = URLQuery.get('email');
  const name = URLQuery.get('firstName');
  const entityId = URLQuery.get('entityId');
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
  });

  const password = watch('password');
  const repeatPassword = watch('repeatPassword');

  const onSubmit = async () => {
    try {
      if (!email) {
        Notify.error('Email is missing, can not proceed');
        return;
      }

      if (!entityId) {
        Notify.error('EntityId is missing, can not proceed');
        return;
      }

      setIsLoading(true);
      // 1. create user and entity and connect to Xero
      const response = await finishSignUp({
        entityId,
        password,
      });

      if (!response.data.success) {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
        return;
      }

      // 2. sign in as a new user
      await auth.signInWithEmailAndPassword(email, password);

      URLQuery.set('step', '2');
      history.push(`${url}?${URLQuery.toString()}`);
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {(!email || !name || !entityId) && (
        <Col alignItems="center" mb mbValue={theme.spacing.xxl}>
          <Title mb>Missing details. Please contact support.</Title>
        </Col>
      )}
      {email && name && entityId && (
        <>
          <Col alignItems="center" mb mbValue={theme.spacing.xxl}>
            <Title mb>Hello {name}!</Title>
            <Paragraph>({email})</Paragraph>
          </Col>

          <Subtitle variant="bold" mb>
            Create a password for your account:
          </Subtitle>
          <form id="password-confirm" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <StaleInput
                id="password"
                label="New password"
                view="moving"
                type="password"
                name="password"
                defaultValue={''}
                control={control}
                rules={{
                  validate: {
                    minLength: (value) => value.length >= 8,
                    oneUppercase: (value) => /(?=.*[A-Z])/.test(value),
                    oneLowercase: (value) => /(?=.*[a-z])/.test(value),
                    oneNumber: (value) => /(?=.*\d)/.test(value),
                    oneSpecial: (value) => /(?=.*[-+_!@#$%^&*.,?])/.test(value),
                  },
                }}
                autoFocus
              />
            </div>

            <ValidationWrapper>
              {VALIDATION_MESSAGES.map(({ id, text, validate }) => (
                <ValidationItem key={id}>
                  <HexagonLine
                    key={id}
                    isValid={!!validate(password)}
                    text={text}
                    hideWhenValid
                  />
                </ValidationItem>
              ))}
            </ValidationWrapper>

            {password && !errors.password && (
              <>
                <div>
                  <StaleInput
                    id="repeat-password"
                    label="Repeat new password"
                    view="moving"
                    type="password"
                    name="repeatPassword"
                    defaultValue={''}
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                      validate: {
                        matchesPasswordPassword: (value) => {
                          return (
                            password === value || 'Passwords have to match'
                          );
                        },
                      },
                    }}
                  />
                </div>

                <Row mt>
                  <HexagonLine
                    isValid={repeatPassword === password}
                    text="Passwords have to match"
                    hideWhenValid
                  />
                </Row>
              </>
            )}

            <Row mt>
              <Controller
                control={control}
                defaultValue={false}
                name="privacyPolicy"
                ref={register}
                rules={{
                  required: ERROR_MESSAGES.requiredField,
                }}
                render={({ value, name, onChange, ref }) => (
                  <Checkbox
                    id={name}
                    checked={value}
                    onChange={(event) => {
                      onChange(event.target.checked);
                    }}
                    inputRef={ref}
                    label={
                      'I have read and agree to the HedgeFlows Privacy Policy'
                    }
                  />
                )}
              />
            </Row>

            {password && !errors.password && (
              <Row mt>
                <Controller
                  control={control}
                  defaultValue={false}
                  name="transactionalServices"
                  ref={register}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  render={({ value, name, onChange, ref }) => (
                    <Checkbox
                      id={name}
                      checked={value}
                      onChange={(event) => {
                        onChange(event.target.checked);
                      }}
                      inputRef={ref}
                      label={
                        'I understand that transactional services are available to UK clients only'
                      }
                    />
                  )}
                />
              </Row>
            )}

            <Button
              mt
              mtValue={theme.spacing.xl}
              mb
              mbValue={theme.spacing.xl}
              type="submit"
              disabled={isLoading || !isValid}
              isLoading={isLoading}
            >
              Continue
            </Button>

            <Row justifyContent="center">
              <Paragraph>
                Already have an account?{' '}
                <Button variant="link" onClick={() => history.push('/login')}>
                  Log in
                </Button>
              </Paragraph>
            </Row>
          </form>
        </>
      )}
    </>
  );
};

export default StepPassword;
