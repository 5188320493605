import { CSSProperties } from 'react';
import styled, { css, DefaultThemeColorKey } from 'styled-components';
import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';
import { Link, LinkProps } from 'react-router-dom';

interface StyledLinkProps
  extends LinkProps,
    SpacingCssProps,
    Pick<CSSProperties, 'display'> {
  color?: DefaultThemeColorKey;
}

const InternalLink = styled(Link).attrs<StyledLinkProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
  })
)<StyledLinkProps>(
  ({ theme, display = 'flex', color = 'dark' }) => css`
    font-weight: 500;
    display: ${display};
    align-items: center;
    color: ${theme.color[color]};
    transition: ${theme.transition};
    text-decoration: underline;
    text-underline-offset: 2px;

    svg {
      height: 24px;
      width: 24px;
      margin: 0 4px;
      transition: fill 0.4s;
      fill: ${theme.color.dark};
    }

    &:hover {
      color: ${theme.color.greyDark};

      svg {
        fill: ${theme.color.greyDark};
      }
    }

    &:focus,
    &:active {
      color: ${theme.color.emeraldDark};
      border-color: ${theme.color.emeraldDark};

      svg {
        fill: ${theme.color.emeraldDark};
      }
    }

    &.disabled,
    &[disabled] {
      color: ${theme.color.grey};

      svg {
        fill: ${theme.color.grey};
      }
    }

    ${spacingCss}
  `
);

export default InternalLink;
