import { CSSProperties, FC, ReactNode, ReactText } from 'react';
import Icon from '../Icon/Icon';
import { InfoBoxText, InfoBoxWrapper } from './StaleInfoBox.styles';

interface OwnProps {
  text: ReactNode | ReactText;
  style?: CSSProperties;
  icon?: string;
}

const StaleInfoBox: FC<OwnProps> = ({ text, style, icon = 'clock-ico' }) => (
  <InfoBoxWrapper style={style}>
    <Icon icon={icon} />
    <InfoBoxText>{text}</InfoBoxText>
  </InfoBoxWrapper>
);

export default StaleInfoBox;
