import { FC } from 'react';
import { Paragraph, Title } from '../../../Typography/Typography';
import Popup from '../../../Popup/Popup';
import Button from '../../../Button/Button';
import Link from 'components/shared/Link/Link';

interface OwnProps {
  onClose: () => void;
}

const PopupContactSupport: FC<OwnProps> = ({ onClose }) => (
  <Popup
    HeaderContent={<Title variant="h3">Contact support</Title>}
    FooterContent={<Button onClick={onClose}>Close</Button>}
    onClose={onClose}
  >
    <Paragraph>
      Contact support in order to extend your prebook:{' '}
      <Link href="mailto:support@hedgeflows.com">support@hedgeflows.com</Link>
    </Paragraph>
  </Popup>
);

export default PopupContactSupport;
