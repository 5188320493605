import styled, { css } from 'styled-components';

const StaleLoadPlaceholder = styled.div<{
  height: string;
  width?: string;
  marginBottom?: string;
}>(
  ({ theme, height, width = '100%', marginBottom = '16px' }) => css`
    width: ${width};
    height: ${height};
    display: block;
    border-radius: 6px;
    background: ${theme.color.greyLight_3};

    &:not(:last-child) {
      margin-bottom: ${marginBottom};
    }
  `
);

export default StaleLoadPlaceholder;
