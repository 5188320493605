import { Pie, PieSvgProps } from '@nivo/pie';
import AutoSizer from 'react-virtualized-auto-sizer';

const ChartPie = <T extends any>(
  props: Omit<PieSvgProps<T>, 'width' | 'height'>
) => {
  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <Pie height={height} width={width} {...props} />
      )}
    </AutoSizer>
  );
};

export default ChartPie;
