import { FC, useState, useRef, useEffect } from 'react';

import { ExpansionWrapper } from './Expansion.styles';

interface OwnProps {
  isOpen?: boolean;
  delay?: boolean;
}

const Expansion: FC<OwnProps> = ({ isOpen, children, delay }) => {
  const [height, setHeight] = useState(0);
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      const itemHeight = itemRef.current?.getBoundingClientRect().height;

      if (itemHeight) {
        setHeight(itemHeight);
      }
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <ExpansionWrapper delay={delay} height={height}>
      <div ref={itemRef}>{children}</div>
    </ExpansionWrapper>
  );
};

export default Expansion;
