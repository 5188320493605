import styled from 'styled-components';
import { Row } from 'components/shared/Row/Row';

export const TableHeader = styled(Row)`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: ${({ theme }) => `${theme.spacing.m}`};
  margin: ${({ theme }) => theme.spacing.xxs};
  align-items: center;
`;
