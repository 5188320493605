import { FC } from 'react';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import { Row } from 'components';
import { useTheme } from 'styled-components';
import { IGroupedInvoicesFromSearch } from 'types';
import useGroupGroupedInvoicesFromSearchByApprovalActions from 'pages/Invoices/components/GroupedInvoicesTable/hooks/useGroupGroupedInvoicesFromSearchByApprovalActions';

interface OwnProps {
  data: IGroupedInvoicesFromSearch;
  onViewDetails: (groupedInvoices: IGroupedInvoicesFromSearch) => void;
  tab: string | null;
}

const GroupedDoActionCell: FC<OwnProps> = ({ data, onViewDetails, tab }) => {
  const theme = useTheme();
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();

  const {
    submittable,
    approvable,
  } = useGroupGroupedInvoicesFromSearchByApprovalActions({
    invoices: data,
  });

  return (
    <Row flex={1} gap={theme.spacing.xs} justifyContent="flex-end">
      <ActionButton maxWidth="100px" onClick={() => onViewDetails(data)}>
        See invoices
      </ActionButton>

      {tab === 'outstanding' && submittable.length > 0 && (
        <ActionButton
          maxWidth="100px"
          disabled={isUpdatingInvoicesApprovalStatus}
          onClick={(event) => {
            event.stopPropagation();
            updateInvoicesApprovalStatus({
              invoiceIds: submittable,
              approvalStatus: 'submitted',
            });
          }}
        >
          Submit all
        </ActionButton>
      )}

      {tab === 'submitted' && approvable.length > 0 && (
        <ActionButton
          disabled={isUpdatingInvoicesApprovalStatus}
          maxWidth="100px"
          onClick={(event) => {
            event.stopPropagation();
            updateInvoicesApprovalStatus({
              invoiceIds: approvable,
              approvalStatus: 'approved',
            });
          }}
        >
          Approve all
        </ActionButton>
      )}
    </Row>
  );
};

export default GroupedDoActionCell;
