import { Icon } from 'components';
import { set } from 'lodash';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { CellProps, Row } from 'react-table';
import { EditableCellInput, Wrapper } from './EditableCell.styles';

export interface EditableCellProps<T extends object>
  extends Pick<CellProps<T>, 'row' | 'value'> {
  valuePath: string;
  onEdit: (recordId: string, updatedRowData: Row<T>['original']) => void;
}

const EditableCell = <T extends Record<string, any>>({
  row,
  value,
  valuePath,
  onEdit,
}: PropsWithChildren<EditableCellProps<T>>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value || '-');
  }, [value]);

  const onBlurHandler = () => {
    const updatedData = set({ ...row.original }, valuePath, inputValue);

    onEdit(row.original.id, updatedData);
  };

  const onEditClick = () => {
    inputRef.current?.focus();
  };

  return (
    <Wrapper>
      <button onClick={onEditClick} style={{ paddingRight: '10px' }}>
        <Icon width="16px" height="16px" icon="edit-ico" />
      </button>

      <EditableCellInput
        ref={inputRef}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onBlur={onBlurHandler}
      />
    </Wrapper>
  );
};

export default EditableCell;
