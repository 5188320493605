// TODO: fully rework this component and make it in a correct way, more flexible and reusable
import { CSSProperties, FC, ReactText, useState } from 'react';
import cx from 'classnames';

import { Tab, TabNav, TabContent } from './StaleTabs.styles';

//  TODO: remove later
const DEFAULT_TABS = [
  {
    id: '1',
    title: 'Send money',
    subTitle: '',
    icon: '',
    disabled: false,
    onTabClick: () => {},
  },
  {
    id: '2',
    title: 'Receive (Coming soon)',
    subTitle: '',
    icon: '',
    disabled: true,
    onTabClick: () => {},
  },
];

interface OwnProps {
  selectByDefault?: boolean;
  contentContainerStyle?: CSSProperties;
  tabs?: {
    id: ReactText;
    title: string;
    subTitle?: string;
    icon?: string;
    disabled?: boolean;
    onTabClick?: Function;
    isActive?: boolean;
  }[];
  largeTabNav?: boolean;
}

const StaleTabs: FC<OwnProps> = ({
  children,
  tabs = DEFAULT_TABS,
  selectByDefault = true,
  contentContainerStyle = {},
  largeTabNav,
}) => {
  const [select, setSelect] = useState(selectByDefault ? tabs[0].id : null);

  return (
    <Tab>
      <TabNav largeTabNav={largeTabNav}>
        {tabs.map((item) => (
          <li
            key={item.id}
            style={{
              // @ts-expect-error TS(2322) FIXME: Type 'false | "default" | undefined' is not assign... Remove this comment to see the full error message
              cursor: item.disabled && 'default',
            }}
            className={cx(
              // @ts-expect-error TS(2339) FIXME: Property 'isActive' does not exist on type '{ id: ... Remove this comment to see the full error message
              item.isActive
                ? // @ts-expect-error TS(2339) FIXME: Property 'isActive' does not exist on type '{ id: ... Remove this comment to see the full error message
                  item.isActive && 'active'
                : select === item.id && !item.disabled && 'active',
              item.disabled && 'disabled',
              item.subTitle && 'pull-left'
            )}
            onClick={() => {
              setSelect(!item.disabled ? item.id : select);

              if (item.onTabClick) {
                item.onTabClick();
              }
            }}
          >
            {item.icon && (
              <svg width="24px" height="24px">
                <use xlinkHref={`#${item.icon}`} />
              </svg>
            )}
            <span>{item.title}</span>
            {item.subTitle && <span className="sub">{item.subTitle}</span>}
          </li>
        ))}
      </TabNav>
      <TabContent largeTabNav={largeTabNav} style={contentContainerStyle}>
        {children}
      </TabContent>
    </Tab>
  );
};

export default StaleTabs;
