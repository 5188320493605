import { FC, useEffect, useState } from 'react';
import awesomeDebouncePromise from 'awesome-debounce-promise';

import StaleInput, { InputProps } from '../StaleInput/StaleInput';
import { useStoreActions } from 'state';

interface OwnProps extends InputProps {
  withBankDetails?: boolean;
  onGetBankDataCallback?: (bankData: any) => any;
  routingType: string;
  swiftCountryCode?: string;
}

const StaleInputRoutingNumber: FC<OwnProps> = ({
  rules,
  withBankDetails = false,
  onGetBankDataCallback,
  routingType,
  control,
  swiftCountryCode,
  error,
  name,
  label,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const { checkRecipientBankDetails } = useStoreActions(
    (actions) => actions.RecipientsState
  );

  useEffect(() => {
    return () => {
      onGetBankDataCallback?.(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StaleInput
        isLoading={isLoading}
        isDone={isDone}
        onChangeCallback={() => {
          setIsDone(false);
        }}
        control={control}
        error={error}
        name={name}
        label={label}
        rules={{
          ...rules,
          validate: awesomeDebouncePromise(async (value: string) => {
            try {
              // hack for CLABE (Mexican IBAN kind of) because bank details endpoint in CC does not support it
              if (routingType === 'clabe') {
                return true;
              }

              if (!value) {
                onGetBankDataCallback && onGetBankDataCallback(null);
              }

              if (!value && !rules?.required) {
                return true;
              }

              setIsDone(false);
              setIsLoading(true);

              const response = await checkRecipientBankDetails({
                // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"iban" | ... Remove this comment to see the full error message
                type: routingType,
                value,
              });

              setIsLoading(false);

              if (response?.success) {
                onGetBankDataCallback && onGetBankDataCallback(response);

                setIsDone(true);
                return true;
              } else {
                return `${label} is not valid`;
              }
            } catch (error: any) {
              return 'Failed to check Routing number, please try again';
            }
          }, 1000),
        }}
        {...restProps}
      />
    </>
  );
};

export default StaleInputRoutingNumber;
