import { useTheme } from 'styled-components';
import Button from '../Button/Button';
import { Row } from '../Row/Row';
import { Subtitle, Title } from '../Typography/Typography';
import { IInvoice } from 'types';
import { FC, useState } from 'react';
import { getInvoiceNumber, getStatusTextFromInvoice } from 'utils/invoices';
import { useStoreState } from 'state';
import PopupAssetEvents from '../PopupAssetEvents/PopupAssetEvents';

interface IOwnProps {
  invoice: IInvoice;
  showApprovalStatus?: boolean;
}

const InvoiceDetailsHeader: FC<IOwnProps> = ({
  invoice,
  showApprovalStatus,
}) => {
  const theme = useTheme();
  const { userEntity } = useStoreState(({ UserState }) => UserState);
  const [showEvents, setShowEvents] = useState(false);
  const requiredNumberOfApprovalsOnUserEntity =
    userEntity?.approvalSettings?.requiredNumberOfApprovals || 1;

  return (
    <>
      <Row gap={theme.spacing.xs} flexWrap="wrap">
        <Title variant="h3">Invoice {getInvoiceNumber(invoice)}</Title>
        <Row>
          <Button variant="link" mr onClick={() => setShowEvents(true)}>
            Show events
          </Button>
          {showApprovalStatus ? (
            <Subtitle>
              {getStatusTextFromInvoice(
                invoice,
                requiredNumberOfApprovalsOnUserEntity
              )}
            </Subtitle>
          ) : (
            <Subtitle>
              {invoice.excludeFromRisk ? 'EXCLUDED' : invoice.status}
            </Subtitle>
          )}
        </Row>
      </Row>
      {showEvents && (
        <PopupAssetEvents
          assetId={invoice.id}
          onClose={() => setShowEvents(false)}
        />
      )}
    </>
  );
};

export default InvoiceDetailsHeader;
