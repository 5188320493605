import { FC, useEffect, useState } from 'react';
import {
  Col,
  StaleSwitch,
  Subtitle,
  Paragraph,
  Row,
  Title,
  Loader,
} from 'components';
import { useTheme } from 'styled-components';
import { IIntegrationPermissions, TPlatformNames } from 'types/integrations';
import Button from '../Button/Button';
import { Controller, useForm } from 'react-hook-form7';
import { errorHandler } from 'utils/errors';
import { useStoreActions, useStoreState } from 'state';
import { IMPORT_DATA } from './consts';
import { getSettings } from 'services/firebase';

type TIntegrationImportPermissions = Pick<
  IIntegrationPermissions,
  | 'importBills'
  | 'importBankBalances'
  | 'importContactDetails'
  | 'importInvoices'
  | 'importPayments'
  | 'importPurchaseAndSalesOrders'
>;

interface OwnProps {
  onContinue: () => void;
  platformName: TPlatformNames;
}

const StepImport: FC<OwnProps> = ({ onContinue, platformName }) => {
  const theme = useTheme();
  const { entityId } = useStoreState((state) => state.UserState);
  const updateIntegrationEngineSettingsPermissions = useStoreActions(
    (actions) => actions.UserState.updateIntegrationEngineSettingsPermissions
  );
  const {
    control,
    handleSubmit,
    reset,
  } = useForm<TIntegrationImportPermissions>({
    defaultValues: {
      importBankBalances: true,
      importBills: true,
      importInvoices: true,
      importPayments: true,
      importContactDetails: true,
      importPurchaseAndSalesOrders: true,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDefaultValues, setIsLoadingDefaultValues] = useState(true);

  useEffect(() => {
    if (entityId) {
      getSettings(entityId)
        .then((response) => {
          if (response.data?.data?.permissions) {
            reset(response.data.data.permissions);
          }
          setIsLoadingDefaultValues(false);
        })
        .catch(errorHandler);
    }
  }, [entityId, reset]);

  const onSubmit = async (values: TIntegrationImportPermissions) => {
    try {
      setIsLoading(true);

      await updateIntegrationEngineSettingsPermissions({
        permissions: values,
        platformName,
      });

      setIsLoading(false);
      onContinue();
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  if (isLoadingDefaultValues) {
    return <Loader size="large" />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        What shall we import from {platformName}?
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        We will need to import the following data from {platformName} to help
        manage your currency needs and streamline you cross-border payments:
      </Paragraph>

      <Col
        as="form"
        id="integration-import-settings-form"
        gap={theme.spacing.xl}
        mb
        mbValue={theme.spacing.xxl}
        onSubmit={handleSubmit(onSubmit)}
      >
        {IMPORT_DATA.map(({ id, disabled, title }) => (
          <Row key={id} justifyContent="flex-start">
            <Controller
              name={id}
              control={control}
              render={({ field }) => (
                <>
                  <StaleSwitch
                    id={id}
                    isOn={field.value}
                    disabled={disabled}
                    handleToggle={field.onChange}
                  />
                  <Subtitle ml>{title}</Subtitle>
                </>
              )}
            />
          </Row>
        ))}
      </Col>

      <Button
        form="integration-import-settings-form"
        isLoading={isLoading}
        disabled={isLoading}
      >
        Continue
      </Button>
    </>
  );
};

export default StepImport;
