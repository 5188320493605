import { CSSProperties, FC } from 'react';
import { BetaTagWrapper, StyledTextHint } from './BetaTag.styles';

const BetaTag: FC<CSSProperties> = (props) => (
  <BetaTagWrapper style={props}>
    <StyledTextHint color="red" variant="bold">
      BETA
    </StyledTextHint>
  </BetaTagWrapper>
);

export default BetaTag;
