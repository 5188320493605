import styled, { css } from 'styled-components';
import { cardBaseCss, cardBaseSmallCss } from '../CardBase/CardBase.styles';
import { Col } from '../Col/Col';
import { Paragraph } from '../Typography/Typography';

interface OwnProps {
  fullWidth?: boolean;
  size?: 'small' | 'medium';
}

export const PlaceholderWrapper = styled(Col)<OwnProps>`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px dashed ${({ theme }) => theme.color.greyLight_1};
  padding: ${({ theme }) => `0 ${theme.spacing.xxl}`};
  box-shadow: ${({ theme }) => theme.shadow};
  min-height: 136px;
  align-self: stretch;
  position: relative;

  ${({ fullWidth, size }) =>
    !fullWidth &&
    css`
      ${size === 'small' ? cardBaseSmallCss : cardBaseCss}
    `}
`;

export const PlaceholderParagraph = styled(Paragraph)`
  text-align: center;
`;
