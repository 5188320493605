import styled from 'styled-components';
import { ResponsiveCenteredContainer } from '../../Layout/Layout.styles';
import { Col } from '../Col/Col';

export const PopupContentWrapper = styled(Col)`
  height: 100%;
`;

export const PopupContentContainerWrapped = styled(ResponsiveCenteredContainer)`
  flex: 0 1 100%;
  overflow: auto;
  padding: 20px 24px 0 !important;
`;
