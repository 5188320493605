import useIsAwaitingRegistration from 'hooks/useIsAwaitingRegistration';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import {
  getInvoicePrebookLink,
  getInvoiceTransferOrSimpleTransferLink,
} from 'utils/links';
import { IInvoice, IInvoiceFromSearch } from 'types';

const useInvoicePrebookAndTransfer = () => {
  const history = useHistory();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const isAwaitingRegistration = useIsAwaitingRegistration();
  const [showLimitedAccess, setShowLimitedAccess] = useState(false);

  const setShowLimitedAccessFalse = () => setShowLimitedAccess(false);
  const setShowLimitedAccessTrue = () => setShowLimitedAccess(true);

  const goToInvoicePrebook = (record: IInvoiceFromSearch | IInvoice) => {
    if (isAwaitingRegistration) {
      setShowLimitedAccess(true);
    } else if (entityCurrencyCode) {
      history.push(getInvoicePrebookLink(record, entityCurrencyCode));
    }
  };

  const goToInvoiceTransfer = (record: IInvoiceFromSearch | IInvoice) => {
    if (isAwaitingRegistration) {
      setShowLimitedAccess(true);
      return;
    }

    history.push(
      getInvoiceTransferOrSimpleTransferLink(record, entityCurrencyCode)
    );
  };

  return {
    showLimitedAccess,
    setShowLimitedAccessFalse,
    setShowLimitedAccessTrue,
    goToInvoicePrebook,
    goToInvoiceTransfer,
  };
};

export default useInvoicePrebookAndTransfer;
