import { FC, useEffect } from 'react';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import {
  IOpenBankingInstitution,
  IOpenBankingTransaction,
  IOpenBankingPaymentAuthResponseData,
} from 'types';
import { DATE_FORMAT } from 'variables';
import { Paragraph, Row, Title, Loader } from 'components';
import Link from 'components/shared/Link/Link';
import Button from 'components/shared/Button/Button';
import OpenBankingRedirectResponse from 'components/shared/OpenBankingRedirectResponse/OpenBankingRedirectResponse';

interface IOwnProps {
  onDismiss: () => void;
  onGoBack: () => void;
  onContinue: () => void;
  opTransaction: IOpenBankingTransaction | null;
  onUnselectInstitution: () => void;
  isRedirected: boolean;
  accountAuthResponse?: IOpenBankingPaymentAuthResponseData;
  isLoadingAccountAuthUrl: boolean;
  selectedInstitution?: IOpenBankingInstitution;
  setIsRedirected: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestAccountProviderAccess: FC<IOwnProps> = ({
  onDismiss,
  onGoBack,
  onContinue,
  opTransaction,
  onUnselectInstitution,
  isRedirected,
  accountAuthResponse,
  isLoadingAccountAuthUrl,
  selectedInstitution,
  setIsRedirected,
}) => {
  const theme = useTheme();

  const onAuthoriseAccountAuth = () => {
    if (!accountAuthResponse) {
      return;
    }
    window.open(accountAuthResponse.authorisationUrl, '_blank');
    setIsRedirected(true);
  };

  useEffect(() => {
    if (opTransaction?.activityStatus === 'authorised') {
      onContinue();
    }
  }, [opTransaction, onContinue]);

  if (isLoadingAccountAuthUrl) {
    return <Loader size="large" />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Permission to request access
      </Title>

      {isRedirected && (
        <OpenBankingRedirectResponse
          onRetry={() => {
            onUnselectInstitution();
            onGoBack();
          }}
        />
      )}

      {!isRedirected && (
        <>
          <Paragraph mb>
            We have partnered with Yapily Connect to access your bank data at 
            {selectedInstitution?.name}.
          </Paragraph>
          <Paragraph mb mbValue={theme.spacing.xl}>
            You will now be securely redirected to {selectedInstitution?.name}{' '}
            to give access to the following information:
          </Paragraph>
          <Paragraph variant="bold" mb>
            Account(s) details
          </Paragraph>
          <Paragraph variant="bold" mb mbValue={theme.spacing.xl}>
            Balances
          </Paragraph>
          <Paragraph mb mbValue={theme.spacing.xl}>
            By using the service, you agree to Yapily Connect accessing your
            bank data, the{' '}
            <Link
              href="https://www.yapily.com/legal/end-user-terms"
              target="_blank"
              rel="noreferrer"
              display="inline"
            >
              Terms & Conditions
            </Link>{' '}
            and{' '}
            <Link
              href="https://www.yapily.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
              display="inline"
            >
              Privacy Notice
            </Link>
            .
          </Paragraph>
          <Paragraph mb mbValue={theme.spacing.xl}>
            This consent will be valid until{' '}
            {dayjs().add(90, 'day').format(DATE_FORMAT)}.
          </Paragraph>
          <Row mt mtValue={theme.spacing.xl} gap={theme.spacing.m}>
            <Button
              flex={1}
              disabled={isLoadingAccountAuthUrl}
              onClick={onAuthoriseAccountAuth}
            >
              Confirm
            </Button>
            <Button
              flex={1}
              variant="secondary"
              disabled={isLoadingAccountAuthUrl}
              onClick={onDismiss}
            >
              Cancel
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default RequestAccountProviderAccess;
