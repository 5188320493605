import { Row } from 'components';
import styled from 'styled-components';

export const BalanceWrapper = styled(Row)`
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.l}`};
  border: 1px solid ${({ theme }) => theme.color.greyLight_2};
  border-bottom: 0;
  border-radius: ${({ theme }) => theme.borderRadius.m};

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.greyLight_2};
  }
`;
