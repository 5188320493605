import { FC, useEffect } from 'react';
import { useTheme } from 'styled-components';
import useUrlValues from 'hooks/useUrlValues';
import { Paragraph, Row } from 'components';
import Button from 'components/shared/Button/Button';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import { CASHFLOW_TYPE_TO_TITLE } from '../../consts';
import { CASHFLOW_TYPES_FOR_FILTER_BUTTONS } from './consts';

const Tabs: FC = () => {
  const theme = useTheme();
  const { setUrlValue, tab, filter } = useUrlValues('tab', 'filter');

  // TODO: Remove after refactor of useUrlValues (add initial values for props to avoid useEffect)
  useEffect(() => {
    if (!tab) {
      setUrlValue({ tab: 'invoices' });
    }
  }, [tab, setUrlValue]);

  return (
    <TableHeader>
      <Row gap={theme.spacing.xs}>
        {CASHFLOW_TYPES_FOR_FILTER_BUTTONS.map((type) => (
          <FilterButton
            onClick={() => setUrlValue({ filter: filter === type ? '' : type })}
            active={filter === type}
          >
            <Paragraph>
              {CASHFLOW_TYPE_TO_TITLE[type]}
            </Paragraph>
          </FilterButton>
        ))}
      </Row>
      <Row justifyContent="flex-end" flex={1}>
        <Button
          onClick={() => setUrlValue({ dateRange: '' })}
          variant="link"
          ml
          mlValue={theme.spacing.xs}
        >
          Back to main view
        </Button>
      </Row>
    </TableHeader>
  );
};

export default Tabs;
