import { components } from 'react-select';

const Option = (props: any) => (
  <components.Option {...props}>
    {props.data.icon && (
      <svg width="24px" height="24px">
        <use xlinkHref={`#${props.data.icon}`} />
      </svg>
    )}
    {props.data.label}
  </components.Option>
);

export default Option;
