import { FC } from 'react';
import { Col, Subtitle, FundingBankTransfer } from 'components';
import { parseIntoCurrencyString } from 'utils';
import { useStoreState } from 'state';
import { IRateContract } from 'types';

interface IPaymentCredentials {
  selectedRateContract: IRateContract;
}

const PaymentCredentials: FC<IPaymentCredentials> = ({
  selectedRateContract,
}) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const sellCurrency = currencyByCode(selectedRateContract?.sellCurrency);

  if (!sellCurrency) {
    return null;
  }

  return (
    <Col mt>
      <Subtitle variant="bold" mb>
        {`Fund the prepayment of ${
          sellCurrency.symbol
        } ${parseIntoCurrencyString(
          selectedRateContract?.prepaymentAmount,
          sellCurrency.precision
        )} to`}
      </Subtitle>

      <FundingBankTransfer sellCurrency={sellCurrency.code} />
    </Col>
  );
};

export default PaymentCredentials;
