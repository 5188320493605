import {
  MultiFactorError,
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
  getAuth,
  getMultiFactorResolver,
} from 'firebase/auth';
import { FC, useEffect, useMemo, useState } from 'react';
import { Notify } from 'utils';
import Phone from './components/Phone/Phone';
import OneTimePassword from './components/OneTimePassword/OneTimePassword';
import { Title } from 'components';

interface IOwnProps {
  multiFactorError: MultiFactorError;
  moveBackToSignIn: () => void;
}

const MultiFactorAuth: FC<IOwnProps> = ({
  multiFactorError,
  moveBackToSignIn,
}) => {
  const mfaResolver = useMemo(
    () => getMultiFactorResolver(getAuth(), multiFactorError),
    [multiFactorError]
  );
  const [activeFactor, setActiveFactor] = useState(mfaResolver.hints[0]);

  useEffect(() => {
    if (!mfaResolver.hints.length) {
      Notify.error('MFA is not enrolled, please contact support');
    }
  }, [mfaResolver.hints]);

  const onSwitchFactor = (currentFactorUid: string) => {
    const otherFactors = mfaResolver.hints.filter(
      (hint) => hint.uid !== currentFactorUid
    );

    if (otherFactors.length) {
      setActiveFactor(otherFactors[0]);
    } else {
      Notify.error('Factor not found, please contact support.');
    }
  };

  if (activeFactor?.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    return (
      <Phone
        multiFactor={activeFactor}
        mfaResolver={mfaResolver}
        onSwitchFactor={onSwitchFactor}
        moveBackToSignIn={moveBackToSignIn}
      />
    );
  }

  if (activeFactor?.factorId === TotpMultiFactorGenerator.FACTOR_ID) {
    return (
      <OneTimePassword
        onSwitchFactor={onSwitchFactor}
        factorUid={activeFactor.uid}
        mfaResolver={mfaResolver}
      />
    );
  }

  return (
    <Title variant="h4">
      Unsupported two factor authentication method. Please, contact support.
    </Title>
  );
};

export default MultiFactorAuth;
