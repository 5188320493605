import styled, { css } from 'styled-components';

import { Paragraph } from '../Typography/Typography';
import { Row } from '../Row/Row';
import { Col } from '../Col/Col';

export const StaleRecipientWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.color.greyLight_1};
    background: ${theme.color.white};
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    ${Row} {
      margin-bottom: 0px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .bank-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 190px;
      overflow: hidden;
    }

    ${Col} {
      flex: 0 0 80%;
      max-width: 80%;
    }
  `
);

interface RecipientHeaderProps {
  isSelected?: boolean;
  isDisabled?: boolean;
}

export const RecipientHeader = styled(Row)<RecipientHeaderProps>(
  ({ theme, isSelected = false, isDisabled = false }) => css`
    padding: 16px;
    border-bottom: 1px solid ${theme.color.greyLight_1};
    margin-bottom: 0;

    ${isSelected && `background: ${theme.color.emeraldLight}`};
    ${isDisabled && `background: ${theme.color.greyLight_1}`};

    ${Row} {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  `
);

export const RecipientContent = styled.div(
  ({ theme }) => css`
    padding: 12px 16px;
    background: ${theme.color.greyLight_4};

    ${Row} {
      &:not(:last-child) {
        margin-bottom: 7px;
        justify-content: space-between;
      }
    }

    ${Paragraph} {
      line-height: 24px;
      display: flex;
      align-items: center;
    }
  `
);

export const RecipientButtons = styled(Row)(
  ({ theme }) => css`
    padding: 16px;
    border-top: 1px solid ${theme.color.greyLight_1};
    justify-content: flex-start;
  `
);

export const TitleParagraph = styled(Paragraph)<{ isDropdown?: boolean }>(
  ({ isDropdown = false }) => css`
    padding-left: 28px;
    line-height: 24px;
    margin-bottom: ${isDropdown ? '' : '15px'};
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 16px;
      left: 16px;
    }
  `
);

export const ShowSvg = styled.svg`
  margin-left: 8px;
  use {
    pointer-events: none;
  }
`;
