import { FC } from 'react';
import {
  TwoSidesTemplateRightCol,
  TwoSidesTemplateLeftCol,
  TwoSidesTemplateWrapper,
  TwoSidesTemplateContentContainer,
  TwoSidesTemplateCrossButton,
} from './TwoSidesTemplate.styles';
import Icon from '../../shared/Icon/Icon';

interface OwnProps {
  onClose: () => void;
  leftColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
}

const TwoSidesTemplate: FC<OwnProps> = ({
  onClose,
  leftColumn,
  rightColumn,
}) => (
  <TwoSidesTemplateWrapper>
    {!!leftColumn && (
      <TwoSidesTemplateLeftCol>
        <TwoSidesTemplateContentContainer>
          {leftColumn}
        </TwoSidesTemplateContentContainer>
      </TwoSidesTemplateLeftCol>
    )}

    <TwoSidesTemplateRightCol>
      <TwoSidesTemplateContentContainer>
        <TwoSidesTemplateCrossButton variant="link" onClick={onClose}>
          <Icon icon="cross-ico" />
        </TwoSidesTemplateCrossButton>
        {rightColumn}
      </TwoSidesTemplateContentContainer>
    </TwoSidesTemplateRightCol>
  </TwoSidesTemplateWrapper>
);

export default TwoSidesTemplate;
