import {
  Subtitle,
  Col,
  Paragraph,
  Row,
  StaleSwitch,
  StaleSelectMenu,
  StaleInfo,
  Loader,
} from 'components';
import Field from 'components/shared/Field/Field.styles';
import { StyledForm } from 'components/shared/Form/Form.styles';
import InputBase from 'components/shared/InputBase/InputBase';
import { InputErrorMessage } from 'components/shared/InputErrorMessage/InputErrorMessage.styles';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form7';
import { setEntityApprovalSettings } from 'services/firebase/entities';
import { useStoreActions, useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { ERROR_MESSAGES } from 'variables';
import { getValuesFromInputs } from '../../utils';
import { TInputs } from '../../ApprovalSettings';

interface IOwnProps {
  isApprovalFlowEnabled: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  form: UseFormReturn<TInputs, any>;
  switchApprovalFlowForEntity: () => Promise<void>;
}

export const ApprovalFlowSettings: FC<IOwnProps> = ({
  isApprovalFlowEnabled,
  setIsLoading,
  form,
  switchApprovalFlowForEntity,
}) => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;
  const { entityId, entityUsers, userEntity } = useStoreState(
    ({ UserState }) => UserState
  );
  const { recipients } = useStoreState(
    ({ RecipientsState }) => RecipientsState
  );
  const { getEntityUsers } = useStoreActions(({ UserState }) => UserState);

  const hasApprovalFlow = !!userEntity.approvalSettings?.hasApprovalFlow;

  // transform recipients to what select components expect
  const recipientsForSelect = recipients.map((recipient) => ({
    value: recipient.id,
    label: recipient.recipientName,
  }));

  const entityUsersForSelect = entityUsers.map((entityUser) => ({
    value: entityUser.id,
    label: entityUser.name,
  }));

  const watchAutoApproveEnabled = watch('autoApproveEnabled');
  const watchExceptionalApprovalEnabled = watch('exceptionalApprovalEnabled');
  const watchTwoApprovers = watch('twoApprovers');

  const onContinue = async (values: TInputs) => {
    try {
      if (entityId) {
        const {
          requiredNumberOfApprovals,
          autoApproveAmount,
          exceptionalAmount,
          autoApproveRecipientList,
          approver1List,
          approver2List,
          exceptionalRecipientList,
          exceptionalApproverList,
        } = getValuesFromInputs(values);

        if (isApprovalFlowEnabled !== hasApprovalFlow) {
          await switchApprovalFlowForEntity();
        }

        setIsLoading(true);

        await setEntityApprovalSettings({
          entityId,
          settings: {
            ...values,
            requiredNumberOfApprovals,
            autoApproveAmount,
            // defaultAmount,
            exceptionalAmount,
            autoApproveRecipientList,
            approver1List,
            approver2List,
            exceptionalRecipientList,
            exceptionalApproverList,
          },
        });
        setIsLoading(false);
      } else {
        console.log('entityId is null. not saving settings');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onError = (error: any) => {
    console.log(error);
  };

  // ensure we have an up to date list of entity users
  useEffect(() => {
    if (entityId) {
      getEntityUsers({ entityId });
    }
  }, [entityId, getEntityUsers]);

  if (!entityUsers) {
    return <Loader size="large" />;
  }

  return (
    <Col>
      <StyledForm
        alignItems="stretch"
        onSubmit={handleSubmit(onContinue, onError)}
        id="approvalsForm"
      >
        {/** Level 1 Auto Approve settings */}
        <Col mb>
          <Field fluid>
            <Row mr mb>
              <Subtitle variant="bold" mr>
                Rule-based automatic approvals
              </Subtitle>
              <StaleInfo mode="hover" strategy="fixed">
                <Paragraph color="white">
                  Bills meeting the criteria will be automatically approved once
                  they are submitted for approval, unless they fall under an
                  exceptional condition.
                </Paragraph>
              </StaleInfo>
            </Row>

            <Controller
              name="autoApproveEnabled"
              control={control}
              render={({ field: { value, onChange } }) => (
                <StaleSwitch
                  id="autoApproveEnabled"
                  isOn={!!value}
                  handleToggle={onChange}
                />
              )}
            />
          </Field>

          {watchAutoApproveEnabled && (
            <Row mb gap={theme.spacing.l}>
              <Field fluid flexDirection="column" justifyContent="flex-start">
                <Paragraph mb>For bills less than or equal to:</Paragraph>
                <Controller
                  name="autoApproveAmount"
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputBase
                      value={value}
                      onChange={onChange}
                      label={`Amount (in ${userEntity.entityCurrency})`}
                      type="number"
                      error={errors.autoApproveAmount?.message}
                    />
                  )}
                />
                {errors.autoApproveAmount?.message && (
                  <InputErrorMessage mt mtValue={theme.spacing.xxs}>
                    {errors.autoApproveAmount?.message}
                  </InputErrorMessage>
                )}
              </Field>

              <Field fluid flexDirection="column">
                <Paragraph mb>Or these recipients:</Paragraph>
                <Controller
                  name="autoApproveRecipientList"
                  control={control}
                  rules={{
                    validate: (value: TInputs['autoApproveRecipientList']) => {
                      if (watchAutoApproveEnabled && !value) {
                        return 'Choose recipient(s)';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <StaleSelectMenu
                      id="autoApproveRecipientList"
                      label="Recipients to auto-approve"
                      name="autoApproveRecipientList"
                      isMulti={true}
                      data={recipientsForSelect}
                      value={value}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        if (!value.length) {
                          onChange(null);
                        } else {
                          onChange(value);
                        }
                      }}
                    />
                  )}
                />
              </Field>
            </Row>
          )}
        </Col>
        {/** Level 2 Default settings */}
        <Col mb>
          <Subtitle variant="bold" mb mbValue={theme.spacing.m}>
            Standard approval process
          </Subtitle>

          <Row mb gap={theme.spacing.l}>
            <Field fluid flexDirection="column">
              <Paragraph mb>These users can approve:</Paragraph>
              <Controller
                name="approver1List"
                control={control}
                rules={{
                  validate: (value: TInputs['approver1List']) => {
                    if (!watchAutoApproveEnabled && !value) {
                      return 'Choose approver(s)';
                    }
                    return true;
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <StaleSelectMenu
                    id="approver1List"
                    label="Approvers"
                    name="approver1List"
                    isMulti={true}
                    data={entityUsersForSelect}
                    value={value}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      if (!value.length) {
                        onChange(null);
                      } else {
                        onChange(value);
                      }
                    }}
                  />
                )}
              />
            </Field>

            <Field fluid flexDirection="column">
              <Row>
                <Paragraph mr>These users can give 2nd approval:</Paragraph>
                <Controller
                  name="twoApprovers"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <StaleSwitch
                      id="twoApprovers"
                      isOn={!!value}
                      handleToggle={onChange}
                    />
                  )}
                />
              </Row>
              <Controller
                name="approver2List"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StaleSelectMenu
                    id="approver2List"
                    label="Approvers"
                    name="approver2List"
                    isMulti={true}
                    data={entityUsersForSelect}
                    value={value}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      if (!value.length) {
                        onChange(null);
                      } else {
                        onChange(value);
                      }
                    }}
                    disabled={!watchTwoApprovers}
                  />
                )}
              />
            </Field>
          </Row>
        </Col>
        {/** Level 3 Exceptional Approval settings */}
        <Col>
          <Field fluid>
            <Row mr mb>
              <Subtitle variant="bold" mr>
                Exceptional Approvals
              </Subtitle>
              <StaleInfo mode="hover" strategy="fixed">
                <Paragraph color="white">
                  Bills meeting these criteria will require a separate approval
                  by specified individuals instead of the standard approval.
                </Paragraph>
              </StaleInfo>
            </Row>

            <Controller
              name="exceptionalApprovalEnabled"
              control={control}
              render={({ field: { value, onChange } }) => (
                <StaleSwitch
                  id="exceptionalApprovalEnabled"
                  isOn={value}
                  handleToggle={onChange}
                />
              )}
            />
          </Field>

          {watchExceptionalApprovalEnabled && (
            <>
              <Row gap={theme.spacing.l}>
                <Field fluid mb flexDirection="column">
                  <Paragraph mb>For bills greater than:</Paragraph>
                  <Controller
                    name="exceptionalAmount"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <InputBase
                        value={value}
                        onChange={onChange}
                        type="number"
                        label={`Amount (in ${userEntity.entityCurrency})`}
                        error={errors.exceptionalAmount?.message}
                      />
                    )}
                  />
                </Field>
                <Field fluid mb flexDirection="column">
                  <Paragraph mb>Or these Recipients:</Paragraph>
                  <Controller
                    name="exceptionalRecipientList"
                    control={control}
                    rules={{
                      validate: (
                        value: TInputs['exceptionalRecipientList']
                      ) => {
                        if (watchExceptionalApprovalEnabled && !value) {
                          return 'Choose recipient(s)';
                        }
                        return true;
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <StaleSelectMenu
                        id="exceptionalRecipientList"
                        label="Recipients"
                        name="Recipients"
                        isMulti={true}
                        data={recipientsForSelect}
                        value={value}
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          if (!value.length) {
                            onChange(null);
                          } else {
                            onChange(value);
                          }
                        }}
                      />
                    )}
                  />
                </Field>
              </Row>

              <Row gap={theme.spacing.l} alignItems="flex-start">
                <Row flex={1}>
                  <Paragraph>
                    These users can give exceptional approvals:
                  </Paragraph>
                </Row>

                <Controller
                  name="exceptionalApproverList"
                  control={control}
                  rules={{
                    validate: (value: TInputs['exceptionalApproverList']) => {
                      if (watchExceptionalApprovalEnabled && !value) {
                        return 'Choose approver(s)';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <StaleSelectMenu
                      id="exceptionalApproverList"
                      label="Approvers"
                      name="exceptionalApproverList"
                      isMulti={true}
                      data={entityUsersForSelect}
                      value={value}
                      style={{ flex: '1' }}
                      onChange={(value) => {
                        if (!value.length) {
                          onChange(null);
                        } else {
                          onChange(value);
                        }
                      }}
                    />
                  )}
                />
              </Row>
            </>
          )}
        </Col>
      </StyledForm>
    </Col>
  );
};

export default ApprovalFlowSettings;
