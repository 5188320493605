import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';

const useRecaptchaVerifier = (containerId: string) => {
  const [
    recaptchaVerifier,
    setRecaptchaVerifier,
  ] = useState<firebase.auth.RecaptchaVerifier | null>(null);

  useEffect(() => {
    if (!recaptchaVerifier) {
      const container = new firebase.auth.RecaptchaVerifier(containerId, {
        size: 'invisible',
      });

      setRecaptchaVerifier(container);
    }
  }, [recaptchaVerifier, containerId]);

  return recaptchaVerifier;
};

export default useRecaptchaVerifier;
