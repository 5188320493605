import Button from 'components/shared/Button/Button';
import Popup from 'components/shared/Popup/Popup';
import { Row } from 'components/shared/Row/Row';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import { FC, useState } from 'react';
import { unlinkInvoiceContact } from 'services/firebase/invoices';
import { IInvoiceFromSearch } from 'types';
import { errorHandler } from 'utils/errors';

interface IOwnProps {
  invoiceId: string;
  onClose: () => void;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
}

const PopupUnlinkContact: FC<IOwnProps> = ({
  invoiceId,
  onClose,
  updateInMemoryInvoices,
}) => {
  const [isUnlinking, setIsUnlinking] = useState(false);
  const onConfirm = async () => {
    try {
      setIsUnlinking(true);
      const response = await unlinkInvoiceContact(invoiceId);

      if (response.data.data) {
        updateInMemoryInvoices((invoices) =>
          invoices.map(
            (existingInvoice) =>
              response.data.data?.find(
                (updatedInvoice) => updatedInvoice.id === existingInvoice.id
              ) ?? existingInvoice
          )
        );
      }

      setIsUnlinking(false);
      onClose();
    } catch (error) {
      setIsUnlinking(false);
      errorHandler(error);
    }
  };

  return (
    <Popup
      width="539px"
      onClose={onClose}
      HeaderContent={<Title variant="h4">Unlink contact</Title>}
      FooterContent={
        <Row flex={1} justifyContent="space-between">
          <Button
            isLoading={isUnlinking}
            disabled={isUnlinking}
            onClick={onConfirm}
          >
            Confirm
          </Button>
          <Button disabled={isUnlinking} variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Row>
      }
    >
      <Paragraph>
        This will unlink contact from this invoice and invoices with the same
        contact. This contact will no longer be associated with these invoices
        in the future, unless you link it again to the invoice.
      </Paragraph>
    </Popup>
  );
};

export default PopupUnlinkContact;
