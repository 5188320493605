import { Chart } from 'chart.js';
import { getElementAtEvent } from 'react-chartjs-2';

export const getElementFromEvent = (
  chart: Chart,
  event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
) => {
  const elements = getElementAtEvent(chart, event);

  if (!elements.length) {
    return;
  }

  return elements[0];
};
