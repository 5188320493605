import styled from 'styled-components';
import { Col } from 'components';
import Card from 'components/shared/Card/Card.styles';

export const CardWrapper = styled(Card)`
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.color.greyLight_1};
  padding: 0;
`;

export const CardContent = styled(Col)`
  flex: 1;
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.m}`};
  gap: ${({ theme }) => theme.spacing.xs};
`;

export const CardFooter = styled(CardContent)`
  flex: unset;
  border-top: 1px solid ${({ theme }) => theme.color.greyLight_1};
`;
