import { useState, FC, ReactNode } from 'react';
import { Subtitle, Icon, Paragraph, Expansion } from 'components';
import {
  AccordionWrapper,
  AccordionHeader,
  ArrowWrapper,
  AccordionContent,
  List,
  ListLi,
} from './Accordion.styles';
import { useTheme } from 'styled-components';

interface OwnProps {
  data: {
    id: number;
    title?: string;
    text: string | ReactNode;
  }[];
  isList?: boolean;
  label: string;
}

const Accordion: FC<OwnProps> = ({ data = [], isList, label }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const renderContent = () =>
    isList ? (
      <List>
        {data.map(({ id, text }) => (
          <ListLi key={id}>
            <Icon
              width="13px"
              height="13px"
              fill={theme.color.emeraldDark}
              icon="star-ico"
            />
            <Paragraph ml mb mbValue={theme.spacing.s} color="white">
              {text}
            </Paragraph>
          </ListLi>
        ))}
      </List>
    ) : (
      data.map(({ id, title, text }) => (
        <Paragraph key={id} color="white">
          <strong>{title}</strong> {text}
        </Paragraph>
      ))
    );

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={() => setIsOpen(!isOpen)}>
        <Subtitle variant="bold" color="white">
          {label}
        </Subtitle>
        <ArrowWrapper isOpen={isOpen}>
          <Icon icon="arrow-down" fill="white" />
        </ArrowWrapper>
      </AccordionHeader>
      <Expansion isOpen={isOpen}>
        <AccordionContent>{data && renderContent()}</AccordionContent>
      </Expansion>
    </AccordionWrapper>
  );
};

export default Accordion;
