import { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Firebase } from 'services';
import { CreateUserLogParams } from 'services/firebase';
import { StateModel } from 'state';

let logger: any;

export const setLoggerRef = (ref: any) => {
  logger = ref;
};

export const log = ({
  triggeredBy,
  type,
  message,
  uiComponentStack: additionalInfo,
  url,
}: Omit<CreateUserLogParams, 'userId'>) => {
  logger.log({
    triggeredBy,
    type,
    message,
    additionalInfo,
    url,
  });
};

class Logger extends PureComponent<PropsFromRedux> {
  log = ({
    type,
    triggeredBy,
    message,
    uiComponentStack,
    url,
  }: Omit<CreateUserLogParams, 'userId'>) => {
    const { isDebuggable, userId } = this.props;

    if (!userId) {
      return;
    }

    if (isDebuggable) {
      switch (type) {
        case 'info':
          console.log(triggeredBy, message);
          Firebase.createUserLog({
            userId,
            type: 'info',
            triggeredBy,
            message,
            uiComponentStack,
            url,
          });
          break;
        case 'warn':
          console.warn(triggeredBy, message);
          Firebase.createUserLog({
            userId,
            type: 'warn',
            triggeredBy,
            message,
            uiComponentStack,
            url,
          });
          break;
        case 'error':
          console.error(triggeredBy, message);
          Firebase.createUserLog({
            userId,
            type: 'error',
            triggeredBy,
            message,
            uiComponentStack,
            url,
          });
          break;
        default:
          return;
      }
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state: StateModel) => ({
  isDebuggable: state.UserState.user?.debuggable,
  userId: state.UserState.user?.id,
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Logger);
