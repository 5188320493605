// TODO: consider moving logic for determining if contact is suspicious to the backend
import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Firebase } from 'services';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import {
  Nullable,
  IContact,
  IEntityDetails,
  ICountry,
  TScamQuestions,
  PartialContact,
  isContact,
} from 'types';
import { isContactCountrySuspiciousCheck } from '../../utils';
import StaleInputRadioNew from 'components/shared/StaleInputRadioNew/StaleInputRadioNew';
import { Col } from 'components/shared/Col/Col';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import Icon from 'components/shared/Icon/Icon';
import { SCAM_QUESTIONS_DATA } from '../../consts';
import { SCAM_HELP_URL } from 'variables';
import { errorHandler } from 'utils/errors';
import Link from 'components/shared/Link/Link';
import { DeepMap } from 'react-hook-form';
import { AddContactInputs } from '../../AddContact';

interface OwnProps {
  setIsOpenContactDetails: Dispatch<SetStateAction<boolean>>;
  setShowExtendedScamAlert: Dispatch<SetStateAction<boolean>>;
  setScamAlertAnswer: Dispatch<SetStateAction<TScamQuestions | undefined>>;
  setIsLoadingCompanyDetails: Dispatch<SetStateAction<boolean>>;
  showExtendedScamAlert: boolean;
  dirtyFields: DeepMap<AddContactInputs, true>;
  scamAlertAnswer?: string;
  recipientCountry?: ICountry;
  recipientForEdit: Nullable<IContact | PartialContact>;
}

const ScamAlert: FC<OwnProps> = ({
  setIsOpenContactDetails,
  setShowExtendedScamAlert,
  setScamAlertAnswer,
  setIsLoadingCompanyDetails,
  dirtyFields,
  showExtendedScamAlert,
  scamAlertAnswer,
  recipientCountry,
  recipientForEdit,
}) => {
  const theme = useTheme();
  const { entityId, isEntityOnboarded } = useStoreState(
    ({ UserState }) => UserState
  );
  const [entityCompanyDetails, setEntityCompanyDetails] = useState<
    Pick<IEntityDetails, 'countriesSendingMoneyTo'>
  >();

  useEffect(() => {
    const getEntityCompanyDetails = async () => {
      try {
        if (!entityId) {
          return;
        }

        if (!isEntityOnboarded) {
          setIsLoadingCompanyDetails(false);
          return;
        }

        setIsLoadingCompanyDetails(true);

        const response = await Firebase.getEntityCompanyDetails(entityId);

        if (response?.data?.success) {
          setEntityCompanyDetails(response.data.data);
        } else {
          Notify.error(response?.data?.message ?? '');
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingCompanyDetails(false);
      }
    };

    getEntityCompanyDetails();
  }, [entityId, isEntityOnboarded, setIsLoadingCompanyDetails]);

  useEffect(() => {
    /**
     * Don't both run scam alert check if contact is not onboarded.
     * Creating a full recipient will be disabled until the entity is onboarded.
     *  */
    if (recipientForEdit && isContact(recipientForEdit) && isEntityOnboarded) {
      const isContactCountrySuspicious = isContactCountrySuspiciousCheck({
        entityKnownCountries: entityCompanyDetails?.countriesSendingMoneyTo,
        recipientCountry,
        recipientForEdit,
      });
      const contactPaymentDetailsChanged =
        dirtyFields.bicSwift ||
        dirtyFields.accountNumber ||
        dirtyFields.routingNumber ||
        dirtyFields.routingNumber2;

      if (contactPaymentDetailsChanged || isContactCountrySuspicious) {
        setIsOpenContactDetails(false);
        setScamAlertAnswer(undefined);

        // required for smooth UX, otherwise popup jumps because of the height change
        setTimeout(() => {
          setShowExtendedScamAlert(true);
        }, 800);
      } else {
        setIsOpenContactDetails(true);
        setShowExtendedScamAlert(false);
      }
    } else {
      const isContactCountrySuspicious = isContactCountrySuspiciousCheck({
        entityKnownCountries: entityCompanyDetails?.countriesSendingMoneyTo,
        recipientCountry,
        recipientForEdit,
      });

      if (isContactCountrySuspicious) {
        setIsOpenContactDetails(false);

        // required for smooth UX, otherwise popup jumps because of the height change
        setTimeout(() => {
          setShowExtendedScamAlert(true);
        }, 800);
      } else {
        setIsOpenContactDetails(true);
        setShowExtendedScamAlert(false);
      }
    }
  }, [
    dirtyFields.accountNumber,
    dirtyFields.bicSwift,
    dirtyFields.routingNumber,
    dirtyFields.routingNumber2,
    entityCompanyDetails?.countriesSendingMoneyTo,
    isEntityOnboarded,
    recipientCountry,
    recipientForEdit,
    setIsOpenContactDetails,
    setScamAlertAnswer,
    setShowExtendedScamAlert,
  ]);

  return (
    <>
      <Title mt variant="h3">
        <Icon fill="red" icon="warning-ico" /> Take five. Could it be fake?
      </Title>

      <Paragraph>
        Criminals often target email, mobile, and chat communications by
        impersonating suppliers and manipulating businesses into sending funds
        into malicious hands. Always verify who you are sending money to -
        recovering international payments is notoriously hard.
        {showExtendedScamAlert && (
          <>
            {' '}
            <Link display="inline-flex" target="_blank" href={SCAM_HELP_URL}>
              Read more.
            </Link>
          </>
        )}
      </Paragraph>

      {!showExtendedScamAlert && (
        <Paragraph mt>
          <Link display="inline-flex" target="_blank" href={SCAM_HELP_URL}>
            Read our recommendations
          </Link>{' '}
          how reduce the risk.
        </Paragraph>
      )}

      {showExtendedScamAlert && (
        <>
          <Paragraph mt>
            Which steps did you take to verify payment details for this
            recipient:
          </Paragraph>

          <Col mt gap={theme.spacing.s}>
            {SCAM_QUESTIONS_DATA.map(({ id, value, name }) => (
              <StaleInputRadioNew
                key={id}
                id={value}
                label={name}
                checked={scamAlertAnswer === value}
                onChange={() => setScamAlertAnswer(value)}
              />
            ))}
          </Col>
        </>
      )}
    </>
  );
};

export default ScamAlert;
