import { action, Action } from 'easy-peasy';

export interface SessionStateModel {
  isSessionExpired: boolean;
  setIsSessionExpired: Action<SessionStateModel, boolean>;
}

export const SessionState: SessionStateModel = {
  isSessionExpired: false,
  setIsSessionExpired: action((state, payload) => {
    state.isSessionExpired = payload;
  }),
};
