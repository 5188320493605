import { FC, useCallback, useEffect, useState } from 'react';
import CashflowAtRisk from './components/CashflowAtRisk/CashflowAtRisk';
import { useTheme } from 'styled-components';
import MonthlyBreakdown from './components/MonthlyBreakdown/MonthlyBreakdown';
import DataTable from './components/DataTable/DataTable';
import { BottomContentWrapper, TopContentWrapper } from './Risks.styles';
import { getCashflowsRisksPerCurrency } from 'services/firebase/analysis';
import useUrlValues from 'hooks/useUrlValues';
import { INetworthValues, TCashflowAtRiskNew } from 'types';
import { errorHandler } from 'utils/errors';
import { InlineLoader, IntegrationSettings, Loader, Row } from 'components';
import { Route, Switch, useRouteMatch } from 'react-router';
import ReduceRisks from 'pages/ReduceRisks/ReduceRisks';
import { TRisksDataPeriod } from './types';
import BudgetRatePopup from './components/BudgetRatePopup/BudgetRatePopup';
import { isEntityHasCashflows } from 'services/cashflows';
import { IntegrationWrapper } from 'pages/Invoices/Invoices.styles';

const Risks: FC = () => {
  const theme = useTheme();
  const { path } = useRouteMatch();
  const { currency, period, popupType, setUrlValue } = useUrlValues(
    'currency',
    'period',
    'popupType'
  );
  const [isCheckingCashflows, setIsCheckingCashflows] = useState(true);
  const [hasCashflows, setHasCashflows] = useState(false);

  useEffect(() => {
    isEntityHasCashflows()
      .then((result) => {
        setHasCashflows(!!result.data.data);
        setIsCheckingCashflows(false);
      })
      .catch(errorHandler);
  }, []);

  const [cashflowsRisksData, setCashflowsRisksData] = useState<{
    perPeriod: TCashflowAtRiskNew[];
    networthValues: INetworthValues;
  }>();
  const [
    isLoadingCashflowsRisksData,
    setIsLoadingCashflowsRisksData,
  ] = useState(true);
  const isLoadingAndHaveData =
    isLoadingCashflowsRisksData && !!cashflowsRisksData;

  const getCashflowsRisksPerCurrencyHandler = useCallback(
    async (currencyCode: string, period: TRisksDataPeriod) => {
      try {
        setIsLoadingCashflowsRisksData(true);

        const result = await getCashflowsRisksPerCurrency({
          currencyCode,
          period,
        });

        setCashflowsRisksData(result.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingCashflowsRisksData(false);
      }
    },
    []
  );

  useEffect(() => {
    if (currency && period) {
      getCashflowsRisksPerCurrencyHandler(currency, period as TRisksDataPeriod);
    }
  }, [currency, getCashflowsRisksPerCurrencyHandler, period]);

  if (isCheckingCashflows) {
    return <Loader size="large" />;
  }

  if (!hasCashflows) {
    return (
      <IntegrationWrapper>
        <IntegrationSettings />
      </IntegrationWrapper>
    );
  }

  return (
    <Switch>
      <Route exact path={path}>
        <>
          <TopContentWrapper
            columnGap={theme.spacing.xl}
            mb
            mbValue={theme.spacing.xl}
          >
            <CashflowAtRisk
              cashflowsRisksData={cashflowsRisksData}
              getCashflowsRisksPerCurrencyHandler={
                getCashflowsRisksPerCurrencyHandler
              }
            />
            <MonthlyBreakdown
              cashflowsRisksData={cashflowsRisksData?.perPeriod ?? []}
              isLoadingCashflowsRisksData={isLoadingCashflowsRisksData}
            />
          </TopContentWrapper>

          <BottomContentWrapper>
            <DataTable
              cashflowsRisksData={cashflowsRisksData?.perPeriod ?? []}
              isLoadingCashflowsRisksData={isLoadingCashflowsRisksData}
              isLoadingAndHaveData={isLoadingAndHaveData}
            />

            {isLoadingAndHaveData && (
              <Row
                style={{
                  position: 'absolute',
                  top: 12,
                  left: 12,
                }}
              >
                <InlineLoader />
              </Row>
            )}
          </BottomContentWrapper>

          {!!currency &&
            !!cashflowsRisksData &&
            popupType === 'setBudgetRates' && (
              <BudgetRatePopup
                onClose={() =>
                  setUrlValue({
                    popupType: '',
                  })
                }
                currencyCode={currency}
                cashflowsRisksData={cashflowsRisksData.perPeriod}
                getCashflowsRisksPerCurrency={
                  getCashflowsRisksPerCurrencyHandler
                }
              />
            )}
        </>
      </Route>
      <Route exact path={`${path}/reduce-recommendations`}>
        {!!cashflowsRisksData && (
          <ReduceRisks cashflowsRisksData={cashflowsRisksData} />
        )}
      </Route>
    </Switch>
  );
};

export default Risks;
