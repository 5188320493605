interface IHfGuruStatus {
  completed: boolean;
  deferUntil?: string;
}
export enum HF_GURU_TASKS {
  register = 'register',
  integrate = 'integrate',
  healthCheck = 'healthCheck',
  riskPreference = 'riskPreference',
  manageRisk = 'manageRisk',
  automateProcesses = 'automateProcesses',
  removeDeferrals = 'removeDeferrals',
}

type hfGuruTaskKeys = keyof typeof HF_GURU_TASKS;

export const isStringInHfGuruTaskKeys = (
  value: string | hfGuruTaskKeys
): value is hfGuruTaskKeys => Object.keys(HF_GURU_TASKS).includes(value);

export type IHfGuruStatuses = Record<HF_GURU_TASKS, IHfGuruStatus>;

export enum HF_GURU_ACCOUNTANT_TASKS {
  integrateClient = 'integrateClient',
  inviteYourTeam = 'inviteYourTeam',
  addApprovalProcess = 'addApprovalProcess',
  onboardClient = 'onboardClient',
  finishClientPaymentRun = 'finishClientPaymentRun',
  removeDeferrals = 'removeDeferrals',
}

type hfAccountantGuruTaskKeys = keyof typeof HF_GURU_ACCOUNTANT_TASKS;

export const isStringInHfAccountantGuruTaskKeys = (
  value: string | hfAccountantGuruTaskKeys
): value is hfAccountantGuruTaskKeys =>
  Object.keys(HF_GURU_ACCOUNTANT_TASKS).includes(value);

export type IHfGuruAccountantStatuses = Record<
  HF_GURU_ACCOUNTANT_TASKS,
  IHfGuruStatus
>;

export interface IHfGuru {
  completed: boolean;
  basic: boolean;
  progress: number;
  statuses?: IHfGuruStatuses;
}

export interface IHfAccountantGuru {
  completed: boolean;
  basic: boolean;
  progress: number;
  statuses?: IHfGuruAccountantStatuses;
}

export interface IGuruCard<T> {
  id: T;
  icon: string;
  title: string;
  text: string;
  link: string;
  buttonText: string;
  order: number;
}
