import {
  AxiosPrivateFirebaseInstance,
  AxiosPublicFirebaseInstance,
} from 'settings';
import { IResponse } from 'types';

/**
 * Gets a auth url using a passed in redirect url.
 */
export const getAuthUrlForSignUpFromExternalSystem = async ({
  system,
  nextUri,
}: {
  system: 'xero' | 'dynamics';
  nextUri: string;
}) =>
  AxiosPublicFirebaseInstance.post<IResponse<string>>(
    `/bff/sign-up/external/connection/${system}`,
    { nextUri }
  );

export const finishSignUp = async ({
  entityId,
  password,
}: {
  entityId: string;
  password: string;
}) =>
  AxiosPublicFirebaseInstance.post<IResponse<void>>(
    `/bff/sign-up/external/complete`,
    { password, entityId }
  );

export const createAnotherEntityForExistingUser = async (payload: {
  entityId: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationName: string;
  organisationCountryCode: string;
}) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<{
      entityId: string;
      email: string;
      companyName: string;
    }>
  >(`/bff/sign-up/external/existing-user`, payload);
