import styled from 'styled-components';
import { Col } from 'components/shared/Col/Col';

export const StyledOpenBankingQRCol = styled(Col)<{ maxWidth?: string }>`
  max-width: ${({ maxWidth = '80px' }) => maxWidth};
  min-width: 80px;
`;

export const StyledOpenBankingQRImage = styled.img`
  width: 100%;
`;

export const StyledOpenBankingInstitutionImage = styled.img`
  width: 70px;
`;
