import { FC } from 'react';
import { Span } from '../Typography/Typography';
import { StyledPill, TPillVariant } from './Pill.styles';

interface OwnProps {
  text: string;
  variant?: TPillVariant;
}

const Pill: FC<OwnProps> = ({ text, variant = 'standard' }) => {
  return (
    <StyledPill variant={variant}>
      <Span>{text}</Span>
    </StyledPill>
  );
};

export default Pill;
