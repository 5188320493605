import { FC } from 'react';
import { Row, ContextMenu, PermissionsChecker } from 'components';
import { ContextMenuItem } from 'components/shared/ContextMenu/ContextMenu';
import { useTheme } from 'styled-components';
import { getInvoiceManualUploadLink } from 'utils';
import { useHistory } from 'react-router';
import useUrlValues from 'hooks/useUrlValues';

interface IOwnProps {
  date: string;
}

const DateThreeDotsMenu: FC<IOwnProps> = ({ date }) => {
  const theme = useTheme();
  const history = useHistory();
  const { currency: currencyCode, setUrlValue } = useUrlValues('currency');

  const list: ContextMenuItem[] = [
    {
      id: 'showContributors',
      title: 'Show contributors',
      onClick: () =>
        setUrlValue({
          dateRange: date,
        }),
    },
    ...(currencyCode
      ? [
          {
            id: 'payable',
            title: 'Add payable',
            onClick: () =>
              history.push(
                getInvoiceManualUploadLink({
                  tab: 'manual',
                  dataType: 'Bill',
                  currency: currencyCode,
                })
              ),
          },
          {
            id: 'receivable',
            title: 'Add receivable',
            onClick: () =>
              history.push(
                getInvoiceManualUploadLink({
                  tab: 'manual',
                  dataType: 'Invoice',
                  currency: currencyCode,
                })
              ),
          },
          {
            id: 'po',
            title: 'Add PO',
            onClick: () =>
              history.push(
                getInvoiceManualUploadLink({
                  tab: 'manual',
                  dataType: 'PO',
                  currency: currencyCode,
                })
              ),
          },
          {
            id: 'hedge',
            title: 'Add hedge',
            onClick: () =>
              history.push(
                getInvoiceManualUploadLink({
                  tab: 'hedges',
                  buyCurrency: currencyCode,
                })
              ),
          },
        ]
      : []),
    {
      id: 'budgetRate',
      title: 'Add/edit budget rate',
      onClick: () => setUrlValue({ popupType: 'setBudgetRates' }),
    },
  ];

  return (
    <>
      <Row gap={theme.spacing.m} style={{ maxHeight: 20 }}>
        <PermissionsChecker action="create" resource="cashflows_manual">
          <ContextMenu list={list} strategy="fixed" portal />
        </PermissionsChecker>
      </Row>
    </>
  );
};
export default DateThreeDotsMenu;
