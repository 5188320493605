import styled from 'styled-components';
import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';

interface IOwnProps {
  spin: boolean;
}

export const StyledInlineLoader = styled.svg.attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.s,
    mtValue: mtValue || theme.spacing.s,
    mrValue: mrValue || theme.spacing.s,
    mlValue: mlValue || theme.spacing.s,
  })
)<IOwnProps>`
  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: ${({ spin }) => spin && 'loading 1.2s linear infinite'};

  ${spacingCss}
`;
