import styled, { css } from 'styled-components';
import { ResponsiveCenteredContainer } from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${ResponsiveCenteredContainer} {
      padding-top: 20px;
      flex: 0 1 100%;
      overflow: auto;
    }

    .field {
      margin-bottom: 14px;
    }

    .outcome {
      &-wrap {
        padding: 0 16px 0 16px;
        width: 100%;
        background: ${theme.color.greyLight_3};
        border-radius: 6px;
        position: relative;
        height: 52px;
        border: 1px solid ${theme.color.transparent};
        display: flex;
        align-items: center;
        font-weight: 500;

        svg {
          width: 24px;
          height: 24px;
          margin: -3px 8px 0 0;
        }

        &.focused {
          box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
        }
      }

      .hide-input {
        position: absolute;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
      }

      .hide-input,
      .input {
        margin: 0 5px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        max-width: 180px;
        min-width: 1px;

        &::placeholder {
          color: ${theme.color.transparent};
        }
        &:-ms-input-placeholder {
          color: ${theme.color.transparent};
        }
        &::-ms-input-placeholder {
          color: ${theme.color.transparent};
        }
      }
    }

    .row {
      justify-content: space-between;
      align-items: flex-start;

      p {
        padding: 0;
        min-height: 18px;

        &:not(:last-child) {
          margin-bottom: 4px !important;
        }
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }

    ${ResponsiveCenteredContainer} {
      padding: 24px 24px 0;
    }
  `
);
