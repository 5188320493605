import { useCallback, useEffect, useState } from 'react';
import {
  IOpenBankingInstitution,
  IOpenBankingPaymentAuthResponseData,
  IOpenBankingSettings,
} from 'types';
import * as FirebasePaymentInitiation from 'services/firebase/paymentInitiation';
import { errorHandler } from 'utils/errors';
import { useStoreState } from 'state';

const useOpenBankingPayments = ({
  fetchInstitutions,
  fetchOpenBankingSettings = true,
  fetchAuthResponse = true,
  assetId,
}: {
  fetchInstitutions: boolean;
  fetchOpenBankingSettings?: boolean;
  fetchAuthResponse?: boolean;
  assetId?: string;
}) => {
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const [institutions, setInstitutions] = useState<IOpenBankingInstitution[]>(
    []
  );
  const [
    selectedInstitution,
    setSelectedInstitution,
  ] = useState<IOpenBankingInstitution>();
  const [
    paymentAuthResponse,
    setPaymentAuthResponse,
  ] = useState<IOpenBankingPaymentAuthResponseData>();
  const [
    openBankingSettings,
    setOpenBankingSettings,
  ] = useState<IOpenBankingSettings>();
  const [isRedirected, setIsRedirected] = useState(false);
  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(false);
  const [isLoadingPaymentAuthUrl, setIsLoadingPaymentAuthUrl] = useState(false);
  const [
    isLoadingOpenBankingSettings,
    setIsLoadingOpenBankingSettings,
  ] = useState(false);

  // Takes institution id and swaps for auth url
  const onPaymentAuthorisationInstitutionSelect = useCallback(
    async (institution: IOpenBankingInstitution) => {
      if (!assetId) {
        return;
      }

      try {
        setIsLoadingPaymentAuthUrl(true);
        setSelectedInstitution(institution);

        const {
          data,
        } = await FirebasePaymentInitiation.postPaymentAuthorisation({
          assetId,
          institutionId: institution.id,
        });

        if (data.success && data.data?.authorisationUrl) {
          setPaymentAuthResponse(data.data);
        } else {
          errorHandler(data);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingPaymentAuthUrl(false);
      }
    },
    [assetId]
  );

  const onUnselectInstitution = useCallback(() => {
    setSelectedInstitution(undefined);
    setPaymentAuthResponse(undefined);
    setIsRedirected(false);
  }, []);

  useEffect(() => {
    const getInstitutions = async () => {
      try {
        setIsLoadingInstitutions(true);
        const {
          data,
        } = await FirebasePaymentInitiation.getPaymentsInstitutions();

        if (data.success && Array.isArray(data.data)) {
          setInstitutions(data.data);
        } else {
          errorHandler(data);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingInstitutions(false);
      }
    };
    if (fetchInstitutions) {
      getInstitutions();
    }
  }, [fetchInstitutions]);

  useEffect(() => {
    if (entityId) {
      const getOpenBankingSettingsHandler = async () => {
        try {
          setIsLoadingOpenBankingSettings(true);
          const {
            data,
          } = await FirebasePaymentInitiation.getOpenBankingSettings();

          setOpenBankingSettings(data.data);
          setIsLoadingOpenBankingSettings(false);
        } catch (error: any) {
          console.warn(error);
          setIsLoadingOpenBankingSettings(false);
        }
      };
      if (fetchOpenBankingSettings) {
        getOpenBankingSettingsHandler();
      }
    }
  }, [entityId, fetchOpenBankingSettings]);

  useEffect(() => {
    const getInstitution = async (institutionId: string) => {
      try {
        setIsLoadingOpenBankingSettings(true);
        const {
          data,
        } = await FirebasePaymentInitiation.getPaymentsInstitutionById(
          institutionId
        );

        if (data.success && data.data) {
          setSelectedInstitution(data.data);
        } else {
          errorHandler(data);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingOpenBankingSettings(false);
      }
    };
    if (openBankingSettings?.defaultInstitutionId) {
      getInstitution(openBankingSettings.defaultInstitutionId);
    }
  }, [openBankingSettings, onPaymentAuthorisationInstitutionSelect]);

  useEffect(() => {
    if (selectedInstitution && fetchAuthResponse) {
      onPaymentAuthorisationInstitutionSelect(selectedInstitution);
    }
  }, [
    onPaymentAuthorisationInstitutionSelect,
    selectedInstitution,
    fetchAuthResponse,
  ]);

  return {
    institutions,
    selectedInstitution,
    isRedirected,
    paymentAuthResponse,
    openBankingSettings,
    isLoadingInstitutions,
    isLoadingPaymentAuthUrl,
    isLoadingOpenBankingSettings,

    onPaymentAuthorisationInstitutionSelect,
    onUnselectInstitution,
    setIsRedirected,
    setSelectedInstitution,
    setOpenBankingSettings,
  };
};

export default useOpenBankingPayments;
