// TODO: rework this component and make logic more uniform, too many similar props now
import {
  AddContact,
  CancellingBalance,
  IntegrationReauthenticatePopup,
} from 'components';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { Dispatch, FC, SetStateAction } from 'react';
import { useStoreState } from 'state';
import {
  IContact,
  IInvoiceFromSearch,
  IRecipient,
  PartialContact,
  TRANSFER_TYPE,
} from 'types';
import PopupTracking from './PopupTracking/PopupTracking';
import AllocateFxForInvoices from './AllocateFxForInvoices/AllocateFxForInvoices';
import DecidePopup from './Decide';
import SelectExistingContact from './SelectExistingContact/SelectExistingContact';
import RemoveExistingPrebook from './RemoveExistingPrebook/RemoveExistingPrebook';
import TransferInner from 'components/shared/TransferInner/TransferInner';
import { updateInvoice } from 'services/firebase/invoices';
import { updateInvoicesBasedOnBackendUpdate } from './SelectExistingContact/utils';
import {
  Notify,
  getInitialRecipientTransferType,
  getRecipientsLinkableToInvoice,
} from 'utils';
import useInvoiceRecipientPrePopulatedValues from './hooks/useInvoiceRecipientPrePopulatedValues';
import DeleteManualInvoice from './DeleteManualInvoice/DeleteManualInvoice';

interface OwnProps {
  changeTargetInvoice: IInvoiceFromSearch | null;
  setChangeTargetInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  existingInvoiceTracking: IInvoiceFromSearch | null;
  setExistingInvoiceTracking: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  invoiceDecide: IInvoiceFromSearch | null;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  showAllDecideFields: boolean;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  cancelPrebookInvoice: IInvoiceFromSearch | null;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  invoicesForAllocateFx: IInvoiceFromSearch[];
  setInvoicesForAllocateFx: Dispatch<SetStateAction<IInvoiceFromSearch[]>>;
  deleteManualInvoice: IInvoiceFromSearch | null;
  setDeleteManualInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  removeExistingPrebookInvoice: IInvoiceFromSearch | null;
  setRemoveExistingPrebookInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  contactForEdit: IContact | PartialContact | null;
  setContactForEdit: Dispatch<SetStateAction<IContact | PartialContact | null>>;
  invoiceForAddContact: IInvoiceFromSearch | null;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  transferIdToShowPaymentDetails: string | null;
  setTransferIdToShowPaymentDetails: Dispatch<SetStateAction<string | null>>;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
}

const Popups: FC<OwnProps> = ({
  changeTargetInvoice,
  setChangeTargetInvoice,
  existingInvoiceTracking,
  setExistingInvoiceTracking,
  invoiceDecide,
  setInvoiceDecide,
  showAllDecideFields,
  setShowAllDecideFields,
  cancelPrebookInvoice,
  setCancelPrebookInvoice,
  invoicesForAllocateFx,
  setInvoicesForAllocateFx,
  deleteManualInvoice,
  setDeleteManualInvoice,
  removeExistingPrebookInvoice,
  setRemoveExistingPrebookInvoice,
  contactForEdit,
  setContactForEdit,
  invoiceForAddContact,
  transferIdToShowPaymentDetails,
  setTransferIdToShowPaymentDetails,
  setInvoiceForAddContact,
  updateInMemoryInvoices,
}) => {
  const { reauthenticateIntegration } = useIntegrationEngine();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const initialCurrency = currencyByCode(contactForEdit?.currency);
  const sellCurrency = currencyByCode(systemVariables?.defaultSellCurrency);
  const { recipients } = useStoreState((state) => state.RecipientsState);
  const initialCurrencyFromInvoice = currencyByCode(
    invoiceForAddContact?.currency
  );
  const recipientsToShow = getRecipientsLinkableToInvoice(
    recipients,
    invoiceForAddContact?.externalRefsSourceSystemContactId,
    invoiceForAddContact?.currency
  );
  const {
    recipientPrePopulatedValues,
    isLoadingContact,
    resetPrePopulatedValues,
  } = useInvoiceRecipientPrePopulatedValues(invoiceForAddContact);

  const onAddContactToInvoice = async (recipient: IRecipient) => {
    try {
      if (!invoiceForAddContact) {
        return;
      }

      const response = await updateInvoice({
        id: invoiceForAddContact.id,
        data: {
          contactId: recipient.id,
        },
        updateForSameContact: true,
        transformToElastic: true,
      });

      if (response.data.success && response.data.data) {
        updateInMemoryInvoices(
          updateInvoicesBasedOnBackendUpdate(response.data.data)
        );
      }

      setInvoiceForAddContact(null);
    } catch (error: any) {
      Notify.error(`Failed to add contact to invoice. Error: ${error}`);
    }
  };

  return (
    <>
      {!!contactForEdit && initialCurrency && sellCurrency && (
        <AddContact
          onClose={() => {
            setContactForEdit(null);
          }}
          withDraftWarning
          recipientForEdit={contactForEdit}
          initialCurrency={initialCurrency}
          sellCurrency={sellCurrency}
          initialTransferType={
            contactForEdit.paymentType && contactForEdit.paymentType === 'swift'
              ? TRANSFER_TYPE.priority
              : TRANSFER_TYPE.regular
          }
          onContinue={() => {
            setContactForEdit(null);
          }}
        />
      )}

      {!!invoiceForAddContact &&
        !isLoadingContact &&
        !recipientsToShow.length &&
        initialCurrencyFromInvoice &&
        sellCurrency && (
          <AddContact
            onClose={() => {
              setInvoiceForAddContact(null);
              resetPrePopulatedValues();
            }}
            disableCurrency
            withSaveAsDraft={false}
            sellCurrency={sellCurrency}
            recipientForEdit={recipientPrePopulatedValues}
            initialCurrency={initialCurrencyFromInvoice}
            initialTransferType={getInitialRecipientTransferType(
              recipientPrePopulatedValues
            )}
            setRecipient={onAddContactToInvoice}
            onContinue={() => {
              resetPrePopulatedValues();
              setInvoiceForAddContact(null);
            }}
          />
        )}
      <IntegrationReauthenticatePopup />
      {existingInvoiceTracking &&
        !changeTargetInvoice &&
        !reauthenticateIntegration && (
          <PopupTracking
            invoice={existingInvoiceTracking}
            onChangeTarget={setChangeTargetInvoice}
            onClose={() => setExistingInvoiceTracking(null)}
          />
        )}
      {changeTargetInvoice && !reauthenticateIntegration && (
        <DecidePopup
          invoice={changeTargetInvoice}
          isEdit
          onClose={() => setChangeTargetInvoice(null)}
        />
      )}
      {invoiceDecide && (
        <DecidePopup
          invoice={invoiceDecide}
          onClose={() => {
            setInvoiceDecide(null);
            setShowAllDecideFields(false);
          }}
          showAllFields={showAllDecideFields}
          setShowAllDecideFields={setShowAllDecideFields}
        />
      )}
      {cancelPrebookInvoice && cancelPrebookInvoice.contractId && (
        <CancellingBalance onClose={() => setCancelPrebookInvoice(null)} />
      )}

      {invoicesForAllocateFx.length > 0 && (
        <AllocateFxForInvoices
          invoices={invoicesForAllocateFx}
          sellCurrency={entityCurrencyCode}
          updateInMemoryInvoices={updateInMemoryInvoices}
          onClose={() => setInvoicesForAllocateFx([])}
        />
      )}

      {!!removeExistingPrebookInvoice && (
        <RemoveExistingPrebook
          invoice={removeExistingPrebookInvoice}
          updateInMemoryInvoices={updateInMemoryInvoices}
          onClose={() => setRemoveExistingPrebookInvoice(null)}
        />
      )}

      {!!transferIdToShowPaymentDetails && (
        <TransferInner
          transferId={transferIdToShowPaymentDetails}
          onClose={() => setTransferIdToShowPaymentDetails(null)}
        />
      )}

      {!!invoiceForAddContact && !!recipientsToShow.length && (
        <SelectExistingContact
          invoice={invoiceForAddContact}
          updateInMemoryInvoices={updateInMemoryInvoices}
          onClose={() => setInvoiceForAddContact(null)}
        />
      )}

      {!!deleteManualInvoice && (
        <DeleteManualInvoice
          invoice={deleteManualInvoice}
          updateInMemoryInvoices={updateInMemoryInvoices}
          onClose={() => setDeleteManualInvoice(null)}
        />
      )}
    </>
  );
};

export default Popups;
