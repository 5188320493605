import { FC } from 'react';
import { ITableFilter, ITableFilterOption } from '../../types';
import Filter from './components/Filter/Filter';

interface OwnProps {
  filters: ITableFilter[];
  setFilter: (filterId: string, filterValue: ITableFilterOption) => void;
}

const Controls: FC<OwnProps> = ({ filters, setFilter }) => (
  <>
    {filters.map((filter) => (
      <Filter key={filter.id} filter={filter} setFilter={setFilter} />
    ))}
  </>
);

export default Controls;
