import { Row, StaleInfo, Title } from 'components';
import Card from 'components/shared/Card/Card.styles';
import { FC } from 'react';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { parseIntoShortNumberWithSymbol } from 'utils';

interface IOwnProps {
  value: number;
  currencyCode: string;
}

const CashBalance: FC<IOwnProps> = ({ value, currencyCode }) => {
  const theme = useTheme();
  const currencyByCode = useStoreState(
    ({ CurrenciesState }) => CurrenciesState.currencyByCode
  );

  return (
    <Card
      padding={`${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.xxs}`}
    >
      <Row justifyContent="flex-start" mb mbValue={theme.spacing.s}>
        <Title variant="h5" mr>
          Cash Balance
        </Title>
        <StaleInfo mode="hover">
          {`Current value of your bank balances across currencies in ${currencyCode}.`}
        </StaleInfo>
      </Row>

      <Title mb mbValue={theme.spacing.l}>
        {parseIntoShortNumberWithSymbol(
          value,
          currencyByCode(currencyCode)?.symbol
        )}
      </Title>
    </Card>
  );
};

export default CashBalance;
