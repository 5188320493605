import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { ICashflowsSummary } from 'types/cashflows';
import { openQuery } from 'utils';
import firestore from './firestore';
import { ISearchResponse } from 'types/search';

interface SubscribeToCashflowSummariesParams {
  entityId: string;
  callback: (cashflowSummaries: ICashflowsSummary[]) => void;
}

export const subscribeToCashflowSummaries = ({
  entityId,
  callback,
}: SubscribeToCashflowSummariesParams) => {
  return firestore
    .collection('entities')
    .doc(entityId)
    .collection('cashflowsSummary')
    .onSnapshot(
      (query) => callback(openQuery(query)),
      (error) =>
        console.warn(
          'Failed to subscribe to cashflow summaries. Error: ',
          error
        )
    );
};

export const refreshCashflowSummaries = async (entityId: string) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `cashflows/refresh/${entityId}`
  );
};

export const createCustomCashflow = async (payload: {
  currency: string;
  amount: number;
  date: string;
  cashflowDefinitionId: string;
}) => AxiosPrivateFirebaseInstance.post<IResponse>(`cashflows/custom`, payload);

export const updateCustomCashflow = async ({
  id,
  data,
}: {
  id: string;
  data: { amount?: number; excludeFromRisk?: boolean };
}) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(`cashflows/custom/${id}`, data);

export enum CASHFLOW_TYPE_NEW {
  hedge = 'hedge',
  externalHedge = 'externalHedge',
  balance = 'balance',
  externalBalance = 'externalBalance',
  salesOrder = 'salesOrder',
  purchaseOrder = 'purchaseOrder',
  payableInvoice = 'payableInvoice',
  receivableInvoice = 'receivableInvoice',
  custom = 'custom',
}

enum CASHFLOW_STATUS {
  live = 'live',
  excluded = 'excluded',
  voided = 'voided',
  used = 'used',
}

export interface ICashflowFromSearch {
  amount: number;
  creationDate: string;
  direction: 'in' | 'out';
  currency: string;
  dueDate: string;
  externalRefsSourceSystemId: string;
  externalRefsSourceSystemRef: string;
  id: string;
  owner: string;
  status: CASHFLOW_STATUS;
  type: CASHFLOW_TYPE_NEW;
  counterParty?: string;
  contractId?: string;
  excludeFromRisk?: boolean;
}

export const getCashflowsPaginated = async (query: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<ISearchResponse<ICashflowFromSearch>>
  >(`/cashflows${query}`);

export const refreshCashflowFromCsv = async () => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `cashflows/custom/sync-spreadsheet`
  );
};

export const isEntityHasCashflows = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<boolean>>(
    `/cashflows/check/records/exist`
  );

export const getCurrenciesFromEntityCashflows = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(
    `/cashflows/currencies`
  );

export const uploadFileWithCustomCashflows = async (formData: FormData) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/cashflows/custom/upload`,
    formData
  );
