import { FC } from 'react';
import { Row, Sidebar } from 'components';
import {
  SidebarPageTemplateContent,
  SidebarPageTemplateHeader,
} from './SidebarPageTemplate.styles';
import { PageHeader } from 'components/shared/PageHeader/PageHeader';
import useDeviceWidth from 'hooks/useDeviceWidth';
import SidebarMobile from './components/SidebarMobile/SidebarMobile';
import { useLocation } from 'react-router';

const HIDE_ICON_LABEL_PATHNAMES = [
  '/app/dashboard',
  '/app/my-clients',
  '/app/settings',
];
const TITLE_PER_PATHNAME = {
  '/app/dashboard': 'Dashboard',
  '/app/my-clients': 'My Clients',
  '/app/switch-clients': 'Switch Client',
  '/app/reports': 'FX Gains & Losses breakdown',
  '/app/settings': 'Settings',
  '/app/risks': 'Cashflows & Risks',
  '/app/fx-trades': 'FX Trades',
  '/app/transfers': 'Transfers',
  // TODO: remove manual upload from invoices sub-route, make it a standalone route
  '/app/invoices/manual-upload': 'Manual Upload',
};
const WITH_BREADCRUMBS_PATHNAMES = [
  '/app/invoices',
  '/app/invoices/payment-run',
  '/app/invoices/prebookings',
  '/app/risks/reduce-recommendations',
  '/app/invoice-details',
];
const WITH_PROFILE_NAV_PATHNAMES = [
  '/app/contacts',
  '/app/dashboard',
  '/app/invoices',
  '/app/my-clients',
  '/app/switch-clients',
  '/app/invoices/payment-run',
  '/app/invoices/prebookings',
  '/app/risks',
  '/app/risks/reduce-recommendations',
  '/app/reports',
  '/app/settings',
];

const SidebarPageTemplate: FC = ({ children }) => {
  const { isMobile } = useDeviceWidth();
  const { pathname } = useLocation();
  const withBreadCrumbs = WITH_BREADCRUMBS_PATHNAMES.includes(pathname);
  const hideIconLabels = HIDE_ICON_LABEL_PATHNAMES.includes(pathname);
  const showFreeTrialEndingNotification = pathname === '/app/dashboard';
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const title = TITLE_PER_PATHNAME[pathname];

  return (
    <Row flex={1}>
      {!isMobile && <Sidebar hideIconLabels={hideIconLabels} />}

      <SidebarPageTemplateContent>
        {(title || withBreadCrumbs) && (
          <SidebarPageTemplateHeader>
            {isMobile && <SidebarMobile />}
            <PageHeader
              title={title}
              withBreadCrumbs={withBreadCrumbs}
              withProfileNav={WITH_PROFILE_NAV_PATHNAMES.includes(pathname)}
              showFreeTrialEndingNotification={showFreeTrialEndingNotification}
            />
          </SidebarPageTemplateHeader>
        )}
        {children}
      </SidebarPageTemplateContent>
    </Row>
  );
};

export default SidebarPageTemplate;
