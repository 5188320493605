import styled, { css } from 'styled-components';

export const Input = styled.input<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    height: 0;
    width: 0;
    opacity: 0;

    &:checked {
      & + label {
        background: ${disabled
          ? theme.color.emeraldLight
          : theme.color.emeraldDark};

        &:before {
          transform: translateX(22px);
        }
      }

      &:focus,
      &:active {
        & + label {
          box-shadow: 0 0 0 2px ${theme.color.grey};
        }
      }
    }

    &:focus,
    &:active {
      & + label {
        box-shadow: 0 0 0 2px ${theme.color.emeraldDark};
      }
    }
  `
);

export const Label = styled.label<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 46px;
    min-width: 46px;
    height: 24px;
    background: ${disabled ? theme.color.greyLight_1 : theme.color.grey};
    border-radius: 16px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      top: 2px;
      left: 2px;
      bottom: 2px;
      border-radius: 16px;
      transition: ${theme.transition};
      background: ${theme.color.white};
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }
  `
);
