import { IExternalHedge } from 'types/externalHedges';
import db from 'services/firestore';
import { openQueryWithTimestamp } from 'utils';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IManualExternalHedge } from 'pages/ManualUpload/hooks/useExternalHedgesUpload';
import { IResponse } from 'types';

export interface SubscribeToExternalHedgesParams {
  entityId: string;
  callback: (hedges: IExternalHedge[]) => void;
}

export const subscribeToExternalHedges = ({
  entityId,
  callback,
}: SubscribeToExternalHedgesParams) => {
  return db
    .collection('externalHedges')
    .where('_owner', '==', entityId)
    .orderBy('_created')
    .onSnapshot(
      (query) => {
        callback(openQueryWithTimestamp(query));
      },
      (error) =>
        console.log('Failed to subscribe to externalHedges. Error: ', error)
    );
};

export const updateExternalHedge = async ({
  id,
  data,
}: {
  id: string;
  data: {
    excludeFromRisk?: boolean;
  };
}) => AxiosPrivateFirebaseInstance.put(`/external_hedges/${id}`, data);

export interface IManualExternalHedgesToUpload extends IManualExternalHedge {
  externalHedgeId?: string;
}

export const bulkHedgesUpload = async (
  payload?: IManualExternalHedgesToUpload[]
) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/external_hedges/import`,
    payload
  );
};

export const bulkHedgesUpdate = async (
  payload?: IManualExternalHedgesToUpload[]
) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    `/external_hedges/import`,
    payload
  );
};
