import { useState, FC, useEffect } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ChangePassword } from './components';
import { IntegrationSettings, Loader, Row, Col } from 'components';
import { SETTINGS_TABS } from './consts';
import { WhiteCenteredContainer } from 'components/shared/WhiteCenteredContainer/WhiteCenteredContainer.styles';
import { WhiteHeaderContainer } from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import { useStoreState } from 'state';
import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import RiskSettings from './components/RiskSettings/RiskSettings';
import GeneralSettings from './components/GeneralSettings/GeneralSettings';
import TeamSettings from './components/TeamSettings/TeamSettings';
import ApprovaLSettings from './components/ApprovalSettings/ApprovalSettings';
import Packages from './components/Packages/Packages';
import Subscriptions from './components/Subscriptions/Subscriptions';
import OpenBankingSettings from './components/OpenBankingSettings/OpenBankingSettings';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import { useQuery } from 'hooks';

const Settings: FC<RouteComponentProps> = () => {
  const theme = useTheme();
  const history = useHistory();
  const URLQuery = useQuery();
  const tabFromQuery = URLQuery.get('tab');
  const { url } = useRouteMatch();
  const [showChangePassword, setShowChangePassword] = useState(false);

  const { isAutomationPackageEnabled } = useStoreState(
    ({ UserState }) => UserState
  );

  const { userEntity, user } = useStoreState((state) => state.UserState);
  const [selectedTab, setSelectedTab] = useState(
    tabFromQuery ?? SETTINGS_TABS[0].id
  );

  const settingsTabsToUse = SETTINGS_TABS.filter(
    // filter packages if user is not super admin
    (tab) => !!user?.isSuperAdmin || tab.id !== 'packages'
  ).filter(
    // filter approval settings if automation package is not enabled
    (tab) => !!isAutomationPackageEnabled || tab.id !== 'approval'
  );

  useEffect(() => {
    if (tabFromQuery !== selectedTab) {
      URLQuery.set('tab', selectedTab);
      history.push(`${url}?${URLQuery.toString()}`);
    }
  }, [selectedTab, URLQuery, url, history, tabFromQuery]);

  const renderSettingsContent = (tab: any) => {
    switch (tab) {
      case 'general':
        return (
          <GeneralSettings setShowChangePassword={setShowChangePassword} />
        );
      case 'approval':
        return <ApprovaLSettings />;
      case 'bankAccounts':
        return !!userEntity ? <OpenBankingSettings /> : <Loader />;
      case 'notifications':
        return !!userEntity ? <NotificationSettings /> : <Loader />;
      case 'risk':
        return !!userEntity ? <RiskSettings /> : <Loader />;
      case 'integrations':
        return <IntegrationSettings />;
      case 'packages':
        return <Packages />;
      case 'subscriptions':
        return <Subscriptions />;
      case 'team':
        return <TeamSettings />;
      default:
        return null;
    }
  };

  return (
    <>
      <WhiteCenteredContainer fullWidth>
        <WhiteHeaderContainer>
          <Row
            flexWrap="wrap"
            rowGap={theme.spacing.xs}
            justifyContent="flex-start"
          >
            {settingsTabsToUse.map(({ title, id }) => (
              <StepperItem
                key={id}
                current={id === selectedTab}
                onClick={() => setSelectedTab(id)}
              >
                {title}
              </StepperItem>
            ))}
          </Row>
        </WhiteHeaderContainer>
        <Col>{renderSettingsContent(selectedTab)}</Col>
      </WhiteCenteredContainer>

      {showChangePassword && (
        <ChangePassword setShowChangePassword={setShowChangePassword} />
      )}
    </>
  );
};

export default Settings;
