import { AxiosPrivateFirebaseInstance } from 'settings';
import {
  IFollowedCurrencyPair,
  IResponse,
  UpdateFollowedCurrencyParams,
} from 'types';
import { openQuery, openQueryWithTimestamp } from 'utils';
import db from 'services/firestore';
import { errorHandler } from 'utils/errors';

export const getCurrencies = async () => {
  try {
    const data = await db
      .collection('referenceData')
      .doc('collections')
      .collection('currencies')
      .where('enabled', '==', true)
      .get();

    if (data) {
      return openQuery(data);
    }
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface SubscribeToFollowedCurrenciesParams {
  uid: string;
  callback: (transfers: IFollowedCurrencyPair[]) => void;
}

export const subscribeToFollowedCurrencies = ({
  uid,
  callback,
}: SubscribeToFollowedCurrenciesParams) =>
  db
    .collection('users')
    .doc(uid)
    .collection('followedCurrencyPairs')
    .onSnapshot((query) => callback(openQueryWithTimestamp(query)));

export const addFollowedCurrencyPair = async (
  params: Omit<IFollowedCurrencyPair, 'id'>
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      '/followed_currencies',
      params
    );

    return data;
  } catch (error: any) {
    errorHandler(error);
  }
};

export const deleteFollowedCurrencyPair = async (
  followedCurrencyId: IFollowedCurrencyPair['id']
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.delete<IResponse>(
      `/followed_currencies/${followedCurrencyId}`
    );

    return data;
  } catch (error: any) {
    errorHandler(error);
  }
};

export const updateFollowedCurrencyPair = async ({
  followedCurrencyData,
  followedCurrencyId,
}: {
  followedCurrencyId: IFollowedCurrencyPair['id'];
  followedCurrencyData: UpdateFollowedCurrencyParams;
}) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.put<IResponse>(
      `/followed_currencies/${followedCurrencyId}`,
      followedCurrencyData
    );

    return data;
  } catch (error: any) {
    errorHandler(error);
  }
};
