import { FC, useState, useEffect, useMemo } from 'react';
import Select, { components } from 'react-select';

import { Wrapper } from './StaleSelectMenu.styles';
import SingleValue from './StaleSingleValue';
import MultiValueLabel from './StaleMultiValueLabel';
import DropdownIndicator from './StaleDropdownIndicator';
import { Paragraph } from 'components';
import { useDebounce } from 'hooks';
import * as Postcodes from 'services/postcodes';
import { Notify } from 'utils';

const StaleSelectLocation: FC<any> = ({
  id,
  name,
  label,
  isMulti,
  defaultIsOpen = false,
  isDisabled = false,
  placeholder = '',
  value: selectValue,
  onChange = () => {},
  tabSelectsValue = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [isFilled, setIsFilled] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const debounceSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debounceSearchValue) {
      Postcodes.searchAddressSuggestions(
        debounceSearchValue
      ).then((suggestions) => setSuggestions(suggestions));
    }
  }, [debounceSearchValue]);

  useEffect(() => {
    if (selectValue?.label) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [selectValue]);

  const handleInput = (value: any) => {
    setSearchValue(value);
  };

  const handleSelect = async (selectedItem: any) => {
    if (!selectedItem) {
      onChange({
        label: '',
        value: null,
        dataForPaste: {
          city: '',
          country: '',
          postalCode: '',
          stateOrProvince: '',
          addressLine1: '',
          addressLine2: '',
        },
      });
      return;
    }

    try {
      const { value } = selectedItem;

      setSearchValue('');
      setSuggestions([]);

      const details = await Postcodes.lookupUdprn(value);

      onChange({
        label: `${details.line_1}, ${details.postcode} ${details.post_town} ${details.country}`,
        value: details,
        dataForPaste: {
          city: details.post_town,
          country: details.country,
          postalCode: details.postcode,
          stateOrProvince: details.postal_county,
          addressLine1: details.line_1,
          addressLine2: details.line_2,
        },
      });
    } catch (error: any) {
      Notify.error(error.message);
    }
  };

  const options = useMemo(
    () =>
      suggestions.map((item) => ({
        label: item.suggestion,
        value: item.udprn,
        content: <Paragraph>{item.suggestion}</Paragraph>,
      })),
    [suggestions]
  );

  return (
    <Wrapper
      open={isOpen}
      filled={isFilled}
      multi={isMulti}
      disabled={isDisabled}
    >
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}

      <Select
        filterOption={() => true}
        value={selectValue}
        inputValue={searchValue}
        onInputChange={handleInput}
        onBlur={() => setSuggestions([])}
        onChange={handleSelect}
        placeholder={placeholder}
        name={name}
        menuIsOpen={isOpen}
        options={options}
        classNamePrefix="select"
        className="select"
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => {
          setIsOpen(false);
          setSuggestions([]);
        }}
        closeMenuOnSelect={!isMulti}
        isDisabled={isDisabled}
        isClearable
        isSearchable
        tabSelectsValue={tabSelectsValue}
        components={{
          Option: (props) => (
            <components.Option {...props}>
              {props.data.content}
            </components.Option>
          ),
          SingleValue,
          MultiValueLabel,
          DropdownIndicator,
        }}
      />
    </Wrapper>
  );
};

export default StaleSelectLocation;
