import { IGroupedInvoicesFromSearch, IRecipient, PartialContact } from 'types';
import { extractUnsavedRecipientFromIInvoiceFromSearch } from 'components/shared/InvoicesTable/utils';

export const getPartialRecipientFromGroupedInvoicesFromSearch = ({
  invoices,
  recipientByName,
  recipientName,
}: {
  recipientByName: (recipientName?: string) => IRecipient | undefined;
  invoices: IGroupedInvoicesFromSearch['results'];
  recipientName: string;
}) => {
  /**
   * recipientByName returns recipient | undefined,
   * and extractUnsavedRecipientFromIInvoiceFromSearch returns a small subset,
   * So we use PartialContact which we need to use as ContactCellNew requires checks on `enabled` and `id` properties,
   * and other components like AddContact require different fields.
   */
  const existingRecipient:
    | IRecipient
    | PartialContact
    | undefined = recipientByName(recipientName);

  let recipientToUse = existingRecipient;

  if (!recipientToUse && invoices?.[0]?.contactRecipientName) {
    recipientToUse = extractUnsavedRecipientFromIInvoiceFromSearch(invoices[0]);
  }

  return recipientToUse;
};
