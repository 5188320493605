import Icon from 'components/shared/Icon/Icon';
import StaleInfo from 'components/shared/StaleInfo/StaleInfo';
import { Paragraph } from 'components/shared/Typography/Typography';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IInvoiceFromSearch } from 'types';
import { parseRateWithPrecision } from 'utils';

interface OwnProps {
  record: IInvoiceFromSearch;
}

const StatusIconCell: FC<OwnProps> = ({ record }) => {
  const theme = useTheme();

  return (
    <>
      {record.trackingId && (
        <Icon icon="eye-table-ico" fill={theme.color.blue} />
      )}

      {record.contractId && (
        <StaleInfo
          mode="hover"
          strategy="fixed"
          placement="top"
          portal
          trigger={<Icon icon="lock-ico" fill={theme.color.coral} />}
          disabled={!record.prebookRate}
        >
          <Paragraph color="white">
            Prebooked rate: {parseRateWithPrecision(record.prebookRate)}
          </Paragraph>
        </StaleInfo>
      )}
    </>
  );
};

export default StatusIconCell;
