import { FC } from 'react';
import { Icon, Paragraph, Row } from 'components';
import { useTheme } from 'styled-components';
import { IContact } from 'types';

interface OwnProps {
  record: IContact;
}

const EmailCell: FC<OwnProps> = ({ record }) => {
  const theme = useTheme();

  return (
    <Row gap={theme.spacing.xs}>
      {record.shouldSendRemittance && (
        <Icon fill={theme.color.emeraldDark} icon="tick-ico" />
      )}
      <Paragraph>{record.contactEmail || '-'}</Paragraph>
    </Row>
  );
};

export default EmailCell;
