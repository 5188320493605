import { Col, Row } from 'components';
import styled from 'styled-components';

export const TopContentWrapper = styled(Row)`
  justify-content: flex-start;
  min-height: 374px;
`;

export const BottomContentWrapper = styled(Col)`
  justify-content: flex-start;
  /* workaround for making sure virtualized table inside this wrapper takes correct height */
  min-height: 0;
  flex: 1;
  position: relative;
`;
